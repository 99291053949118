import { Kuva } from "../../interfaces/kuvaweb";
import { ApiClientBase } from "../apiClientBase";

type ParseBundleOrderResponseModel = Kuva.PL.KuvaWeb.Areas.BundleAgent.Models.ParseBundleOrderResponseModel;
type BundleOrderShortModel = Kuva.PL.KuvaWeb.Areas.BundleAgent.Models.BundleOrderShortModel;
type BundleOrderModel = Kuva.PL.KuvaWeb.Areas.BundleAgent.Models.BundleOrderModel;
type CreateBundleOrderResponseModel = Kuva.PL.KuvaWeb.Areas.BundleAgent.Models.CreateBundleOrderResponseModel;
type BundleOrderApiClientInterface = Kuva.PL.KuvaWeb.Areas.BundleAgent.Controllers.BundleOrderApiClientInterface;
type PaidStatus = Kuva.Module.Enums.BundleOrderPaymentStatus;
type CashRailFlowType = Kuva.Module.Enums.CashRailFlowType;
type BankDetails = Kuva.PL.KuvaWeb.Areas.BundleAgent.Models.BankDetails;

export class BundleOrderApiClient extends ApiClientBase implements BundleOrderApiClientInterface {
    base = "bundle-order";

    sendCsvForParse = (csv: FormData) => this.postFile<ParseBundleOrderResponseModel>([this.base, "parse-csv-order"], csv, null);
    createOrder = (model: BundleOrderModel) => this.postNewUrl<BundleOrderModel, CreateBundleOrderResponseModel>([this.base], model, null);
    getOrders = (tab: 'new' | 'processing' | 'ready' | 'delivered' | 'completed') => this.getNewUrl<BundleOrderShortModel[]>([this.base], { orderStatus: tab });
    getOrderDetails = (id: number) => this.getNewUrl<BundleOrderModel>([this.base, `${id}`]);
    changePaymentStatus = (id: number, status: PaidStatus) => this.putNewUrl<PaidStatus, null>([this.base, `${id}/set-paid-status`], status, null);
    getBankDetails = (id: number) => this.getNewUrl<BankDetails>([this.base, `${id}/bank-details`]);
}