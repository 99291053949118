import * as signalR from '@microsoft/signalr'
import * as url from '../utils/url'
import { BehaviorSubject } from 'rxjs'
import { Kuva } from '../interfaces/kuvaweb'

type AgentCashOnDeliveryRequestModel =
  Kuva.PL.KuvaWeb.Areas.Agent.Models.AgentCashOnDeliveryRequestModel
import OrderStatus = Kuva.Module.Enums.OrderStatus
import PaymentMethod = Kuva.Module.Enums.PaymentMethod
import { CashOnDeliveryApiClient } from '../api/agent/cashOnDeliveryApiClient'

export class CashOnDeliveryManager {
  private static _instance: CashOnDeliveryManager
  static get instance() {
    return (this._instance = new CashOnDeliveryManager())
  }

  private bookedRequestsSubject = new BehaviorSubject<AgentCashOnDeliveryRequestModel[]>(null)
  public get bookedRequests() {
    return this.bookedRequestsSubject.asObservable()
  }

  private bookedRequestsCountSubject = new BehaviorSubject<number>(null)
  public get bookedRequestsCount() {
    return this.bookedRequestsCountSubject.asObservable()
  }

  private _bookedRequests: AgentCashOnDeliveryRequestModel[] = null

  private api = new CashOnDeliveryApiClient()

  constructor() {
    this.load()
    this.connect()
  }

  protected async load(): Promise<void> {
    try {
      this._bookedRequests = await this.getRequests()
    } catch (error) {
      this._bookedRequests = []
    }
    this.publish()
  }

  protected async getRequests() {
    const result = await this.api.getAgentBookedCashOnDelivery()
    const uniqueItems = result.filter(
      (item, index) => result.findIndex(dataItem => item.id === dataItem.id) === index
    )

    return uniqueItems
  }

  protected async connect(): Promise<void> {
    const hubConnection = new signalR.HubConnectionBuilder()
      .withUrl(url.combine(window.configData.apiUrl, 'api/v1/agents/events'))
      .withAutomaticReconnect()
      .build()
    hubConnection.on('cashOnDeliveryUpdated', e => {
      this.handleRequestUpdated(e)
    })
    await hubConnection.start()
  }

  protected handleRequestUpdated(request: AgentCashOnDeliveryRequestModel): void {
    if (this._bookedRequests == null) return
    const currentIndex = this._bookedRequests.findIndex(req => req.id === this.id(request))
    if (currentIndex !== -1) {
      if (!this.filter(request)) {
        const newCollection = [...this._bookedRequests]
        newCollection.splice(currentIndex, 1)
        this._bookedRequests = newCollection
      }
      this.publish()
    } else {
      if (this.filter(request)) {
        request.isNew = true
        this._bookedRequests = [request, ...this._bookedRequests]
      }
      this.publish()
    }
  }

  private publish() {
    this.bookedRequestsSubject.next(this._bookedRequests)
    this.bookedRequestsCountSubject.next(this._bookedRequests?.length)
  }

  protected id(request: AgentCashOnDeliveryRequestModel): string {
    return request.id
  }

  protected filter(request: AgentCashOnDeliveryRequestModel): boolean {
    return (
      request.status !== OrderStatus.Complete &&
      request.paymentMethod == PaymentMethod.CashOnDelivery
    )
  }
}
