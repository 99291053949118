﻿import { t } from "i18next";
import { observer } from "mobx-react";
import React from 'react';

import { PageHeader } from "../../components/pageHeader";
import { TopupForm } from "../../components/topups/topupForm";
import { TopupsList } from "../../components/topups/topupsList";

import { CashTopupDetailsViewModel } from "../../viewModels/cashTopup/cashTopupDetailsViewModel";
import { CashTopupFormViewModel } from "../../viewModels/cashTopup/cashTopupFormViewModel";
import { CashTopupProcessViewModel } from "../../viewModels/cashTopup/cashTopupProcessViewModel";
import { CashTopupsViewModel } from "../../viewModels/cashTopup/cashTopupsViewModel";

import { CashTopupDetailsDialogue } from "./cashTopupDetailsDialogue";
import { CashTopupProcessDialogue } from "./cashTopupProcessDialogue";

@observer
export class CashTopupsPage extends React.Component {
  listViewModel = new CashTopupsViewModel();
  formViewModel = new CashTopupFormViewModel();

  componentDidMount() {
    this.listViewModel.mount();
  }

  componentWillUnmount() {
    this.listViewModel.unmount();
  }

  render() {
    return <div className="page-topup">
      <PageHeader>
        {t("topup.cash.title")}
      </PageHeader>
      <div className="container">
        {this.formViewModel.process != null ? <CashTopupProcessDialogue viewModel={this.formViewModel.process as CashTopupProcessViewModel} /> : null}
        {this.listViewModel.details != null ? <CashTopupDetailsDialogue viewModel={this.listViewModel.details as CashTopupDetailsViewModel} /> : null}
        <TopupForm viewModel={this.formViewModel} />
        <TopupsList viewModel={this.listViewModel} />
      </div>
    </div>
  }
}