import { t } from "i18next";
import { observer } from "mobx-react"
import React from "react"
import { PageHeader } from "../../components/pageHeader";
import { Button, Table } from "react-bootstrap";
import { lang } from '../../utils/lang'
import { SearchInput } from "../../components/searchInput";
import * as fmt from '../../utils/formatting';
import BundleCashrailDetailsDialogue from "./bundleCashrailDetailsDialogue";
import BundleAgentOrderDetailsViewModel from "../../viewModels/cashrail/bundleAgentOrderDetailsViewModel";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { Kuva } from "../../interfaces/kuvaweb";
import { Icon } from "../../components/icons";

const FlowType = Kuva.Module.Enums.CashRailFlowType;
const PaymentStatus = Kuva.Module.Enums.BundleOrderPaymentStatus;

@observer
export class BundleCashrailDetailsPage extends React.Component<RouteComponentProps> {
    params = new URLSearchParams(window.location.search)
    id = this.params.get('id');
    orderId = parseInt(this.id);
    modalShow = false;

    viewModel = new BundleAgentOrderDetailsViewModel(this.orderId);

    componentDidMount() {
        this.viewModel.mount();
    }

    componentWillUnmount() {
        this.viewModel.unmount();
    }

    onHide = () => {
        this.modalShow = !this.modalShow;
    }

    handleRedirect = () => {
        this.props.history.push('/ba-cashrail');
    }

    hanldeSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        this.viewModel.search = e.target.value;
        this.viewModel.updateFilter();
    }

    onModalLinkClick = () => {
        this.viewModel.getBankDetails(this.viewModel.order?.id)
        this.modalShow = !this.modalShow;
    }

    render() {
        const order = this.viewModel.order;

        return (
            <div>
                {
                    this.viewModel
                        ?
                        <div>
                            <div>
                                <div className="header">
                                    <PageHeader>
                                        <span className="button-form">
                                            <div
                                                className="redirect-back-mobile"
                                                onClick={this.handleRedirect}>
                                                <Icon.MobileBackArrow />
                                            </div>
                                            <button
                                                className="btn-default redirect-back redirect-back-desktop"
                                                onClick={this.handleRedirect}>
                                                {t("cashrail.button.back")}
                                            </button>
                                            <div
                                                className="order-reference">
                                                <span>
                                                    {t("cashrail.bundleDetails.title")}
                                                </span>
                                                <span>
                                                    {order?.reference}
                                                </span>
                                            </div>
                                        </span>
                                    </PageHeader>
                                </div>
                                <div className="container">
                                    <div
                                        className="order-details">
                                        <div>
                                            <strong>
                                                {t("cashrail.createdDate")}
                                                {fmt.dateShort(order?.createdDate)}
                                            </strong>
                                        </div>
                                        <div>
                                            <strong>
                                                {t("cashrail.ba-table.orderTypeCol")} -
                                                {" " + lang.cashrailFlow(order?.flowType)}
                                            </strong>
                                        </div>
                                        <div>
                                            <strong>
                                                {order?.bundleOrderItems.length}
                                                {t("cashrail.bundleDetails.ordersCount")}
                                            </strong>
                                            {fmt.currencySymbol(order?.dstCurrency)}
                                            {order?.dstAmount}
                                        </div>
                                        <div>
                                            <strong>{t("cashrail.bundleDetails.total")}</strong>
                                            {fmt.currencySymbol(order?.srcCurrency)}{order?.srcAmountIncludeFee.toFixed(2)}
                                        </div>
                                        <div>
                                            <strong>{t("cashrail.bundleDetails.orderStatus")}</strong>
                                            <span>{lang.cashrailOrderStatus(order?.orderStatus)}</span>
                                        </div>
                                        <div>
                                            <strong>{t("cashrail.bundleDetails.payStatus")}</strong>
                                            <span
                                                className={order?.paymentStatus == PaymentStatus.unpaid ? "error" : "success"}>
                                                {lang.cashrailDropdownPaidStatus(order?.paymentStatus)}</span>
                                        </div>
                                        {
                                            order?.credited ?
                                                <div>
                                                    <strong>{t("cashrail.cashrailDetails.credTitle")}</strong>
                                                    <span
                                                        className="credited">
                                                        {t("cashrail.cashrailDetails.credited")}
                                                    </span>
                                                </div>
                                                : null
                                        }
                                        <div className="settlement-payment">
                                            <div>
                                                <span>
                                                    <strong>{t("cashrail.bundleDetails.settlstatus")}</strong>
                                                </span>
                                                <span
                                                    className={order?.settled ? "success" : "error"}>

                                                    {order?.settled ? t("cashrail.bundleDetails.settled") : t("cashrail.bundleDetails.unsettled")}
                                                </span>
                                            </div>
                                        </div>
                                        <div className="btn-wrapper">
                                            <button
                                                className="btn-default btn-primary"
                                                onClick={this.onModalLinkClick}>
                                                {t("cashrail.button.details")}
                                            </button>
                                        </div>
                                    </div>
                                    <div className="dashboard-page-panel">
                                        <div className="search">
                                            <SearchInput onChange={(e) => this.hanldeSearchChange(e)} className="search-inp" />
                                        </div>
                                        <ul className={order?.flowType == FlowType.Individual ? "list list-col8 list-bundle-details" : "list list-col4 list-bundle-details"}>
                                            <li className="list-header">
                                                <div className="col-date">
                                                    {t("cashrail.bundleDetails.date")}
                                                </div>
                                                <div className="col-ref">
                                                    {t("cashrail.bundleDetails.code")}
                                                </div>
                                                <div className="col-id">
                                                    {t("cashrail.bundleDetails.customerId")}
                                                </div>
                                                {
                                                    order?.flowType == FlowType.Individual &&
                                                    <>
                                                        <div className="">
                                                            {t("cashrail.bundleDetails.documentType")}
                                                        </div>
                                                        <div className="">
                                                            {t("cashrail.bundleDetails.documentNumber")}
                                                        </div>
                                                        <div className="">
                                                            {t("cashrail.bundleDetails.fullName")}
                                                        </div>
                                                        <div>
                                                            {t("phone")}
                                                        </div>
                                                    </>
                                                }
                                                <div className="col-amount">
                                                    {t("cashrail.bundleDetails.costamount")}
                                                </div>
                                            </li>
                                            {this.viewModel?.filteredRequests?.map((item, index) =>
                                                <li key={item.id}>
                                                    <div className="col-date">
                                                        {fmt.dateShort(order.createdDate)}
                                                    </div>
                                                    <div className="col-ref">
                                                        {item.reference}
                                                    </div>
                                                    <div className="col-id">
                                                        {item.customerId}
                                                    </div>
                                                    {
                                                        order?.flowType == FlowType.Individual &&
                                                        <>
                                                            <div>
                                                                {lang.documentType(item.recipient.documentType)}
                                                            </div>
                                                            <div>
                                                                {item.recipient.documentNumber}
                                                            </div>
                                                            <div>
                                                                {item.recipient.firstName + " " + item.recipient.lastName}
                                                            </div>
                                                            <div>
                                                                {item.recipient.phoneNumber}
                                                            </div>
                                                        </>
                                                    }
                                                    <div className="col-amount">
                                                        {fmt.currencySymbol(order.dstCurrency) + item.amount}
                                                    </div>
                                                </li>
                                            )}
                                        </ul>
                                    </div>
                                </div>
                                <BundleCashrailDetailsDialogue
                                    viewModel={this.viewModel}
                                    order={order}
                                    onClick={async () => this.onModalLinkClick()}
                                    bankDetails={this.viewModel?.bankDetails}
                                    show={this.modalShow}
                                    onHide={() => this.onHide()}
                                />
                            </div>
                        </div >
                        :
                        <div></div>
                }
            </div >
        )
    }
}

export default withRouter(BundleCashrailDetailsPage);