import { Kuva } from "../interfaces/kuvaweb";

import { ApiClientBase } from "./apiClientBase";

type AgentsApiClientInterface = Kuva.PL.KuvaWeb.Controllers.Api.AgentsApiClientInterface;
type AgentScheduleDTO = Kuva.BL.BusinessModels.AdminDTO.AgentScheduleDTO;

export class AgentsApiClient extends ApiClientBase implements AgentsApiClientInterface {
    apiBasePath = "api/agents"

    getSchedules = () => this.get<AgentScheduleDTO[]>(['schedules']);
    updateDaySchedule = (schedule: AgentScheduleDTO) => this.putOldURL<AgentScheduleDTO, AgentScheduleDTO>(['schedule'], schedule);
}