import { t } from 'i18next'
import { observer } from 'mobx-react-lite'
import React, { useMemo } from 'react'
import { useHistory, useLocation, withRouter } from 'react-router-dom'
import { Icon } from '../../../components/icons'
import { PageHeader } from '../../../components/pageHeader'
import { KwlMultiCashOutBundleViewModel } from '../../../viewModels/kwlCashout/kwlMultiCashOut/kwlMultiCashOutBundleViewModel'
import * as fmt from '../../../utils/formatting'
import Table from '../../../components/table/table'
import DialogModal from '../../../components/modal/DialogModal'
import { KWLMultiCashOutBundleTableConfig } from '../config'
import { lang } from '../../../utils/lang'

export const KwlMultiCashOutBundlePage = observer(() => {
  const location = useLocation()
  const id = location.pathname.slice(21)
  const history = useHistory()
  const viewModel = useMemo(() => new KwlMultiCashOutBundleViewModel(id, history.push), [])

  const tableConfig = KWLMultiCashOutBundleTableConfig(viewModel.status)
  const countColumn = viewModel.status === 0 ? 8 : 7

  const goBack = () => {
    history.goBack()
  }

  return (
    <>
      <div className="header">
        <PageHeader>
          <span className="button-form">
            <div className="redirect-back-mobile" onClick={goBack}>
              <Icon.MobileBackArrow />
            </div>
            <button className="btn-default redirect-back redirect-back-desktop" onClick={goBack}>
              {t('kwlCashout.bundleDetails.backbtn')}
            </button>
            <div className="order-reference">
              <span>{t('kwlCashout.bundleDetails.title')}</span>
              <span>{viewModel.bundleId}</span>
            </div>
          </span>
        </PageHeader>
      </div>
      <div className="container">
        <div className="order-details">
          <div>
            <strong>{t('kwlCashout.bundleDetails.createdDate')}</strong>
            {fmt.dateShort(viewModel.createdDate)}
          </div>
          <div>
            <strong>
              {t('kwlCashout.bundleDetails.orderItem', { count: viewModel.cashOutsCount })}
            </strong>
          </div>
          <div>
            <strong>{t('kwlCashout.bundleDetails.totalCost')}</strong>
            {fmt.currency(viewModel.cashOutsAmount, 'USD')}
          </div>
          <div>
            <strong>{t('kwlCashout.bundleDetails.status')} - </strong>
            {lang.cashOutBundleStatus(viewModel.status)}
          </div>
          <div>
            <strong>{t('kwlCashout.bundleDetails.completedDate')}</strong>
            {fmt.dateShort(viewModel.completedDate)}
          </div>
          {viewModel.status === 0 && (
            <div className="btn-wrapper">
              <button className="btn-default btn-primary" onClick={viewModel.openCompleteAllModal}>
                {t('kwlCashout.bundleDetails.completeAllbtn')}
              </button>
            </div>
          )}
        </div>
        <div className="dashboard-page-panel">
          {Boolean(viewModel.cashOutList.length > 0) && (
            <Table
              list={viewModel.cashOutList}
              columnCount={countColumn}
              tableConfig={tableConfig}
              {...(viewModel.status === 0 && {
                onClick: viewModel.openDeleteModal,
                className: 'cash-out-processing-list',
              })}
            />
          )}
        </div>
      </div>
      <DialogModal
        mainText={t('kwlCashout.bundleDetails.completeAllDialogModal.mainText', {
          count: viewModel.cashOutsCount,
        })}
        cancelBtnText={t('kwlCashout.bundleDetails.completeAllDialogModal.cancelBtn')}
        acceptBtnText={t('kwlCashout.bundleDetails.completeAllDialogModal.acceptBtn')}
        isOpenModal={viewModel.isOpenCompleteAllModal}
        closeModal={viewModel.closeModal}
        confirmModal={viewModel.confirmModal}
      />

      <DialogModal
        mainText={t('kwlCashout.bundleDetails.deleteCashOutFromBundleDialogModal.mainText')}
        cancelBtnText={t('kwlCashout.bundleDetails.deleteCashOutFromBundleDialogModal.cancelBtn')}
        acceptBtnText={t('kwlCashout.bundleDetails.deleteCashOutFromBundleDialogModal.acceptBtn')}
        isOpenModal={viewModel.isOpenRemoveModal}
        closeModal={viewModel.closeModal}
        confirmModal={viewModel.confirmModal}
      />
    </>
  )
})

export default withRouter(KwlMultiCashOutBundlePage)
