import { t } from "i18next";
import { observer } from "mobx-react";
import React, { Component, ReactNode } from 'react';
import { Link } from 'react-router-dom';
import DashboardPermissionButton from "../components/dashboard/dashboardPermissionButton";
import { Icon } from "../components/icons";
import isInRole from "../helpers/isRole";
import { AuthenticationManager } from "../managers/authenticationManager";
import { DashboardCountViewModel } from "../viewModels/dashboard/dashboardCountViewModel";

import { DashboardViewModel } from "../viewModels/dashboardViewModel";

@observer
export class DashboardPage extends Component {
  private viewModel = new DashboardViewModel()
  private countViewModel = new DashboardCountViewModel();

  authenticationManager = AuthenticationManager.instance;

  isBundleAgent = isInRole(this.authenticationManager.roles, 'BundleAgent');
  isSettlementAllow = isInRole(this.authenticationManager.roles, "KuvaLocalSettlement");
  isCashrailAgent = isInRole(this.authenticationManager.roles, 'CashrailAgent');
  isCoDVisible = isInRole(this.authenticationManager.roles, "Cash_On_Delivery_Courier");
  isCashTopUpAvailable = isInRole(this.authenticationManager.roles, 'AgentCashTopUp');
  isBankTopUpAvailable = isInRole(this.authenticationManager.roles, 'AgentBankTopUp');
  isKWLCashoutAvailable = isInRole(this.authenticationManager.roles, 'KWL_cashouts');
  isChangeMerchantVisible = isInRole(this.authenticationManager.roles, 'ChangeMerchantView');
  isMultiCashoutsVisible = isInRole(this.authenticationManager.roles, 'Multicash_cashouts');

  componentDidMount() {
    this.viewModel.mount();
  }

  componentWillUnmount() {
    this.viewModel.unmount()
  }

  render(): ReactNode {
    return <div className={"page-dashboard"}>
      <Link
        className={"btn btn-secondary btn-lg"}
        to={"/cashouts"}>
        {t("dashboard.cashoutsLink")}
        <span
          className={"badge"}>
          {
            this.viewModel.bookedCashoutsCount
            ??
            <Icon.DashboardIndicator />
          }
        </span>
      </Link>
      <DashboardPermissionButton
        to="/cash-topups"
        lang="dashboard.cashTopupsLink"
        count={this.viewModel.bookedCashTopupsCount}
        permission={this.isCashTopUpAvailable} />
      <DashboardPermissionButton
        to="/bank-topups"
        lang="dashboard.bankTopupsLink"
        count={this.viewModel.bookedBankTopupsCount}
        permission={this.isBankTopUpAvailable} />
      <DashboardPermissionButton
        to="/settlements"
        lang="dashboard.settlementsLink"
        count={this.countViewModel.bookedSettlementsCount}
        permission={this.isSettlementAllow} />
      <DashboardPermissionButton
        to="/cash-on-delivery"
        lang="component.menu.cashOnDelivery"
        count={this.viewModel.bookedCashOnDeliveryCount}
        permission={this.isCoDVisible} />
      <DashboardPermissionButton
        to={this.isBundleAgent ? "/ba-cashrail" : "/ca-cashrail"}
        lang="dashboard.cashrailLink"
        count={this.countViewModel.cashrailCount}
        permission={Boolean(this.isBundleAgent || this.isCashrailAgent)} />
      <DashboardPermissionButton
        to="/kwl-cashouts"
        lang="dashboard.kwlCashout"
        count={this.countViewModel.kwlCashOutCount}
        permission={this.isKWLCashoutAvailable} />
      <DashboardPermissionButton
        to="/change-merchant"
        lang="dashboard.changeMerchantLink"
        count={this.countViewModel.bookedChangeMerchantTransactionsCount}
        permission={this.isChangeMerchantVisible} />
      <DashboardPermissionButton
        to="/multi-cashouts"
        lang="dashboard.multiCashoutsTitle"
        count={this.viewModel.bookedMulticashOutsCount}
        permission={this.isMultiCashoutsVisible} />
    </div>
  }
}