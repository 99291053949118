import { t } from "i18next"
import { Kuva } from "../../../interfaces/kuvaweb"
import * as fmt from "../../../utils/formatting";
import { lang } from "../../../utils/lang";
import React from "react";

type ChangeMerchantTransaction = Kuva.DL.DomainModel.NopDbEntities.KuvaLocal.ChangeMerchantTransaction;
type Config = Kuva.Module.Table.TableConfig<ChangeMerchantTransaction>;

const changeMerchantTableConfig = (onClick: (id: number) => void): Config => {
  return {
    columns: [
      {
        id: "date",
        header: () => t("changeMerchant.table.date"),
        column: tx => fmt.dateTimeShort(tx.date),
        className: "col-date"
      },
      {
        id: "reference",
        header: () => t("changeMerchant.table.reference"),
        column: a => a.reference,
        className: "col-ref"
      },
      {
        id: "type",
        header: () => t("changeMerchant.table.type"),
        column: tx => lang.changeMerchantType(tx.type),
        className: "col-pay-method"
      },
      {
        id: "status",
        header: () => t("changeMerchant.table.status"),
        column: tx => lang.creditWalletTransactionStatus(tx.status),
        className: "col-order-status"
      },
      {
        id: "cashProvided",
        header: () => t("changeMerchant.table.cashProvided"),
        column: tx => tx.receivedAmount,
        className: "col-amount"
      },
      {
        id: "change",
        header: () => t("changeMerchant.table.change"),
        column: tx => tx.changeAmount,
        className: "col-pay-with-cash"
      },
      {
        id: "agent",
        header: () => t("changeMerchant.table.agent"),
        column: tx => tx.agentName,
        className: "col-createdBy"
      },
      {
        id: "phone",
        header: () => t("changeMerchant.table.phone"),
        column: tx => tx.phone,
        className: "col-phone"
      },
      {
        id: "details",
        header: () => t("changeMerchant.table.details"),
        column: tx => <button
          className="btn-sm btn-primary"
          onClick={() => onClick(tx.id)}>
          {t("changeMerchant.button.details")}
        </button>,
        className: "btn-column"
      }
    ]
  }
}
export default changeMerchantTableConfig;