import { observer } from "mobx-react";
import React from "react"
import { Button, Table } from "react-bootstrap";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { PageHeader } from "../../../components/pageHeader";
import { lang } from "../../../utils/lang";
import CashrailAgentOrderDetailsViewModel from "../../../viewModels/cashrail/cashrailAgentOrderDetailsViewModel";
import * as fmt from "../../../utils/formatting";
import { t } from "i18next";
import { SearchInput } from "../../../components/searchInput";
import { Kuva } from "../../../interfaces/kuvaweb";
import { Icon } from "../../../components/icons";

const BundleOrderStatus = Kuva.Module.Enums.BundleOrderStatus;
const PaymentStatus = Kuva.Module.Enums.BundleOrderPaymentStatus;
const FlowType = Kuva.Module.Enums.CashRailFlowType;

@observer
export class AgentCashrailDetailPage extends React.Component<RouteComponentProps> {
    params = new URLSearchParams(window.location.search)
    id = this.params.get("id");
    orderId = parseInt(this.id);

    viewModel = new CashrailAgentOrderDetailsViewModel(this.orderId);

    componentDidMount() {
        this.viewModel.mount();
    }

    componentWillUnmount() {
        this.viewModel.unmount();
    }

    handleRedirect = () => {
        this.props.history.push('/ca-cashrail');
    }

    hanldeSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        this.viewModel.search = e.target.value;
        this.viewModel.updateFilter();
    }

    render() {
        const order = this.viewModel.order;

        return (
            <div>
                {
                    this.viewModel
                        ?
                        <div>
                            <PageHeader>
                                <div className="button-form">
                                    <div
                                        className="redirect-back-mobile"
                                        onClick={this.handleRedirect}>
                                        <Icon.MobileBackArrow />
                                    </div>
                                    <button
                                        className="btn-default redirect-back redirect-back-desktop"
                                        onClick={this.handleRedirect}>
                                        {t("cashrail.button.back")}
                                    </button>
                                    <div
                                        className="text">
                                        <span
                                            className="title">
                                            {t("cashrail.cashrailDetails.title")}
                                        </span>
                                        <span
                                            className="order-reference">
                                            {order?.reference}
                                        </span>
                                    </div>
                                </div>
                                <div
                                    className="download-btns order-status-title">
                                    <span
                                        className="status-text">
                                        {t("cashrail.cashrailDetails.orderStatus")}
                                    </span>
                                    {
                                        this.viewModel.orderStatus !== undefined && this.viewModel.orderStatus == BundleOrderStatus.ready && order?.settled && order?.flowType != 20
                                            ?
                                            <div
                                                className="status-ready">
                                                {lang.cashrailOrderStatus(order?.orderStatus)}
                                                <button
                                                    className="btn-default btn-primary"
                                                    onClick={() => this.viewModel.changeOrderStatus(order.id)}>
                                                    {t("cashrail.button.markDelivered")}
                                                </button>
                                            </div>
                                            :
                                            <span
                                                className="order-status">
                                                {lang.cashrailOrderStatus(order?.orderStatus)}
                                            </span>
                                    }
                                </div>
                            </PageHeader>
                            <div className="container">
                                <div
                                    className="mobile-settl-block order-status-title">
                                    <span
                                        className="status-text">
                                        {t("cashrail.cashrailDetails.orderStatus")}
                                    </span>
                                    {
                                        order?.orderStatus == BundleOrderStatus.ready && order?.settled
                                            ?
                                            <div
                                                className="status-ready">

                                                {lang.cashrailOrderStatus(order?.orderStatus)}
                                                <button
                                                    className="btn-default btn-primary"
                                                    onClick={() => this.viewModel.changeOrderStatus(order.id)}>
                                                    {t("cashrail.button.markDelivered")}
                                                </button>
                                            </div>
                                            :
                                            <span
                                                className="order-status">
                                                {lang.cashrailOrderStatus(order?.orderStatus)}
                                            </span>
                                    }
                                </div>
                            </div>
                            <div className="container">
                                <div className="pay-status-content">
                                    <div>
                                        <strong>
                                            {t("cashrail.createdDate")}
                                            {fmt.dateShort(order?.createdDate)}
                                        </strong>
                                    </div>
                                    {
                                        <div>
                                            <strong>
                                                {t("cashrail.ba-table.orderTypeCol")} -
                                                {" " + lang.cashrailFlow(order?.flowType)}
                                            </strong>
                                        </div>

                                    }
                                    {
                                        order?.flowType == FlowType.Individual &&
                                        <>

                                        </>
                                    }
                                    <div>
                                        {order?.bundleOrderItems.length}
                                        {t("cashrail.cashrailDetails.ordersCount")}
                                        {fmt.currencySymbol(order?.dstCurrency)}
                                        {order?.dstAmount}
                                    </div>
                                    <div>
                                        {t("cashrail.cashrailDetails.total")}
                                        {fmt.currencySymbol(order?.srcCurrency)}{order?.srcAmountIncludeFee.toFixed(2)}
                                    </div>
                                    <div>
                                        {t("cashrail.cashrailDetails.status")}
                                        <span
                                            className={order?.settled ? "settled-status" : "error"}>
                                            {order?.settled ? t("cashrail.cashrailDetails.settled") : t("cashrail.cashrailDetails.unsettled")}
                                        </span>
                                    </div>
                                    {
                                        order?.credited ?
                                            <div>
                                                {t("cashrail.cashrailDetails.credTitle")}
                                                <span
                                                    className="credited">
                                                    {t("cashrail.cashrailDetails.credited")}
                                                </span>
                                            </div>
                                            : null
                                    }
                                    <div>
                                        {t("cashrail.cashrailDetails.payTitle")}
                                        <span
                                            className={order?.paymentStatus === PaymentStatus.paid ? "paid-status" : "error"}>
                                            {lang.cashrailPaymentStatus(order?.paymentStatus)}
                                        </span>
                                    </div>
                                </div>
                                <div className="dashboard-page-panel">
                                    <div className="search">
                                        <SearchInput onChange={(e) => this.hanldeSearchChange(e)} />
                                        {order?.orderStatus === BundleOrderStatus.ready || order?.orderStatus == BundleOrderStatus.delivered ?
                                            <span
                                                className="fully-processed">
                                                {t("cashrail.cashrailDetails.fullyProcessed")}
                                                <Icon.SuccessTick />
                                            </span>
                                            : ""
                                        }
                                    </div>
                                    <ul className={order?.flowType == FlowType.Individual ? "list list-col9" : "list list-col5" + " " + "cashrail-details-list"}>
                                        <li className="list-header">
                                            <div className="col-date">
                                                {t("cashrail.bundleDetails.date")}
                                            </div>
                                            <div className="col-ref">
                                                {t("cashrail.bundleDetails.code")}
                                            </div>
                                            <div className="col-id">
                                                {t("cashrail.bundleDetails.customerId")}
                                            </div>
                                            {
                                                order?.flowType == FlowType.Individual &&
                                                <>
                                                    <div className="">
                                                        {t("cashrail.bundleDetails.documentType")}
                                                    </div>
                                                    <div className="">
                                                        {t("cashrail.bundleDetails.documentNumber")}
                                                    </div>
                                                    <div className="">
                                                        {t("cashrail.bundleDetails.fullName")}
                                                    </div>
                                                    <div>
                                                        {t("phone")}
                                                    </div>
                                                </>
                                            }
                                            <div className="col-amount">
                                                {t("cashrail.bundleDetails.costamount")}
                                            </div>
                                            <div className="col-process">
                                                {t("cashrail.bundleDetails.process")}
                                            </div>
                                        </li>
                                        {this.viewModel?.filteredRequests?.map((item) =>
                                            <li key={item.id} className={(order.flowType == FlowType.Individual ? "list-col9" : "list-col5") + " " + (item.packed ? "packed" : "")}>
                                                <div className="col-date">
                                                    {fmt.dateShort(order.createdDate)}
                                                </div>
                                                <div className="col-ref">
                                                    {item.reference}
                                                </div>
                                                <div className="col-id">
                                                    {item.customerId}
                                                </div>
                                                {
                                                    order?.flowType == FlowType.Individual &&
                                                    <>
                                                        <div>
                                                            {lang.documentType(item.recipient.documentType)}
                                                        </div>
                                                        <div>
                                                            {item.recipient.documentNumber}
                                                        </div>
                                                        <div>
                                                            {item.recipient.firstName + " " + item.recipient.lastName}
                                                        </div>
                                                        <div>
                                                            {item.recipient.phoneNumber}
                                                        </div>
                                                    </>
                                                }
                                                <div className="col-amount">
                                                    {fmt.currencySymbol(item.destinationCurrency)}
                                                    {item.amount}
                                                </div>
                                                <div className="col-process">
                                                    <input
                                                        type='checkbox'
                                                        onChange={() => this.viewModel.packBundleItem.bind(this.viewModel)(item.bundleOrderId, item.id, !item.packed, order.canPack)}
                                                        checked={item.packed}
                                                        disabled={
                                                            (!order?.settled
                                                                && !order?.credited)
                                                            || order.flowType == 20
                                                            || !order.settled
                                                            || order?.orderStatus === BundleOrderStatus.delivered
                                                            || order?.orderStatus === BundleOrderStatus.completed
                                                        }>
                                                    </input>
                                                </div>
                                            </li>
                                        )}
                                    </ul>
                                </div>
                            </div>
                        </div >
                        :
                        <div>
                        </div>
                }
            </div >

        )
    }
}
export default withRouter(AgentCashrailDetailPage);