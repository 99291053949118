import { action, autorun, makeObservable, observable, runInAction } from "mobx";
import { Kuva } from "../interfaces/kuvaweb";
import { IPromiseViewModel } from "./promiseViewModel";
import { ViewModelBase } from "./viewModelBase";
import * as fmt from "../utils/formatting";
import { lang } from "../utils/lang";
import { dateFormatter } from "../helpers/dateFormatter"
import React from "react";

type AgentSettlementPaymentType = Kuva.Module.Enums.AgentSettlementPaymentType;
type AgentSettlementPagedModel = Kuva.BL.BusinessModels.Api.Agent.AgentSettlementPagedModel;
type AgentSettlementResponseModel = Kuva.PL.KuvaWeb.Areas.Agent.Models.AgentSettlementResponseModel;

interface ISettlementList<AgentSettlementResponseModel> extends ViewModelBase {
  loading: boolean;
  requestCount: number;
  filteredRequests: AgentSettlementResponseModel[];
  setSearch(search: string): void;
  hasNextPage: boolean;
  onLoadMore(step: number, startDate?: string, endDate?: string, paymentType?: AgentSettlementPaymentType | string): Promise<void>;
  changeSearch(e: React.ChangeEvent<HTMLInputElement>): void
  changeSettlementSearchStatus(e: React.ChangeEvent<HTMLSelectElement>): void
  changePaymentType(e: React.ChangeEvent<HTMLSelectElement>): void
  changeFlowType(e: React.ChangeEvent<HTMLSelectElement>): void
  getSettlementsWithFilter(): void
  resetFilter(): void
  changeStartDate(startDate: Date): void
  changeEndDate(endDate: Date): void
  status?: number
  payType?: number
  flowType?: number
  startDate?: Date
  endDate?: Date
  search?: string
}

type AgentSettlementFilter = Kuva.Module.Models.Paging.Agent.AgentSettlementFilter

export abstract class BookedSettlementsCommon extends ViewModelBase implements ISettlementList<AgentSettlementResponseModel> {
  protected requests: AgentSettlementResponseModel[] = [];
  public search: string = "";

  step = 15;
  offset: number = 0;
  timer: undefined | NodeJS.Timeout;

  @observable filteredRequests: AgentSettlementResponseModel[] = new Array();
  @observable requestCount: number = 0;
  @observable loading: boolean = false;
  @observable hasNextPage: boolean = null;
  @observable status?: number;
  @observable payType?: number;
  @observable flowType?: number;
  @observable error: string = "";
  @observable startDate?: Date = null;
  @observable endDate?: Date = null;

  constructor() {
    super();
    makeObservable(this);
  }

  protected abstract getRequests(filter: AgentSettlementFilter): Promise<AgentSettlementPagedModel>;

  protected async load(): Promise<void> {
    try {
      this.loading = true;
      const result = await this.getRequests({ query: null, pageSize: 15, offset: 0, endDate: null, startDate: null });
      this.hasNextPage = result.hasNextPage;
      this.requests = result.items;
      this.updateFilter();
      this.loading = false;
    } catch (error) {
      this.loading = false;
    }
  }

  @action getSettlementsWithFilter = async () => {
    this.loading = true;
    try {
      let response: AgentSettlementPagedModel = null

      response = await this.getRequests({
        query: this.search,
        pageSize: this.step,
        offset: 0,
        startDate: dateFormatter.formatAsISOString(this.startDate),
        endDate: dateFormatter.formatToEndOfDay(this.endDate),
        paymentTypes: this.payType,
        statuses: this.status,
        flowTypes: this.flowType
      })
      this.requests = null

      runInAction(() => {
        this.hasNextPage = response.hasNextPage
        this.requests = response.items
        this.loading = false
        this.updateFilter()
      })
    } catch (error) {
      this.loading = false
      this.hasNextPage = false
    }
  }

  @action changeSettlementSearchStatus = async (e: React.ChangeEvent<HTMLSelectElement>) => {
    const { target: { value } } = e;

    this.status = Number(value);
    await this.getSettlementsWithFilter()
  }

  @action changePaymentType = async (e: React.ChangeEvent<HTMLSelectElement>) => {
    const { target: { value } } = e;
    this.payType = Number(value);
    await this.getSettlementsWithFilter()
  }

  @action changeFlowType = async (e: React.ChangeEvent<HTMLSelectElement>) => {
    const { target: { value } } = e
    this.flowType = Number(value)
    await this.getSettlementsWithFilter()
  }

  @action changeStartDate = (startDate: Date) => {
    this.startDate = startDate
  }

  @action changeEndDate = (endDate: Date) => {
    this.endDate = endDate
  }

  @action
  async onLoadMore(step: number, startDate?: string, endDate?: string, paymentType?: AgentSettlementPaymentType) {
    const response = await this.getRequests({ query: this.search, offset: this.requests.length, pageSize: 15, startDate, endDate, paymentTypes: paymentType, statuses: this.status, flowTypes: this.flowType })
    runInAction(() => {
      this.requests = [...this.requests, ...response.items];
      this.hasNextPage = response.hasNextPage;
      this.requestCount += response.items.length
      this.updateFilter()
      this.loading = false;
    })
  }

  @action
  private updateFilter(): void {
    if (this.requests == null) {
      return;
    }
    this.filteredRequests = this.requests;
    this.requestCount = this.filteredRequests.length;
  }

  @action resetFilter = () => {
    this.search = ""
    this.payType = 100
    this.status = 100
    this.flowType = 100
    this.startDate = null
    this.endDate = null
  }

  @action setSearch(search: string): void {
    if (this.search != search) {
      this.search = search;
      this.updateFilter();
    }
  }

  @action changeSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    this.search = e.target.value;
    this.setSearch(this.search)
    clearTimeout(this.timer);
    this.timer = setTimeout(() => this.getSettlementsWithFilter(), 1000)
  }
}

export abstract class SettlementsCommon<T extends AgentSettlementResponseModel> extends ViewModelBase {
  settlements: ISettlementList<T> = null;

  @observable currentList: ISettlementList<T> = null;

  @observable details: IPromiseViewModel<void> = null;

  @observable search: string = "";


  protected constructor() {
    super();
    this.updateTab();
    makeObservable(this);
  }

  mount() {
    this.settlements.mount();
    super.mount();
  }

  unmount() {
    this.settlements.unmount();
    super.unmount();
  }

  *subscribe() {
    yield autorun(() => {
      this.updateTab();
    });
  }

  protected updateTab() {
    this.currentList = this.settlements;
    this.currentList?.setSearch(this.search);
  }

}

type SearchFunction = (settlement: AgentSettlementResponseModel) => string

const searchableFields: (keyof AgentSettlementResponseModel | SearchFunction)[] = [
  'id',
  settlement => fmt.dateTimeShort(settlement.createdDate),
  'amount',
  'currency',
  settlement => lang.settlementPaymentType(settlement.paymentType),
  settlement => lang.settlementStatus(settlement.status),
]

function applyFilter(query: string): (a: AgentSettlementResponseModel) => boolean {
  if (query === null || query.length < 1)
    return () => true;
  query = query.trim().toLocaleLowerCase();

  return (a) => {
    return searchableFields.some(field => {
      let value = ''
      if (typeof field === 'function') {
        value = field(a)
      } else {
        value = a[field].toString()
      }

      return value.toLocaleLowerCase().startsWith(query)
    })
  }
}