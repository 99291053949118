import { ApiClientBase } from '../apiClientBase'
import { Kuva, KuvaWeb } from '../../interfaces/kuvaweb'
type CashOnDeliveryApiClientInterface =
  KuvaWeb.Areas.Agent.Controllers.CashOnDeliveryApiClientInterface
type AgentCashOnDeliveryRequestModel =
  Kuva.PL.KuvaWeb.Areas.Agent.Models.AgentCashOnDeliveryRequestModel
type AgentCashOnDeliveryDetails = Kuva.PL.KuvaWeb.Areas.Agent.Models.AgentCashOnDeliveryDetails

export class CashOnDeliveryApiClient
  extends ApiClientBase
  implements CashOnDeliveryApiClientInterface
{
  apiBasePath = 'cash-on-delivery'

  getAgentBookedCashOnDelivery = () =>
    this.getNewUrl<AgentCashOnDeliveryRequestModel[]>([this.apiBasePath, 'booked-orders'])

  getAgentPastCashOnDelivery = () =>
    this.getNewUrl<AgentCashOnDeliveryRequestModel[]>([this.apiBasePath, 'past-orders'], null)

  agentCashOnDeliveryDetails = (orderId: string) =>
    this.getNewUrl<AgentCashOnDeliveryDetails>([this.apiBasePath, `${orderId}/order-details`], null)

  completeCashOnDelivery = (
    orderReference: string,
    amount: number,
    completedDmIds: string[],
    deliveryDate: string
  ) =>
    this.postNewUrl([this.apiBasePath, 'complete'], {
      orderReference,
      amount,
      completedDmIds,
      deliveryDate,
    })

  agentCashOnDeliveryDetailsByReference = (referenceNumber: string) =>
    this.getNewUrl<AgentCashOnDeliveryDetails>([this.apiBasePath, 'order-details'], {
      referenceNumber,
    })
}
