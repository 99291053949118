import { formatISO9075 } from 'date-fns'
import endOfDay from 'date-fns/endOfDay'


function formatAsISOString(date: Date): string {
    if (date === null || date === undefined)
        return ""
    return formatISO9075(date)
}

function formatToEndOfDay(date: Date): string {
    if (date === null || date === undefined)
        return ""

    let isoDate = endOfDay(date)
    return formatAsISOString(isoDate)
}

export const dateFormatter = {
    formatAsISOString,
    formatToEndOfDay
}