import { t } from 'i18next'
import { observer } from 'mobx-react'
import React from 'react'
import { PageHeader } from '../../components/pageHeader'
import { KWLCashoutRequestsViewModel } from '../../viewModels/kwlCashout/cashoutViewModel'
import { KWlCashoutDetailsViewModel } from '../../viewModels/kwlCashout/kwlCashoutDetailsViewModel'
import { KWLCashOutFormViewModel } from '../../viewModels/kwlCashout/KWLCashOutsFormViewModel'
import { KwlMultiCashOutViewModel } from '../../viewModels/kwlCashout/kwlMultiCashOut/kwlMultiCashOutViewModel'
import KwlSingleCashOutList from './KwlSingleCashOutList'
import KWLCashOutVerifyForm from './kwlCashoutVerifyForm'
import KwlDetailsDialog from './KwlDetailsDialog'
import KwlMultiCashOutList from './KwlMultiCashOutList'
import { AuthenticationManager } from '../../managers/authenticationManager'
import { RouteComponentProps, withRouter } from 'react-router-dom'
import KwlReportViewModal from '../../viewModels/kwlCashout/KwlReportViewModel'
import KwlReportPopUp from './KwlReportModal'

@observer
export class KWLCashOutPage extends React.Component<RouteComponentProps> {
  isCashOutBundleEnabled = AuthenticationManager.instance.agent.isCashOutBundleEnabled
  availableCashoutCsv = AuthenticationManager.instance.agent.availableCashoutCsv

  listViewModel = this.isCashOutBundleEnabled
    ? new KwlMultiCashOutViewModel()
    : new KWLCashoutRequestsViewModel()
  formViewModel = new KWLCashOutFormViewModel()
  reportPopUpViewModel = new KwlReportViewModal()

  isKWLCashoutRequestsViewModel(
    model: KWLCashoutRequestsViewModel | KwlMultiCashOutViewModel
  ): model is KWLCashoutRequestsViewModel {
    return (model as KWLCashoutRequestsViewModel).mount !== undefined
  }

  constructor(props: RouteComponentProps) {
    super(props)
    this.moveToBundleDetails = this.moveToBundleDetails.bind(this)
  }
  componentDidMount() {
    if (!this.isCashOutBundleEnabled && this.isKWLCashoutRequestsViewModel(this.listViewModel)) {
      this.listViewModel.mount()
    }
  }

  componentWillUnmount() {
    if (!this.isCashOutBundleEnabled && this.isKWLCashoutRequestsViewModel(this.listViewModel)) {
      this.listViewModel.unmount()
    }
  }

  moveToBundleDetails(id: number) {
    this.props.history.push(`/kwl-cashouts/bundle/${id}`)
  }

  render() {
    return (
      <>
        <div className="page-cashout">
          <PageHeader>{t('kwlCashout.title')}</PageHeader>
          <div className="container">
            {!this.isCashOutBundleEnabled ? (
              <>
                {this.isKWLCashoutRequestsViewModel(this.listViewModel) &&
                  this.listViewModel.details !== null && (
                    <KwlDetailsDialog
                      listViewModel={this.listViewModel}
                      viewModel={this.listViewModel.details as KWlCashoutDetailsViewModel}
                    />
                  )}
                <div className="form-section">
                  <KWLCashOutVerifyForm viewModel={this.formViewModel} />
                  {this.availableCashoutCsv && (
                    <button className="btn btn-link" onClick={this.reportPopUpViewModel.showPopUp}>
                      {t('kwlCashout.downloadCsvBtn')}
                    </button>
                  )}
                </div>
              </>
            ) : (
              <>
                {this.availableCashoutCsv && (
                  <div className="form-section">
                    <button className="btn btn-link" onClick={this.reportPopUpViewModel.showPopUp}>
                      {t('kwlCashout.downloadCsvBtn')}
                    </button>
                  </div>
                )}
              </>
            )}

            {this.isCashOutBundleEnabled ? (
              <KwlMultiCashOutList
                viewModel={this.listViewModel as KwlMultiCashOutViewModel}
                moveToBundleDetails={this.moveToBundleDetails}
              />
            ) : (
              <KwlSingleCashOutList viewModel={this.listViewModel as KWLCashoutRequestsViewModel} />
            )}
          </div>
        </div>
        <KwlReportPopUp viewModel={this.reportPopUpViewModel} />
      </>
    )
  }
}
export default withRouter(KWLCashOutPage)
