﻿import React, {CSSProperties} from "react";

interface TransitionPanelProps {page: string, children: React.ReactElement<{key: string}>[]}

export class TransitionPanel extends React.Component<TransitionPanelProps> {
    containerRef: React.RefObject<HTMLDivElement> = React.createRef<HTMLDivElement>();
    previousRef: React.RefObject<HTMLDivElement> = React.createRef<HTMLDivElement>();
    nextRef: React.RefObject<HTMLDivElement> = React.createRef<HTMLDivElement>();

    transitionTime = 150;
    transition = ( this.transitionTime / 1000 ).toFixed(2) + "s";

    state = {
        previousPage: null as string,
        nextPage: this.props.page,
        sizingPage: this.props.page,
        fixedHeight: null as number,
        phase: "completed"
    };

    /*componentDidMount() {
        this.setState({
            nextPage: this.props.page,
            sizingPage: this.props.page,
            phase: "completed"
        })        
    }*/

    componentDidUpdate(prevProps: Readonly<TransitionPanelProps>, prevState: Readonly<{ previousPage: string }>, snapshot?: any) {
        if (this.props.page != prevProps.page) {
            this.beginTransitionTo(this.props.page, prevProps.page);
        }
    }

    transitionEnterReady() {
        console.log("entering", this.nextRef.current.getBoundingClientRect().height);
        this.setState({
            phase: "entering",
            fixedHeight: this.nextRef.current.getBoundingClientRect().height
        });
        setTimeout(() => {
            this.setState({
                phase: "completed",
                sizingPage: this.state.nextPage,
                fixedHeight: null
            })
        }, this.transitionTime*1.1);
    }

    beginTransitionTo(toPage: string, fromPage: string) {
        const transitionTo = this.props.page;
        console.log("enter", this.nextRef.current.getBoundingClientRect().height);
        this.setState({
            previousPage: this.state.nextPage,
            nextPage: toPage,
            phase: "enter",
            fixedHeight: this.nextRef.current.getBoundingClientRect().height
        });
    }

    render() {
        const phase = this.state.phase;

        const previousChild = this.props.children.find(e => e != null && e.key == this.state.previousPage);
        const nextChild = this.props.children.find(e => e != null && e.key == this.state.nextPage);

        const sizingChild = this.state.sizingPage == this.state.nextPage ? nextChild : previousChild;
        const previousStyle: CSSProperties = {
            position: sizingChild == previousChild ? null : "absolute",
            width: "100%",
            top: 0,
            left: 0
        };

        const nextStyle: CSSProperties = {
            position: sizingChild == nextChild ? null : "absolute",
            width: "100%",
            top: 0,
            left: 0
        };

        const translateTransition = `transform ${this.transition}`;

        if (phase == "completed") {
            previousStyle.transform = "translateX(-100%)";
            nextStyle.transform = "translateX(0%)";
        } else if (phase == "enter") {
            previousStyle.transform = "translateX(0%)";
            //previousStyle.transition = "transform 0.15s";
            nextStyle.transform = "translateX(100%)";
            //nextStyle.transition = "transform 0.15s";
            setTimeout(() => {
                this.transitionEnterReady()
            }, 0)
        } else if (phase == "entering") {
            previousStyle.transform = "translateX(-100%)";
            previousStyle.transition = translateTransition;
            nextStyle.transform = "translateX(0%)";
            nextStyle.transition = translateTransition;
        }

        return <div ref={this.containerRef} style={{
            position: "relative",
            overflow: "hidden",
            transition: `height ${this.transition}`,
            height: this.state.fixedHeight
        }}>
            {(previousChild != null && this.state.phase != "completed") ?
                <div ref={this.previousRef} style={previousStyle}>
                    {previousChild}
                </div>
                : null}
            <div ref={this.nextRef} style={nextStyle}>
                {nextChild}
            </div>
        </div>;
    }
}