import i18next from 'i18next';
import React, { ChangeEvent, EventHandler, useEffect, useState } from 'react';

export function LanguageSelection() {
    const [currentLanguage, setCurrentLanguage] = useState(i18next.resolvedLanguage);

    useEffect(() => {
        function handleLanguageChanged() {
            setCurrentLanguage(i18next.resolvedLanguage);
        }

        i18next.on("languageChanged", handleLanguageChanged);

        return () => {
            i18next.off("languageChanged", handleLanguageChanged);
        }
    })

    function handleChange(event: ChangeEvent<HTMLSelectElement>) {
        const selectedLanguage = event.target.value;
        i18next.changeLanguage(selectedLanguage);
    }

    return <div className="language-selection">
        <select onChange={handleChange} className="form-control" value={currentLanguage}>
            <option value="en">English</option>
            <option value="es">Español</option>
        </select>
    </div>;
}