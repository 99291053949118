﻿import {TopUpBankApiClient} from "../../api/agent/topUpBankApiClient";
import {Kuva} from "../../interfaces/kuvaweb";
import {tryAlternatives} from "../../utils/refNum";

import {TopupFormViewModel} from "../topup/topupFormViewModel";

import {BankTopupProcessViewModel} from "./bankTopupProcessViewModel";

type TopUpRequestDTOwithConversion = Kuva.BL.BusinessModels.Api.TopUp.TopUpRequestDTOwithConversion;

export class BankTopupFormViewModel extends TopupFormViewModel {
    api = new TopUpBankApiClient();

    protected async findTopup(referenceNumber: string): Promise<TopUpRequestDTOwithConversion> {
        return await tryAlternatives(referenceNumber, (refNum) => this.api.detailsByReference(refNum));
    }

    protected startProcess(request: Kuva.BL.BusinessModels.Api.TopUp.TopUpRequestDTOwithConversion): BankTopupProcessViewModel {
        return new BankTopupProcessViewModel(request);
    }
}