import { CashOutApiClient } from "../../api/agent/cashOutApiClient";
import { Kuva, KuvaWeb } from "../../interfaces/kuvaweb";
import * as signalR from "@microsoft/signalr";
import * as url from "../../utils/url";

import CashOutBookingStatus = Kuva.Module.Enums.CashOutBookingStatus;
import { BehaviorSubject } from "rxjs";

type CashoutAgentResponseModel = Kuva.PL.KuvaWeb.Areas.Agent.Models.AgentCashoutRequestModel;
type LazyLoadedPagedCashoutsList = Kuva.Module.Models.Paging.LazyLoadingPagedListModel<CashoutAgentResponseModel>
const CountType = Kuva.Module.Enums.RecordCountType;

export class PagedCashoutsManager {
    private static _instance: PagedCashoutsManager;
    static get instance() {
        return this._instance = new PagedCashoutsManager();
    }

    private api = new CashOutApiClient();

    bookedPagedResponse = new BehaviorSubject<LazyLoadedPagedCashoutsList>(null);
    public get pagedCashoutResponse() { return this.bookedPagedResponse.asObservable(); }

    bookedRequestsCountSubject = new BehaviorSubject<number>(null);
    public get bookedRequestsCount() { return this.bookedRequestsCountSubject.asObservable(); }

    _bookedCashoutsPagedResponse: LazyLoadedPagedCashoutsList;
    _bookedCashoutsCount: number

    constructor() {
        this.connect();
        this.load();
    }

    async load(): Promise<void> {
        try {
            var response = await
                this.getRequests(
                    {
                        pageSize: 30, offset: 0,
                        statuses: [], query: "",
                        dateRange:
                            { startDate: "", endDate: "" }
                    });

            this._bookedCashoutsPagedResponse = response;

            var count = await this.getRequestsCount();
            this._bookedCashoutsCount = count.value;

            this.publish();
        } catch (error) {
            this.publish()
        }
    }

    private publish(): void {
        this.bookedPagedResponse.next(this._bookedCashoutsPagedResponse);
        this.bookedRequestsCountSubject.next(this._bookedCashoutsCount)
    }

    private async getRequests(filter: Kuva.Module.Models.Paging.Agent.CashoutFilter): Promise<Kuva.Module.Models.Paging.LazyLoadingPagedListModel<Kuva.PL.KuvaWeb.Areas.Agent.Models.AgentCashoutRequestModel>> {
        return await this.api.getLazyPagedBookedCashouts(filter)
    }

    private async getRequestsCount() {
        return await this.api.getBookedCashoutsCount(CountType.booked)
    }

    private async connect(): Promise<void> {
        const hubConnection = new signalR.HubConnectionBuilder()
            .withUrl(url.combine(window.configData.apiUrl, "api/v1/agents/events"))
            .build();
        hubConnection.on("CashoutUpdated", (e) => {
            this.handleRequestUpdated(e);
        });
        await hubConnection.start();
    }

    private handleRequestUpdated(request: CashoutAgentResponseModel): void {
        if (this._bookedCashoutsPagedResponse.items == null)
            return;

        const currentIndex = (this._bookedCashoutsPagedResponse.items
            .findIndex(req =>
                this.id(req) == this.id(request)));
        if (currentIndex !== -1) {
            if (!this.filter(request)) {
                const newCollection = [...this._bookedCashoutsPagedResponse.items];
                newCollection.splice(currentIndex, 1);
                this._bookedCashoutsPagedResponse.items = newCollection;
                this._bookedCashoutsPagedResponse.count -= 1;
                this._bookedCashoutsCount = this._bookedCashoutsPagedResponse.count;
            }
            this.publish();
        } else {
            if (this.filter(request)) {
                request.isNew = true;

                this._bookedCashoutsPagedResponse.items = [request, ...this._bookedCashoutsPagedResponse.items];
                this._bookedCashoutsPagedResponse.count += 1;
                this._bookedCashoutsCount = this._bookedCashoutsPagedResponse.count;
            }
            this.publish();
        }
    }

    private id(request: CashoutAgentResponseModel): string {
        return request.bookingId;
    }

    private filter(request: CashoutAgentResponseModel): boolean {
        return request.status == CashOutBookingStatus.active;
    }
}