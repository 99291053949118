import React, { useEffect, useState } from "react";
import { AuthenticationManager } from "../managers/authenticationManager"
import { useHistory } from "react-router"
import { Redirect } from 'react-router-dom';

interface PrivateRouteProps {
    Component?: React.ElementType;
    path: string;
    exact?: boolean;
    roles: string[];
}

const PrivateRouteComponent: React.FC<PrivateRouteProps> = (props: PrivateRouteProps,) => {
    const history = useHistory();
    const [allowRender, setAllowRender] = useState(false);

    const { Component, roles } = props;

    const authenticated = AuthenticationManager.instance.authenticated;
    const ready = AuthenticationManager.instance.ready;
    const currentUserRoles = AuthenticationManager.instance.roles;

    useEffect(() => {
        if (ready) {
            if (roles.some(n => currentUserRoles.includes(n))) {
                setAllowRender(true);
            }
            else {
                history.push('/');
            }
            if (!authenticated) {
                history.push('/');
            }
        }

    }, [authenticated, currentUserRoles, roles, ready]);

    return allowRender ? <Component {...props} /> : null
}

export default PrivateRouteComponent;