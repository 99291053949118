import * as signalR from '@microsoft/signalr'
import { TopUpBankApiClient } from '../api/agent/topUpBankApiClient'
import { Kuva } from '../interfaces/kuvaweb'
import { PastCashRequestsManager } from './pastCashRequestsManager'
import * as url from '../utils/url'

import TopUpStatus = Kuva.Module.Enums.TopUpStatus

type AgentTopUpRequestModel = Kuva.PL.KuvaWeb.Areas.Agent.Models.AgentTopUpRequestModel
import AddFundsType = Kuva.Module.Enums.AddFundsType

export class PastBankTopupsManager extends PastCashRequestsManager<AgentTopUpRequestModel> {
  private static _instance: PastBankTopupsManager
  static get instance() {
    return (this._instance = new PastBankTopupsManager())
  }

  private api = new TopUpBankApiClient()

  constructor() {
    super()
    this.load()
  }

  protected async getRequests() {
    return await this.api.getAgentPastTopups()
  }

  protected async connect(): Promise<void> {
    const hubConnection = new signalR.HubConnectionBuilder()
      .withUrl(url.combine(window.configData.apiUrl, 'api/v1/agents/events'))
      .withAutomaticReconnect()
      .build()
    hubConnection.on('TopupUpdated', e => {
      this.handlePastRequestUpdated(e)
    })
    await hubConnection.start()
  }

  protected id(request: AgentTopUpRequestModel): string {
    return request.id
  }

  protected filter(requests: AgentTopUpRequestModel): boolean {
    return (
      (requests.status === TopUpStatus.cancelled || requests.status === TopUpStatus.completed) &&
      requests.type == AddFundsType.bankTransfer
    )
  }
}
