import { t } from "i18next";
import React, { ChangeEvent, Dispatch, FormEvent, SetStateAction, useMemo, useState } from "react"
import { ChangeMerchantProcessViewModel } from "../../viewModels/changeMerchant/changeMerchantProcessViewModel";
import { Modal } from "../modal";
import { CompactField } from "../compactField";
import { observer } from "mobx-react";
import { IndicatorButton } from "../indicatorButton";
import { ChangeMerchantViewModel } from "../../viewModels/changeMerchant/changemerchantTransactionsViewModel";
import { DEFAULT_MIN_VERSION } from "tls";

const ChangeMerchantProcessDialogue: React.FC<{ countries: string[]; setShowModal: Dispatch<SetStateAction<boolean>>; showModal: boolean; viewModel: ChangeMerchantViewModel }> = observer(({ countries, setShowModal, showModal, viewModel }) => {
  const procViewModel = new ChangeMerchantProcessViewModel();
  const countryCodes = useMemo(() => countries, [countries]);

  const [receipt, setReceipt] = useState<string>("");
  const [phoneNumber, setPhoneNumber] = useState<string>("");
  const [selectedCountryCode, setSelectedCountryCode] = useState<string>(countryCodes[0]);
  const [pageNumber, setPageNumber] = useState(1);
  const [showError, setShowError] = useState(false);
  const [purchase, setPurchase] = useState<number | string>('');
  const [received, setReceived] = useState<number | string>('');
  const [customerName, setCustomerName] = useState<string>();
  const [checkId, setCheckId] = useState(false);
  const [checkTerms, setCheckTerms] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string>(undefined);

  const handleCodeChange = (event: ChangeEvent<HTMLSelectElement>) => {
    setSelectedCountryCode(event.target.value);
  }

  const checkCustomerName = async (e: React.FormEvent) => {
    e.preventDefault();
    setShowError(false);
    setLoading(true);
    try {
      if (+received - +purchase <= 0) {
        setShowError(true);
        return;
      }
      const result = await procViewModel.checkCustomerName(selectedCountryCode + phoneNumber);
      setCustomerName(result.fullName);
      setLoading(false);
      setPageNumber(2);
    } catch (error) {
      setError(t("changeMerchant.error.phoneInvalid"));
      setLoading(false)
      return;
    }
  }

  const onConfirmTX = async (e: FormEvent) => {
    e.preventDefault()
    setError("")
    try {
      setLoading(true);
      const result = await procViewModel.createTransaction(+purchase, +received, change, fullPhoneNumber, receipt, true, true);
      viewModel.addTx(result);
      setLoading(false);
      setPageNumber(3);
    } catch (error) {
      setLoading(false);
      setError(error.message)
    }
  }

  const changePhoneNumber = (phone: string) => {
    setError("");
    setPhoneNumber(phone)
  }

  const onBackClick = () => {
    setPageNumber(1);
  }

  const fullPhoneNumber = useMemo(() => selectedCountryCode + phoneNumber, [selectedCountryCode, phoneNumber]);
  const change = useMemo(() => (+received > +purchase) && +received - +purchase, [received, purchase])

  return <Modal
    className="process-modal">
    <div className="modal-header">
      <h5 className="modal-title">{t("changeMerchant.new.title")}</h5>
      <button onClick={() => setShowModal(!showModal)} className="btn-close" />
    </div>
    <div className="modal-body">
      {
        pageNumber === 1 ?
          <>
            <form onSubmit={(e) => checkCustomerName(e)}>
              <div className="section-identity">
                <div className="info-field">
                  <div className="label">{t("changeMerchant.new.purchaseF")}</div>
                  <div className="info">
                    <input placeholder="0" inputMode="numeric" autoComplete="none" type="number" onChange={(e) => setPurchase(e.target.value)} value={purchase} required />
                  </div>
                </div>
                <div className="info-field">
                  <div className="label">{t("changeMerchant.new.receivedF")}</div>
                  <div className="info">
                    <input placeholder="0" inputMode="numeric" autoComplete="none" type="number" onChange={(event) => setReceived(event.target.value)} value={received} required />
                  </div>
                </div>
                <div className="info-field">
                  <div className="label">{t("changeMerchant.new.creditedF")}</div>
                  <div className="info">{change || 0}</div>
                </div>
                <div className="fixed-validation-message">
                  {showError && <p className="enter-done">{t("changeMerchant.error.amountGreater")}</p>}
                </div>
                <div className="receipt">
                  <CompactField inputMode="text" autoCapitalize="none" type="text" placeholder={t("changeMerchant.new.addReceipt")} onChange={(value) => setReceipt(value)} value={receipt} />
                </div>
                <div className="modal-phone">
                  <p>{t("changeMerchant.new.enterPhone")}</p>
                  <div>
                    {error?.length > 0 && <div className="error text-center mb-10">{error}</div>}
                  </div>
                  <div className="select-phone">
                    <select className="country-select" onChange={handleCodeChange} value={selectedCountryCode}>
                      {countryCodes.map((country) =>
                        <option value={country} key={country}>
                          {country}
                        </option>
                      )}
                    </select>
                    <CompactField inputMode="numeric" autoCapitalize="none" type="number" placeholder={t("changeMerchant.new.klPhone")} onChange={changePhoneNumber} value={phoneNumber} required />
                  </div>
                </div>
              </div>

              <div className="button-form justifyCenter">
                <button
                  disabled={loading}
                  onClick={() => setShowModal(!showModal)}
                  className="btn-indicator btn-default btn-red  ">
                  {t("changeMerchant.button.cancel")}
                </button>
                <IndicatorButton
                  loading={loading}
                  disabled={loading}
                  type="submit"
                  className="btn-default btn-green">
                  {t("changeMerchant.button.next")}
                </IndicatorButton>
              </div>
            </form>
          </>
          :
          pageNumber === 2
            ?
            <form onSubmit={onConfirmTX} className="section-identity">
              <div className="info-field">
                <div className="label">{t("changeMerchant.new.customerName")}</div>
                <div className="info">{customerName}</div>
              </div>
              <div className="info-field">
                <div className="label">{t("changeMerchant.new.phoneNumber")}</div>
                <div className="info">{fullPhoneNumber}</div>
              </div>
              <div className="info-field">
                <div className="label">{t("changeMerchant.new.purchaseReady")}</div>
                <div className="info">{purchase}</div>
              </div>
              <div className="info-field">
                <div className="label">{t("changeMerchant.new.receivedReady")}</div>
                <div className="info">{received}</div>
              </div>
              <div className="info-field">
                <div className="label">{t("changeMerchant.new.changeReady")}</div>
                <div className="info">{change.toFixed(2)}</div>
              </div>
              <div className="checkbox-field checkbox-field-curPointer">
                <input id="idCheck" type="checkbox" onChange={() => setCheckId(!checkId)} required />
                <label htmlFor="idCheck">{t("changeMerchant.new.idCheck")}</label>
              </div>
              <div className="checkbox-field checkbox-field-curPointer">
                <input id="terms" onChange={() => setCheckTerms(!checkTerms)} type="checkbox" checked={checkTerms} required />
                <label htmlFor="terms">{t("changeMerchant.new.receivedCorrect")}</label>
              </div>
              <div className="error text-center">{error.length > 0 && error}</div>
              <div className="button-form justifyCenter">
                <button
                  disabled={loading}
                  onClick={() => onBackClick()}
                  className="btn-default btn-red">
                  {t("changeMerchant.button.back")}
                </button>
                <IndicatorButton
                  loading={loading}
                  disabled={loading}
                  type="submit"
                  className="btn-default btn-green">
                  {t("changeMerchant.button.create")}
                </IndicatorButton>
              </div>
            </form>
            :
            <div
              className="modal-body-completed">
              <h5 className="modal-title">{t("changeMerchant.ok")}</h5>
              <div className="button-form justifyCenter">
                <button
                  className="btn-default btn-green"
                  onClick={() => setShowModal(!showModal)}>
                  {t("changeMerchant.button.ok")}
                </button>
              </div>
            </div>
      }
    </div>
  </Modal >
})
export default ChangeMerchantProcessDialogue;