﻿import { action, makeAutoObservable, observable } from "mobx";
import { ChangeEvent } from "react";

import { CashOutApiClient } from "../../api/agent/cashOutApiClient";
import { ApiError } from "../../api/apiError";
import { tryAlternatives } from "../../utils/refNum";

import { CashoutProcessViewModel } from "./cashoutProcessViewModel";

export class CashoutFormViewModel {
  api = new CashOutApiClient();

  @observable referenceNumber: string = "";
  @observable validating: boolean = false;
  @observable valid: boolean = null;

  @observable process: CashoutProcessViewModel = null;

  constructor() {
    makeAutoObservable(this);
  }

  @action changeReference(value: string) {
    if (value.replace(/ /g, '').length > 11) {
      this.referenceNumber = value.substring(0, 11)
      return
    }
    this.referenceNumber = value.replace(/ /g, '');
  }

  async verify() {
    if (this.process != null)
      return;

    this.validating = true;
    this.valid = null;
    try {
      let cashoutRequest = await tryAlternatives(this.referenceNumber, (refNum) => this.api.getCashoutRequestDetailsByReference(refNum));
      if (cashoutRequest != null && typeof cashoutRequest !== "string")
        this.process = new CashoutProcessViewModel(cashoutRequest);
      else
        this.valid = false;
    } catch (e) {
      if (e instanceof ApiError) {
        this.valid = false;
      } else {
        //TODO: Handle network errors
      }
    } finally {
      this.validating = false;
    }

    if (this.process) {
      try {
        await this.process.promise;
      } finally {
        this.process = null;
        this.referenceNumber = "";
      }
    }
  }
}