import { action, makeObservable, observable } from "mobx";
import { SettlementApiClient } from "../../api/agent/settlementApiClient";
import { Kuva } from "../../interfaces/kuvaweb";
import { IPromiseViewModel } from "../promiseViewModel";

var fileDownload = require('js-file-download');

type SettlementDTO = Kuva.BL.BusinessModels.AdminDTO.KuvaLocalSettlementManifestDTO;


export class SettlementDetailsViewModel implements IPromiseViewModel<void>{
    private api = new SettlementApiClient();

    promise: Promise<void>;

    private promiseAccept: () => void;
    private promiseReject: () => void;

    private readonly requestId: number;
    @observable request: SettlementDTO;
    @observable downLoading: boolean = false;
    @observable loadIng: boolean = false;   

    constructor(requestId: number) {
        this.requestId = requestId;

        this.promise = new Promise((acc, rej) => {
            this.promiseAccept = acc;
            this.promiseReject = rej;
        });
        this.load();

        makeObservable(this);
    }

    @action
    private async load() {
        this.request = null;
        this.loadIng = true;
        try {
            this.request = await this.api.detailsById(this.requestId);
            this.loadIng = false;
        } catch (error) {
            this.loadIng = false;
        }
        finally {
            this.loadIng = false;
        }
    }

    @action
    async getPdf() {
        if (this.requestId === null) return;
        this.downLoading = true;
        const fileResponse = await this.api.getPdf(this.requestId);
        const file = await fileResponse.blob();
        const header = fileResponse.headers.get("Content-Disposition")
        const fileName = header.match(/filename=(.*)\;/)[1]
        await fileDownload(file, fileName);
        this.downLoading = false;
    }

    @action
    async getCsv() {
        if (this.requestId === null) return;
        this.downLoading = true;
        const fileResponse = await this.api.getCsv(this.requestId);
        const file = await fileResponse.blob();
        const header = fileResponse.headers.get("Content-Disposition")
        const fileName = header.match(/filename=(.*)\;/)[1]
        await fileDownload(file, fileName);
        this.downLoading = false;
    }
}