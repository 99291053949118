import React from "react"
import ReactTooltip from "react-tooltip"
import { Icon } from "../components/icons"
import { Kuva } from "../interfaces/kuvaweb"

type InfoCopyFieldProps = Kuva.Module.Props.InfoCopyFieldProps;

export const InfoCopyField: React.FC<InfoCopyFieldProps> = (props) => {
    const { field, dataFor } = props;
    const onCopyClick = (value: string | number) => {
        navigator.clipboard.writeText(value.toString());
    }

    return <p className="info copy-field" data-tip="Copied to clipboard" data-event="click focus" data-for={dataFor}
        onClick={() => onCopyClick(field)}>
        {field}
        <Icon.CopyText />
        <ReactTooltip
            id={dataFor}
            type="success"
            afterShow={() => setTimeout(() => ReactTooltip.hide(), 1000)}
            isCapture
            place="right"
            event="click">
            Copied to clipboard
        </ReactTooltip>
    </p>
}