import { t } from "i18next";
import { action, computed, makeAutoObservable, observable, runInAction } from "mobx";
import { KWlCashoutApiClient } from "../../api/agent/kwlCashOutApiClient";
import { Kuva } from "../../interfaces/kuvaweb";
import { Mutex } from "../../utils/mutex";
import * as refNum from "../../utils/refNum";

const KWLCashOutStatus = Kuva.Module.Enums.CashOutBookingStatus;
type KWLCashOutsRequestModel = Kuva.PL.KuvaWeb.Areas.Agent.KWL.AgentKWLCashoutDetails;
type CustomResponse = Kuva.PL.KuvaWeb.API.CustomResponse;

export class KWLCashOutProcessViewModel {
    api = new KWlCashoutApiClient();

    @computed get completed(): boolean {
        return this.result != null;
    };

    @observable result: "accepted" | "cancelled" | "failed" = null;
    @observable error: string = "";
    @observable loading: boolean = false;
    @observable canSubmit: boolean = false;
    @observable digitalNumber: string = "";
    @observable validationMessage: string = null;
    @observable bookError: string = null;

    @observable request: KWLCashOutsRequestModel;
    promise: Promise<void>;

    private promiseAccept: () => void;
    private promiseReject: () => void;
    private mutex = new Mutex();

    constructor(cashoutRequest: KWLCashOutsRequestModel) {
        makeAutoObservable(this);

        if (cashoutRequest.status == KWLCashOutStatus.cancelled)
            this.result = "cancelled";
        else if (cashoutRequest.status == KWLCashOutStatus.completed)
            this.result = "accepted";
        else if (cashoutRequest.status != KWLCashOutStatus.active && cashoutRequest.status != KWLCashOutStatus.pendingPayment)
            this.result = "failed";

        this.load(cashoutRequest)

        this.promise = new Promise((accept, reject) => {
            this.promiseAccept = accept;
            this.promiseReject = reject;
        });
    }

    @action load(cashoutRequest: KWLCashOutsRequestModel) {
        this.request = cashoutRequest;
        this.request.isBooked && (this.request.status !== KWLCashOutStatus.completed && this.request.status !== KWLCashOutStatus.cancelled) ? (this.canSubmit = true) : false;
    }

    @action changeDigital(e: string) {
        if (e.replace(/ /g, '').length > 13) {
            this.digitalNumber = e.substring(0, 13)
            return
        }
        this.digitalNumber = e.replace(/ /g, '');
    }

    async accept(setShowPopup: (show: boolean) => void) {
        await this.mutex.auto(async () => {
            this.error = undefined;
            if (!this.validate()) {
                setShowPopup(false)
                return;
            }
            try {
                this.loading = true;
                const response = await this.api.complete(this.digitalNumber.replace(/-/g, " "), this.request.id);
                this.request = response;
                this.canSubmit = false;
                setShowPopup(false);
                this.loading = false;
                return;
            } catch (error) {
                this.loading = false;
                setShowPopup(false);
                let res = error as CustomResponse;
                if (res.code === 404) {
                    this.error = t("kwlCashout.referenceError");
                    return
                }
                else if (res.message.length > 0) {
                    this.error = res.message;
                    return
                }
            }
        });
    }

    @action async book(requestId: string) {
        this.bookError = undefined;
        try {
            this.loading = true;
            await this.api.bookRequest(requestId);

            this.request.isBooked = true;
            this.canSubmit = true;
            this.loading = false;
        } catch (error) {
            let res = error as CustomResponse;
            if (res.errorCode === "404") {
                this.bookError = res.message;
                return;
            }
            else if (res.message.length > 0) {
                this.bookError = res.message;
                return;
            }
            this.bookError = t("component.error.somWentWrong");
            return;
        }

    }

    @action handleBookedCashOut() {
        this.request = {
            ...this.request,
            isBooked: true
        }
    }

    private validate() {
        const result = refNum.validateDigital(this.digitalNumber);
        this.validationMessage = result.message

        return result.valid;
    }

    @action async complete(enteredRefnum: string, setShowPopup: React.Dispatch<React.SetStateAction<boolean>>) {
        this.error = undefined;
        if (!this.validate()) {
            setShowPopup(false)
            return;
        }
        try {
            this.loading = true;
            const response = await this.api.complete(enteredRefnum.replace(/-/g, " "), this.request.id);
            this.request = response;
            this.canSubmit = false;
            setShowPopup(false);
            this.loading = false;
            return;
        } catch (error) {
            this.loading = false;
            setShowPopup(false);
            let res = error as CustomResponse;
            if (res.code === 404) {
                this.error = t("kwlCashout.referenceError");
                return
            }
            else if (res.message.length > 0) {
                this.error = res.message;
                return
            }
        }
    }

    @action clearErrors() {
        this.error = undefined
        this.bookError = undefined
    }

    // async delete() {
    //     await this.mutex.auto(async () => {
    //         if (confirm(t("cashout.deletePrompt"))) {
    //             try {
    //                 await this.api.cancelCashOutRequest({ bookingId: this.request.bookingId, referenceNumber: this.request.referenceNumber })
    //                 this.request.status = CashOutBookingStatus.cancelled;
    //                 this.result = "cancelled";
    //             } catch {
    //                 this.result = "failed";
    //             }
    //         }
    //     });
    // }

    async cancel() {
        this.promiseAccept();
    }

    async okay() {
        this.promiseAccept();
    }
}