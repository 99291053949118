import { t } from 'i18next'
import { observer } from 'mobx-react-lite'
import React, { useMemo } from 'react'
import { SearchInput } from '../../components/searchInput'
import { KWLCashoutRequestsViewModel } from '../../viewModels/kwlCashout/cashoutViewModel'
import useInfiniteScroll from 'react-infinite-scroll-hook'
import LazyLoadingTable from '../../components/table/lazyLoadingTable'
import { KWLSingleCashOutsTableConfig } from './config'

const KwlSingleCashOutList: React.FC<{ viewModel: KWLCashoutRequestsViewModel }> = observer(
  props => {
    const { viewModel } = props
    let current = viewModel.current
    let step = 10
    let loading = viewModel.tab === 'booked' ? viewModel.booked.loading : viewModel.past.loading
    let hasNextPage =
      viewModel.tab === 'booked' ? viewModel.booked.hasNextPage : viewModel.past.hasNextPage
    const tableConfig = useMemo(() => KWLSingleCashOutsTableConfig(viewModel.tab), [viewModel.tab])
    const onLoadMore = () => {
      viewModel.tab === 'booked'
        ? viewModel.booked.onLoadMore(step)
        : viewModel.past.onLoadMore(step)
    }

    const [sentryRef, { rootRef }] = useInfiniteScroll({
      loading,
      hasNextPage,
      onLoadMore,
      disabled: false,
      rootMargin: '0px 0px 300px 0px',
    })

    return (
      <div className="dashboard-page-panel">
        <div className="page-panel-title">
          <ul className="nav custom-nav-pills nav-pills nav-fill">
            <li className="nav-item">
              <button
                onClick={() => viewModel.changeTab('booked')}
                className={`nav-link ${viewModel.tab === 'booked' ? 'active' : ''}`}
              >
                {t('kwlCashout.bookedTab')}
              </button>
            </li>
            <li className="nav-item">
              <button
                onClick={() => viewModel.changeTab('past')}
                className={`nav-link ${viewModel.tab === 'past' ? 'active' : ''}`}
              >
                {t('kwlCashout.pastTab')}
              </button>
            </li>
          </ul>
        </div>
        <div className="search">
          <SearchInput onChange={e => viewModel.changeSearch(e)} />
        </div>
        <LazyLoadingTable
          sentryRef={sentryRef}
          rootRef={rootRef}
          columnCount={5}
          hasNextPage={hasNextPage}
          tableConfig={tableConfig}
          onClick={viewModel.select.bind(viewModel)}
          loading={loading}
          list={current.filteredRequests}
          className="kwl-cashout-without-bundle"
        />
      </div>
    )
  }
)
export default KwlSingleCashOutList
