﻿import React from "react";
import {CSSTransition} from "react-transition-group";

export interface ModalProps {
    size?: "lg" | "sm" | "md";
    className?: string;
}

export class Modal extends React.Component<ModalProps> {
    render() {
        return <>
            <CSSTransition in appear timeout={100} classNames={{appearActive: "show", appearDone: "show"}}>
                <div className={`modal fade ${this.props.className}`} style={{display: 'block'}} tabIndex={-1}>
                    <div className={`modal-dialog modal-dialog-centered ${this.props.size ? `modal-${this.props.size}` : null}`}>
                        <div className="modal-content">
                            {this.props.children}
                        </div>
                    </div>
                </div>
            </CSSTransition>
            <CSSTransition in appear timeout={100} classNames={{appearActive: "show", appearDone: "show"}}>
                <div className={"modal-backdrop fade"}/>
            </CSSTransition>
        </>;
    }
}