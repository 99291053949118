import { t } from "i18next"
import { observer } from "mobx-react"
import React, { FormEvent } from "react"
import { IndicatorButton } from "../../components/indicatorButton"
import { ReferenceNumberInput } from "../../components/referenceNumberInput"
import { CSSTransition } from "react-transition-group";
import { CashOnDeliveryViewModel } from "../../viewModels/cashOnDelivery/cashOnDeliveryOrdersViewModel"

@observer
export class CashOnDeliveryForm extends React.Component<{ viewModel: CashOnDeliveryViewModel }> {
  viewModel = this.props.viewModel;

  handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    this.viewModel.select(null, null, this.viewModel.reference);
  }

  render() {
    const viewModel = this.props.viewModel;
    return <>
      <div className="verify-form">
        <form onSubmit={(e) => this.handleSubmit(e)}>
          <div className="reference-number-wrapper">
            <ReferenceNumberInput
              value={this.viewModel.reference}
              onChange={(e) => this.viewModel.changeReference(e.target.value)} />
            <IndicatorButton className="btn-default btn-primary"
              type="submit">
              {t("cashOnDelivery.button.verify")}
            </IndicatorButton>
          </div>
          <CSSTransition in={viewModel.valid == false} unmountOnExit timeout={150}>
            <p>{t("referenceNumberNotFound")}</p>
          </CSSTransition>
        </form>
      </div>
    </>
  }
}