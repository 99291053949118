import React from 'react'
import { observer } from 'mobx-react'
import { t } from 'i18next'

import ReactDatePicker from 'react-datepicker'
import { Modal } from '../../components/modal'

import KwlReportViewModal from '../../viewModels/kwlCashout/KwlReportViewModel'

type KwlReportPopUpProps = {
  viewModel: KwlReportViewModal
}

const KwlReportPopUp: React.FC<KwlReportPopUpProps> = observer(
  ({ viewModel }: KwlReportPopUpProps) => {
    return (
      <>
        {viewModel.isOpenPopUp && (
          <Modal size="md" className="dialogue-cash dialogue-cash-out">
            <div className="modal-header">
              <h5>{t('kwlCashout.downloadingReportModal.title')}</h5>
              <button
                type="button"
                onClick={viewModel.closePopUp}
                className="btn-close"
                aria-label="Close"
              />
            </div>
            <div className="modal-body">
              <div className="date-select-wrapper d-flex d-flex-center mt-20 mb-20">
                <div className="datepicker date-picker-modal">
                  <div className="d-flex align-items-center">
                    <div className="datepicker-item">
                      <ReactDatePicker
                        selected={viewModel.startDate}
                        className="cur-default"
                        dateFormat="dd/MM/yyyy"
                        selectsStart
                        placeholderText={t(
                          'kwlCashout.downloadingReportModal.startDatePlaceholder'
                        )}
                        onChange={viewModel.onChangeStart}
                        startDate={viewModel.startDate}
                        portalId="react-root"
                        maxDate={viewModel.endDate}
                        endDate={viewModel.endDate}
                      />
                    </div>
                    <div className="datepicker-item">
                      <ReactDatePicker
                        selected={viewModel.endDate}
                        dateFormat="dd/MM/yyyy"
                        className="cur-default"
                        selectsEnd
                        placeholderText={t('kwlCashout.downloadingReportModal.endDatePlaceholder')}
                        portalId="react-root"
                        endDate={viewModel.endDate}
                        onChange={viewModel.onChangeEnd}
                        minDate={viewModel.startDate}
                        startDate={viewModel.startDate}
                      />
                    </div>
                  </div>
                  <button className="btn-default btn-primary" onClick={viewModel.resetDates}>
                    {t('kwlCashout.downloadingReportModal.resetBtn')}
                  </button>
                  <button className="btn btn-link" onClick={viewModel.getCsv}>
                    {t('kwlCashout.downloadingReportModal.downloadBtn')}
                  </button>
                </div>
              </div>
            </div>
          </Modal>
        )}
      </>
    )
  }
)

export default KwlReportPopUp
