import { Kuva } from "../../interfaces/kuvaweb"
import React from "react"
import { Link } from "react-router-dom"
import { Icon } from "../icons"
import { t } from "i18next"

type DashboardProps = Kuva.Module.Props.DashboardProps

const DashboardPermissionButton: React.FC<DashboardProps> = (props) => {
    const { permission, count, lang, to } = props

    return <>
        {
            permission ?
                <Link
                    className="btn btn-secondary btn-lg"
                    to={to}>
                    {t(lang)}
                    <span className="badge">
                        {
                            count ??
                            <Icon.DashboardIndicator />
                        }
                    </span>
                </Link>
                :
                null
        }
    </>
}
export default DashboardPermissionButton