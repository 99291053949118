﻿import { action, autorun, IReactionDisposer, makeObservable, observable, runInAction } from "mobx";
import { Subscription } from "rxjs";

import { IPromiseViewModel } from "./promiseViewModel";
import { ViewModelBase } from "./viewModelBase";

import * as fmt from '../utils/formatting';

interface ICashRequestList<T> extends ViewModelBase {
  loading: boolean;
  requestsCount: number;
  filteredRequests: T[];
  setSearch(search: string): void;
}

interface ICashRequestFields {
  date: string;
  phoneNumber: string;
  referenceNumber: string;
  bookingId?: string
}

export abstract class BookedCashRequestsViewModel<T extends ICashRequestFields> extends ViewModelBase implements ICashRequestList<T> {
  protected requests: T[] = [];
  protected search: string = "";

  @observable filteredRequests: T[] = [];
  @observable requestsCount: number = 0;
  @observable loading: boolean = false;

  constructor() {
    super();
    makeObservable(this);
  }

  *subscribe(): Generator<Subscription | IReactionDisposer> {
    yield autorun(() => {
      this.updateFilter(this.search);
    });
  }

  @action
  handleRequests(requests: T[]) {
    if (requests == null) {
      this.loading = true;
      return;
    }

    this.requestsCount = requests.length;
    this.requests = requests.sort(applySort());
    this.updateFilter(this.search);
    this.loading = false;
  }

  @action
  private updateFilter(search: string) {
    if (this.requests == null)
      return;

    this.filteredRequests = this.requests.filter(applyFilter(search)).sort(applySort());
  }

  setSearch(search: string): void {
    this.search = search;
    this.updateFilter(this.search);
  }
}

export abstract class PastCashRequestsViewModel<T extends ICashRequestFields> extends ViewModelBase implements ICashRequestList<T> {
  protected requests: T[] = [];
  protected search: string = "";

  @observable filteredRequests: T[] = [];
  @observable requestsCount: number = 0;
  @observable loading: boolean = false;


  constructor() {
    super();
    makeObservable(this);
  }

  *subscribe(): Generator<Subscription | IReactionDisposer> {
    yield autorun(() => {
      this.updateFilter();
    })
  }

  @action
  handleRequests(requests: T[]) {
    if (requests === null) {
      this.loading = true;
      return;
    }
    this.requestsCount = requests.length;
    this.requests = requests.sort(applySort());
    this.updateFilter();
    this.loading = false;
  }

  @action
  private updateFilter() {
    runInAction(() => {
      this.filteredRequests = this.requests.filter(applyFilter(this.search));
    })
  }

  setSearch(search: string): void {
    this.search = search;
    this.updateFilter();
  }
}

export abstract class CashRequestsViewModel<T extends ICashRequestFields> extends ViewModelBase {
  booked: ICashRequestList<T> = null;
  past: ICashRequestList<T> = null;

  @observable current: ICashRequestList<T> = null;

  @observable details: IPromiseViewModel<void> = null;

  @observable search: string = "";

  @observable tab: "past" | "booked" = "booked";

  protected constructor() {
    super();

    this.updateTab();
    makeObservable(this);
  }

  mount() {
    this.booked.mount();
    this.past.mount();
    super.mount();
  }

  unmount() {
    this.booked.unmount();
    this.past.unmount();
    super.unmount();
  }

  *subscribe() {
    yield autorun(() => {
      this.updateTab();
    });
  }

  abstract select(request: T): void;

  protected updateTab() {
    this.current = this.tab == "booked" ? this.booked : this.past;
    this.current?.setSearch(this.search);
  }

  @action changeSearch(e: React.ChangeEvent<HTMLInputElement>) {
    this.search = e.target.value;
  }
}

function applySort(): (a: ICashRequestFields, b: ICashRequestFields) => number {
  return (a, b) => {
    return new Date(b.date).getTime() - new Date(a.date).getTime();
  };
}

function applyFilter(query: string): (a: ICashRequestFields) => boolean {

  if (query == null || query.length < 1)
    return () => true;

  query = query.trim();

  var phoneSearch = query.replace(/\s/g, "");
  var refNumStartSearch = query.substr(0, 2).toUpperCase();
  var refNumEndSearch = query.substr(query.length - 2, 2).toUpperCase();

  return (a) => {
    if (a.phoneNumber.replace(/\s/g, "").indexOf(phoneSearch) !== -1)
      return true;
    if (a.referenceNumber.startsWith(refNumStartSearch))
      return true;
    if (a.referenceNumber.endsWith(refNumEndSearch))
      return true;
    return false;
  };
}