import { action, makeAutoObservable, observable, onBecomeObserved } from 'mobx'
import KwlBookedMultiCashOutViewModel from './kwlBookedMultiCashOutViewModel'
import { KwlPastMultiCashOutViewModel } from './kwlPastMultiCashOutViewModel.ts'
import KwlProcessingMultiCashOutViewModel from './kwlProcessingMultiCashOutViewModel'

export class KwlMultiCashOutViewModel {
  booked: KwlBookedMultiCashOutViewModel = null
  processing: KwlProcessingMultiCashOutViewModel = null
  past: KwlPastMultiCashOutViewModel = null

  @observable current:
    | KwlBookedMultiCashOutViewModel
    | KwlProcessingMultiCashOutViewModel
    | KwlPastMultiCashOutViewModel = null
  @observable search: string = ''
  @observable loaded: boolean = false
  @observable tab: 'booked' | 'processing' | 'past' = 'booked'

  timer: undefined | NodeJS.Timeout

  constructor() {
    makeAutoObservable(this, {}, { autoBind: true })

    this.booked = new KwlBookedMultiCashOutViewModel()
    this.processing = new KwlProcessingMultiCashOutViewModel()
    this.past = new KwlPastMultiCashOutViewModel()
    this.updateTab()

    onBecomeObserved(this, 'current', this.getRequest)
  }

  @action protected updateTab() {
    if (this.tab === 'booked') {
      this.current = this.booked
      this.current?.setSearch(this.search)
    }
    if (this.tab === 'processing') {
      this.current = this.processing
    }
    if (this.tab === 'past') {
      this.current = this.past
    }
  }

  @action changeSearch(e: React.ChangeEvent<HTMLInputElement>) {
    this.search = e.target.value
    this.current?.setSearch(this.search)
    clearTimeout(this.timer)
    this.timer = setTimeout(() => this.getRequest(), 1000)
  }

  @action changeTab(tab: 'booked' | 'processing' | 'past') {
    this.tab = tab
    this.updateTab()

    this.search = ''

    this.getRequest()
  }
  *getRequest() {
    this.loaded = false
    if (this.tab === 'booked') {
      yield this.booked.getCashoutsWithSearch(this.search)

      this.current = this.booked
      this.loaded = true
    }
    if (this.tab === 'processing') {
      yield this.processing.getCashouts()

      this.current = this.processing
      this.loaded = true
    }
    if (this.tab === 'past') {
      yield this.past.getCashouts()

      this.current = this.past
      this.loaded = true
    }
  }
}
