﻿import { t } from "i18next";
import { observer } from "mobx-react";
import React from "react";

import { IndicatorButton } from "../../components/indicatorButton";
import { Modal } from "../../components/modal";
import { TransitionPanel } from "../../components/transitionPanel";
import * as fmt from "../../utils/formatting";
import { lang } from "../../utils/lang";
import { TopupProcessViewModel } from "../../viewModels/topup/topupProcessViewModel";

@observer
export class CashTopupProcessDialogue extends React.Component<{ viewModel: TopupProcessViewModel }> {
    render() {
        const viewModel = this.props.viewModel;
        const topup = viewModel.request;
        const completed = viewModel.completed;


        return <Modal className={"process-modal"} size={completed ? "sm" : "md"}>
            <div className="modal-header">
                <h5 className="modal-title">{t("topup.process.title")}</h5>
                <button type="button" onClick={() => viewModel.cancel()} className="btn-close" aria-label="Close" />
            </div>
            <div className="modal-body">
                <TransitionPanel page={completed ? "completed" : "prompt"}>
                    <div key={"completed"}>
                        <div className={"result"}>
                            {viewModel.result == "accepted" ? <p>{t("topup.completedMessage")}</p> : null}
                            {viewModel.result == "cancelled" ? <p>{t("topup.cancelledMessage")}</p> : null}
                            {viewModel.result == "failed" ? <p>{t("topup.failedMessage")}</p> : null} { /*TODO: Message?*/}
                        </div>
                        <div className={"actions"}>
                            <div>
                                <IndicatorButton className="btn-default btn-primary"
                                    onCommand={() => viewModel.okay()}>{t("okayButton")}</IndicatorButton>
                            </div>
                        </div>
                    </div>
                    <div key={"prompt"}>
                        <div className={"amount"}>
                            {fmt.currencyRich(topup.from.amount, topup.from.currency)}
                        </div>
                        <div className={"info"}>
                            {/*<div>
                                <div>Phone Number</div>
                                <div>{fmt.phoneNumber(topup.phoneNumber)}</div>
                            </div>*/}
                            <div className="info-field">
                                <div className="label">{t("topup.userNameLabel")}</div>
                                <div className="info">{topup.requesterName}</div>
                            </div>
                            {/*<div>
                                <div>User Date Of Birth</div>
                                <div>{fmt.date(topup.dateOfBirth, "dd-MM-yyyy")}</div>
                            </div>*/}

                            <div className="info-field">
                                <div className="label">{t("topup.statusLabel")}</div>
                                <div className="info">{lang.topupStatus(topup.status)}</div>
                            </div>
                            <div className="info-field">
                                <div className="label">{t("topup.bookedForLabel")}</div>
                                <div className="info">{fmt.dateTimeShort(topup.createdDate)}</div>
                            </div>
                        </div>
                        <div className={"actions"}>
                            <div>
                                <IndicatorButton className="btn-default btn-red"
                                    onCommand={() => viewModel.delete()}>{t("requestCancelButton")}</IndicatorButton>
                            </div>
                            <div>
                                <IndicatorButton className="btn-default btn-green"
                                    onCommand={() => viewModel.accept()}>{t("requestAcceptButton")}</IndicatorButton>
                            </div>
                        </div>
                    </div>
                </TransitionPanel>
            </div>
        </Modal>
    }
}