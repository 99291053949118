import { t } from "i18next";
import { observer } from "mobx-react-lite";
import React, { useState } from "react"
import { useHistory } from "react-router-dom";
import { CompactField } from "../components/compactField";
import { IndicatorButton } from "../components/indicatorButton";
import { LanguageSelection } from "../components/languageSelection";
import useQuery from "../helpers/useQuery";
import { AuthenticationManager } from "../managers/authenticationManager";
import { errorsConfigs } from "../utils/recoveryPasswordErrors";



const RecoveryPassword: React.FC = observer(() => {
    const query = useQuery();
    const history = useHistory();
    const authManager = AuthenticationManager.instance;
    const id = query.get("key");

    const [password, setPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const [showError, setShowError] = useState(false);
    const [loading, setLoading] = useState(false);
    const [passError, setPassError] = useState([]);

    const onSubmit = async (e: React.SyntheticEvent) => {
        e.preventDefault();
        setPassError([]);
       
        const errors = errorsConfigs.map(errorConfig => {
            return password.match(errorConfig.regexp) ? '' : errorConfig.errorName
        }).filter(error => error)

        if (errors.length) {
            setPassError(errors)
        } else {
            setLoading(true);
            await authManager.recoveryPassword(id, password);
            setLoading(false);
        }
    }

    const handleRedirect = () => {
        history.push('/login')
    }

    const comparePasswords = (e: string) => {
        setConfirmPassword(e);
        password === e ? setShowError(false) : setShowError(true);
        e === "" ? setShowError(false) : null;
    }

    const checkError = (): JSX.Element => {
        if (authManager.recoveryError === "expired") {
            return <div
                className="expired">
                <p>{t("forgotPage.recovery.tokenExp")}</p>
                <p>{t("forgotPage.recovery.resend")}</p>
            </div>
        }
        else if (authManager.recoveryError === "somWrong") {
            return <div
                className="expired">
                <p>{t("forgotPage.recovery.somWrong")}</p>
            </div>
        }
        return <div
            className="text-center">
            <div>
                <p>{t("errorConnection")}</p>
            </div>
        </div>

    }

    return (
        <div
            className="page-login">
            <div
                className="login-panel">
                <div
                    className="content">
                    <div>
                        <h1>
                            {t("forgotPage.recoveryPageTitle")}
                        </h1>
                    </div>
                    <div>
                        {
                            !authManager.showOk ?
                                authManager.recoveryError?.length < 1 || authManager.recoveryError === null
                                    ?
                                    <form onSubmit={(e: React.SyntheticEvent) => onSubmit(e)}>
                                        {passError.length > 0 ?
                                            <ul className="password-error">
                                                {passError.map((err, index) =>
                                                    <li
                                                        key={index}>
                                                        {t(`forgotPage.recovery.error.${err}`)}
                                                    </li>
                                                )}
                                            </ul>
                                            : false
                                        }
                                        <div className="password">
                                            <CompactField
                                                inputMode="text"
                                                autoCapitalize="none"
                                                type="password"
                                                autoComplete="off"
                                                placeholder={t("forgotPage.recovery.passwordField")}
                                                value={password}
                                                onChange={(e) => setPassword(e)}
                                                required />
                                        </div>
                                        <div className="confirm-password">
                                            <CompactField
                                                inputMode="text"
                                                autoCapitalize="none"
                                                type="password"
                                                autoComplete="off"
                                                placeholder={t("forgotPage.recovery.confirmPassword")}
                                                value={confirmPassword}
                                                onChange={(e) => comparePasswords(e)}
                                                required />
                                        </div>
                                        {showError && <span className="text-center text-danger small">{t("forgotPage.recovery.mismatch")}</span>}
                                        <div className="text-center">
                                            <IndicatorButton
                                                disabled={password != confirmPassword || password.length < 1}
                                                type="submit"
                                                className="btn-primary btn-lg"
                                                loading={loading}>
                                                {t("forgotPage.send")}
                                            </IndicatorButton>
                                        </div>
                                    </form>
                                    :
                                    checkError()
                                :
                                <div
                                    className="recovery-done">
                                    <p>
                                        {t("forgotPage.recovery.done")}
                                    </p>
                                    <p
                                        onClick={handleRedirect}
                                        className="link">
                                        {t("forgotPage.recovery.link")}
                                    </p>
                                </div>
                        }
                        <LanguageSelection />
                    </div>
                </div>
            </div >
        </div >
    )
});
export default RecoveryPassword;