import { t } from "i18next";
import React, { useMemo } from "react"
import { PageHeader } from "../pageHeader";

const PlacedOrderHeader: React.FC<{ orderReference: string }> = (props) => {
    const orderReference = useMemo(() => props?.orderReference, [props?.orderReference]);

    return (
        <PageHeader>
            <span
                className="parsed-title">
                <span>
                    {t("cashrail.placed.title")}
                </span>
                <span className="reference">
                    {orderReference}
                </span>
            </span>
        </PageHeader>
    )
}
export default PlacedOrderHeader;