import * as signalR from '@microsoft/signalr'
import { BehaviorSubject } from 'rxjs'
import { CashOnDeliveryApiClient } from '../api/agent/cashOnDeliveryApiClient'

import { Kuva } from '../interfaces/kuvaweb'
import * as url from '../utils/url'

type AgentCashOnDeliveryRequestModel =
  Kuva.PL.KuvaWeb.Areas.Agent.Models.AgentCashOnDeliveryRequestModel
const status = Kuva.Module.Enums.OrderStatus

export class PastCashOnDeliveryManager {
  private static _instance: PastCashOnDeliveryManager
  static get instance() {
    return (this._instance = new PastCashOnDeliveryManager())
  }

  private pastRequestsSubject = new BehaviorSubject<AgentCashOnDeliveryRequestModel[]>(null)
  public get pastRequests() {
    return this.pastRequestsSubject.asObservable()
  }

  private pastRequestsCountSubject = new BehaviorSubject<number>(null)
  public get pastRequestsCount() {
    return this.pastRequestsCountSubject.asObservable()
  }

  private _pastRequests: AgentCashOnDeliveryRequestModel[] = null

  private api = new CashOnDeliveryApiClient()

  constructor() {
    this.connect()
    this.load()
  }

  protected async load(): Promise<void> {
    this._pastRequests = await this.getRequests()
    this.publish()
  }

  protected async connect(): Promise<void> {
    const hubConnection = new signalR.HubConnectionBuilder()
      .withUrl(url.combine(window.configData.apiUrl, 'api/v1/agents/events'))
      .withAutomaticReconnect()
      .build()
    hubConnection.on('CashOnDeliveryUpdated', e => {
      this.handlePastRequestUpdated(e)
    })
    await hubConnection.start()
  }

  protected filter(request: AgentCashOnDeliveryRequestModel): boolean {
    return request.status == status.Complete
  }

  protected handlePastRequestUpdated(request: AgentCashOnDeliveryRequestModel): void {
    if (this._pastRequests == null) return

    const currentIndex = this._pastRequests.findIndex(e => this.id(e) == this.id(request))
    if (currentIndex !== -1) return
    if (this.filter(request)) {
      this._pastRequests = [request, ...this._pastRequests]
    }
    this.publish()
  }

  private publish(): void {
    this.pastRequestsSubject.next(this._pastRequests)
    this.pastRequestsCountSubject.next(this._pastRequests.length)
  }

  protected async getRequests() {
    const result = await this.api.getAgentPastCashOnDelivery()
    const uniqueItems = result.filter(
      (item, index) => result.findIndex(dataItem => item.id === dataItem.id) === index
    )

    return uniqueItems
  }

  protected id(request: AgentCashOnDeliveryRequestModel): string {
    return request.id
  }
}
