import { t } from "i18next";
import { Kuva } from "../../../interfaces/kuvaweb"

type Option = Kuva.Module.Props.CustomOptionItem;
const Status = Kuva.Module.Enums.AgentSettlemenManifestStatus

export const settlementSearchStatusOptions = (): Option[] => [
    {
        label: () => t("settlement.search.pendingAndSettled"),
        value: 100,
        id: 1
    },
    {
        label: () => t("settlement.search.payDue"),
        value: Status.PaymentDue,
        id: 2
    },
    {
        label: () => t("settlement.search.settled"),
        value: Status.Completed,
        id: 3
    }
]
export const settlementPaymentTypeOptions: Option[] = [
    {
        label: () => t("settlement.search.payStatus.allPayType"),
        value: 100,
        id: 1
    },
    {
        label: () => t("settlement.search.payStatus.kuvaCash"),
        value: 0,
        id: 2
    },
    {
        label: () => t("settlement.search.payStatus.bank"),
        value: 1,
        id: 3
    },
    {
        label: () => t("settlement.search.payStatus.cash"),
        value: 2,
        id: 4
    }
]
export const settlementFlowTypeOptions: Option[] = [
    {
        label: () => t("settlement.search.flowType.all"),
        value: 100,
        id: 1
    },
    {
        label: () => t("settlement.search.flowType.fee"),
        value: 10,
        id: 2
    },
    {
        label: () => t("settlement.search.flowType.bulk"),
        value: 20,
        id: 3
    },
    {
        label: () => t("settlement.search.flowType.other"),
        value: 0,
        id: 4
    }
]