import * as signalR from '@microsoft/signalr'

import { TopUpCashApiClient } from '../api/agent/topUpCashApiClient'
import { Kuva } from '../interfaces/kuvaweb'
import { CashRequestsManager } from './cashRequestsManager'

import * as url from '../utils/url'

type AgentTopUpRequestModel = Kuva.PL.KuvaWeb.Areas.Agent.Models.AgentTopUpRequestModel
const TopUpStatus = Kuva.Module.Enums.TopUpStatus
import AddFundsType = Kuva.Module.Enums.AddFundsType
import { PastCashRequestsManager } from './pastCashRequestsManager'

export class PastCashTopUpsManager extends PastCashRequestsManager<AgentTopUpRequestModel> {
  private static _instance: PastCashTopUpsManager
  static get instance() {
    return (this._instance = new PastCashTopUpsManager())
  }

  private api = new TopUpCashApiClient()

  constructor() {
    super()
    this.load()
  }

  protected async getRequests() {
    return await this.api.getAgentPastTopups()
  }

  protected async connect(): Promise<void> {
    const hubConnection = new signalR.HubConnectionBuilder()
      .withUrl(url.combine(window.configData.apiUrl, 'api/v1/agents/events'))
      .withAutomaticReconnect()
      .build()
    hubConnection.on('TopupUpdated', e => {
      this.handlePastRequestUpdated(e)
    })
    await hubConnection.start()
  }

  protected id(request: AgentTopUpRequestModel) {
    return request.id
  }

  protected filter(request: Kuva.PL.KuvaWeb.Areas.Agent.Models.AgentTopUpRequestModel): boolean {
    return (
      !(
        Boolean(request.status === TopUpStatus.transferred) ||
        Boolean(request.status === TopUpStatus.ordered)
      ) && request.type == AddFundsType.cash
    )
  }
}
