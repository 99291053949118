﻿import { t } from 'i18next'
import { observer } from 'mobx-react'
import React from 'react'

import { PageHeader } from '../../components/pageHeader'
import { TopupForm } from '../../components/topups/topupForm'
import { TopupsList } from '../../components/topups/topupsList'
import { BankTopupDetailsViewModel } from '../../viewModels/bankTopup/bankTopupDetailsViewModel'
import { BankTopupFormViewModel } from '../../viewModels/bankTopup/bankTopupFormViewModel'
import { BankTopupProcessViewModel } from '../../viewModels/bankTopup/bankTopupProcessViewModel'
import { BankTopupsViewModel } from '../../viewModels/bankTopup/bankTopupsViewModel'

import { BankTopupDetailsDialogue } from './bankTopupDetailsDialogue'
import { BankTopupProcessDialogue } from './bankTopupProcessDialogue'

@observer
export class BankTopupsPage extends React.Component {
  listViewModel = new BankTopupsViewModel()
  formViewModel = new BankTopupFormViewModel()

  componentDidMount() {
    this.listViewModel.mount()
  }

  componentWillUnmount() {
    this.listViewModel.unmount()
  }

  render() {
    return (
      <div className="page-topup">
        <PageHeader>{t('topup.bank.title')}</PageHeader>
        <div className="container">
          {this.formViewModel.process != null && (
            <BankTopupProcessDialogue
              viewModel={this.formViewModel.process as BankTopupProcessViewModel}
            />
          )}
          {this.listViewModel.details != null && (
            <BankTopupDetailsDialogue
              viewModel={this.listViewModel.details as BankTopupDetailsViewModel}
            />
          )}
          <TopupForm viewModel={this.formViewModel} />
          <TopupsList viewModel={this.listViewModel} />
        </div>
      </div>
    )
  }
}
