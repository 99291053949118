import { action, makeObservable, observable, runInAction } from "mobx";
import { MultiCashApiClient } from "../../../api/multicash/multiCashoutsApiClient";
import { Kuva } from "../../../interfaces/kuvaweb";
import { IPromiseViewModel } from "../../promiseViewModel";
import { ViewModelBase } from "../../viewModelBase";
import * as refNum from "../../../utils/refNum";
import { MultiCashoutsViewModel } from "./multicashCashoutsViewModel";
import { t } from "i18next";

type MulticashDetailsRequestModel = Kuva.PL.KuvaWeb.Areas.Agent.Multicash.MulticashDetailsRequestModel;
const CashoutStatus = Kuva.Module.Enums.CashOutBookingStatus;
type CustomResponse = Kuva.PL.KuvaWeb.API.CustomResponse;

export class MultiCashoutDetailsViewModel extends ViewModelBase implements IPromiseViewModel<void> {
    private api = new MultiCashApiClient();

    promise: Promise<void>;

    private promiseAccept: () => void;
    private promiseReject: () => void;

    @observable loading: boolean = false;

    @observable canSubmit: boolean = false;
    @observable validationMessage: string = null;

    @observable isCompleted: boolean = false;
    @observable amount: number = null;

    @observable request: { data: MulticashDetailsRequestModel | null } = { data: undefined };

    @observable error: string = undefined;
    @observable bookError: string = undefined;

    @observable enteredRefnum: string = "";

    constructor(requestId: string) {
        super();
        makeObservable(this);
        this.promise = new Promise((acc, rej) => {
            this.promiseAccept = acc
            this.promiseReject = rej
        })

        this.load(requestId)
    }

    @action changeReference(value: string) {
        if (value.replace(/ /g, '').length > 13) {
            this.enteredRefnum = value.substring(0, 13)
            return
        }
        this.enteredRefnum = value.replace(/ /g, '');
    }

    @action private async load(requestId: string) {
        this.canSubmit = false;
        if (this.request.data != undefined) {
            this.request = { data: undefined };
        }

        try {
            const cashout = await this.api.getById(requestId)
            this.request.data = cashout;
            this.canSubmit = this.request.data.status != CashoutStatus.completed &&
                this.request.data.status != CashoutStatus.cancelled;
        }
        catch (error) {
            console.log(error);
            return;
        }
    }

    private validate() {
        const result = refNum.validate(this.enteredRefnum);
        this.validationMessage = result.message

        return result.valid;
    }

    @action async book(requestId: string, listViewModel: MultiCashoutsViewModel) {
        this.bookError = undefined;
        try {
            this.loading = true;
            await this.api.bookRequest(requestId)

            runInAction(() => {
                this.request.data.isBooked = true;
            })

            this.loading = false;
            
        } catch (error) {
            let res = error as CustomResponse;
            this.loading = false;
            if (res.errorCode === "404") {
                this.bookError = res.message;
                return;
            }
            else if (res.message.length > 0) {
                this.bookError = res.message;
                return;
            }
            this.bookError = t("component.error.somWentWrong");
            return;
        }
    }

    @action async complete(enteredRefnum: string, setShowPopup: React.Dispatch<React.SetStateAction<boolean>>) {
        this.error = undefined;
        if (!this.validate()) {
            setShowPopup(false)
            return;
        }
        try {
            this.loading = true;
            const response = await this.api.complete(enteredRefnum.replace(/-/g, " "), this.request.data.id);
            this.request.data = response;
            this.canSubmit = false;
            setShowPopup(false);
            this.loading = false;
            return;
        } catch (error) {
            this.loading = false;
            setShowPopup(false);
            let res = error as CustomResponse;
            if (res.code === 404) {
                this.error = t("kwlCashout.referenceError");
                return
            }
            else if (res.message.length > 0) {
                this.error = res.message;
                return
            }
        }
    }

    @action async delete(enteredRefnum: string) {
        if (!this.validate())
            return;
        try {
            this.loading = true;
            await this.api.cancel(this.request.data.id, enteredRefnum.replace(/-/g, " "));
            this.request.data.status = CashoutStatus.cancelled;
            alert("Success")
            return;
        } catch (error) {
            this.loading = false;
            let res = error as CustomResponse;
            if (res.errorCode === "404") {
                alert(t("kwlCashout.referenceError"));
                return;
            }
            else if (res.message.length > 0) {
                alert(res.message)
                return;
            }
            alert("Something went wrong")
            return;
        }
    }

    @action clearErrors() {
        this.error = undefined
        this.bookError = undefined
    }

    cancel() {
        this.promiseAccept();
    }
}