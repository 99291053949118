import { action, autorun, IReactionDisposer, makeObservable, observable } from "mobx";
import { Subscription } from "rxjs";
import { Kuva } from "../interfaces/kuvaweb";
import { IPromiseViewModel } from "./promiseViewModel";
import { ViewModelBase } from "./viewModelBase";
import * as fmt from '../utils/formatting';
import { lang } from "../utils/lang";
import { ChangeMerchantDetailsViewModel } from "./changeMerchant/changeMerchantDetailsViewModel";

type AgentChangeMerchantTransactionsViewModel = Kuva.PL.KuvaWeb.Areas.Agent.Models.AgentChangeMerchantTransactionsViewModel;
type ChangeMerchantTransaction = Kuva.DL.DomainModel.NopDbEntities.KuvaLocal.ChangeMerchantTransaction;

interface IChangeMerchantList extends ViewModelBase {
  loading: boolean;
  requestCount: number;
  filteredRequests: ChangeMerchantTransaction[];
  countryCodes: string[];
  setSearch(search: string): void;
}

export abstract class ChangeMerchantTXsCommon extends ViewModelBase implements IChangeMerchantList {
  protected requests: ChangeMerchantTransaction[] = [];
  protected search: string = "";

  @observable filteredRequests: ChangeMerchantTransaction[] = new Array();
  @observable requestCount: number = 0;
  countryCodes: string[];
  @observable loading: boolean = false;

  constructor() {
    super();
    makeObservable(this);
  }

  protected abstract getRequests(): Promise<AgentChangeMerchantTransactionsViewModel>;

  protected async load(): Promise<void> {
    this.loading = true;
    var res = await this.getRequests();
    this.countryCodes = res.countryCodes;
    this.requests = res.transactions;
    this.updateFilter(this.search);
    this.loading = false;
  }

  @action updateFilter(search: string) {
    if (this.requests == null)
      return;
    this.filteredRequests = this.requests.filter(applyFilter(search));
    this.requestCount = this.filteredRequests.length;
  }

  @action setSearch(search: string): void {
    if (search !== this.search) {
      this.search = search;
      this.updateFilter(search);
    }
  }
}

export abstract class ChangeMerchantCommon extends ViewModelBase {
  transactions: IChangeMerchantList = null;

  @observable currentTransactions: IChangeMerchantList = null;

  @observable details: ChangeMerchantDetailsViewModel | null = null;


  @observable search: string = "";

  protected constructor() {
    super();
    makeObservable(this);
  }

  mount() {
    this.transactions.mount();
    super.mount();
  }

  unmount() {
    this.transactions.unmount();
    super.unmount();
  }

  *subscribe() {
    yield autorun(() => {
      this.updateTab();
    })
  }

  abstract select(id: number): void;

  protected updateTab() {
    this.currentTransactions = this.transactions;
    this.currentTransactions.setSearch(this.search);
  }

  @action addTx(tx: ChangeMerchantTransaction) {
    this.transactions.filteredRequests.unshift(tx);
  }
}

type SearchFunction = (transaction: ChangeMerchantTransaction) => string

const searchableFields: (keyof ChangeMerchantTransaction | SearchFunction)[] = [
  model => fmt.dateTimeShort(model.date),
  model => lang.creditWalletTransactionStatus(model.status),
  model => lang.changeMerchantType(model.type),
  'changeAmount',
  'receivedAmount',
  'agentName',
  'phone'
]

function applyFilter(query: string): (tx: ChangeMerchantTransaction) => boolean {
  if (query === null || query.length < 1)
    return () => true;
  query = query.trim().toLocaleLowerCase();

  return (tx) => {
    return searchableFields.some(field => {
      let value = ''
      if (typeof field === 'function') {
        value = field(tx)
      } else {
        value = tx[field].toString()
      }

      return value.toLocaleLowerCase().startsWith(query);
    })
  }
}