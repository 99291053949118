import { action, autorun, makeObservable, observable, runInAction } from "mobx";
import { CashrailOrderApiClient } from "../../api/cashrail/cashrailOrderApiClient";
import { Kuva } from "../../interfaces/kuvaweb";
import { IPromiseViewModel } from "../promiseViewModel";
import { ViewModelBase } from "../viewModelBase";


type Order = Kuva.PL.KuvaWeb.Areas.BundleAgent.Models.BundleOrderModel;
type BankDetails = Kuva.PL.KuvaWeb.Areas.BundleAgent.Models.BankDetails;
type BundleOrderItemModel = Kuva.PL.KuvaWeb.Areas.BundleAgent.Models.BundleOrderItemModel;
type PaymentStatus = Kuva.Module.Enums.BundleOrderPaymentStatus;
type MultiCashrailCashoutType = Kuva.Module.Enums.MultiCashrailCashoutType;
const BundleOrderStatus = Kuva.Module.Enums.BundleOrderStatus;
type OrderStatus = Kuva.Module.Enums.BundleOrderStatus;
const CashoutType = Kuva.Module.Enums.MultiCashrailCashoutType;

class CashrailAgentOrderDetailsViewModel extends ViewModelBase implements IPromiseViewModel<void>{
    private api = new CashrailOrderApiClient();

    promise: Promise<void>;

    private promiseAccept: () => void;
    private promiseReject: () => void;

    private readonly orderId: number;

    @observable order: Order = undefined;
    @observable search: string = "";
    @observable filteredRequests: BundleOrderItemModel[] = new Array()
    @observable bankDetails: BankDetails = null
    @observable error: string;
    @observable orderLoading: boolean = true;
    @observable bankLoading: boolean
    @observable orderStatus: OrderStatus;

    constructor(id: number) {
        super();
        this.orderId = id;
        this.promise = new Promise((ac, rj) => {
            this.promiseAccept = ac;
            this.promiseReject = rj;
        })
        this.getOrder();
        makeObservable(this);
    }

    mount() {
        super.mount();
    }

    unmount() {
        super.unmount();
    }

    *subscribe() {
        yield autorun(() => {
            this.updateFilter();
        })
    }

    @action
    updateFilter() {
        if (this.order?.bundleOrderItems) {
            this.filteredRequests = this.order?.bundleOrderItems.filter(applyFilter(this.search));
        }
    }

    @action
    private getOrder = async () => {
        this.orderLoading = true;
        const response = await this.api.getOrderDetails(this.orderId);
        runInAction(() => {
            this.order = response;
            this.filteredRequests = this.order.bundleOrderItems;
            this.orderStatus = response.orderStatus;
            this.orderLoading = false;
        });
        this.updateFilter();
    }

    changeOrderStatus = async (orderId: number) => {
        try {
            const response = await this.api.changeOrderStatus(orderId)
            this.setOrderStatus(response);
        } catch (error) {
        }
    }

    @action setOrderStatus(response: Order) {
        this.order.orderStatus = response.orderStatus;
        this.orderStatus = response.orderStatus;
    }

    async packBundleItem(orderId: number, itemId: number, packed: boolean, canPack: boolean) {
        try {
            if (canPack) {
                await this.api.puckBundleItem(orderId, itemId, packed)
                runInAction(() => {
                    this.changeItemProcess(packed, itemId)
                })
            }
            else {
                return;
            }
        } catch (error) {
            return;
        }
    }


    @action changeItemProcess(packed: boolean, itemId: number) {
        this.order.orderStatus = BundleOrderStatus.processing;
        this.orderStatus = BundleOrderStatus.processing;
        const orderItem = this.order.bundleOrderItems.find(i => i.id == itemId)

        orderItem.packed = packed;
        this.order.bundleOrderItems = this.order.bundleOrderItems.map(i => i.id == itemId ? i = orderItem : i);

        if (this.order.bundleOrderItems.length === this.order.bundleOrderItems.filter(i => i.packed === true).length) {
            this.order.orderStatus = BundleOrderStatus.ready;
            this.orderStatus = BundleOrderStatus.ready;
        }
        this.updateFilter();
    }
}
export default CashrailAgentOrderDetailsViewModel;

type SearchFunction = (settlement: BundleOrderItemModel) => string;

const searchableFields: (keyof BundleOrderItemModel | SearchFunction)[] = [
    'amount',
    'customerId',
    'reference'
]

function applyFilter(query: string): (a: BundleOrderItemModel) => boolean {
    if (query === null || query?.length < 1)
        return () => true;
    query = query.trim().toLocaleLowerCase();

    return (a) => {
        return searchableFields.some(field => {
            let value = ''
            if (typeof field === 'function') {
                value = field(a)
            } else {
                value = a[field].toString()
            }

            return value.toLocaleLowerCase().startsWith(query)
        })
    }
}