export const errorsConfigs = [
    {
        regexp: /[A-Z]/,
        errorName: 'uppercase'
    },

    {
        regexp: /[a-z]/,
        errorName: 'lowercase'
    },

    {
        regexp: /[0-9]/,
        errorName: 'numeric'
    },

    {
        regexp: /[^A-Za-z0-9]/,
        errorName: 'alphanumeric'
    }
]
