import { action, makeObservable, observable } from "mobx"
import { ChangeMerchantApiClient } from "../../api/agent/changeMerchantApiClient"
import { SettlementApiClient } from "../../api/agent/settlementApiClient"
import { CashrailOrderApiClient } from "../../api/cashrail/cashrailOrderApiClient"
import { KWlCashoutApiClient } from "../../api/agent/kwlCashOutApiClient"

export class DashboardCountViewModel {
    private changeMerchantApi = new ChangeMerchantApiClient()
    private settlementsApi = new SettlementApiClient()
    private cashrailApi = new CashrailOrderApiClient()
    private kwlCashOutApi = new KWlCashoutApiClient()

    @observable bookedSettlementsCount: number = null
    @observable bookedChangeMerchantTransactionsCount: number = null
    @observable cashrailCount: number = null
    @observable kwlCashOutCount: number = null

    constructor() {
        makeObservable(this)
        this.load()
    }

    @action load = () => {
        this.getSettlementsCount()
        this.getCashrailOrdersCount()
        this.getChangeMerchantTransactionsCount()
        this.getKwlCashOutCount()
    }

    @action getSettlementsCount = async () => {
        try {
            this.bookedSettlementsCount = await this.settlementsApi.getCount()
        } catch (error) {
            this.bookedSettlementsCount = 0
        }
    }

    @action getCashrailOrdersCount = async () => {
        try {
            this.cashrailCount = await this.cashrailApi.getCount()
        } catch (error) {
            this.bookedSettlementsCount = 0
        }
    }

    @action getChangeMerchantTransactionsCount = async () => {
        try {
            this.bookedChangeMerchantTransactionsCount = await this.settlementsApi.getCount()
        } catch (error) {
            this.bookedSettlementsCount = 0
        }
    }

    @action getKwlCashOutCount = async () => {
        try {
            this.kwlCashOutCount = await this.kwlCashOutApi.getCount()
        } catch (error) {
            this.kwlCashOutCount = 0
        }
    }
}