import { action, makeAutoObservable, observable, onBecomeObserved } from 'mobx'
import { KWlCashoutApiClient } from '../../../api/agent/kwlCashOutApiClient'
import { Kuva } from '../../../interfaces/kuvaweb'

type BundleDetails = Kuva.PL.KuvaWeb.Areas.Agent.KWL.IKwlCashOutBundleDetailsResponse
type CashOut = Kuva.PL.KuvaWeb.Areas.Agent.KWL.IKwlCashOutFromBundle
type BundleStatus = Kuva.Module.Enums.MultiCashOutBundleStatus
type CustomError = Kuva.PL.KuvaWeb.API.CustomError
export class KwlMultiCashOutBundleViewModel {
  private api = new KWlCashoutApiClient()

  private readonly pushHistory: (path: string) => void
  private selectedCashOut: string

  currentTypeModal: 'completeAll' | 'removeCashOut' | '' = ''

  @observable loading: boolean = false
  @observable error: string = ''
  @observable isOpenCompleteAllModal: boolean = false
  @observable isOpenRemoveModal: boolean = false
  @observable bundleId: string = ''
  @observable cashOutList: CashOut[] | [] = []
  @observable createdDate: string = ''
  @observable completedDate: string | null = null
  @observable cashOutsAmount: number = 0
  @observable cashOutsCount: number = 0
  @observable status: BundleStatus = 0

  constructor(id: string, pushHistory: (path: string) => void) {
    this.bundleId = id
    this.pushHistory = pushHistory

    makeAutoObservable(this, {}, { autoBind: true })

    onBecomeObserved(this, 'bundleId', this.getBundleRequest)
  }

  @action openDeleteModal(cashOutId: string) {
    this.isOpenRemoveModal = true
    this.currentTypeModal = 'removeCashOut'
    this.selectedCashOut = cashOutId
  }

  @action openCompleteAllModal() {
    this.isOpenCompleteAllModal = true
    this.currentTypeModal = 'completeAll'
  }

  @action closeModal() {
    if (this.currentTypeModal === 'removeCashOut') {
      this.isOpenRemoveModal = false
      this.selectedCashOut = ''
    }
    if (this.currentTypeModal === 'completeAll') {
      this.isOpenCompleteAllModal = false
    }
    this.currentTypeModal = ''
  }

  @action confirmModal() {
    if (this.currentTypeModal === 'completeAll') {
      this.completeBundle()
    }
    if (this.currentTypeModal === 'removeCashOut') {
      this.deleteCashOutFromBundle()
    }
    this.closeModal()
  }

  private *getBundleRequest() {
    this.error = ''
    this.loading = true
    try {
      const result: BundleDetails = yield this.api.getCashOutBundleDetails(this.bundleId)

      this.createdDate = result.createdDate
      this.cashOutList = result.cashOuts
      this.cashOutsAmount = result.cashOutsAmount
      this.cashOutsCount = result.cashOutsCount
      this.status = result.status
      this.completedDate = result.completedDate
      this.loading = false
    } catch (e) {
      this.pushHistory('/kwl-cashouts')
      this.loading = false
    }
  }

  *deleteCashOutFromBundleRequest() {
    this.error = ''
    try {
      yield this.api.deleteCashOutFromBundle(this.bundleId, this.selectedCashOut)
    } catch (e) {
      const error = e as CustomError
      this.error = error.message
      console.log(this.error)
      alert(this.error)
    }
  }

  *completeBundleRequest() {
    this.error = ''
    this.loading = true
    try {
      yield this.api.completeBundle(this.bundleId)
      this.loading = false
    } catch (e) {
      const error = e as CustomError
      this.error = error.message
      console.log(this.error)
      alert(this.error)
      this.loading = false
    }
  }

  *completeBundle() {
    yield this.completeBundleRequest()
    yield this.getBundleRequest()
  }

  @action deleteCashOutFromBundle() {
    this.error = ''
    this.loading = true
    try {
      this.deleteCashOutFromBundleRequest()

      const deletedCashOutAmount = this.cashOutList.find(
        (cashOut: CashOut) => cashOut.id === this.selectedCashOut
      )
      this.cashOutList = this.cashOutList.filter(
        (cashOut: CashOut) => cashOut.id !== this.selectedCashOut
      )

      if (this.cashOutList.length === 0) {
        throw new Error('You have removed all cashouts from this bundle')
      }
      this.cashOutsCount = this.cashOutList.length
      this.cashOutsAmount = this.cashOutsAmount - deletedCashOutAmount.amount

      this.loading = false
    } catch (e) {
      const error = e as CustomError
      this.error = error.message

      if (error.message === 'You have removed all cashouts from this bundle') {
        alert(this.error)
        this.pushHistory('/kwl-cashouts')
        return
      }

      alert(this.error)
      this.loading = false
    }
  }
}
