import { t } from 'i18next'
import { observer } from 'mobx-react-lite'
import React from 'react'
import { SearchInput } from '../../components/searchInput'
import useInfiniteScroll from 'react-infinite-scroll-hook'
import LazyLoadingTable from '../../components/table/lazyLoadingTable'
import { KWLMultiCashOutsTableConfig } from './config'
import { KwlMultiCashOutViewModel } from '../../viewModels/kwlCashout/kwlMultiCashOut/kwlMultiCashOutViewModel'
import { IndicatorButton } from '../../components/indicatorButton'
import KwlMultiCashOutProcessDialog from './kwlMultiCashOut/kwlMultiCashOutProcessDialog'
import { Kuva } from '../../interfaces/kuvaweb'

type MultiCashOutList = Kuva.PL.KuvaWeb.Areas.Agent.KWL.IMultiCashOutList
type KwlCashOutResponse = Kuva.PL.KuvaWeb.Areas.Agent.KWL.AgentCashoutKWLRequestModel
type Config = Kuva.Module.Table.TableConfig<KwlCashOutResponse>
type MultiCashOutConfig = Kuva.Module.Table.TableConfig<MultiCashOutList>

const KwlMultiCashOutList: React.FC<{
  viewModel: KwlMultiCashOutViewModel
  moveToBundleDetails: (id: number) => void
}> = observer(props => {
  const { viewModel, moveToBundleDetails } = props

  const currentList = viewModel.current.filteredRequests
  let step = 10
  const getloading = () => {
    if (viewModel.tab === 'booked') {
      return viewModel.booked.loading
    }
    if (viewModel.tab === 'processing') {
      return viewModel.processing.loading
    }
    if (viewModel.tab === 'past') {
      return viewModel.past.loading
    }
  }
  const loading = getloading()

  const getHasNextPage = () => {
    if (viewModel.tab === 'booked') {
      return viewModel.booked.hasNextPage
    }
    if (viewModel.tab === 'processing') {
      return viewModel.processing.hasNextPage
    }
    if (viewModel.tab === 'past') {
      return viewModel.past.hasNextPage
    }
  }
  const hasNextPage = getHasNextPage()

  const tableConfig = KWLMultiCashOutsTableConfig(viewModel.tab)

  const onLoadMore = () => {
    if (viewModel.tab === 'booked') {
      viewModel.booked.onLoadMore(step)
    }
    if (viewModel.tab === 'processing') {
      viewModel.processing.onLoadMore(step)
    }
    if (viewModel.tab === 'past') {
      viewModel.past.onLoadMore(step)
    }
  }

  const [sentryRef, { rootRef }] = useInfiniteScroll({
    loading,
    hasNextPage,
    onLoadMore,
    disabled: false,
    rootMargin: '0px 0px 300px 0px',
  })

  return (
    <>
      <div className="dashboard-page-panel">
        <div className="page-panel-title">
          <ul className="nav custom-nav-pills nav-pills nav-fill">
            <li className="nav-item">
              <button
                onClick={() => viewModel.changeTab('booked')}
                className={`nav-link ${viewModel.tab === 'booked' ? 'active' : ''}`}
              >
                {t('kwlCashout.bookedTab')}
              </button>
            </li>
            <li className="nav-item">
              <button
                onClick={() => viewModel.changeTab('processing')}
                className={`nav-link ${viewModel.tab === 'processing' ? 'active' : ''}`}
              >
                {t('kwlCashout.processingTab')}
              </button>
            </li>
            <li className="nav-item">
              <button
                onClick={() => viewModel.changeTab('past')}
                className={`nav-link ${viewModel.tab === 'past' ? 'active' : ''}`}
              >
                {t('kwlCashout.pastTab')}
              </button>
            </li>
          </ul>
        </div>
        {viewModel.tab === 'booked' && (
          <div className="search">
            <SearchInput onChange={e => viewModel.changeSearch(e)} />
          </div>
        )}
        {viewModel.tab === 'booked' && (
          <div className="proceed-button">
            <IndicatorButton
              onClick={viewModel.booked.bookCashOuts}
              className="btn-default btn-primary"
            >
              {t('kwlCashout.proceedCashOutButton')}
            </IndicatorButton>
          </div>
        )}
        {viewModel.tab === 'booked' && viewModel.loaded && (
          <LazyLoadingTable
            sentryRef={sentryRef}
            rootRef={rootRef}
            columnCount={7}
            hasNextPage={hasNextPage}
            tableConfig={tableConfig as Config}
            onClick={viewModel.booked.onCheckBoxChange.bind(viewModel)}
            loading={loading}
            list={currentList as KwlCashOutResponse[]}
            checkedList={viewModel.booked.checkedList}
            className="kwl-booked-multi-cashout"
          />
        )}
        {(viewModel.tab === 'processing' || viewModel.tab === 'past') && viewModel.loaded && (
          <LazyLoadingTable
            sentryRef={sentryRef}
            rootRef={rootRef}
            columnCount={5}
            hasNextPage={hasNextPage}
            tableConfig={tableConfig as MultiCashOutConfig}
            onClick={moveToBundleDetails}
            loading={loading}
            list={currentList as MultiCashOutList[]}
          />
        )}
      </div>
      <KwlMultiCashOutProcessDialog
        details={viewModel.booked.detailsModal}
        closePopUp={viewModel.booked.closePopUp}
        confirmFunction={viewModel.booked.processCashOuts}
        isOpenPopUp={viewModel.booked.isOpenPopUp}
      />
    </>
  )
})
export default KwlMultiCashOutList
