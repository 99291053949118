﻿import { t } from "i18next";
import { observer } from "mobx-react";
import React from "react";
import { FixedValidationMessage } from "../../components/fixedValidationMessage";
import { IndicatorButton } from "../../components/indicatorButton";
import { InfoField } from "../../components/infoField";

import { Modal } from "../../components/modal";
import PopUp from "../../components/popUp";
import { ReferenceNumberInput } from "../../components/referenceNumberInput";
import * as fmt from "../../utils/formatting";
import { lang } from "../../utils/lang";
import { CashTopupDetailsViewModel } from "../../viewModels/cashTopup/cashTopupDetailsViewModel";

@observer
export class CashTopupDetailsDialogue extends React.Component<{ viewModel: CashTopupDetailsViewModel }> {


  render() {
    const viewModel = this.props.viewModel;
    const topup = viewModel.request;

    if (topup == null)
      return null;

    return <>
      <Modal size={"lg"} className={"dialogue-cash dialogue-top-up"}>
        <div className="modal-header">
          <h5 className="modal-title">{t("topup.detailsTitle")}</h5>
          <button type="button" onClick={() => viewModel.cancel()} className="btn-close" aria-label="Close" />
        </div>
        <div className="modal-body">
          <div className="body-container">
            <div className="section-identity">
              <InfoField label={t("topup.createdDateLabel")}>
                {fmt.dateTimeShort(topup.createdDate)}
              </InfoField>
              <InfoField label={t("topup.typeLabel")}>
                {lang.addFundsType(topup.requestType)}
              </InfoField>
              <InfoField label={t("topup.requesterNameLabel")}>
                {topup.requesterName}
              </InfoField>
              <InfoField label={t("topup.expirationDateLabel")}>
                {fmt.dateTimeShort(topup.expirationDate)}
              </InfoField>
              {topup.completedDate != null ?
                <InfoField label={t("topup.completedDateLabel")}>
                  {fmt.dateTimeShort(topup.completedDate)}
                </InfoField>
                : null}
              {topup.treasuryTransfer != null ?
                <>
                  <InfoField label={t("topup.treasuryTransferLabel")}>
                    {topup.treasuryTransfer.treasuryTransferStatus}
                  </InfoField>

                  <InfoField label={t("topup.treasuryIdLabel")}>
                    {topup.treasuryTransfer.id}
                  </InfoField>
                </>
                : null}
              <InfoField label={t("topup.statusLabel")}>
                {lang.topupStatus(topup.status)}
              </InfoField>
            </div>
            <div className="section-payment">
              <div className={"payment-reference"}>
                <div className={"label"}>{t("topup.referenceLabel")}</div>
                <div className={"reference"}>{fmt.refNum(topup.reference)}</div>
              </div>
              {viewModel.canSubmit && <div className="payment-actions">
                <div className="reference-number-wrapper">
                  <ReferenceNumberInput type="text" value={viewModel.enteredRefnum}
                    onChange={(e) => viewModel.enteredRefnum = e.target.value} />
                  <FixedValidationMessage message={viewModel.validationMessage} />
                </div>
                {viewModel.error?.length > 0 && <div className="error text-center mb-10">
                  {viewModel.error}
                </div>}
                <div className="row">
                  <div className={"col-6"}>
                    <IndicatorButton
                      onClick={() => viewModel.openPopUp("cancel")}
                      className="btn-default btn-red">
                      {t("requestCancelButton")}
                    </IndicatorButton>
                  </div>
                  <div
                    className={"col-6"}>
                    <IndicatorButton
                      onClick={() => viewModel.openPopUp("accept")}
                      className="btn-default btn-green">
                      {t("requestCompleteButton")}
                    </IndicatorButton>
                  </div>
                </div>
              </div>}
              <div className={"payment-info"}>
                <InfoField label={t("topup.fromLabel")}>
                  {fmt.currency(topup.from.amount, topup.from.currency)}
                </InfoField>
                <InfoField label={t("topup.toLabel")}>
                  {fmt.currency(topup.to.amount, topup.to.currency)}
                </InfoField>
                <InfoField label={t("topup.agentFixedCashFeeLabel")}>
                  {fmt.currency(topup.agentFixedFee, topup.from.currency)}
                </InfoField>
                <InfoField label={t("topup.agentPercentCashFeeLabel")}>
                  {fmt.currency(topup.agentPercentFee, topup.from.currency)}
                </InfoField>
                <InfoField label={t("topup.kuvaFixedCashFeeLabel")}>
                  {fmt.currency(topup.kuvaFixedCashFee, topup.from.currency)}
                </InfoField>
                <InfoField label={t("topup.kuvaPercentCashFeeLabel")}>
                  {fmt.currency(topup.kuvaPercentCashFee, topup.from.currency)}
                </InfoField>
                <InfoField label={t("topup.liquidityFeePercentAmountLabel")}>
                  {fmt.currency(topup.liquidityFeePersentsAmount, topup.from.currency)}
                </InfoField>
                <InfoField label={t("topup.totalFeeLabel")}>
                  {fmt.currency(topup.totalFee, topup.from.currency)}
                </InfoField>
              </div>
            </div>
          </div>
        </div>
      </Modal>
      <PopUp
        reqType="topUp"
        sure={viewModel.sure}
        completeMode={viewModel.completeMode}
        loading={viewModel.loading}
        onClosePopup={viewModel.onClosePopUp}
        showPopup={viewModel.showPopUp} />
    </>
  }
}
