import { t } from "i18next";
import { observer } from "mobx-react"
import React from "react"
import * as fmt from "../../utils/formatting";
import { lang } from '../../utils/lang'
import { RouteComponentProps, withRouter } from "react-router-dom";
import { BundleCommon } from "../../viewModels/bundleOrderCommon";
import { Kuva } from "../../interfaces/kuvaweb";
import { SearchInput } from "../../components/searchInput";

type BundleOrderShortModel = Kuva.PL.KuvaWeb.Areas.BundleAgent.Models.BundleOrderShortModel;
const OrderStatus = Kuva.Module.Enums.BundleOrderStatus;
const paid = Kuva.Module.Enums.BundleOrderPaymentStatus.paid;

@observer
class BundleOrdersList extends React.Component<{ viewModel: BundleCommon<BundleOrderShortModel> } & RouteComponentProps>  {

    handleSelect(orderId: number) {
        this.props.history.push(`/cashrail/details?id=${orderId}`)
    }

    handleTabChange = (tab: 'new' | 'processing' | 'ready' | 'delivered' | 'completed') => {
        this.props.viewModel.changeTab(tab);
    }

    render() {
        const list = this.props.viewModel.currentList;
        const viewModel = this.props.viewModel;

        return <div className="dashboard-page-panel">
            <div className="table-wrapper">
                <div
                    className="page-panel-title">
                    <ul className="nav custom-nav-pills nav-pills nav-fill nav-responsible">
                        <li className="nav-item">
                            <button
                                onClick={() => this.handleTabChange("new")}
                                className={`nav-link ${viewModel.tab == "new" ? 'active' : ''}`}
                                aria-current="page">
                                {t("cashrail.ba-table.button.new")}
                                <span className="badge bg-secondary">
                                    {viewModel.orders?.filteredRequests?.length === 0 ? '' : viewModel.orders?.filteredRequests?.length}
                                </span>
                            </button>
                        </li>
                        <li className="nav-item">
                            <button
                                onClick={() => this.handleTabChange("processing")}
                                className={`nav-link ${viewModel.tab == "processing" ? 'active' : ''}`}>
                                {t("cashrail.ba-table.button.process")}
                                <span
                                    className={"badge bg-secondary"}>
                                    {viewModel.processing?.filteredRequests?.length === 0 ? '' : viewModel.processing?.filteredRequests?.length}
                                </span>
                            </button>
                        </li>
                        <li className="nav-item">
                            <button
                                onClick={() => this.handleTabChange("ready")}
                                className={`nav-link ${viewModel.tab == "ready" ? 'active' : ''}`}>
                                {t("cashrail.ba-table.button.ready")}
                                <span
                                    className={"badge bg-secondary"}>
                                    {viewModel.ready?.filteredRequests?.length === 0 ? '' : viewModel.ready?.filteredRequests?.length}
                                </span>
                            </button>
                        </li>
                        <li className="nav-item">
                            <button
                                onClick={() => this.handleTabChange("delivered")}
                                className={`nav-link ${viewModel.tab == "delivered" ? 'active' : ''}`}>
                                {t("cashrail.ba-table.button.delivered")}
                                <span className={"badge bg-secondary"}>
                                    {viewModel.delivered?.filteredRequests?.length === 0 ? '' : viewModel.delivered?.filteredRequests?.length}
                                </span>
                            </button>
                        </li>
                        <li className="nav-item">
                            <button
                                onClick={() => this.handleTabChange("completed")}
                                className={`nav-link ${viewModel.tab == "completed" ? 'active' : ''}`}>
                                {t("cashrail.ba-table.button.completed")}
                                <span
                                    className={"badge bg-secondary"}>
                                    {viewModel.completed?.filteredRequests?.length === 0 ? '' : viewModel.completed?.filteredRequests?.length}
                                </span>
                            </button>
                        </li>
                    </ul>
                </div>

                <div className="search">
                    <SearchInput onChange={(e) => viewModel.changeSearch(e)} />
                </div>
                <ul className="list list-col7 list-bundle">
                    <li className="list-header">
                        <div className="col-date">{t("cashrail.ba-table.date")}</div>
                        <div className="col-code">{t("cashrail.ba-table.code")}</div>
                        <div className="col-amount">{t("cashrail.ba-table.amount")}</div>
                        <div className="col-cost">{t("cashrail.ba-table.cost")}</div>
                        <div className="col-status">{t("cashrail.ba-table.status")}</div>
                        <div className="col-type">{t("cashrail.ba-table.orderTypeCol")}</div>
                    </li>
                    {
                        list?.filteredRequests?.filter(order => OrderStatus[order.orderStatus] === viewModel.tab).map((order) =>
                            <li className="table-hover" key={order.id} onClick={() => this.handleSelect(order.id)}>
                                <div
                                    className="col-date">
                                    {fmt.dateShort(order.createdDate)}
                                </div>
                                <div
                                    className="col-code">
                                    {order.reference}
                                </div>
                                <div
                                    className="col-amount">
                                    {fmt.currencySymbol(order.dstCurrency) + order.dstAmount.toFixed(2)}
                                </div>
                                <div
                                    className="col-cost">
                                    {fmt.currencySymbol(order.srcCurrency) + order.srcAmountIncludeFee.toFixed(2)}
                                </div>
                                <div
                                    className={`col-status${order.paymentStatus != paid ? '-red' : '-green'}`}>
                                    {lang.cashrailDropdownPaidStatus(order.paymentStatus)}
                                </div>
                                <div>
                                    {lang.cashrailFlow(order.orderType)}
                                </div>
                            </li>
                        )}
                    {
                        list.loading ?
                            <li className="list-loading">
                                {t("loadingData")}
                            </li>
                            :
                            list.filteredRequests?.length < 1 ?
                                <li className="list-noresults">
                                    {
                                        viewModel.search ?
                                            <>{t("cashrail.noSearchResults")}</>
                                            :
                                            <>{t("cashrail.noResults")}</>
                                    }
                                </li>
                                :
                                <div />
                    }
                </ul>
            </div>
        </div >
    }
}
export default withRouter(BundleOrdersList);