import { t } from "i18next";
import { action, makeObservable, observable } from "mobx";
import { ChangeMerchantApiClient } from "../../api/agent/changeMerchantApiClient";
import { Kuva } from "../../interfaces/kuvaweb";
import { IPromiseViewModel } from "../promiseViewModel";

type ChangeMerchantTransaction = Kuva.DL.DomainModel.NopDbEntities.KuvaLocal.ChangeMerchantTransaction;
type CustomerName = Kuva.BL.BusinessModels.Api.KuvaLocal.CustomerName;
export class ChangeMerchantDetailsViewModel implements IPromiseViewModel<void>{
  private api = new ChangeMerchantApiClient();

  promise: Promise<void>;

  private promiseAccept: () => void;
  private promiseReject: () => void;

  private readonly requestId: number;

  @observable customerName: CustomerName = null;
  @observable loading: boolean = false;
  @observable error: string = "";
  @observable phoneCheked: boolean = false;
  @observable transaction: ChangeMerchantTransaction;

  constructor(orderId: number) {
    this.transaction = null;
    this.load(orderId);

    this.promise = new Promise((acc, rej) => {
      this.promiseAccept = acc;
      this.promiseReject = rej;
    });

    makeObservable(this);
  }

  @action private async load(orderId: number) {
    this.transaction = await this.api.detailsById(orderId);
  }

  @action async checkCustomerName(phone: string) {
    this.phoneCheked = false;
    this.loading = true;
    var result = await this.api.customerNameCheck(phone);
    if (typeof result != "string") {
      this.customerName = result;
      this.phoneCheked = true;
    } else {
      alert(t("changeMerchant.errors.phoneInvalid"));
    }
  }

  @action async createTransaction() {

  }
}
