import React from 'react'
import { SettlementsViewModel } from '../viewModels/settlement/settlementsViewModel'
export module Kuva.PL.KuvaWeb.Areas.Agent.Models {
  export interface AgentCashoutRequestModel {
    bookingId: string
    date: string
    phoneNumber: string
    referenceNumber: string
    amountUsd: number
    status: Kuva.Module.Enums.CashOutBookingStatus
    isNew?: boolean
  }
}
export module Kuva.PL.KuvaWeb.Areas.Agent.Models {
  export interface AgentChangeMerchantTransactionsViewModel {
    countryCodes: string[]
    transactions: Kuva.DL.DomainModel.NopDbEntities.KuvaLocal.ChangeMerchantTransaction[]
  }
}
export module Kuva.Module.Table {
  export type Column<T> = {
    id: string | number
    header: () => string
    column: (tableItem: T, checkedList?: {}) => string | number | JSX.Element
    className?: string
  }
}
export module Kuva.Module.Props {
  export interface InfoCopyFieldProps {
    field: string | number
    dataFor: string
  }
}
export module Kuva.Module.Props {
  export interface DashboardProps {
    permission: boolean
    count: number
    to: string
    lang: string
  }
}
export module Kuva.Module.Props {
  export interface CustomSelectorProps {
    options: Kuva.Module.Props.CustomOptionItem[]
    value: string | number
    onChange: (e: React.ChangeEvent<HTMLSelectElement>) => void
  }
}
export module Kuva.Module.Props {
  export interface CustomOptionItem {
    value: number | string
    label: () => string
    id: number
  }
}
export module Kuva.Module.Props {
  export interface SettlementFilterProps {
    startDate: Date
    endDate: Date
    viewModel: SettlementsViewModel
    error: string
    resetFilter: () => void
    onChangeStart: (date: Date) => void
    onChangeEnd: (date: Date) => void
  }
}
export module Kuva.Module.Table {
  export type TableConfig<T> = {
    columns: Column<T>[]
  }
}
export module Kuva.Module.Table {
  export type Props<T> = {
    tableConfig: TableConfig<T>
    list: T[]
    columnCount: number
    className?: string
    onClick?: (requestId: string | number) => void
  }
}
export module Kuva.Module.Table {
  export type LazyLoadingProps<T> = {
    tableConfig: TableConfig<T>
    list: T[]
    columnCount: number
    onClick?: (requestId: string | number) => void
    sentryRef: any
    rootRef: any
    loading: boolean
    hasNextPage: boolean
    checkedList?: {}
    className?: string
  }
}
export module Kuva.Module.Table {
  export type BaseGeneric = {
    id: string | number
    isNew?: boolean
  }
}
export module Kuva.DL.DomainModel.NopDbEntities.KuvaLocal {
  export interface ChangeMerchantTransaction {
    id: number
    date: string
    reference: string
    purchaseAmount: number
    receivedAmount: number
    changeAmount: number
    fee: number
    agentName: string
    agentId: string
    phone: string
    type: Kuva.Module.Enums.ChangeMerchantType
    status: Kuva.Module.Enums.CreditWalletTransactionStatus
    customerName: string
    receipt: string
    idConfirmed: boolean
    isNew: boolean
  }
}
export module Kuva.Module.Enums {
  export enum CreditWalletTransactionStatus {
    locked,
    completed,
    suppressed,
    canceled,
  }
}
export module Kuva.Module.Enums {
  export enum ChangeMerchantType {
    cash = 10,
  }
}
export module Kuva.BL.BusinessModels.Api.KuvaLocal {
  export interface CustomerName {
    fullName: string
  }
}
export module Kuva.BL.BusinessModels.Api.KuvaLocal {
  export interface ChangeMerchantTransactionRequestModel {
    purchaseAmount: number
    receivedAmount: number
    changeAmount: number
    phone: string
    receipt: string
    idConfirmed: boolean
    terms: boolean
  }
}
export module Kuva.PL.KuvaWeb.Areas.Agent.KWL {
  export interface CashoutKWLRequestsTable {
    items: Kuva.PL.KuvaWeb.Areas.Agent.KWL.AgentCashoutKWLRequestModel[]
    hasNextPage: boolean
  }
}

export module Kuva.PL.KuvaWeb.Areas.Agent.Multicash {
  export interface MultiCashRequestModel
    extends Kuva.PL.KuvaWeb.Areas.Agent.KWL.AgentCashoutKWLRequestModel { }
}
export module Kuva.PL.KuvaWeb.Areas.Agent.Multicash {
  export interface MulticashDetailsRequestModel
    extends Kuva.PL.KuvaWeb.Areas.Agent.KWL.AgentKWLCashoutDetails { }
}
export module Kuva.PL.KuvaWeb.Areas.Agent.KWL {
  export interface AgentCashoutKWLRequestModel {
    createdDate: string
    completedDate?: string
    id: string
    recipient: Kuva.PL.KuvaWeb.Areas.Agent.Models.Recipient.CashoutKWLRecipient
    digitalReference: string
    referenceNumber: string
    amountUsd: string
    booked: boolean
    status: Kuva.Module.Enums.CashOutBookingStatus
    isNew: boolean
  }
  export interface IMultiCashOutList {
    id: number
    createdDate: 'string'
    cashOutsAmount: number
    cashOutsCount: number
    status: Kuva.Module.Enums.MultiCashOutBundleStatus
    isNew: boolean
  }
  export interface IGetMultiCashOutsResponse {
    items: IMultiCashOutList[]
    pageNumber: number
    count: number
    hasNextPage: boolean
  }
  export interface IKwlDetailsProcessDialog {
    cashOuts: AgentCashoutKWLRequestModel[]
    totalAmount: string
    selectedAmount: number
  }

  export interface IKwlCashOutFromBundle {
    id: string
    referenceNumber: string
    digitalReference: string
    recipient: {
      firstName: string
      lastName: string
      phoneNumber: string
      dateOfBirth: string
      documentNumber: string
      address: {
        countryName: string
        city: string
        street: string
        postcode: string
      }
    }
    amount: number
    status: Kuva.Module.Enums.CashOutBookingStatus
    createdDate: string
    redeemedDate: string
  }
  export interface IKwlCashOutBundleDetailsResponse {
    cashOuts: IKwlCashOutFromBundle[]
    id: number
    createdDate: string
    completedDate: string | null
    cashOutsAmount: number
    cashOutsCount: number
    status: Kuva.Module.Enums.MultiCashOutBundleStatus
  }
}
export module Kuva.PL.KuvaWeb.Areas.Agent.Multicash {
  export interface MultiCashoutRequestsTable {
    items: Kuva.PL.KuvaWeb.Areas.Agent.KWL.AgentCashoutKWLRequestModel[]
    hasNextPage: boolean
  }
}
export module Kuva.PL.KuvaWeb.Areas.Agent.Models.Recipient {
  export interface CashoutKWLRecipient {
    name: string
    phoneNumber: string
  }
}
export module Kuva.Module.Enums {
  export enum BundleOrderPaymentStatus {
    unpaid = 10,
    paid = 20,
  }
}
export module Kuva.Module.Enums {
  export enum MultiCashrailCashoutType {
    multicash = 10,
    cashrail = 10,
  }
}
export module Kuva.PL.KuvaWeb.Areas.CashrailAgent.Models {
  export interface CashrailOrderModel {
    order: Kuva.PL.KuvaWeb.Areas.BundleAgent.Models.BundleOrderModel
    errors: string[]
  }
}
export module Kuva.PL.KuvaWeb.Areas.BundleAgent.Models {
  export interface ParseBundleOrderResponseModel {
    order: Kuva.PL.KuvaWeb.Areas.BundleAgent.Models.BundleOrderModel
    errors: string[]
  }
}
export module Kuva.PL.KuvaWeb.Areas.BundleAgent.Models {
  export interface BundleOrderShortModel {
    id: number
    reference: string
    paymentStatus: Kuva.Module.Enums.BundleOrderPaymentStatus
    orderStatus: Kuva.Module.Enums.BundleOrderStatus
    srcAmount: number
    srcCurrency: string
    dstAmount: number
    dstCurrency: string
    createdDate: string
    settled: boolean
    credited: boolean
    srcAmountIncludeFee: number
    orderType: Kuva.Module.Enums.CashRailFlowType
  }
}
export module Kuva.PL.KuvaWeb.Areas.BundleAgent.Models {
  export interface CreateBundleOrderResponseModel {
    orderDetails: Kuva.PL.KuvaWeb.Areas.BundleAgent.Models.BundleOrderModel
    bankDetails: Kuva.PL.KuvaWeb.Areas.BundleAgent.Models.BankDetails
  }
}
export module Kuva.PL.KuvaWeb.Areas.BundleAgent.Models {
  export interface BankDetails {
    id: string
    createdDate: string
    updatedDate?: string
    name: string
    number: string
    sortCode: string
    branchNumber: string
    swiftCode: string
    usedBankFlow: Kuva.Module.Enums.BankFlow
    isActive: boolean
    countryId?: number
    country: Kuva.DL.DomainModel.Entities.Settings.Country
    agentGroupId?: number
    agentGroup: Kuva.DL.DomainModel.Entities.AgentGroup
  }
}
export module Kuva.DL.DomainModel.Entities {
  export interface AgentGroup extends Kuva.Module.BaseEntities.BaseEntity {
    title: string
    kDAgentPercentageFee: number
    topUpAgentPercentageFee: number
    topUpAgentFixedFee: number
    agentPercentageFee: number
    agentFixedFee: number
    cashoutAgentFixedFee?: number
    cashoutAgentPercentageFee?: number
    cashoutKuvaPercetageFee?: number
    changeMerchantFee: number
    changeLimit: number
    rate: number
    currency: string
    countryId?: number
    country: Kuva.DL.DomainModel.Entities.Settings.Country
    phoneNumber: string
    settlementCurrency: string
    agents: Kuva.DL.DomainModel.Entities.AgentProfile[]
    settlements: Kuva.DL.DomainModel.Entities.KuvaLocal.Settlement[]
    bankDetails: Kuva.PL.KuvaWeb.Areas.BundleAgent.Models.BankDetails[]
  }
}
export module Kuva.DL.DomainModel.Entities {
  export interface AgentProfile extends Kuva.Module.BaseEntities.ExtendedEntity {
    aspNetUserId: string
    aspNetUser: Kuva.DL.DomainModel.Entities.User
    cashOutBalanceUsd: number
    availableCashOutAmountUsd: number
    country: string
    city: string
    street: string
    zipCode: string
    lng: number
    lat: number
    timeZoneId: string
    fullName: string
    CanAgentEditProfile: boolean
    agentGroupId?: number
    agentGroup: Kuva.DL.DomainModel.Entities.AgentGroup
    vendorId?: string
    agentSchedules: Kuva.DL.DomainModel.Entities.AgentSchedule[]
    cashOutBookings: Kuva.DL.DomainModel.Entities.CashOutBooking[]
    contactToAgents: Kuva.DL.DomainModel.Entities.ContactToAgent[]
    invites: Kuva.DL.DomainModel.Entities.Referral.ReferralInvite[]
    onboardingFixedFee?: number
    changeMerchantFee?: number
    changeLimit?: number
    limitAmountWarning: number
    cashoutEnabled: boolean
    isPendingGroupCashoutsVisible: boolean
    isPastGroupCashoutsVisible: boolean
    isShowUserDetailsEnabled: boolean
    isPendingGroupCashOnDeliveryVisible: boolean
    twoFactorEnabled: boolean
    groupChangeMerchantTransactionsVisible: boolean
  }
}
export module Kuva.DL.DomainModel.Entities.Referral {
  export interface ReferralInvite extends Kuva.Module.BaseEntities.BaseEntity {
    referralCodeId?: number
    refferalCode: Kuva.DL.DomainModel.Entities.Referral.ReferralCode
    appType?: Kuva.Module.Enums.AppType
    agentInviterId?: number
    agentInviter: Kuva.DL.DomainModel.Entities.AgentProfile
    promoCodeId?: number
    promoCode: Kuva.DL.DomainModel.Entities.KuvaLocal.PromoCode
    mobileUserId: number
    mobileUser: Kuva.DL.DomainModel.Entities.MobileUser
  }
}
export module Kuva.DL.DomainModel.Entities.Referral {
  export interface ReferralCode extends Kuva.Module.BaseEntities.BaseEntity {
    code: string
    count: number
    userId: Kuva.DL.DomainModel.Entities.MobileUser
    invites: Kuva.DL.DomainModel.Entities.Referral.ReferralInvite[]
  }
}
export module Kuva.DL.DomainModel.Entities.KuvaLocal {
  export interface PromoCode extends Kuva.Module.BaseEntities.BaseEntity { }
}
export module Kuva.Module.Enums {
  export enum AppType {
    KuvaCash,
    KuvaLocal,
  }
}
export module Kuva.Module.Enums {
  export enum RecordCountType {
    booked = 1,
    past,
  }
}
export module Kuva.DL.DomainModel.Entities {
  export interface ContactToAgent extends Kuva.Module.BaseEntities.BaseEntity {
    version: string
    message: string
    senderId: number
    sender: Kuva.DL.DomainModel.Entities.MobileUser
    agentId: number
    agent: Kuva.DL.DomainModel.Entities.AgentProfile
  }
}
export module Kuva.DL.DomainModel.Entities {
  export interface CashOutBooking extends Kuva.Module.BaseEntities.ExtendedEntity {
    referenceNumber: string
    date: string
    amountUsd: number
    status: Kuva.Module.Enums.CashOutBookingStatus
    userId: number
    user: Kuva.DL.DomainModel.Entities.MobileUser
    agentId: number
    agent: Kuva.DL.DomainModel.Entities.AgentProfile
    processedByAgentId?: number
    processedByAgent: Kuva.DL.DomainModel.Entities.AgentProfile
    agentFixedFee: number
    agentFeePersents: number
    kuvaDirectAgentFeePersents: number
    kuvaFeePersents: number
    settlementId?: number
    settlement: Kuva.DL.DomainModel.Entities.KuvaLocal.Settlement
    topUpRequestId?: number
    topUpRequest: Kuva.DL.DomainModel.Entities.TopUpRequest
  }
}
export module Kuva.DL.DomainModel.Entities {
  export interface TopUpRequest extends Kuva.Module.BaseEntities.ExtendedEntity { }
}
export module Kuva.DL.DomainModel.Entities.KuvaLocal {
  export interface Settlement extends Kuva.Module.BaseEntities.BaseEntity {
    status: Kuva.Module.Enums.KuvaLocalSettlementManifestStatus
    agentGroupId: number
    agentGroup: Kuva.DL.DomainModel.Entities.AgentGroup
    treasuryTransfer: Kuva.DL.DomainModel.Entities.TreasuryTransfer
    topUpRequests: Kuva.DL.DomainModel.Entities.TopUpRequest
    cashOutBookings: Kuva.DL.DomainModel.Entities.CashOutBooking
    bundleOrders: Kuva.DL.DomainModel.Entities.Cashrail.BundleOrder
    generatedBy: Kuva.DL.DomainModel.Entities.User
    generatedById: string
    notes: string
    completeNote: string
    settlementPaymentType: Kuva.Module.Enums.AgentSettlementPaymentType
    currency: string
    transactionDetails: string
    orderCount: number
    direction: Kuva.Module.Enums.AgentSettlementDirection
    phone: string
    coinType: Kuva.Module.Enums.CoinType
  }
}
export module Kuva.DL.DomainModel.Entities.Cashrail {
  export interface BundleOrder extends Kuva.Module.BaseEntities.ExtendedEntity {
    reference: string
    paymentStatus: Kuva.Module.Enums.BundleOrderPaymentStatus
    orderStatus: Kuva.Module.Enums.BundleOrderStatus
    fromAmount: number
    fromCurrency: string
    dstAmount: number
    dstCurrency: string
    isCredited: boolean
    kuvaFixedFee?: number
    KuvaPercentFee?: number
    AgentFixedFee?: number
    AgentPercentFee?: number
    BundleOrderItems: Kuva.PL.KuvaWeb.Areas.BundleAgent.Models.BundleOrderItemModel[]
    TopUpRequest: Kuva.DL.DomainModel.Entities.TopUpRequest
    TopUpRequestId?: number
    Settlement: Kuva.DL.DomainModel.Entities.KuvaLocal.Settlement
    SettlementId?: number
    CreatedByAgent: Kuva.DL.DomainModel.Entities.AgentProfile
    CreatedByAgentId: number
  }
}
export module Kuva.DL.DomainModel.Entities {
  export interface TreasuryTransfer extends Kuva.Module.BaseEntities.BaseEntity {
    amount: number
    address: string
    txType: string
    topUpId?: number
    kcInvoiceId?: number
    refundedOutboundTransferId?: number
    walletType: Kuva.Module.Enums.CoinType
    treasuryTransferStatus: Kuva.Module.Enums.TreasuryTransferStatus
    senderId?: number
    sender: Kuva.DL.DomainModel.Entities.MobileUser
    transactionFormJson: string
    kuvaLocalSettlementManifestId?: number
    settlement: Kuva.DL.DomainModel.Entities.KuvaLocal.Settlement
    kuvaLocalVendorSettlementManifestId?: number
    kuvaLocalVendorSettlementManifest: Kuva.DL.DomainModel.Entities.KuvaLocal.KuvaLocalVendorSettlementManifest
  }
}
export module Kuva.DL.DomainModel.Entities.KuvaLocal {
  export interface KuvaLocalVendorSettlementManifest extends Kuva.Module.BaseEntities.BaseEntity {
    status: Kuva.Module.Enums.KuvaLocalSettlementManifestStatus
    userId: number
    user: Kuva.DL.DomainModel.Entities.MobileUser
    vendorId: string
    treasuryTransfer: Kuva.DL.DomainModel.Entities.TreasuryTransfer
    manifests: Kuva.DL.DomainModel.Entities.KuvaLocal.kuvaLocalManifest
  }
}
export module Kuva.Module.Models.Paging.Agent {
  export interface CashoutFilter extends Kuva.Module.Models.Paging.PageQuery {
    dateRange?: Kuva.DL.Filters.DateFilter
    query?: string
    statuses?: Kuva.Module.Enums.CashOutBookingStatus[]
  }
}
export module Kuva.Module.Models {
  export interface RecordsCount {
    countType: Kuva.Module.Enums.RecordCountType
    value: number
  }
}
export module Kuva.Module.Models.Paging {
  export interface LazyLoadingPagedListModel<T> {
    items: T[]
    hasNextPage: boolean
    count: number
  }
}
export module Kuva.DL.Filters {
  export interface DateFilter {
    startDate?: string
    endDate?: string
  }
}
export module Kuva.Module.Enums {
  export enum TreasuryTransferStatus {
    Successful = 10,
    Failed = 20,
    Pending = 30,
    DustAmount = 40,
    Processing = 50,
    Refunded = 60,
  }
}
export module Kuva.Module.Enums {
  export enum KuvaLocalSettlementManifestStatus {
    New = 10,
    Processing = 20,
    Completed = 30,
    PayoutFailed = 40,
    Canceled = 50,
    Transferred = 60,
    PaymentDue = 70,
  }
}
export module Kuva.DL.DomainModel.Entities {
  export interface MobileUser extends Kuva.Module.BaseEntities.ExtendedEntity { }
}
export module Kuva.DL.DomainModel.Entities {
  export interface AgentSchedule extends Kuva.Module.BaseEntities.BaseEntity {
    dayOfWeek: Kuva.Module.Enums.DayOfWeek
    startTime: string
    endTime: string
    isAvailable: boolean
    agentProfileId: number
    agentProfile: Kuva.DL.DomainModel.Entities.AgentProfile
  }
}
export module Kuva.Module.Enums {
  export enum DayOfWeek {
    Sunday,
    Monday,
    Tuesday,
    Wednesday,
    Thursday,
    Friday,
    Saturday,
  }
}
export module Kuva.Module.BaseEntities {
  export interface ExtendedEntity extends Kuva.Module.BaseEntities.BaseEntity {
    extId: string
  }
}
export module Kuva.DL.DomainModel.Entities.Settings {
  export interface Country {
    id: string
    createdDate: string
    updatedDate?: string
    name: string
    phoneCode: string
    available: boolean
    kLAvailable: boolean
    kLShopAvailable: boolean
    kLMailOutAvailable: boolean
    mobileTransferAvailable: boolean
    creditCardDeliveryAvailable: boolean
    defaultCurrency: string
    iSO2Code: string
    iso3Code: string
    currencyCode?: number
    mccMnc: string
    amlCheckFlowId: number
    amlCheckFlow: Kuva.DL.DomainModel.Entities.AmlCheckFlow
    couriers: Kuva.DL.DomainModel.Entities.KuvaLocal.KuvaLocalCourier[]
    paymentOptions: Kuva.DL.DomainModel.Entities.KuvaLocal.CountryPaymentOption
  }
}
export module Kuva.DL.DomainModel.Entities.KuvaLocal {
  export interface CountryPaymentOption extends Kuva.Module.BaseEntities.BaseEntity {
    paymentMethod: Kuva.Module.Enums.PaymentMethod
    currency: string
    countryId?: number
    country: Kuva.DL.DomainModel.Entities.Settings.Country
  }
}
export module Kuva.BL.BusinessModels.Api.Agent {
  export interface AgentSettlementPagedModel {
    items: Kuva.PL.KuvaWeb.Areas.Agent.Models.AgentSettlementResponseModel[]
    hasNextPage: boolean
  }
}
export module Kuva.Module.Enums {
  export enum PaymentMethod {
    KuvaCash = 1,
    CreditCard,
    BankAccount, //Agent
    Cash,
    InstantCredit,
    ApplePay,
    GooglePay,
    CashOnDelivery,
    CreditWalletOnly,
    CreditVendor,
    Mpesa = 11,
  }
}
export module Kuva.DL.DomainModel.Entities.KuvaLocal {
  export interface KuvaLocalCourier extends Kuva.Module.BaseEntities.BaseEntity {
    companyName: string
    city: string
    countryId: number
    country: Kuva.DL.DomainModel.Entities.Settings.Country
    zones: number[]
    managerEmail: string
    managerName: string
    managerPhoneNumber: string
    orderingEmail: string
    carbonCopyEmail: string
    isDeleted: boolean
    agentGroupId?: number
    //dispatches:
  }
}
export module Kuva.Module.Models.Paging {
  export interface PageQuery {
    offset: number
    pageSize: number
  }
}
export module Kuva.Module.Models.Paging.Agent {
  export interface AgentSettlementFilter extends Kuva.Module.Models.Paging.PageQuery {
    query?: string
    statuses?: Kuva.Module.Enums.AgentSettlemenManifestStatus | string
    startDate?: string
    endDate?: string
    paymentTypes?: Kuva.Module.Enums.AgentSettlementPaymentType | string
    flowTypes?: Kuva.Module.Enums.SettlementFlowType | string
  }
}
export module Kuva.DL.DomainModel.Entities.KuvaLocal {
  export interface KuvaLocalCourierDispatchOrder extends Kuva.Module.BaseEntities.BaseEntity {
    courierId: number
    courier: Kuva.DL.DomainModel.Entities.KuvaLocal.KuvaLocalCourier
    orderedUserId?: string
    orderedUser: Kuva.DL.DomainModel.Entities.User
    manifests: Kuva.DL.DomainModel.Entities.KuvaLocal.KuvaLocalDeliveryManifest[]
  }
}
export module Kuva.DL.DomainModel.Entities.KuvaLocal {
  export interface KuvaLocalDeliveryManifest extends Kuva.Module.BaseEntities.BaseEntity {
    orderId: string
    ReceiverName: string
    ReceiverPhone: string
    TrackingNumber: string
    ShipmentCompany: string
    ShippingAddressDetail: string
    note: string
    status: Kuva.Module.Enums.DeliveryManifestStatus
    state: Kuva.Module.Enums.DeliveryManifestState
    shippingType: Kuva.Module.Enums.ShippingType
    pickupAdressId?: number
    addressId?: number
    products: Kuva.DL.DomainModel.KuvaLocal.VendorProduct[]
    productsCount: number
    kuvaLocalManifestId: number
    deliveryTime?: string
    courierDispatchOrderId?: number
    courierDispatchOrder: Kuva.DL.DomainModel.Entities.KuvaLocal.KuvaLocalCourierDispatchOrder
    agentGroupId?: number
    kuvaLocalManifest: Kuva.DL.DomainModel.Entities.KuvaLocal.kuvaLocalManifest
    lastUpdateUserId?: string
    lastUpdateUser: Kuva.DL.DomainModel.Entities.User
  }
}
export module Kuva.DL.DomainModel.Entities.KuvaLocal {
  export interface kuvaLocalManifest extends Kuva.Module.BaseEntities.BaseEntity { }
}
export module Kuva.DL.DomainModel.KuvaLocal {
  export interface VendorProduct { }
}
export module Kuva.Module.Enums {
  export enum ShippingType {
    Delivery = 1,
    PickUp = 2,
    SpecialDelivery = 3,
  }
}
export module Kuva.Module.Enums {
  export enum DeliveryManifestState {
    New = 10,
    Processing = 20,
    Completed = 30,
    Exception = 40,
  }
}
export module Kuva.Module.Enums {
  export enum DeliveryManifestStatus {
    Processing = 10,
    ReadyForPickup = 30,
    PickedUp = 40,
    Delivered = 60,
    ReceiverNotPresent = 70,
    ReadyForPickupByCourierDispatch = 80,
    PickedUpByCourierDispatch = 90,
    NotPickedUp = 100,
    NotPickedUpByCourierDispatch = 110,
    DeliveryAbandonment = 120,
  }
}
export module Kuva.DL.DomainModel.Entities {
  export interface User {
    id: string
    userName: string
    email: string
    createdDate: string
    deletedOn?: string
    blocked: boolean
    userCountries: Kuva.DL.DomainModel.Entities.Linked.UserCountry
  }
}
export module Kuva.DL.DomainModel.Entities.Linked {
  export interface UserCountry {
    userId: string
    user: Kuva.DL.DomainModel.Entities.User
    countryId: number
    country: Kuva.DL.DomainModel.Entities.Settings.Country
  }
}
export module Kuva.DL.DomainModel.Entities.KuvaLocal {
  export interface KuvaLocalCourier { }
}
export module Kuva.Module.BaseEntities {
  export interface BaseEntity {
    id: string
    CreatedDate: string
    UpdatedDate?: string
  }
}
export module Kuva.DL.DomainModel.Entities {
  export interface AmlCheckFlow {
    id: string
    createdDate: string
    updatedDate?: string
    name: string
  }
}
export module Kuva.PL.KuvaWeb.Areas.BundleAgent.Models {
  export interface BundleOrderModel {
    id: number
    reference: string
    paymentStatus: Kuva.Module.Enums.BundleOrderPaymentStatus
    orderStatus: Kuva.Module.Enums.BundleOrderStatus
    srcAmount: number
    srcCurrency: string
    dstAmount: number
    dstCurrency: string
    kuvaFixedFee?: number
    kuvaPercentFee?: number
    agentFixedFee?: number
    agentPercentFee?: number
    rate: number
    srcAmountIncludeFee: number
    settled: boolean
    credited: boolean
    createdDate: string
    bundleOrderItems: Kuva.PL.KuvaWeb.Areas.BundleAgent.Models.BundleOrderItemModel[]
    flowType: Kuva.Module.Enums.CashRailFlowType
    canPack: boolean
  }
}
export module Kuva.Module.Enums {
  export enum CashRailFlowType {
    Bundle = 10,
    Individual = 20,
  }
}
export module Kuva.PL.KuvaWeb.Areas {
  export interface Recipient {
    id: string
    firstName: string
    lastName: string
    documentType: Kuva.Module.Enums.DocumentType
    documentNumber: string
    phoneNumber: string
  }
}
export module Kuva.PL.KuvaWeb.Areas.BundleAgent.Models {
  export interface BundleOrderItemModel {
    id: number
    customerId: string
    amount: number
    reference: string
    destinationCurrency: string
    bundleOrderId: number
    packed: boolean
    canPack: boolean
    recipient: Kuva.PL.KuvaWeb.Areas.Recipient
  }
}
export module Kuva.Module.Enums {
  export enum DocumentType {
    nationalId = 10,
    driversLicense = 20,
    passport = 30,
  }
}
export module Kuva.Module.Enums {
  export enum CashOutBookingStatus {
    pending = 0,
    active = 10,
    processing = 15,
    cancelled = 20,
    completed = 30,
    invalid = 40,
    pendingPayment = 50,
  }
  export enum MultiCashOutBundleStatus {
    processing = 0,
    past = 1,
  }
}
export module Kuva.BL.BusinessModels.AdminDTO {
  export interface CashoutRequestModel {
    id: number
    extId: string
    bookingId: string
    createdDate: string
    date: string
    userId: string
    phoneNumber: string
    referenceNumber: string
    fullName: string
    dateOfBirth: string
    isShowUserDetailsEnabled: boolean
    amountUsd: number
    agentEmail: string
    agentFullName: string
    processedAgentEmail: string
    processedAgentFullName: string
    amountFee: number
    status: Kuva.Module.Enums.CashOutBookingStatus
    location: string
    updatedDate: string | null
    agentFixedFee: number
    agentFeePersents: number
    kuvaDirectAgentFeePersents: number
    kuvaFeePersents: number
    totalFee: number
    currency: string
    topUpRequestId: number | null
    settlementId: number | null
    settlementAvailableForAgent: boolean
  }
}
export module Kuva.PL.KuvaWeb.Areas.Agent.Models.Account {
  export interface LoginInfoModel {
    agent: Kuva.PL.KuvaWeb.Areas.Agent.Models.Account.AgentInfoModel
    roles: string[]
  }
}
export module Kuva.PL.KuvaWeb.Areas.Agent.Models.Account {
  export interface AgentInfoModel {
    id: string
    username: string
    canEditProfile: boolean
    canEditAvailability: boolean
    canViewSettlementDetailsFields: boolean
    canViewCashoutTotalFee: boolean
    canViewCashoutAgentFee: boolean
    canViewCashoutKuvaPercentFee: boolean
    canViewKWLPhoneNumbers: boolean
    canViewRecipientInfo: boolean
    isCashOutBundleEnabled: boolean
    availableCashoutCsv: boolean
    kwlCashOutViewerOnly: boolean
  }
}
export module Kuva.PL.KuvaWeb.Areas.Agent.Models.Account {
  export interface EnableTwoFactorRequestModel {
    twoFactorCode: string
  }
}
export module Kuva.PL.KuvaWeb.Areas.Agent.KWL {
  export interface AgentKWLCashoutDetails {
    id: string
    digitalReference: string
    referenceNumber: string
    senderPhoneNumber: string
    amount: number
    status: Kuva.Module.Enums.CashOutBookingStatus
    createdDate: string
    processedAgentEmail: string
    isBooked: boolean
    redeemedDate?: string
    recipient: Kuva.BL.BusinessModels.KWL.KWLRecipientShortModel
  }
}
// TODO: Wait Teacher
// export interface CustomInputProps<T> extends Omit<React.InputHTMLAttributes<HTMLInputElement>, 'onChange'> {
//   onChange: (e: { target: { value: T } }) => void
// }
export module Kuva.PL.KuvaWeb.Props {
  export interface PopUpProps {
    showPopup: boolean
    completeMode: 'accept' | 'cancel'
    onClosePopup: () => void
    sure: () => void
    loading: boolean
    reqType: 'cashout' | 'topUp'
  }
}
export module Kuva.BL.BusinessModels.KWL {
  export interface KWLRecipientShortModel {
    firstName: string
    lastName: string
    phoneNumber: string
    dateOfBirth: string
    documentNumber: string
    address: {
      countryName: string
      city: string
      street: string
      postcode: string
    }
    branchId: string | null
    tellerId: string | null
  }
}
export module Kuva.PL.KuvaWeb.Areas.Agent.Models.Account {
  export interface LoginRequestModel {
    email: string
    password: string
    twoFactorCode: string
  }
}
export module Kuva.PL.KuvaWeb.Areas.Agent.Models.Account {
  export interface LoginResponseModel {
    result: Kuva.BL.Interfaces.Services.LoginResult
    agent: Kuva.PL.KuvaWeb.Areas.Agent.Models.Account.AgentInfoModel
    roles: string[]
    twoFactorEnrollKey: string
    twoFactorEnrollUri: string
  }
}
export module Kuva.BL.Interfaces.Services {
  export enum LoginResult {
    unknownAccount = 0,
    secondFactorRequired = 1,
    enableSecondFactor = 2,
    accountBlocked = 3,
    accountLocked = 4,
    success = 5,
  }
}
export module Kuva.PL.KuvaWeb.Areas.Agent.Models.Profile {
  export interface LocationModel {
    street: string
    city: string
    zipCode: string
    country: string
    longitude: number
    latitude: number
  }
}
export module Kuva.PL.KuvaWeb.Areas.Agent.Models.Profile {
  export interface AddressModel {
    street: string
    city: string
    zipCode: string
    country: string
  }
}
export module Kuva.PL.KuvaWeb.Generic {
  export interface NewT {
    isNew?: boolean
  }
}
export module Kuva.PL.KuvaWeb.Areas.Agent.Models {
  export interface AgentTopUpRequestModel {
    id: string
    date: string
    referenceNumber: string
    phoneNumber: string
    fromAmount: number
    fromCurrency: string
    status: Kuva.Module.Enums.TopUpStatus
    type: Kuva.Module.Enums.AddFundsType
    isNew?: boolean
  }
}
export module Kuva.PL.KuvaWeb.Areas.Agent.Models {
  export interface AgentSettlementsRequestModel {
    settlementId: number
    agentGroupId: number
  }
}
export module Kuva.PL.KuvaWeb.Areas.Agent.Models {
  export interface AgentSettlementResponseModel {
    id: number
    agentGroupId: number
    ordersCount: number
    items: Kuva.BL.BusinessModels.AdminDTO.SettlementModelItem[]
    topUpRequests: Kuva.BL.BusinessModels.AdminDTO.TopUpRequestSettlementDTO[]
    cashOutBookings: Kuva.BL.BusinessModels.AdminDTO.CashoutRequestModel[]
    destinationUser: Kuva.PL.KuvaWeb.Areas.Agent.Models.SettlementDestinationUserModel
    treasuryTransfer: Kuva.PL.KuvaWeb.Areas.Agent.Models.TreasuryTransfer
    notes: string
    completeNote: string
    transactionDetails: string
    generatedByEmail: string
    phone: string
    currency: string
    amount: number
    cashTopup: boolean
    cashPayout: boolean
    kuvaLocal: boolean
    createdDate: string
    updatedDate: string
    coinType: Kuva.Module.Enums.CoinType
    paymentType: Kuva.Module.Enums.AgentSettlementPaymentType
    direction: Kuva.Module.Enums.AgentSettlementDirection
    status: Kuva.Module.Enums.AgentSettlemenManifestStatus
    flowType: Kuva.Module.Enums.SettlementFlowType
    isNew?: boolean
  }
}
export module Kuva.Module.Enums {
  export enum SettlementFlowType {
    other = 0,
    kuvaWhileLabelFee = 10,
    kuvaWhileLabelBulk = 20,
  }
}
export module Kuva.BL.BusinessModels.AdminDTO {
  export interface KuvaLocalSettlementManifestDTO {
    id: number
    agentGroupId: number
    isProcessableAgent: boolean
    amount: number
    totalKuvaPayment: number
    totalAgentPayment: number
    treasuryTransfer: Kuva.PL.KuvaWeb.Areas.Agent.Models.TreasuryTransfer
    destinationUser: Kuva.PL.KuvaWeb.Areas.Agent.Models.SettlementDestinationUserModel
    items: Kuva.BL.BusinessModels.AdminDTO.SettlementModelItem[]
    phone: string
    notes: string
    completeNote: string
    transactionDetails: string
    currency: string
    generatedByEmail: string
    createdDate: string
    updatedDate?: string
    direction: Kuva.Module.Enums.AgentSettlementDirection
    paymentType: Kuva.Module.Enums.AgentSettlementPaymentType
    status: Kuva.Module.Enums.AgentSettlemenManifestStatus
    coinType: Kuva.Module.Enums.CoinType
    totalPayment: number
    flowType: Kuva.Module.Enums.SettlementFlowType
  }
}
export module Kuva.Module.Enums {
  export enum CoinType {
    Dash = 10,
    USDk = 20,
    KuvaCoin = 30,
    Btc = 40,
  }
}
export module Kuva.Module.Enums {
  export enum AgentSettlementDirection {
    ToAgent,
    ToKuva,
  }
}
export module Kuva.PL.KuvaWeb.API {
  export interface QueryParams {
    [key: string]: string | number | boolean | string[]
  }
}
export module Kuva.PL.KuvaWeb.API {
  export type CustomResponse = Response & {
    errorCode: string
    message: string
    errorMessage: string
    title: string
    status: number
    code: number
  }
}
export module Kuva.PL.KuvaWeb.API {
  export type CustomError = {
    code: number
    errorCode: string
    errorMessage: string
    message: string
  }
}

export module Kuva.BL.BusinessModels.AdminDTO {
  export interface SettlementModelItem {
    isSelected: boolean
    createdDate: string
    endDate: string
    orderType: Kuva.Module.Enums.OrderType
    orderStatus: string
    ordered: number
    cost: number
    agentFee: number
    kuvaFee: number
    agentPayment: number
    kuvaPayment: number
    entityId: string
    currency: string
    url: string
    digitalReference: string | null
    reference: string
    fromAmount?: number
    agentThatBooked: string
    agentThatCashedOut: string
    timeOfBooking?: string
    timeOfCashout?: string
  }
}
export module Kuva.Module.Enums {
  export enum OrderType {
    TopUpCash,
    Cashout,
    KuvaLocal,
    KuvaDirect,
    ChangeMerchant,
    TopUpBank,
    Cashrail,
    KuvaWhileLabel,
  }
}
export module Kuva.BL.BusinessModels.AdminDTO {
  export interface TopUpRequestSettlementDTO {
    id: string
    extId?: number
    totalSendAmount: number
    receiveAmount: number
    totalFee: number
    agentFixedFee: number
    agentPercentFee: number
    totalAgentFee: number
    totalKuvaFee: number
    kuvaFixedCashFee: number
    kuvaPercentCashFee: number
    liquidityFeePersentsAmount: number
    agent: string
    currency: string
    settlementCurrency: string
  }
}
export module Kuva.PL.KuvaWeb.Areas.Agent.Models {
  export interface SettlementDestinationUserModel {
    id: string
    phone: string
    name: string
  }
}
export module Kuva.PL.KuvaWeb.Areas.Agent.Models {
  export interface TreasuryTransfer {
    id: number
    createdDate: string
    updatedDate?: string
    amount: number
    address: string
    txType: string
    walletType: string
    treasuryTransferStatus: string
    senderPhoneNumber: string
    senderName: string
    senderId: string
    originTxId?: string
  }
}
export module Kuva.Module.Enums {
  export enum AgentSettlemenManifestStatus {
    New = 10,
    Processing = 20,
    Completed = 30,
    PayoutFailed = 40,
    Canceled = 50,
    Transferred = 60,
    PaymentDue = 70,
  }
}
export module Kuva.Module.Enums {
  export enum AgentSettlementPaymentType {
    Kuvacash,
    Bank,
    Cash,
    All,
  }
}
export module Kuva.Module.Enums {
  export enum TopUpStatus {
    ordered = 10,
    transferred = 20,
    completed = 30,
    cancelled = 40,
    expired = 50,
    fundsCleared = 60,
    transferFailed = 70,
    incorrectPayin = 80,
    errorNopCommerceOrder = 90,
    underpaid = 100,
    overpaid = 110,
  }
}
export module Kuva.Module.Enums {
  export enum AddFundsType {
    bankTransfer = 10,
    cash = 20,
    kuvaLocalBankTransfer = 30,
    kuvaLocalCash = 40,
    kuvaDirectBankTransfer = 50,
    cashOnDelivery = 60,
    changeMerchant = 70,
    creditVendor = 80,
    cashrail = 90,
  }
}
export module Kuva.Module.Enums {
  export enum OrderStatus {
    Pending = 10,
    Processing = 20,
    Complete = 30,
    Rejected = 40,
    Saved = 60,
    PaymentExpired = 70,
    Partial = 80,

    /// <summary>
    /// When user paid over or less then agent entered. (BankAccount/Cash payment type)
    /// </summary>
    Underpaid = 100,
    Overpaid = 110,
    Paid = 120,
  }
}
export module KuvaWeb.Areas.Agent.Controllers {
  export interface CashOnDeliveryApiClientInterface {
    getAgentBookedCashOnDelivery?(): Promise<
      Kuva.PL.KuvaWeb.Areas.Agent.Models.AgentCashOnDeliveryRequestModel[]
    >
    getAgentPastCashOnDelivery?(): Promise<
      Kuva.PL.KuvaWeb.Areas.Agent.Models.AgentCashOnDeliveryRequestModel[]
    >
    agentCashOnDeliveryDetails?(
      orderId: any
    ): Promise<Kuva.PL.KuvaWeb.Areas.Agent.Models.AgentCashOnDeliveryDetails>
    completeCashOnDelivery?(
      orderReference: string,
      amount: number,
      completedDmIds: string[],
      deliveryDate: string
    ): Promise<unknown>
    agentCashOnDeliveryDetailsByReference?(
      referenceNumber: string
    ): Promise<Kuva.PL.KuvaWeb.Areas.Agent.Models.AgentCashOnDeliveryDetails>
  }
}
export module Kuva.PL.KuvaWeb.Areas.Agent.Controllers {
  export interface ChangeMerchantApiClientInterface {
    getTransactions?(): Promise<Kuva.PL.KuvaWeb.Areas.Agent.Models.AgentChangeMerchantTransactionsViewModel>
    detailsById?(
      id: number
    ): Promise<Kuva.DL.DomainModel.NopDbEntities.KuvaLocal.ChangeMerchantTransaction>
    customerNameCheck?(
      phone: string
    ): Promise<Kuva.BL.BusinessModels.Api.KuvaLocal.CustomerName | string>
    createTransaction?(
      request: Kuva.BL.BusinessModels.Api.KuvaLocal.ChangeMerchantTransactionRequestModel
    ): Promise<Kuva.DL.DomainModel.NopDbEntities.KuvaLocal.ChangeMerchantTransaction | string>
  }
}
export module Kuva.PL.KuvaWeb.Areas.Agent.Models {
  export interface AgentCashOnDeliveryDetails {
    id: string
    mobileUserId?: string
    topUpRequestId?: string
    manifests: Kuva.BL.BusinessModels.AdminDTO.AgentCashOnDeliveryManifests[]
    customerNumber: string
    paidAmountRealMoneyWithRejectPaidCurrency: number
    orderReference: string
    orderNumber: string
    customerEmail: string
    shopCurrency: string
    isPaid: boolean
    status: Kuva.Module.Enums.OrderStatus
    change: number
    totalPaid: number
  }
}
export module Kuva.BL.BusinessModels.AdminDTO {
  export interface AgentCashOnDeliveryManifests {
    id: string
    orderId: string
    customerNumber: string
    vendorId: string
    createdDate: string
    vendor: string
    orderReference: string
    orderNumber: string
    orderedBy: string
    deliveryManifests: Kuva.BL.BusinessModels.AdminDTO.KuvaLocalDeliveryManifestDto[]
    agentId?: string
    manifestStatus: Kuva.Module.Enums.ManifestStatus
    orderStatus: Kuva.Module.Enums.OrderStatus
    paymentType: Kuva.Module.Enums.StripePaymentTrackingType
    orderPaymentMethod: Kuva.Module.Enums.PaymentMethod
    settlementId: number
    settlementStatus: Kuva.Module.Enums.KuvaLocalSettlementManifestStatus
    isAvailableForAgentAssign: boolean
  }
}
export module Kuva.BL.BusinessModels.AdminDTO {
  export interface KuvaLocalDeliveryManifestDto {
    id: string
    orderId: string
    receiverName: string
    receiverPhone: string
    trackingNumber: string
    shipmentCompany: string
    shippingAddressDetail: string
    note: string
    status: Kuva.Module.Enums.DeliveryManifestStatus
    mobileStatus: Kuva.Module.Enums.DeliveryManifestMobileStatus
    state: Kuva.Module.Enums.DeliveryManifestState
    shippingType: Kuva.Module.Enums.ShippingType
    deliveryTime?: string
    addressId?: number
    products: Kuva.BL.BusinessModels.AdminDTO.VendorProductDto[]
    productsCount: number
    isCompleted: boolean
    courierDispatchOrderId?: number
  }
}
export module Kuva.BL.BusinessModels.AdminDTO {
  export interface VendorProductDto {
    Id: string
    OrderId: string
    ProductId: string
    ProductName: string
    Sku: string
    Quantity: number
    VendorId: string
    Vendor: string
    OrderAmount: number
    Cost: number
    AddressId?: number
    ShippingType: Kuva.Module.Enums.ShippingType
    UnitType: string
    UnitTypeAmount: string
    Details: Kuva.BL.BusinessModels.AdminDTO.VendorProductDetailDto
  }
}
export module Kuva.BL.BusinessModels.AdminDTO {
  export interface VendorProductDetailDto {
    OrderItemId: string
    KuvaFixedResult: number
    KuvaCategoryValue: number
    KuvaCategoryResult: number
    KuvaLiquidityResult: number
    SpecialDeliveryResult: number
    CurrencyCloudValue: number
    CurrencyCloudResult: number
    FinalUnitPrice: number
    ShippingType: Kuva.Module.Enums.ShippingType
    AddressId?: number
  }
}
export module Kuva.Module.Enums {
  export enum DeliveryManifestMobileStatus {
    Shipped = 10,
    ReadyForPickup = 20,
    Processing = 30,
    PickedUp = 40,
    PickupDue = 50,
    Delivered = 60,
  }
}
export module Kuva.Module.Enums {
  export enum StripePaymentTrackingType {
    None,
    Intent,
    Charge,
  }
}
export module Kuva.PL.KuvaWeb.Areas.Agent.Controllers {
  export interface SettlementApiClientInterface {
    getAgentSettlements?(
      filter: Kuva.Module.Models.Paging.Agent.AgentSettlementFilter
    ): Promise<Kuva.BL.BusinessModels.Api.Agent.AgentSettlementPagedModel>
    detailsById?(
      settlementId: number
    ): Promise<Kuva.BL.BusinessModels.AdminDTO.KuvaLocalSettlementManifestDTO>
    getPdf?(settlementId: number): Promise<Response>
    getCsv?(settlementId: number): Promise<Response>
    getAgentsSettlementsPDF?(): Promise<Response>
  }
}
export module Kuva.Module.Enums {
  export enum ManifestStatus {
    Unassigned = 0,
    Rejected = 1,
    Processing = 2,
    PickupReady = 3,
    Delivered = 4,
    FullyCompleted = 5,
    PartiallyCompleted = 6,
  }
}
export module Kuva.PL.KuvaWeb.Areas.Agent.Models {
  export interface AgentCashOnDeliveryRequestModel {
    id: string
    topUpRequestId?: string
    date: string
    orderAmount: number
    toPayWithCash: number
    referenceNumber: string
    phone: string
    paymentMethod: Kuva.Module.Enums.PaymentMethod
    status: Kuva.Module.Enums.OrderStatus
    isPaid: boolean
    actualCashPaid: number
    isNew?: boolean
  }
}
export module Kuva.BL.BusinessModels.Api.TopUp {
  export interface TopUpRequestDTOwithConversion
    extends Kuva.BL.BusinessModels.Api.TopUp.TopUpRequestDTO {
    conversion: Kuva.BL.BusinessModels.Api.TopUp.Conversion
    kuvaFee: number
    currencyCloudFee: number
    clearJunctionFee: number
    treasuryTransfer: Kuva.BL.BusinessModels.TreasuryTransfer
    agentFixedFee: number | null
    agentPercentFee: number | null
    settlementCurrency: string
    toAgentFixedCashFee: number | null
    toAgentPercentCashFee: number | null
    kuvaFixedCashFee: number | null
    kuvaPercentCashFee: number | null
    liquidityFeePersentsAmount: number | null
    onboardingFeeSrcCurrencyAmount: number
    onboardingFeeDestCurrencyAmount: number
    kdAgentFeeSrcCurrencyAmount: number
    kdAgentFeeDestCurrencyAmount: number
    initialExpectedAmount: number
    change: number
    totalFee: number | null
    agentEmail: string
    manualActionDate: string | null
    manualAmount: number | null
    cjFeePayerKuva: boolean | null
    boUserEmail: string
    amountThreshold: number
    settlementId: number | null
  }
}
export module Kuva.BL.BusinessModels.Api.TopUp {
  export interface Conversion {
    shortReference: string
    settlementDate: string | null
    status: Kuva.Module.Enums.ConversionStatus
  }
}
export module Kuva.Module.Enums {
  export enum ConversionStatus {
    pending = 10,
    settled = 20,
    cancelled = 30,
    cancelFailed = 40,
    creationFailed = 50,
  }
}
export module Kuva.BL.BusinessModels {
  export interface TreasuryTransfer {
    id: number
    createdDate: string
    updatedDate: string | null
    amount: number
    address: string
    txType: string
    walletType: string
    treasuryTransferStatus: string
    senderPhoneNumber: string
    senderName: string
    senderId: string | null
    originTxId: number | null
  }
}
export module Kuva.BL.BusinessModels.Api.TopUp {
  export interface TopUpRequestDTO
    extends Kuva.BL.BusinessModels.MobileDTO.TopUp.TopUpRequestWithUserDTO {
    agentId: string | null
    requesterName: string
  }
}
export module Kuva.BL.BusinessModels.MobileDTO.TopUp {
  export interface TopUpRequestWithUserDTO
    extends Kuva.BL.BusinessModels.MobileDTO.TopUp.TopUpRequestDTO {
    localUser: Kuva.BL.BusinessModels.Api.KuvaLocal.LocalUserProfile
  }
}
export module Kuva.BL.BusinessModels.Api.KuvaLocal {
  export interface LocalUserProfile {
    id: number
    firstName: string
    lastName: string
    phoneNumber: string
    email: string
    isEmailConfirmed: boolean
    createdDate: string
    countryId: number | null
    previousShopLocation: Kuva.DL.DomainModel.GeoId
  }
}
export module Kuva.DL.DomainModel {
  export interface GeoId {
    id: number
    countryCode: string
  }
}
export module Kuva.PL.KuvaWeb.API {
  export interface SendCashrailScvOrderModel {
    csv: FormData
    flowType: Kuva.Module.Enums.CashRailFlowType
  }
}
export module Kuva.BL.BusinessModels.MobileDTO.TopUp {
  export interface TopUpRequestDTO extends Kuva.BL.BusinessModels.MobileDTO.TopUp.TopUpRequestForm {
    id: string
    createdDate: string
    expirationDate: string
    completedDate: string | null
    reference: string
    status: Kuva.Module.Enums.TopUpStatus
    requestType: Kuva.Module.Enums.AddFundsType
    nopCommerceOrderId: Kuva.DL.DomainModel.GeoId
    cashOutId: string | null
    manualAmount: number | null
    totalFee: number | null
    bankDetailsModel: Kuva.BL.BusinessModels.AdminDTO.BankDetailsDTO
  }
}
export module Kuva.BL.BusinessModels.AdminDTO {
  export interface BankDetailsDTO {
    name: string
    number: string
    sortCode: string
    usedBankFlow: Kuva.Module.Enums.BankFlow
    branchNumber: string
    swiftCode: string
    isActive: boolean
    countryId: number | null
    agentGroupId: number | null
  }
}
export module Kuva.Module.Enums {
  export enum BankFlow {
    kuva = 0,
    agent = 1,
    topUp = 2,
    cashRail,
  }
}
export module Kuva.BL.BusinessModels.MobileDTO.TopUp {
  export interface TopUpRequestForm {
    rate: number
    from: Kuva.BL.BusinessModels.MobileDTO.TopUp.TopUpCashDTO
    to: Kuva.BL.BusinessModels.MobileDTO.TopUp.TopUpCashDTO
  }
}
export module Kuva.BL.BusinessModels.MobileDTO.TopUp {
  export interface TopUpCashDTO {
    amount: number
    currency: string
    manualAmount: number | null
    totalFromAmount: number
  }
}
export module Kuva.BL.BusinessModels.AdminDTO {
  export interface AgentScheduleDTO {
    dayOfWeek: any
    startTime: string
    endTime: string
    isAvailable: boolean
  }
}
export module KuvaWeb.Areas.Agent.Controllers {
  export interface CashOutApiClientInterface {
    getAgentBookedCashouts?(): Promise<
      | Kuva.PL.KuvaWeb.Areas.Agent.Models.AgentCashoutRequestModel[]
      | Kuva.PL.KuvaWeb.API.CustomResponse
    >
    getAgentPastCashouts?(
      query: string
    ): Promise<
      | Kuva.PL.KuvaWeb.Areas.Agent.Models.AgentCashoutRequestModel[]
      | Kuva.PL.KuvaWeb.API.CustomResponse
    >
    getCashoutRequestDetails?(
      bookingId: string
    ): Promise<Kuva.BL.BusinessModels.AdminDTO.CashoutRequestModel>
    getCashoutRequestDetailsByReference?(
      referenceNumber: string
    ): Promise<Kuva.BL.BusinessModels.AdminDTO.CashoutRequestModel>
    completeCashOutRequest?(query: {
      bookingId: string
      referenceNumber: string
    }): Promise<Kuva.BL.BusinessModels.AdminDTO.CashoutRequestModel>
    cancelCashOutRequest?(query: {
      bookingId: string
      referenceNumber: string
    }): Promise<Kuva.BL.BusinessModels.AdminDTO.CashoutRequestModel>
    getBookedCashoutsCount?(
      type: Kuva.Module.Enums.RecordCountType
    ): Promise<Kuva.Module.Models.RecordsCount>
    getPastCashoutsCount?(
      type: Kuva.Module.Enums.RecordCountType
    ): Promise<Kuva.Module.Models.RecordsCount>
  }
}
export module Kuva.PL.KuvaWeb.Areas.Agent.Controllers.Api {
  export interface AuthApiClientInterface {
    logout?(): Promise<any>
    getCurrentLogin?(): Promise<
      Kuva.PL.KuvaWeb.Areas.Agent.Models.Account.LoginInfoModel | Kuva.PL.KuvaWeb.API.CustomResponse
    >
    enableTwoFactor?(
      model: Kuva.PL.KuvaWeb.Areas.Agent.Models.Account.EnableTwoFactorRequestModel
    ): Promise<boolean | Kuva.PL.KuvaWeb.API.CustomResponse>
    login?(
      body: Kuva.PL.KuvaWeb.Areas.Agent.Models.Account.LoginRequestModel
    ): Promise<
      | Kuva.PL.KuvaWeb.Areas.Agent.Models.Account.LoginResponseModel
      | Kuva.PL.KuvaWeb.API.CustomResponse
    >
    forgot?(email: string): Promise<void | Kuva.PL.KuvaWeb.API.CustomResponse>
    recoveryPassword?(
      credentials: Kuva.PL.KuvaWeb.Areas.Agent.Models.RecoveryPasswordModel
    ): Promise<void | Kuva.PL.KuvaWeb.API.CustomResponse>
  }
}
export module Kuva.PL.KuvaWeb.Areas.Agent.Controllers.Api {
  export interface MapboxApiClientInterface {
    getToken?(): Promise<string>
  }
}
export module Kuva.PL.KuvaWeb.Areas.Agent.Controllers.Api {
  export interface ProfileApiClientInterface {
    location?(): Promise<Kuva.PL.KuvaWeb.Areas.Agent.Models.Profile.LocationModel>
    findAddress?(query: {
      longitude: number
      latitude: number
    }): Promise<Kuva.PL.KuvaWeb.Areas.Agent.Models.Profile.AddressModel>
    updateLocation?(body: Kuva.PL.KuvaWeb.Areas.Agent.Models.Profile.LocationModel): Promise<any>
  }
}
export module Kuva.PL.KuvaWeb.Areas.Agent.Controllers {
  export interface TopUpBankApiClientInterface {
    getAgentBookedTopups?(): Promise<Kuva.PL.KuvaWeb.Areas.Agent.Models.AgentTopUpRequestModel[]>
    getAgentPastTopups?(): Promise<
      Kuva.PL.KuvaWeb.Areas.Agent.Models.AgentTopUpRequestModel[] | string
    >
    detailsByReference?(
      reference: string
    ): Promise<Kuva.BL.BusinessModels.Api.TopUp.TopUpRequestDTOwithConversion>
    detailsById?(
      topUpRequestId: string
    ): Promise<Kuva.BL.BusinessModels.Api.TopUp.TopUpRequestDTOwithConversion>
    cancel?(query: { topUpRequestId: string; reference: string }): Promise<any>
    complete?(query: {
      topUpRequestId: string
      reference: string
      enteredAmount: number
    }): Promise<any>
    checkAmount?(query: { topUpRequestId: string; amount: number }): Promise<any>
  }
}
export module Kuva.PL.KuvaWeb.Areas.BundleAgent.Controllers {
  export interface BundleOrderApiClientInterface {
    sendCsvForParse?(
      csv: FormData,
      cashoutType: Kuva.Module.Enums.CashRailFlowType
    ): Promise<
      | Kuva.PL.KuvaWeb.Areas.BundleAgent.Models.ParseBundleOrderResponseModel
      | Kuva.PL.KuvaWeb.API.CustomResponse
    >
    createOrder?(
      model: Kuva.PL.KuvaWeb.Areas.BundleAgent.Models.BundleOrderModel
    ): Promise<
      | Kuva.PL.KuvaWeb.Areas.BundleAgent.Models.CreateBundleOrderResponseModel
      | Kuva.PL.KuvaWeb.API.CustomResponse
    >
    getOrders?(
      tab: 'new' | 'processing' | 'ready' | 'delivered' | 'completed'
    ): Promise<Kuva.PL.KuvaWeb.Areas.BundleAgent.Models.BundleOrderShortModel[]>
    getOrderDetails?(id: number): Promise<Kuva.PL.KuvaWeb.Areas.BundleAgent.Models.BundleOrderModel>
    changePaymentStatus?(
      id: number,
      status: Kuva.Module.Enums.BundleOrderPaymentStatus
    ): Promise<Response | Kuva.PL.KuvaWeb.API.CustomResponse>
    getBankDetails?(id: number): Promise<Kuva.PL.KuvaWeb.Areas.BundleAgent.Models.BankDetails>
  }
}
export module Kuva.PL.KuvaWeb.Areas.Agent.Controllers {
  export interface TopUpApiClientInterface {
    getAgentBookedTopups?(): Promise<
      | Kuva.PL.KuvaWeb.Areas.Agent.Models.AgentTopUpRequestModel[]
      | Kuva.PL.KuvaWeb.API.CustomResponse
    >
    getAgentPastTopups?(
      query: string
    ): Promise<
      | Kuva.PL.KuvaWeb.Areas.Agent.Models.AgentTopUpRequestModel[]
      | Kuva.PL.KuvaWeb.API.CustomResponse
    >
    detailsByReference?(
      reference: string
    ): Promise<
      | Kuva.BL.BusinessModels.Api.TopUp.TopUpRequestDTOwithConversion
      | Kuva.PL.KuvaWeb.API.CustomResponse
    >
    detailsById?(
      topUpRequestId: string
    ): Promise<
      | Kuva.BL.BusinessModels.Api.TopUp.TopUpRequestDTOwithConversion
      | Kuva.PL.KuvaWeb.API.CustomResponse
    >
    cancel?(query: { topUpRequestId: string; reference: string }): Promise<any>
    complete?(query: { topUpRequestId: string; reference: string }): Promise<any>
  }
}
export module Kuva.PL.KuvaWeb.Areas.CashrailAgent.Controllers {
  export interface CashrailOrderApiClientInterface {
    puckBundleItem?(
      orderId: number,
      itemId: number,
      packed: boolean
    ): Promise<
      Kuva.PL.KuvaWeb.Areas.BundleAgent.Models.BundleOrderModel | Kuva.PL.KuvaWeb.API.CustomResponse
    >
    getBankDetails?(id: number): Promise<Kuva.PL.KuvaWeb.Areas.BundleAgent.Models.BankDetails>
    changeOrderStatus?(
      id: number
    ): Promise<
      Kuva.PL.KuvaWeb.Areas.BundleAgent.Models.BundleOrderModel | Kuva.PL.KuvaWeb.API.CustomResponse
    >
    getOrderDetails?(id: number): Promise<Kuva.PL.KuvaWeb.Areas.BundleAgent.Models.BundleOrderModel>
    getOrders?(
      tab: 'new' | 'processing' | 'ready' | 'delivered' | 'completed'
    ): Promise<Kuva.PL.KuvaWeb.Areas.BundleAgent.Models.BundleOrderShortModel[]>
  }
}
export module Kuva.PL.KuvaWeb.Controllers.Api {
  export interface AgentsApiClientInterface {
    getSchedules?(): Promise<Kuva.BL.BusinessModels.AdminDTO.AgentScheduleDTO[]>
    updateDaySchedule?(
      body: Kuva.BL.BusinessModels.AdminDTO.AgentScheduleDTO
    ): Promise<Kuva.BL.BusinessModels.AdminDTO.AgentScheduleDTO>
  }
}
export module Kuva.Module.Enums {
  export enum BundleOrderStatus {
    new = 10,
    processing = 20,
    ready = 30,
    delivered = 40,
    completed = 50,
  }
}
export module Kuva.PL.KuvaWeb.Areas.Agent.Models {
  export interface RecoveryPasswordModel {
    key: string
    password: string
  }
}
