import { t } from "i18next";
import { observer } from "mobx-react"
import React from "react"
import { PageHeader } from "../../components/pageHeader";
import BundleOrdersList from "./OrdersList";
import { BundleAgentViewModel } from "../../viewModels/cashrail/bundleAgentOrdersViewModel";
import { RouteComponentProps, withRouter } from "react-router-dom";

@observer
class BundleCashRailPage extends React.Component<RouteComponentProps> {
    viewModel = new BundleAgentViewModel();

    componentDidMount() {
        this.viewModel.mount();
    }

    componentWillUnmount() {
        this.viewModel.unmount()
    }

    handleRedirect = () => {
        this.props.history.push("/cashrail/new");
    }

    render() {
        return <div className="bundle-page">
            <PageHeader>
                <span>
                    {t("cashrail.title")}
                </span>

                <span
                    className="download-btns">
                    <button className="btn-default btn-primary"
                        onClick={() => this.handleRedirect()}>
                        {t("cashrail.button.createOrder")}
                    </button>
                </span>
            </PageHeader>
            <div className="container body">
                <div className="mobile-settl-block">
                    <button
                        className="btn-default btn-primary"
                        onClick={() => this.handleRedirect()}>
                        {t("cashrail.button.createOrder")}
                    </button>
                </div>
                <BundleOrdersList viewModel={this.viewModel} />
            </div>
        </div>
    }
};
export default withRouter(BundleCashRailPage);