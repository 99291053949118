import * as mobx from 'mobx';
import { Kuva } from "../interfaces/kuvaweb"
import { ViewModelBase } from "./viewModelBase";
import * as fmt from "../utils/formatting";
import { lang } from '../utils/lang';
import { ChangeEvent } from 'react';


type BundleOrderShortModel = Kuva.PL.KuvaWeb.Areas.BundleAgent.Models.BundleOrderShortModel;

interface IOrdersList<T> extends ViewModelBase {
    loading: boolean;
    requestCount: number;
    filteredRequests: T[],
    setSearch(search: string): void;
}

export abstract class BundleOrdersCommon<T extends BundleOrderShortModel> extends ViewModelBase implements IOrdersList<T> {
    protected requests: T[] = [];
    protected search: string = "";

    @mobx.observable filteredRequests: T[] = new Array();
    @mobx.observable requestCount: number = 0;
    @mobx.observable loading: boolean = false;

    constructor() {
        super();
        mobx.makeObservable(this);
    }

    protected abstract getRequests(tab: 'new' | 'processing' | 'ready' | 'delivered' | 'completed'): Promise<T[]>;

    protected load = async (tab: 'new' | 'processing' | 'ready' | 'delivered' | 'completed') => {
        if (this.requests.length > 0) {
            return;
        }
        this.loading = true;
        const response = await this.getRequests(tab);
        this.requestCount = response.length;
        this.requests = response
        this.updateFilter()
        this.loading = false
    }

    @mobx.action updateFilter(): void {
        if (this.requests == null)
            return;
        mobx.runInAction(() => {
            this.filteredRequests = this.requests.filter(applyFilter(this.search));
            this.requestCount = this.filteredRequests.length;
        });
    }

    @mobx.action setSearch(search: string): void {
        this.search = search;
        this.updateFilter();
    }
}

export abstract class BundleCommon<T extends BundleOrderShortModel> extends ViewModelBase {
    processing: IOrdersList<T> = null;
    delivered: IOrdersList<T> = null;
    ready: IOrdersList<T> = null;
    completed: IOrdersList<T> = null;
    orders: IOrdersList<T> = null;

    @mobx.observable currentList: IOrdersList<T>;

    @mobx.observable tab: "new" | "processing" | "ready" | "delivered" | 'completed' = "new";

    @mobx.observable search: string = "";

    protected constructor() {
        super();

        mobx.makeObservable(this);
    }

    mount() {
        this.orders.mount()
        this.processing.mount()
        this.ready.mount()
        this.completed.mount()
        super.mount();
    }

    unmount() {
        this.orders.unmount()
        this.processing.unmount()
        this.ready.unmount()
        this.completed.unmount()
        super.unmount();
    }

    *subscribe() {
        yield mobx.autorun(() => {
            this.updateTab();
        })
    }

    @mobx.action changeTab = (tab: 'new' | 'processing' | 'ready' | 'delivered' | 'completed') => {
        this.tab = tab;
        this.updateTab();
    }

    protected updateTab() {
        switch (this.tab) {
            case 'new':
                this.currentList = this.orders;
                this.currentList?.setSearch(this.search);
                break;
            case 'processing':
                this.currentList = this.processing;
                this.currentList?.setSearch(this.search);
                break;
            case 'ready':
                this.currentList = this.ready;
                this.currentList?.setSearch(this.search);
                break;
            case 'delivered':
                this.currentList = this.delivered;
                this.currentList?.setSearch(this.search);
                break;
            case 'completed':
                this.currentList = this.completed;
                this.currentList?.setSearch(this.search);
                break;
            default:
                return;
        }
    }

    @mobx.action changeSearch(e: ChangeEvent<HTMLInputElement>): void {
        this.search = e.target.value;
    }
}

type SearchFunction = (order: BundleOrderShortModel) => string;

const searchableFields: (keyof BundleOrderShortModel | SearchFunction)[] = [
    'reference',
    order => fmt.dateShort(order.createdDate),
    'srcAmountIncludeFee',
    'dstAmount',
    'srcAmountIncludeFee',
    order => lang.cashrailDropdownPaidStatus(order.paymentStatus)
]

function applyFilter(query: string): (a: BundleOrderShortModel) => boolean {
    if (query === null || query.length < 1)
        return () => true;
    query = query.trim().toLocaleLowerCase();

    return (a) => {
        return searchableFields.some(field => {
            let value = ''
            if (typeof field === 'function') {
                value = field(a)
            } else {
                value = a[field].toString()
            }

            return value.toLocaleLowerCase().startsWith(query)
        })
    }
}