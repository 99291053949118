import { action, autorun, IReactionDisposer, makeObservable, observable, runInAction } from "mobx";
import React from "react";
import { Subscription } from "rxjs";
import { KWlCashoutApiClient } from "../../api/agent/kwlCashOutApiClient";
import { Kuva } from "../../interfaces/kuvaweb";
import { IPromiseViewModel } from "../promiseViewModel";
import { ViewModelBase } from "../viewModelBase";
import { KWlCashoutDetailsViewModel } from "./kwlCashoutDetailsViewModel";

import * as fmt from "../../utils/formatting";
import { KwlCashoutManager } from "../../managers/kwlCashoutManager";
import { PastKwlCashoutManager } from "../../managers/pastKwlCashoutManager";
import { lang } from "../../utils/lang";

type AgentKWLTopupRequestModel = Kuva.PL.KuvaWeb.Areas.Agent.KWL.AgentCashoutKWLRequestModel;
type CustomError = Kuva.PL.KuvaWeb.API.CustomError

export class BookedCashoutRequests extends ViewModelBase {
  private api = new KWlCashoutApiClient();
  manager = KwlCashoutManager.instance;

  protected requests: AgentKWLTopupRequestModel[] = [];
  protected search: string = "";

  step = 15;
  offset: number = 0;

  @observable filteredRequests: AgentKWLTopupRequestModel[] = [];
  @observable requestsCount: number = 0;
  @observable loading: boolean = this.manager.loading;
  @observable hasNextPage: boolean = null;
  @observable error: string = "";

  constructor() {
    super();
    makeObservable(this);
  }

  @action book(requestId: string) {
    this.requests.forEach(req => req.id === requestId ? req.booked = true : req)
  }

  async getRequests(query: string, pageSize: number, offset: number) {
    return await this.api.getBooked(query, pageSize, offset)
  }

  @action
  async onLoadMore(step: number) {
    if (this.step = 10) {
      this.loading = false;
    }
    else {
      this.loading = true
    }
    const response = await this.getRequests(this.search, step, this.requests.length)
    runInAction(() => {
      this.requests = [...this.requests, ...response.items];
      this.hasNextPage = response.hasNextPage;
      this.requestsCount = response.items.length
    })
    this.updateFilter()
    this.loading = false;
  }

  * subscribe() {
    yield* super.subscribe();
    yield this.manager.bookedRequests.subscribe((cashouts) => this.handleRequests(cashouts));
    yield this.manager.bookedHasNextPage.subscribe((hasNextPage) => this.hasNextPage = hasNextPage)
    yield autorun(() => {
      this.updateFilter();
    })
  }

  @action handleRequests(requests: AgentKWLTopupRequestModel[]) {
    if (requests === null) {
      this.loading = true;
      return;
    }

    this.requestsCount = requests.length
    this.requests = requests
    this.updateFilter()
    this.loading = false
  }

  @action private updateFilter() {
    if (this.requests == null)
      return

    this.filteredRequests = this.requests
  }

  @action async getCashoutsWithSearch(search: string) {
    this.search = search
    this.loading = true;
    const response = await this.getRequests(search, this.step, 0)
    this.hasNextPage = response.hasNextPage
    this.requests = null
    this.requests = response.items
    this.updateFilter()
    this.loading = false;
  }

  setSearch(search: string) {
    this.search = search
    this.updateFilter()
  }
}

export class PastCashoutRequests extends ViewModelBase {
  private api = new KWlCashoutApiClient();

  manager = PastKwlCashoutManager.instance;

  protected requests: AgentKWLTopupRequestModel[] = []
  protected search: string = ""

  step = 15;
  offset = 0;

  @observable filteredRequests: AgentKWLTopupRequestModel[] = [];
  @observable requestsCount: number = 0;
  @observable loading: boolean = this.manager.loading;
  @observable hasNextPage: boolean = null;
  @observable error: string = "";

  constructor() {
    super()
    makeObservable(this)
  }

  async getRequests(search: string, step: number, offset: number) {
    return await this.api.getPast(search, step, offset)
  }

  @action
  async onLoadMore(step: number) {
    this.loading = true;

    const response = await this.getRequests(this.search, step, this.requests.length)
    runInAction(() => {
      this.requests = [...this.requests, ...response.items];
      this.hasNextPage = response.hasNextPage;
      this.requestsCount = response.items.length
    })
    this.updateFilter()
    this.loading = false;

  }

  *subscribe(): Generator<Subscription | IReactionDisposer> {
    yield this.manager.pastRequests.subscribe((cashouts) => this.handleRequests(cashouts));
    yield this.manager.pastHasNextPage.subscribe((hasNxtPage) => this.hasNextPage = hasNxtPage);
    yield* super.subscribe();
    yield autorun(() => {
      this.updateFilter();
    })
  }

  @action handleRequests(requests: AgentKWLTopupRequestModel[]) {
    if (requests === null) {
      this.loading = true;
      return
    }

    this.requestsCount = requests.length;
    this.requests = requests;
    this.updateFilter()
    this.loading = false;
  }

  @action private updateFilter() {
    if (this.requests == null)
      return

    this.filteredRequests = this.requests
  }

  @action async getCashoutsWithSearch(search: string) {
    this.search = search
    this.loading = true;
    const response = await this.getRequests(search, this.step, 0)
    this.hasNextPage = response.hasNextPage
    this.requests = null
    this.requests = response.items
    this.updateFilter()
    this.loading = false;
  }

  setSearch(search: string) {
    this.search = search;
  }
}

export class KWLCashoutRequestsViewModel extends ViewModelBase {
  booked: BookedCashoutRequests = null;
  past: PastCashoutRequests = null;

  @observable current: BookedCashoutRequests | PastCashoutRequests = null;

  @observable details: IPromiseViewModel<void> = null;

  @observable showModal: boolean = false;

  @observable search: string = "";

  @observable tab: "past" | "booked" = "booked";

  timer: undefined | NodeJS.Timeout;

  constructor() {
    super();
    makeObservable(this);

    this.booked = new BookedCashoutRequests();
    this.past = new PastCashoutRequests();
    this.updateTab();
  }

  public mount(): void {
    this.booked.mount()
    this.past.mount()
    super.mount()
  }

  public unmount(): void {
    this.booked.unmount()
    this.past.unmount()
    super.unmount()
  }

  *subscribe() {
    yield autorun(() => {
      this.updateTab();
    });
  }

  select(requestId: string): void {
    if (this.details === null) {
      this.details = new KWlCashoutDetailsViewModel(requestId);
      this.details?.promise?.then(() => {
        this.details = null;
      })
    }
  }

  @action removeFromBooked(reference: string) {
    const request = this.booked.filteredRequests.find(r => r.digitalReference = reference);
    this.booked.filteredRequests = this.booked.filteredRequests.filter(a => a.digitalReference !== reference);

    this.past.requestsCount += 1;
    this.booked.requestsCount -= 1;

    request.digitalReference = reference;
    this.updateTab();
  }

  @action protected updateTab() {
    this.current = this.tab === "booked" ? this.booked : this.past;
    this.current?.setSearch(this.search);
  }

  @action changeSearch(e: React.ChangeEvent<HTMLInputElement>) {
    this.search = e.target.value;
    this.current?.setSearch(this.search)
    clearTimeout(this.timer);
    if (this.tab === "booked") {
      this.timer = setTimeout(() => this.current.getCashoutsWithSearch(this.search), 1000)
    } else {
      this.timer = setTimeout(() => this.current.getCashoutsWithSearch(this.search), 1000)
    }
  }

  @action changeTab(tab: "booked" | "past") {
    this.tab = tab;
    this.updateTab();
    if (tab === 'booked') {
      this.booked.getCashoutsWithSearch(this.search);
    }
    else {
      this.past.getCashoutsWithSearch(this.search);
    }
  }
}
