import { t } from 'i18next'
import { Kuva } from '../interfaces/kuvaweb'
import * as url from '../utils/url'
import { ApiError } from './apiError'
import { ApiRemoteError } from './apiRemoteError'
import { NetworkError } from './networkError'

type QueryParams = Kuva.PL.KuvaWeb.API.QueryParams

type CustomResponse = Kuva.PL.KuvaWeb.API.CustomResponse

export class ApiClientBase {
  apiUrl = window.configData.apiUrl
  apiBasePath = 'api/v1/agents/'
  newApiBasePath = 'api/agents/v1/'

  private dictionaryToQueryString(dict: {
    [key: string]: string | number | boolean | string[]
  }): string {
    const params = new URLSearchParams(dict as any)
    return params.toString()
  }

  private async ensureResponseOk(httpResponse: Response): Promise<void> {
    if (!httpResponse.ok) {
      let response = null
      if (httpResponse.headers.get('Content-Type')?.startsWith('application/json')) {
        try {
          response = await httpResponse.json()
        } catch (e) {
          console.warn('No json body from non-ok API response', e)
        }
      }

      if (response != null) {
        throw new ApiRemoteError(httpResponse.status, response)
      } else {
        throw new ApiError(httpResponse.status)
      }
    }
  }

  postFile<TResponse>(path: string[], request?: FormData, query?: QueryParams): Promise<TResponse> {
    return this.sendFile(path, request, query)
  }

  async sendFile<TResponse>(
    path: string[],
    request?: FormData,
    query?: QueryParams
  ): Promise<TResponse> {
    const queryString = query != null ? '?' + this.dictionaryToQueryString(query) : ''
    const requestUri = url.combine(this.apiUrl, this.newApiBasePath, ...path) + queryString

    let httpResponse: Response
    try {
      httpResponse = await fetch(requestUri, {
        method: 'POST',
        credentials: 'include',
        body: request,
      })
      if (!httpResponse.ok && httpResponse.status === 500) {
        alert(t('api.cashrail.parseCsv'))
      } else if (!httpResponse.ok && httpResponse.status === 404) {
        alert(t('api.cashrail.bankDetails'))
      }
    } catch (error) {
      console.log(error)
      throw new NetworkError()
    }

    await this.ensureResponseOk(httpResponse)

    if (httpResponse.headers.get('Content-Type')?.startsWith('application/json')) {
      const responseContent = await httpResponse.json()
      return responseContent as TResponse
    }
    return null
  }

  postNewUrl<TRequest, TResponse>(
    path: string[],
    request?: TRequest,
    query?: QueryParams
  ): Promise<TResponse> {
    return this.sendNewUrl('post', path, request, query)
  }

  putNewUrl<TRequest, TResponse>(
    path: string[],
    request?: TRequest,
    query?: QueryParams
  ): Promise<TResponse> {
    return this.sendNewUrl('put', path, request, query)
  }

  deleteNewUrl<TRequest, TResponse>(
    path: string[],
    request?: TRequest,
    query?: QueryParams
  ): Promise<TResponse> {
    return this.sendNewUrl('delete', path, request, query)
  }

  async getNewUrl<TResponse>(path: string[], query?: QueryParams): Promise<TResponse> {
    const queryString = query != null ? '?' + this.dictionaryToQueryString(query) : ''
    const requestUri = url.combine(this.apiUrl, this.newApiBasePath, ...path) + queryString
    let httpResponse: TResponse

    await fetch(requestUri, {
      method: 'get',
      credentials: 'include',
      headers: {
        Accept: 'application/json',
      },
    })
      .then(async response => {
        if (!response.ok) {
          const text = await response.json()
          return Promise.reject(text)
        } else {
          try {
            httpResponse = await response?.json()
          } catch {
            httpResponse = null
          }
        }
      })
      .catch((error: CustomResponse) => {
        return Promise.reject(error)
      })

    return httpResponse
  }

  async sendNewUrl<TRequest, TResponse>(
    method: 'post' | 'put' | 'delete',
    path: string[],
    request?: TRequest,
    query?: QueryParams
  ): Promise<TResponse> {
    const queryString = query != null ? '?' + this.dictionaryToQueryString(query) : ''
    const requestUri = url.combine(this.apiUrl, this.newApiBasePath, ...path) + queryString

    let httpResponse: TResponse
    httpResponse = await fetch(requestUri, {
      method: method,
      credentials: 'include',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: request != null ? JSON.stringify(request) : undefined,
    })
      .then(async response => {
        if (!response.ok) {
          const text = await response.json()
          return Promise.reject(text)
        } else {
          try {
            return await response?.json()
          } catch (error) {
            return null
          }
        }
      })
      .catch(async error => {
        return Promise.reject(error as CustomResponse)
      })
    return httpResponse
  }

  async putOldURL<TRequest, TResponse>(
    // method: 'post' | 'put' | 'delete',
    path: string[],
    request?: TRequest,
    query?: QueryParams
  ): Promise<TResponse> {
    const queryString = query != null ? '?' + this.dictionaryToQueryString(query) : ''
    const requestUri = url.combine(this.apiUrl, this.apiBasePath, ...path) + queryString

    let httpResponse: TResponse
    httpResponse = await fetch(requestUri, {
      method: 'put',
      credentials: 'include',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: request != null ? JSON.stringify(request) : undefined,
    })
      .then(async response => {
        if (!response.ok) {
          const text = await response.json()
          return Promise.reject(text)
        } else {
          try {
            return await response?.json()
          } catch (error) {
            return null
          }
        }
      })
      .catch(async error => {
        return Promise.reject(error as CustomResponse)
      })
    return httpResponse
  }

  post<TRequest, TResponse>(
    path: string[],
    request?: TRequest,
    query?: QueryParams
  ): Promise<TResponse> {
    return this.send('post', path, request, query)
  }

  put<TRequest, TResponse>(
    path: string[],
    request?: TRequest,
    query?: QueryParams
  ): Promise<TResponse> {
    return this.send('put', path, request, query)
  }

  async send<TRequest, TResponse>(
    method: 'post' | 'put',
    path: string[],
    request?: TRequest,
    query?: QueryParams
  ): Promise<TResponse> {
    const queryString = query != null ? '?' + this.dictionaryToQueryString(query) : ''
    const requestUri = url.combine(this.apiUrl, this.apiBasePath, ...path) + queryString

    await fetch(requestUri, {
      method: method,
      credentials: 'include',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: request != null ? JSON.stringify(request) : undefined,
    })
      .then(async res => {
        if (!res.ok) {
          return res.text().then(t => {
            throw new Error(t)
          })
        } else {
          try {
            return (await res?.json()) as TResponse
          } catch (t) {
            throw t
          }
        }
      })
      .catch(async error => {
        return Promise.reject(error as CustomResponse)
      })

    throw new NetworkError()
  }

  async get<TResponse>(path: string[], query?: QueryParams): Promise<TResponse> {
    const queryString = query != null ? '?' + this.dictionaryToQueryString(query) : ''
    const requestUri = url.combine(this.apiUrl, this.apiBasePath, ...path) + queryString
    const httpResponse = await fetch(requestUri, {
      method: 'get',
      credentials: 'include',
      headers: {
        Accept: 'application/json',
      },
    })

    await this.ensureResponseOk(httpResponse)

    const responseContent = await httpResponse.json()
    return responseContent as TResponse
  }

  async getFile(path: string[], query?: QueryParams, contentType?: string): Promise<Response> {
    const queryString = query != null ? '?' + this.dictionaryToQueryString(query) : ''
    const requestUri = url.combine(this.apiUrl, this.newApiBasePath, ...path) + queryString
    const httpResponse = await fetch(requestUri, {
      method: 'get',
      credentials: 'include',
      headers: {
        'Content-Type': contentType,
      },
    })

    await this.ensureResponseOk(httpResponse)

    return httpResponse
  }
}
