﻿import React, { ButtonHTMLAttributes } from "react";

export interface IndicatorButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
    loading?: boolean;
    onCommand?: (e: React.MouseEvent<HTMLButtonElement>) => Promise<any>;
}

export class IndicatorButton extends React.Component<IndicatorButtonProps> {
    state = {
        loading: null as boolean
    }

    handleCommand(e: React.MouseEvent<HTMLButtonElement>) {
        if (this.props.onCommand) {
            const invocation = this.props.onCommand(e);
            if (invocation != null) {
                if (invocation instanceof Promise) {
                    this.setState({ loading: true });
                    invocation.then(() => {
                        this.setState({ loading: false });
                    }, () => {
                        this.setState({ loading: false });
                    });
                }
            }
        }
    }

    render() {
        const props = { ...this.props };
        delete props.loading;

        if (props.onCommand) {
            delete props.onCommand;
            props.onClick = (e) => this.handleCommand(e);
        }

        let loading = this.props.loading;
        if (typeof loading === "undefined")
            loading = this.state.loading;

        return (<button {...props} className={`btn-indicator ${props.className || ''} ${loading == true ? 'btn-indicator-loading' : ''}`}><span>{this.props.children}</span></button>);
    }
}