﻿import * as signalR from "@microsoft/signalr";

import { TopUpBankApiClient } from "../api/agent/topUpBankApiClient";
import { Kuva } from "../interfaces/kuvaweb";
import * as url from "../utils/url";

import { CashRequestsManager } from "./cashRequestsManager";

import TopUpStatus = Kuva.Module.Enums.TopUpStatus;
import AgentTopUpRequestModel = Kuva.PL.KuvaWeb.Areas.Agent.Models.AgentTopUpRequestModel;
import AddFundsType = Kuva.Module.Enums.AddFundsType;

export class BankTopupsManager extends CashRequestsManager<AgentTopUpRequestModel> {
  private static _instance: BankTopupsManager;
  static get instance() {
    return this._instance = new BankTopupsManager();
  }

  private api = new TopUpBankApiClient();

  constructor() {
    super();
    this.load();
  }

  protected async getRequests() {
    return await this.api.getAgentBookedTopups();
  }

  protected async connect(): Promise<void> {
    const hubConnection = new signalR.HubConnectionBuilder()
      .withUrl(url.combine(window.configData.apiUrl, "api/v1/agents/events"))
      .withAutomaticReconnect()
      .build();
    hubConnection.on("TopupUpdated", (e) => {
      this.handleRequestUpdated(e);
    });
    await hubConnection.start();
    hubConnection.onreconnected(async () => {
      await hubConnection.start();
      console.log("recconected")
    })
  }

  protected id(request: AgentTopUpRequestModel): string {
    return request.id;
  }

  protected filter(request: AgentTopUpRequestModel): boolean {
    return (request.status == TopUpStatus.ordered || request.status == TopUpStatus.transferred) && request.type == AddFundsType.bankTransfer;
  }
}