import React, { CSSProperties, useState, createRef, useEffect, useCallback, Component, useMemo } from "react";
import { timeOfDayHour } from "../utils/formatting";

interface DragHandleProps {
    value?: number;
    onChange?: (position: number) => void;
    onBeforeChange?: ((position: number) => number | undefined);
}

class DragHandle extends Component<DragHandleProps> {
    state = {
        dragging: false,
        dragMode: null as "touch" | "mouse",
        position: this.props.value
    }

    selfRef = createRef<HTMLDivElement>();

    constructor(props: DragHandleProps) {
        super(props);
        this.handleMouseMove = this.handleMouseMove.bind(this);
        this.handleTouchMove = this.handleTouchMove.bind(this);
        this.handleMouseUp = this.handleMouseUp.bind(this);
        this.handleTouchEnd = this.handleTouchEnd.bind(this);
        this.handleMouseDown = this.handleMouseDown.bind(this);
        this.handleTouchStart = this.handleTouchStart.bind(this);
    }

    componentDidMount(): void {
        window.addEventListener("mousemove", this.handleMouseMove);
        window.addEventListener("touchmove", this.handleTouchMove, { passive: false });
        window.addEventListener("mouseup", this.handleMouseUp);
        window.addEventListener("touchend", this.handleTouchEnd);
        this.selfRef.current.addEventListener("touchstart", this.handleTouchStart, { passive: false });
    }

    componentWillUnmount(): void {
        window.removeEventListener("mousemove", this.handleMouseMove);
        window.removeEventListener("touchmove", this.handleTouchMove);
        window.removeEventListener("mouseup", this.handleMouseUp);
        window.removeEventListener("touchend", this.handleTouchEnd);
        this.selfRef.current.removeEventListener("touchstart", this.handleTouchStart);
    }

    componentDidUpdate(prevProps: Readonly<DragHandleProps>, prevState: Readonly<{}>, snapshot?: any): void {
        if(prevProps.value != this.props.value) {
            if(this.props.value) {
                this.setState({ position: this.props.value });
            }
        }
    }

    private handleMouseUp(e: MouseEvent) {
        this.stopDrag("mouse");
    }

    private handleTouchEnd(e: TouchEvent) {
        this.stopDrag("touch");
    }

    private handleTouchMove(e: TouchEvent) {
        if(!e.touches.length)
            return;

        if(this.updatePosition("touch", e.touches[0].clientX)) {
            e.preventDefault();
            e.stopPropagation();
        }
    }

    private handleMouseMove(e: MouseEvent) {
        this.updatePosition("mouse", e.clientX);
    }

    private handleTouchStart(e: TouchEvent) {
        if(this.startDrag("touch")) {
            e.preventDefault();
            e.stopPropagation();
        }
    }

    private handleMouseDown(e: React.MouseEvent) {
        if(this.startDrag("mouse")) {
            e.preventDefault();
            e.stopPropagation();
        }
    }

    private startDrag(mode: "mouse" | "touch"): boolean {
        if(this.state.dragging == true)
            return false;

        this.setState({ dragging: true, dragMode: mode });
        return true;
    }

    private stopDrag(mode: "mouse" | "touch") {
        if(this.state.dragMode != mode)
            return;

        this.setState({ dragging: false });
    }

    private updatePosition(mode: "mouse" | "touch", clientX: number): boolean {
        if(!this.state.dragging)
            return false;

        const parent = this.selfRef.current.parentElement;
        const parentRect = parent.getBoundingClientRect();

        const relativeX = clientX - parentRect.left;

        const newPosition = Math.max(Math.min(relativeX / parentRect.width, 1), 0);

        const updatedPosition: number | undefined = this.props.onBeforeChange ? this.props.onBeforeChange(newPosition) : undefined;

        const finalPosition = updatedPosition ?? newPosition;

        this.setState({ position: finalPosition });
        if(this.props.onChange)
            this.props.onChange(finalPosition);
        //props.onChange(newPosition);

        return true;
    }

    render() {
        const styleProps: CSSProperties = { left: 0, position: "absolute", userSelect: "none" };
        styleProps.left = (this.state.position * 100).toFixed(4) + "%";

        return <div className="drag-handle" style={styleProps} ref={this.selfRef} onMouseDown={this.handleMouseDown}></div>
    }
}

export interface TimeRangeSliderProps {
    value: [string, string];
    onChange: (value: [string, string]) => void;
}

export function TimeRangeSlider(props: TimeRangeSliderProps) {
    const [min, setMin] = useState(valueFromTime(props.value[0]));
    const [max, setMax] = useState(valueFromTime(props.value[1]));
    const intervals = useMemo<JSX.Element[]>(() => {
        return Array(24).fill(0).map((e, i) => { return { time: i, position: i / 24 }}).map((interval) => {
            return <div key={interval.position} className="interval" style={{ left: `${interval.position * 100}%`  }}>{timeOfDayHour(interval.time)}</div>;
        });
    }, []);

    useEffect(() => {
        setMin(valueFromTime(props.value[0]));
        setMax(valueFromTime(props.value[1]));
    }, [props.value]);

    function valueFromTime(time: string) {
        const [hours, minutes, _] = time.split(":");
        const totalMinutes = (parseInt(hours) * 60) + parseInt(minutes);
        return totalMinutes / 1440;
    }

    function valueToTime(value: number) {
        //1440 minutes in 24 hours
        const totalMinutes = value * 1440;
        const hours = Math.floor(totalMinutes / 60);
        const minutes = totalMinutes % 60;
        return hours.toFixed(0).padStart(2, "0") + ":" + minutes.toFixed(0).padStart(2, "0") + ":00";
    }

    function handleSnap(position: number) {
        const timeIntervals = (24 * 60) / 15;

        //Cap to 23:59
        const newPosition = Math.min(Math.round(position * timeIntervals) / timeIntervals, 1439/1440);
        return newPosition;
    }

    function handleMin(position: number) {
        if(position > max) {
            setMax(position);
        }
        setMin(position);

        updateValue();        
    }

    function handleMax(position: number) {
        if(position < min) {
            setMin(position);
        }
        setMax(position);

        updateValue();
    }

    function updateValue() {
        props.onChange([valueToTime(min), valueToTime(max)]);
    }

    return <div className="time-range-slider">
        <div className="span" style={{ left: `${min * 100}%`, right: `${(1-max) * 100}%` }}></div>
        <DragHandle value={min} onChange={handleMin} onBeforeChange={handleSnap}/>
        <DragHandle value={max} onChange={handleMax} onBeforeChange={handleSnap}/>
        <>
            {intervals}
        </>
    </div>
}