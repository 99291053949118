import { BehaviorSubject } from "rxjs";
import { Kuva } from "../interfaces/kuvaweb";

type NewT = Kuva.PL.KuvaWeb.Generic.NewT;

export abstract class PastCashRequestsManager<T extends NewT>{

  private pastRequestsSubject = new BehaviorSubject<T[]>(null);
  public get pastRequests() { return this.pastRequestsSubject.asObservable(); }

  private pastRequestsCountSubject = new BehaviorSubject<number>(null);
  public get pastRequestsCount() { return this.pastRequestsCountSubject.asObservable(); }

  private _pastRequests: T[] = null;

  constructor() {
    this.connect();
  }

  protected async load(): Promise<void> {
    var result = await this.getRequests();
    this._pastRequests = result
    this.publish();
  }

  protected abstract connect(): Promise<void>;
  protected abstract filter(requests: T): boolean;

  protected handlePastRequestUpdated(request: T): void {
    if (this._pastRequests == null)
      return;

    const currentIndex = this._pastRequests.findIndex(e => this.id(e) == this.id(request));
    if (currentIndex !== -1) return
    if (this.filter(request)) {
      this._pastRequests = [request, ...this._pastRequests,];
    }
    this.publish();
  }

  private publish(): void {
    this.pastRequestsSubject.next(this._pastRequests);
    this.pastRequestsCountSubject.next(this._pastRequests.length);
  }

  protected abstract getRequests(): Promise<T[]>;
  protected abstract id(request: T): string;
}