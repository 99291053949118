import * as mobx from "mobx";
import { CashrailOrderApiClient } from "../../api/cashrail/cashrailOrderApiClient";
import { Kuva } from "../../interfaces/kuvaweb";
import { CashrailCommon, CashrailOrdersCommon } from "../cashrailOrderCommon";


type BundleOrderShortModel = Kuva.PL.KuvaWeb.Areas.BundleAgent.Models.BundleOrderShortModel;


export class CashrailOrdersViewModel extends CashrailOrdersCommon<BundleOrderShortModel>{
    private apiClient = new CashrailOrderApiClient();

    constructor(tab: 'new' | 'processing' | 'ready' | 'completed' | 'delivered') {
        super();
        this.load(tab)
    }

    protected async getRequests(tab: 'new' | 'processing' | 'ready' | 'completed' | 'delivered' = "new") {
        return await this.apiClient.getOrders(tab);
    }
}

export class CashrailAgentViewModel extends CashrailCommon<BundleOrderShortModel>{
    constructor() {
        super();

        this.orders = new CashrailOrdersViewModel('new');
        this.completed = new CashrailOrdersViewModel('completed');
        this.processing = new CashrailOrdersViewModel("processing");
        this.ready = new CashrailOrdersViewModel("ready");
        this.delivered = new CashrailOrdersViewModel("delivered");

        this.updateTab();
    }

    @mobx.action changeSearch(e: React.ChangeEvent<HTMLInputElement>) {
        this.search = e.target.value;
    }
}