﻿import { IReactionDisposer } from "mobx";
import { Subscription } from "rxjs";

export class ViewModelBase {
  constructor() {
  }

  private subscriptions: (Subscription | IReactionDisposer)[] = [];

  protected * subscribe(): Generator<Subscription | IReactionDisposer> {
    return;
  }

  protected unsubscribe() {

  }

  public mount() {
    const subscriptions = this.subscribe();
    for (const subscription of subscriptions) {
      this.subscriptions.push(subscription);
    }
  }

  public unmount() {
    const subscriptions = this.subscriptions;
    this.subscriptions = [];
    for (const subscription of subscriptions) {
      if (subscription instanceof Subscription)
        subscription.unsubscribe();
      else
        subscription();
    }
    this.unsubscribe();
  }
}