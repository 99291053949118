﻿import { t } from "i18next";
import { observer } from "mobx-react";
import React, { Component, createRef } from "react";
import { NavLink } from "react-router-dom";
import isInRole from "../helpers/isRole";
import { AuthenticationManager } from "../managers/authenticationManager";
import { Icon } from "./icons";
import { LanguageSelection } from "./languageSelection";

export interface SideMenuProps {
  mode: "collapsed" | "hidden" | "full",
  open: boolean
  onToggle: () => void;
}

@observer
export class SideMenu extends Component<SideMenuProps> {
  authenticationManager = AuthenticationManager.instance;
  menuRef = createRef<HTMLDivElement>();

  isBundleAgent = isInRole(this.authenticationManager.roles, 'BundleAgent');
  isCashrailVisible = isInRole(this.authenticationManager.roles, ['BundleAgent', 'CashrailAgent']);
  isCoDVisible = isInRole(this.authenticationManager.roles, "Cash_On_Delivery_Courier");
  isSettlementAllow = isInRole(this.authenticationManager.roles, "KuvaLocalSettlement");
  isCashrailAgent = isInRole(this.authenticationManager.roles, 'CashrailAgent');
  isCashTopUpAvailable = isInRole(this.authenticationManager.roles, 'AgentCashTopUp');
  isBankTopUpAvailable = isInRole(this.authenticationManager.roles, 'AgentBankTopUp');
  isKWLCashoutAvailable = isInRole(this.authenticationManager.roles, ["KWL_cashouts"]);
  isChangeMerchantVisible = isInRole(this.authenticationManager.roles, 'ChangeMerchantView');
  isMultiCashoutsVisible = isInRole(this.authenticationManager.roles, 'Multicash_cashouts');

  canEditProfile = this.authenticationManager.agent?.canEditProfile;
  canEditAvailability = this.authenticationManager.agent?.canEditAvailability;

  constructor(props: SideMenuProps) {
    super(props);
    this.documentClickHandler = this.documentClickHandler.bind(this);
  }

  documentClickHandler(e: MouseEvent) {
    function isChildOf(child: HTMLElement | null, parent: HTMLElement | null): boolean {
      if (child == null || parent == null)
        return false;
      if (child == parent)
        return true;
      return isChildOf(child.parentElement, parent);
    }
    if (this.props.open) {
      if (!isChildOf(e.target as HTMLElement, this.menuRef.current)) {
        this.handleToggle();
      }
    }
  }

  componentDidMount() {
    window.addEventListener("click", this.documentClickHandler);
  }

  componentWillUnmount() {
    window.removeEventListener("click", this.documentClickHandler);
  }

  handleToggle() {
    this.props.onToggle();
  }
  checkAgent(): string {
    return this.isBundleAgent ? "/ba-cashrail" : "/ca-cashrail";
  }

  handleLogout() {
    if (confirm(t("component.menu.logoutPrompt"))) {
      this.authenticationManager.logout();
    }
  }
  //TODO: ADD ICON for settlement!
  render() {
    const agent = this.authenticationManager.agent;
    return <div className={`component-side-menu ${this.props.mode} ${this.props.open ? 'open' : null}`} ref={this.menuRef}>
      <button className={`menu-toggle`} onClick={() => this.handleToggle()}>
        {this.props.mode == "collapsed" && this.props.open && <Icon.MenuCollapse />}
        {this.props.mode == "collapsed" && !this.props.open && <Icon.MenuExpand />}
        {this.props.mode == "hidden" && <Icon.Menu />}
      </button>
      <div className={"menu"}>
        <div className={"brand"}><Icon.LogoWhite /> <span>{t("kuvacash")}</span></div>
        <ul>
          <li><NavLink exact to="/dashboard"><Icon.Dashboard /> <span className={"label"}>{t("component.menu.dashboard")}</span></NavLink></li>
          <li><NavLink to="/cashouts"><Icon.Cashout /> <span className={"label"}>{t("component.menu.cashouts")}</span></NavLink></li>
          {this.isCashTopUpAvailable && <li> <NavLink to="/cash-topups"><Icon.Topup /> <span className={"label"}>{t("component.menu.cashTopups")}</span></NavLink></li>}
          {this.isBankTopUpAvailable && <li><NavLink to="/bank-topups"><Icon.Topup /> <span className={"label"}>{t("component.menu.bankTopups")}</span></NavLink></li>}
          {this.isSettlementAllow && <li><NavLink to="/settlements"><Icon.Topup /> <span className={"label"}>{t("component.menu.settlements")}</span></NavLink></li>}
          {this.isCoDVisible && <li><NavLink to="/cash-on-delivery"><Icon.Cashout /> <span className={"label"}>{t("component.menu.cashOnDelivery")}</span></NavLink></li>}
          {this.isCashrailVisible && <li><NavLink to={this.isBundleAgent ? "/ba-cashrail" : "/ca-cashrail"}><Icon.Cashout /> <span className={"label"}>{t("component.menu.cashrail")} </span></NavLink></li>}
          {this.isKWLCashoutAvailable && <li> <NavLink to={'/kwl-cashouts'}><Icon.Cashout /> <span className="label">{t("component.menu.kwlCashout")}</span></NavLink></li>}
          {this.isChangeMerchantVisible ? <li><NavLink to="/change-merchant"><Icon.Cashout /> <span className={"label"}>{t("component.menu.changeMerchant")}</span></NavLink></li> : null}
          {this.isMultiCashoutsVisible ? <li><NavLink to="/multi-cashouts"><Icon.Cashout /> <span className={"label"}>{t("component.menu.multiCashouts")}</span></NavLink></li> : null}
        </ul>
        {agent != null && <div className={"menu-bottom"}>
          <ul>
            {this.canEditProfile && <li><NavLink to="/profile"><Icon.Profile /> <span className={"label"}>{t("component.menu.profile")}</span></NavLink></li>}
            {this.canEditAvailability && <li><NavLink to="/availability"><Icon.Availability /> <span className={"label"}>{t("component.menu.availability")}</span></NavLink></li>}
          </ul>
          <div className={"agent"}>
            <div>{agent.username}</div>
            <button className={"btn btn-dark w-100 mt-3"} onClick={() => this.handleLogout()}>{t("component.menu.logout")}</button>
          </div>
        </div>}
        <LanguageSelection />
      </div>
    </div >
  }
}