import { t } from "i18next";
import { observer } from "mobx-react-lite";
import React, { useMemo } from "react";
import { ChangeMerchantDetailsViewModel } from "../../viewModels/changeMerchant/changeMerchantDetailsViewModel";
import { ChangeMerchantViewModel } from "../../viewModels/changeMerchant/changemerchantTransactionsViewModel";
import { Modal } from "../modal";


const ChangeMerchantDetailsDialogue: React.FC<{ viewModel: ChangeMerchantDetailsViewModel, closeModel: ChangeMerchantViewModel }> = observer(({ viewModel, closeModel }) => {
  const { transaction } = useMemo(() => viewModel, [viewModel.transaction]);

  return <Modal
    className="process-modal">
    <div className="modal-header">
        <h5 className="modal-title">{t("changeMerchant.modal.title")} {transaction.reference}</h5>
      <button className="btn-close" onClick={() => closeModel.close()} />
    </div>
    <div className="modal-body">
      <div className="section-identity">
        <div className="info-field">
          <div className="label">{t("changeMerchant.modal.cusName")}</div>
          <div className="info">{transaction.customerName}</div>
        </div>
        <div className="info-field">
          <div className="label">{t("changeMerchant.modal.phone")}</div>
          <div className="info">{transaction.phone}</div>
        </div>
        <div className="info-field">
          <div className="label">{t("changeMerchant.modal.purchase")}</div>
          <div className="info">{transaction.purchaseAmount.toFixed(2)}</div>
        </div>
        <div className="info-field">
          <div className="label">{t("changeMerchant.modal.received")}</div>
          <div className="info">{transaction.receivedAmount}</div>
        </div>
        <div className="info-field">
          <div className="label">{t("changeMerchant.modal.change")}</div>
          <div className="info">{transaction.changeAmount}</div>
        </div>
      </div>
      <div className="button-form justifyCenter">
        <span className="customer-checked">
          {t("changeMerchant.modal.cusIdCheck")}
        </span>
        <button
          onClick={() => closeModel.close()}
          className="btn-default btn-green">
          OK
        </button>
      </div>
    </div>
  </Modal >
})
export default ChangeMerchantDetailsDialogue