import * as signalR from "@microsoft/signalr";
import { observable } from "mobx";
import { BehaviorSubject } from "rxjs";
import { KWlCashoutApiClient } from "../api/agent/kwlCashOutApiClient";

import { Kuva } from "../interfaces/kuvaweb";
import * as url from "../utils/url";

import { CashRequestsManager } from "./cashRequestsManager";

import CashOutBookingStatus = Kuva.Module.Enums.CashOutBookingStatus;

type AgentCashoutKWLRequestModel = Kuva.PL.KuvaWeb.Areas.Agent.KWL.AgentCashoutKWLRequestModel;

export class KwlCashoutManager {
  private static _instance: KwlCashoutManager;

  @observable loading: boolean = true;

  private bookedRequestsSubject = new BehaviorSubject<AgentCashoutKWLRequestModel[]>(null);
  public get bookedRequests() { return this.bookedRequestsSubject.asObservable(); }

  private bookedRequestsCountSubject = new BehaviorSubject<number>(null);
  public get bookedRequestsCount() { return this.bookedRequestsCountSubject.asObservable(); }

  private bookedHasNextPageSubject = new BehaviorSubject<boolean>(null);
  public get bookedHasNextPage() { return this.bookedHasNextPageSubject.asObservable(); }

  private _bookedRequests: AgentCashoutKWLRequestModel[] = null;
  _hasNextPage: boolean = null;

  static get instance() {
    return this._instance = new KwlCashoutManager();
  }

  private api = new KWlCashoutApiClient();

  constructor() {

    this.connect()
    this.load();
  }

  protected async load(): Promise<void> {
    try {
      this.loading = true;
      var result = await this.getRequests();
      this._bookedRequests = result.items;
      this._hasNextPage = result.hasNextPage;
      this.loading = false;
      this.publish();
    } catch (error) {
    }
  }

  async getRequests() {
    const res = await this.api.getBooked("", 20, 0);
    return res;
  }

  protected async connect(): Promise<void> {
    const hubConnection = new signalR.HubConnectionBuilder()
      .withUrl(url.combine(window.configData.apiUrl, "api/v1/agents/events"))
      .withAutomaticReconnect()
      .build();
    hubConnection.on("KwlCashoutUpdated", (e) => {
      this.handleRequestUpdated(e);
    });
    await hubConnection.start();
  }

  protected id(request: AgentCashoutKWLRequestModel): string {
    return request.id;
  }

  protected filter(request: AgentCashoutKWLRequestModel): boolean {
    return request.status == CashOutBookingStatus.pendingPayment || request.status == CashOutBookingStatus.active;
  }

  bookRequest(id: string) {
    const currentIndex = this._bookedRequests.findIndex(e => e.id == id);
    this._bookedRequests[currentIndex].booked = this._bookedRequests[currentIndex].booked == true;
    this.publish();
  }

  protected handleRequestUpdated(request: AgentCashoutKWLRequestModel): void {
    if (this._bookedRequests == null)
      return;

    const currentIndex = this._bookedRequests.findIndex(e => this.id(e) == this.id(request));
    if (currentIndex !== -1) {
      if (!this.filter(request)) {
        const newCollection = [...this._bookedRequests];
        newCollection.splice(currentIndex, 1);
        this._bookedRequests = newCollection;
      } else {
        const newCollection = [...this._bookedRequests];
        request.isNew = true;
        newCollection[currentIndex] = request;
        this._bookedRequests = newCollection;
      }
      this.publish();
    } else {
      if (this.filter(request)) {
        request.isNew = true;
        this._bookedRequests = [request, ...this._bookedRequests];
      }
      this.publish();
    }
  }

  private publish(): void {
    this.bookedRequestsSubject.next(this._bookedRequests);
    this.bookedRequestsCountSubject.next(this._bookedRequests.length);
    this.bookedHasNextPageSubject.next(this._hasNextPage);
  }
}