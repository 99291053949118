import { Kuva } from "../../interfaces/kuvaweb";
import { ApiClientBase } from "../apiClientBase";

type SettlementApiClientInterface = Kuva.PL.KuvaWeb.Areas.Agent.Controllers.SettlementApiClientInterface
type AgentSettlementPagedModel = Kuva.BL.BusinessModels.Api.Agent.AgentSettlementPagedModel;
type KuvaLocalSettlementManifestDTO = Kuva.BL.BusinessModels.AdminDTO.KuvaLocalSettlementManifestDTO;
type AgentSettlementFilter = Kuva.Module.Models.Paging.Agent.AgentSettlementFilter;

export class SettlementApiClient extends ApiClientBase implements SettlementApiClientInterface {
  base = "settlements";

  getAgentSettlements = (filter: AgentSettlementFilter) =>
    this.getNewUrl<AgentSettlementPagedModel>([this.base],
      {
        query: filter.query,
        offset: filter.offset,
        pageSize: filter.pageSize,

        "createdDates.startDate": filter.startDate,
        "createdDates.endDate": filter.endDate,
        ...(Boolean(filter.paymentTypes.toString().length) && { paymentTypes: filter.paymentTypes }),
        ...(Boolean(filter.flowTypes.toString().length) && { flowTypes: filter.flowTypes }),
        ...(Boolean(filter.statuses.toString().length) && { statuses: filter.statuses, })
      });
  detailsById = (settlementId: number) => this.getNewUrl<KuvaLocalSettlementManifestDTO>([this.base, `${settlementId}`]);
  getPdf = (settlementId: number) => this.getFile([this.base, `${settlementId}/pdf`], null, "application/pdf");
  getCsv = (settlementId: number) => this.getFile([this.base, `${settlementId}/csv`], { settlementId }, "text/csv");
  getAgentsSettlementsPDF = () => this.getFile([this.base, 'pdf'], null, "application/pdf");
  getCount = () => this.getNewUrl<number>([this.base, 'count']);
}