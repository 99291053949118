﻿import { action, makeObservable, observable } from "mobx";
import { ChangeMerchantApiClient } from "../api/agent/changeMerchantApiClient";
import { SettlementApiClient } from "../api/agent/settlementApiClient";

import { BankTopupsManager } from "../managers/bankTopupsManager";
import { CashOnDeliveryManager } from "../managers/cashOnDeliveryManager";
import { CashTopupsManager } from "../managers/cashTopupsManager";
import { KwlCashoutManager } from "../managers/kwlCashoutManager";
import { MultiCashoutsManager } from "../managers/multiCashoutsManager";
import { PagedCashoutsManager } from "../managers/paged/pagedCashoutsManager";
import { DashboardCountViewModel } from "./dashboard/dashboardCountViewModel";

import { ViewModelBase } from "./viewModelBase";

export class DashboardViewModel extends ViewModelBase {
    private cashTopupsManager = CashTopupsManager.instance;
    private bankTopupsManager = BankTopupsManager.instance;
    private cashoutsManager = PagedCashoutsManager.instance;
    private cashOnDeliveryManager = CashOnDeliveryManager.instance;
    private multicashOutsManager = MultiCashoutsManager.instance;
    private kwlCashOutsManager = KwlCashoutManager.instance;

    @observable bookedCashTopupsCount: number = null;
    @observable bookedBankTopupsCount: number = null;
    @observable bookedCashoutsCount: number = null;
    @observable bookedCashOnDeliveryCount: number = null;
    @observable bookedMulticashOutsCount: number = null;
    @observable bookedKWLCashOutsCount: number = null;



    constructor() {
        super();
        makeObservable(this);
    }

    *subscribe() {
        yield this.cashTopupsManager.bookedRequestsCount.subscribe((topups) => this.bookedCashTopupsCount = topups)
        yield this.bankTopupsManager.bookedRequestsCount.subscribe((topups) => this.bookedBankTopupsCount = topups)
        yield this.cashoutsManager.bookedRequestsCount.subscribe((cashouts) => this.bookedCashoutsCount = cashouts)
        yield this.cashOnDeliveryManager.bookedRequestsCount.subscribe((order) => this.bookedCashOnDeliveryCount = order)
        yield this.multicashOutsManager.bookedRequestsCount.subscribe((count) => this.bookedMulticashOutsCount = count)
        yield this.kwlCashOutsManager.bookedRequestsCount.subscribe((count) => this.bookedKWLCashOutsCount = count)
    }
}