﻿import { t } from "i18next";
import { observer } from "mobx-react";
import React, { Component, FormEvent } from 'react';
import { RouteComponentProps, withRouter } from "react-router-dom";

import { AuthApiClient } from "../api/authApiClient";
import { CompactField } from "../components/compactField";
import { Icon } from '../components/icons';
import { Indicator } from "../components/indicator";
import { IndicatorButton } from "../components/indicatorButton";
import { LanguageSelection } from "../components/languageSelection";
import { QRCode } from "../components/qrCode";
import { TransitionPanel } from "../components/transitionPanel";
import { Kuva } from "../interfaces/kuvaweb";
import { AuthenticationManager } from "../managers/authenticationManager";

import LoginResult = Kuva.BL.Interfaces.Services.LoginResult;
type LoginResponseModel = Kuva.PL.KuvaWeb.Areas.Agent.Models.Account.LoginResponseModel;

@observer
export class LoginPage extends Component<RouteComponentProps> {
    authManager = AuthenticationManager.instance;

    handleReconnect() {
        this.authManager.checkLogin();
    }

    handleForgotRedirect = () => {
        this.props.history.push('/forgot-password')
    }


    render() {
        return (
            <div className={"page-login"}>
                <div className={"login-panel"}>
                    <div className={"contents"}>
                        {this.authManager.ready ? (
                            <LoginForm {...this.props} authManager={this.authManager} />
                        ) : (
                            <>
                                <h1><Icon.Logo /> {t("kuvacash")}</h1>
                                <div className={"text-center"}>
                                    {this.authManager.readyError ? (
                                        <div>
                                            <p>Error connecting, please check your network connection and try again</p>
                                            <button onClick={() => this.handleReconnect()} className={"btn-default btn-primary"}>Retry</button>
                                        </div>
                                    ) : (
                                        <Indicator />
                                    )}
                                </div>
                            </>
                        )}
                        <div className="forgot-language">
                            <LanguageSelection />
                            <span
                                className="forgot-field"
                                onClick={this.handleForgotRedirect}>
                                {t("forgot")}
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

@observer
class LoginForm extends React.Component<{ authManager: AuthenticationManager } & RouteComponentProps> {
    private api = new AuthApiClient();

    state = {
        loading: false,
        displayTwoFactor: false,
        displayTwoFactorEnroll: false,
        error: null as string,

        email: "",
        password: "",
        twoFactorCode: "",

        twoFactorKey: "",
        twoFactorUri: "",
        twoFactorEnrollCode: ""
    }

    async handleSubmit(event: FormEvent) {
        event.preventDefault();

        if (this.state.displayTwoFactorEnroll) {
            await this.enroll();
        } else {
            await this.login();
        }
    }

    async enroll() {
        this.setState({ loading: true, error: null });

        try {
            const success = await this.api.enableTwoFactor({ twoFactorCode: this.state.twoFactorEnrollCode });
            if (!success)
                this.setState({ error: "Authenticator code incorrect", displayTwoFactor: true });
            else
                await this.props.authManager.checkLogin();
        } catch (e) {
            this.setState({ error: "Could not connect, check your network connection and try again" })
        }

        this.setState({ loading: false });
    }

    async login() {
        this.setState({ loading: true, error: null });
        let result: LoginResponseModel;
        try {
            result = await this.props.authManager.login(this.state.email, this.state.password, this.state.twoFactorCode);
        } catch (e) {
            this.setState({ error: "Could not connect, check your network connection and try again" })
        }

        switch (result.result) {
            case LoginResult.unknownAccount:
                if (this.state.displayTwoFactor) {
                    this.setState({ error: t("login.error.authenticatorCodeIncorrect"), displayTwoFactor: true })
                } else {
                    this.setState({ error: t("login.error.usernameOrPasswordIncorrect"), displayTwoFactor: false })
                }
                break;
            case LoginResult.secondFactorRequired:
                if (this.state.displayTwoFactor) {
                    this.setState({ error: t("login.error.authenticatorCodeIncorrect"), displayTwoFactor: true })
                } else {
                    this.setState({ error: null, displayTwoFactor: true })
                }
                break;
            case LoginResult.enableSecondFactor:
                this.setState({ error: null, twoFactorKey: result.twoFactorEnrollKey, twoFactorUri: result.twoFactorEnrollUri, displayTwoFactor: false, displayTwoFactorEnroll: true })
                break;
            case LoginResult.accountBlocked:
                this.setState({ error: t("login.error.agentBlocked"), displayTwoFactor: false })
                break;
            case LoginResult.accountLocked:
                this.setState({ error: t("login.error.lockout"), displayTwoFactor: false })
                break;
            case LoginResult.success:
                //Handled by authmanager
                break;
        }

        this.setState({ loading: false });
    }

    handleResetLogin() {
        this.setState({ displayTwoFactor: false, email: "", password: "", twoFactorCode: "", error: "" })
    }


    render() {
        const page = this.state.displayTwoFactorEnroll ? "twofactorenroll" : (this.state.displayTwoFactor ? "twofactor" : "username");

        const buttonText = this.state.displayTwoFactorEnroll ? "login.verifyButton" : "login.loginButton";

        return <form onSubmit={(e) => this.handleSubmit(e)}>
            <h1><Icon.Logo /> {t("kuvacash")}</h1>
            <div className={"panel-container"}>
                <TransitionPanel page={page}>
                    <div key={"username"} className={"login-fields"}>
                        <CompactField inputMode="email" autoCapitalize="none" type="text" placeholder={this.state.email.length > 0 ? "" : t("login.emailLabel")} value={this.state.email} onChange={(e) => this.setState({ email: e })} />
                        <CompactField type="password" placeholder={this.state.password.length > 0 ? "" : t("login.passwordLabel")} value={this.state.password} onChange={(e) => this.setState({ password: e })} />
                    </div>
                    <div key={"twofactor"} className={"login-fields"}>
                        <span className={"current-email"}>{this.state.email}</span>
                        <a className={"switch-account"} href="#" onClick={() => this.handleResetLogin()}>Switch account</a>
                        <CompactField type="test" placeholder={t("login.authenticatorCodeLabel")} value={this.state.twoFactorCode} onChange={(e) => this.setState({ twoFactorCode: e })} />
                    </div>
                    <div key={"twofactorenroll"} className={"login-fields"}>
                        <h3>{t("login.2faEnroll.title")}</h3>
                        <p>{t("login.2faEnroll.instruction")}</p>
                        <ol>
                            <li>{t("login.2faEnroll.steps.0", {
                                androidLink: "<a target={\"_blank\"} href={\"https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2&hl=en\"}>Android</a>",
                                iosLink: "<a target={\"_blank\"} href={\"https://itunes.apple.com/us/app/google-authenticator/id388497605?mt=8\"}>iOS</a>"
                            })}</li>
                            <li>{t("login.2faEnroll.steps.1")}</li>
                        </ol>
                        <QRCode uri={this.state.twoFactorUri} color={"#000000"} />
                        <span className={"twofactorkey"}>{this.state.twoFactorKey}</span>
                        <p>{t("login.2faEnroll.complete")}</p>
                        <CompactField type="test" placeholder={t("login.authenticatorCodeLabel")} value={this.state.twoFactorEnrollCode} onChange={(e) => this.setState({ twoFactorEnrollCode: e })} />
                    </div>
                </TransitionPanel>
            </div>
            {this.state.error != null && <p className={"text-center text-danger small"}>{this.state.error}</p>}
            <div className={"text-center"}>
                <IndicatorButton className="btn-default btn-login" loading={this.state.loading}>{t(buttonText)}</IndicatorButton>
            </div>
        </form>
    }
}
