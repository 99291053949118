import { action, autorun, IReactionDisposer, makeObservable, observable, runInAction } from "mobx";
import React from "react";
import { Subscription } from "rxjs";
import { MultiCashApiClient } from "../../../api/multicash/multiCashoutsApiClient";
import { Kuva } from "../../../interfaces/kuvaweb";
import { MultiCashoutsManager } from "../../../managers/multiCashoutsManager";
import { ViewModelBase } from "../../viewModelBase";
import { MultiCashoutDetailsViewModel } from "./multicashDetailsViewModel";
import { dateFormatter } from "../../../helpers/dateFormatter";
import { PastMultiCashoutsManager } from "../../../managers/pastMultiCashoutsManager";

type MultiCashRequestModel = Kuva.PL.KuvaWeb.Areas.Agent.Multicash.MultiCashRequestModel;
type LazyLoadedPagedCashoutsList = Kuva.Module.Models.Paging.LazyLoadingPagedListModel<MultiCashRequestModel>
type CustomError = Kuva.PL.KuvaWeb.API.CustomError
type CashoutFilter = Kuva.Module.Models.Paging.Agent.CashoutFilter;

export class BookedMultiCashoutsViewModel extends ViewModelBase {
    private api = new MultiCashApiClient();
    manager = MultiCashoutsManager.instance;

    @observable requests: MultiCashRequestModel[] = [];
    @observable search: string = "";

    step = 15;
    offset: number = 0;

    timer: undefined | NodeJS.Timeout;

    @observable loading: boolean = true;
    @observable hasNextPage: boolean = null;
    @observable error: string;
    @observable dateRange: string;
    @observable startDateString: string;
    @observable endDateString: string;

    @observable startDate: Date;
    @observable endDate: Date;
    @observable withoutFilter: boolean = false;
    @observable requestsCount: number = 0;
    @observable filteredCount: number;

    constructor() {
        super();
        makeObservable(this);
    }

    @action handleRequests(request: Kuva.Module.Models.Paging.LazyLoadingPagedListModel<MultiCashRequestModel>) {
        if (request === null) {
            return;
        }
        this.requestsCount = request.count ?? 0;
        this.hasNextPage = request.hasNextPage;
        this.requests = request.items;
        this.loading = false;
    }

    @action handleSearchedRequests(request: Kuva.Module.Models.Paging.LazyLoadingPagedListModel<MultiCashRequestModel>) {
        if (request === null) {
            return;
        }
        this.filteredCount = request.count ?? 0;
        this.hasNextPage = request.hasNextPage;
        this.requests = request.items;
    }

    @action async getCashoutsWithSearch(filter: CashoutFilter) {
        try {
            this.search = filter.query
            let response: LazyLoadedPagedCashoutsList;
            this.loading = true;

            response = await this.getRequests(filter)
            this.handleSearchedRequests(response)
            this.loading = false;
        } catch (error) {
            this.loading = false;
            this.hasNextPage = false;
        }
    }

    @action async getCashoutsWithoutSearch(filter: CashoutFilter) {
        try {
            this.loading = true;

            const response = await this.getRequests(filter);

            this.hasNextPage = response.hasNextPage;
            this.requests = response.items;
            this.filteredCount = undefined;

            this.loading = false;
        } catch (error) {
            this.loading = false;
            this.hasNextPage = false;
        }
    }

    @action resetCount() {
        this.filteredCount = undefined;
    }

    @action
    async onLoadMore(step: number, startDate?: string, endDate?: string) {
        try {
            const response = await this.getRequests({ query: this.search, offset: this.requests.length, pageSize: 15, dateRange: { startDate, endDate } })
            runInAction(() => {
                this.requests = [...this.requests, ...response.items];
                this.hasNextPage = response.hasNextPage;
            })
            this.loading = false;
        } catch (error) {
            let e = error as CustomError;
            console.log(e);
        }
    }

    @action changeSearch(search: string) {
        this.search = search;
        clearTimeout(this.timer);

        let start = this.startDate?.toISOString();
        let end = this.endDate?.toISOString();

        if (search?.length > 0 || this.startDate !== undefined || this.endDate !== undefined) {
            this.timer = setTimeout(
                () => this.getCashoutsWithSearch({ query: this.search, offset: 0, pageSize: 20, dateRange: { startDate: start, endDate: end } }), 1000)
        }
        {
            this.timer = setTimeout(
                () => this.getCashoutsWithoutSearch({ query: this.search, offset: 0, pageSize: 20, dateRange: { startDate: start, endDate: end } }), 1000)
        }
    }

    @action resetSearch() {
        this.search = undefined
        this.filteredCount = undefined
    }

    async getRequests(filter: CashoutFilter): Promise<Kuva.Module.Models.Paging.LazyLoadingPagedListModel<MultiCashRequestModel>> {
        const { dateRange, query } = this.fillQueryFilter(filter);

        return this.api.getLazyPagedBookedCashouts({ query: query, pageSize: filter.pageSize, offset: filter.offset, dateRange });
    }

    @action setSearch(query: string) {
        this.search = query;
        if (query?.length > 0 || this.startDate !== undefined || this.endDate !== undefined) {
            this.getCashoutsWithSearch({ query, offset: 0, pageSize: 15, dateRange: { startDate: this.startDateString, endDate: this.endDateString } });
        }
        else {
            this.getCashoutsWithoutSearch({ pageSize: 15, offset: 0 })
        }
    }

    @action setStartDate(date: Date) {
        this.startDate = date;
        this.startDateString = dateFormatter.formatAsISOString(date);
    }

    @action setEndDate(date: Date) {
        this.endDate = date;
        this.endDateString = dateFormatter.formatToEndOfDay(date);
    }

    * subscribe() {
        yield* super.subscribe();
        yield this.manager.bookedRequests.subscribe((cashouts: LazyLoadedPagedCashoutsList) => this.handleRequests(cashouts));
        yield this.manager.bookedRequestsCount.subscribe((count: number) => this.setCashoutsCount(count))
        yield this.manager.bookedHasNextPage.subscribe((hasNextPage: boolean) => this.hasNextPage = hasNextPage)
    }

    @action setCashoutsCount(count: number) {
        this.requestsCount = count;
    }

    private fillQueryFilter(filter: CashoutFilter): CashoutFilter {
        if (filter.dateRange !== undefined && filter.dateRange !== null) {
            if (filter.dateRange?.startDate === null || filter.dateRange.startDate === undefined) {
                filter.dateRange.startDate = "";
            }
            if (filter.dateRange.endDate === null || filter.dateRange.endDate === undefined) {
                filter.dateRange.endDate = "";
            }
        }
        else {
            filter.dateRange = {
                startDate: "",
                endDate: ""
            }
        }
        if (filter.query === undefined || filter.query === null) {
            filter.query = ""
        }
        return filter;
    }
}

export class PastMultiCashoutsViewModel extends ViewModelBase {
    private api = new MultiCashApiClient();
    manager = PastMultiCashoutsManager.instance;

    constructor() {
        super()
        makeObservable(this)
    }

    step = 15;
    offset = 0;
    timer: undefined | NodeJS.Timeout;

    @observable search: string;

    @observable loading: boolean = true;
    @observable hasNextPage: boolean;

    @observable requests: MultiCashRequestModel[] = []

    @observable requestsCount: number = 0;
    @observable filteredCount: number;

    @observable error: string;

    @observable startDate: Date;
    @observable endDate: Date;

    @observable dateRange: string;
    @observable startDateString: string;
    @observable endDateString: string;


    @action handleRequests(requests: Kuva.Module.Models.Paging.LazyLoadingPagedListModel<MultiCashRequestModel>) {
        if (requests === null)
            return;
        this.requestsCount = requests.count ?? 0;
        this.hasNextPage = requests.hasNextPage;
        this.requests = requests.items;
        this.loading = false;
    }

    @action handleSearchedRequests(requests: Kuva.Module.Models.Paging.LazyLoadingPagedListModel<MultiCashRequestModel>) {
        if (requests === null)
            return;
        this.filteredCount = requests.count ?? 0;
        this.hasNextPage = requests.hasNextPage;
        this.requests = requests.items;
        this.loading = false;
    }

    @action resetCount() {
        this.filteredCount = undefined;
    }

    @action async getCashoutsWithSearch(filter: CashoutFilter) {
        try {
            this.search = filter.query
            let response: LazyLoadedPagedCashoutsList;
            this.loading = true;

            response = await this.getRequests(filter)
            this.handleSearchedRequests(response)
        } catch (error) {
            this.loading = false;
            this.hasNextPage = false;
        }
    }

    @action async getCashoutsWithoutSearch(filter: CashoutFilter) {
        try {
            this.loading = true;

            const response = await this.getRequests(filter);

            this.hasNextPage = response.hasNextPage;
            this.requests = response.items;
            this.filteredCount = undefined;

            this.loading = false;
        } catch (error) {
            this.loading = false;
            this.hasNextPage = false;
        }
    }

    @action async onLoadMore(step: number, startDate?: string, endDate?: string) {
        this.loading = true;
        const response = await this.getRequests({ query: this.search, offset: this.requests.length, pageSize: 15, dateRange: { startDate, endDate } })
        runInAction(() => {
            this.hasNextPage = response.hasNextPage;
            this.requests = [...this.requests, ...response.items];
        })
        this.loading = false;
    }

    @action resetSearch() {
        this.search = undefined
        this.filteredCount = undefined
    }

    @action changeSearch(search: string) {
        this.search = search;
        clearTimeout(this.timer);

        let start = this.startDate?.toISOString();
        let end = this.endDate?.toISOString();
        if (search?.length > 0 || this.startDate !== undefined || this.endDate !== undefined) {
            this.timer = setTimeout(
                () => this.getCashoutsWithSearch({ query: this.search, offset: 0, pageSize: 20, dateRange: { startDate: start, endDate: end } }), 1000)
        }
        {
            this.timer = setTimeout(
                () => this.getCashoutsWithoutSearch({ query: this.search, offset: 0, pageSize: 20, dateRange: { startDate: start, endDate: end } }), 1000)
        }
    }

    protected getRequests(filter: Kuva.Module.Models.Paging.Agent.CashoutFilter): Promise<Kuva.Module.Models.Paging.LazyLoadingPagedListModel<MultiCashRequestModel>> {

        const { dateRange } = this.fillQueryFilter(filter);

        return this.api.getLazyPagedPastCashouts({ query: filter.query, pageSize: filter.pageSize, offset: filter.offset, dateRange });
    }

    private fillQueryFilter(filter: CashoutFilter): CashoutFilter {
        if (filter.dateRange !== undefined && filter.dateRange !== null) {
            if (filter.dateRange?.startDate === null || filter.dateRange.startDate === undefined) {
                filter.dateRange.startDate = "";
            }
            if (filter.dateRange.endDate === null || filter.dateRange.endDate === undefined) {
                filter.dateRange.endDate = "";
            }
        }
        else {
            filter.dateRange = {
                startDate: "",
                endDate: ""
            }
        }
        if (filter.query === undefined || filter.query === null) {
            filter.query = ""
        }
        return filter;
    }

    @action setSearch(query: string) {
        this.search = query;
        if (query?.length > 0 || this.startDate !== undefined || this.endDate !== undefined) {
            this.getCashoutsWithSearch({ query, offset: 0, pageSize: 15, dateRange: { startDate: this.startDateString, endDate: this.endDateString } });
        }
        else {
            this.getCashoutsWithoutSearch({ pageSize: 15, offset: 0 })
        }
    }

    *subscribe(): Generator<Subscription | IReactionDisposer> {
        yield this.manager.pastRequests.subscribe((cashouts) => this.handleRequests(cashouts));
        yield this.manager.pastHasNextPage.subscribe((hasNxtPage) => this.hasNextPage = hasNxtPage);
        yield this.manager.pastRequestsCount.subscribe((count: number) => this.setCount(count));
        yield* super.subscribe();
    }

    @action setCount(count: number) {
        this.requestsCount = count;
    }

    @action setStartDate(date: Date) {
        this.startDate = date;
        this.startDateString = dateFormatter.formatAsISOString(date);
    }

    @action setEndDate(date: Date) {
        this.endDate = date;
        this.endDateString = dateFormatter.formatToEndOfDay(date);
    }
}

export class MultiCashoutsViewModel extends ViewModelBase {
    booked: BookedMultiCashoutsViewModel = null;
    past: PastMultiCashoutsViewModel = null;

    @observable details: MultiCashoutDetailsViewModel = undefined;

    @observable search: string = "";

    @observable tab: "past" | "booked" = "booked";

    constructor() {
        super();
        makeObservable(this);

        this.booked = new BookedMultiCashoutsViewModel();
        this.past = new PastMultiCashoutsViewModel();
    }

    public mount(): void {
        this.booked.mount()
        this.past.mount()
        super.mount()
    }

    public unmount(): void {
        this.booked.unmount()
        this.past.unmount()
        super.unmount()
    }

    @action changeTab(tab: "booked" | "past", startDate: Date, endDate: Date) {
        this.tab = tab;
        if (tab === 'booked') {
            this.booked.setStartDate(startDate);
            this.booked.setEndDate(endDate);
            this.booked.setSearch(this.past.search)
        }
        else {
            this.past.setStartDate(startDate);
            this.past.setEndDate(endDate);
            this.past.setSearch(this.booked.search);
        }
    }

    @action select(requestId: string): void {
        if (this.details === undefined) {
            this.details = new MultiCashoutDetailsViewModel(requestId);
            this.details?.promise?.then(() => {
                this.details = undefined;
            })
        }
    }

}
