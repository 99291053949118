var fileDownload = require('js-file-download');
export const exportToCsv = (filename: string, rows: string[][], headers?: string[]): void => {
    if (!rows || !rows.length) {
        return;
    }
    const separator: string = ";";


    const headerString = headers ? headers.join(separator) + '\n' : ''

    const rowsString = rows.map(row => {
        return row.join(separator);
    }).join('\n')

    const csvContent =
        headerString +
        rowsString;

    const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
    fileDownload(blob, filename)
}