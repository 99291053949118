import React, { useState } from "react"
import { t } from "i18next"
import { Icon } from "../../components/icons"
import { BundleOrderApiClient } from "../../api/cashrail/bundleOrderApiClient"
import { observer } from "mobx-react"
import { BundleParsedOrderViewModel } from "../../viewModels/cashrail/bundleAgentParsedOrderViewModel"
import CashrailParsedOrderComponent from "../../components/cashrail/parsedOrderComponent"
import NewCashBundleHeader from "../../components/cashrail/newCashBundleHeader"
import PlacedOrderHeader from "../../components/cashrail/placedOrderHeader"
import PlacedOrderComponent from "../../components/cashrail/placedOrderComponent"
import { CustomSpinner } from "../../components/spinner"
import { Kuva } from "../../interfaces/kuvaweb"
import makeAnimated from 'react-select/animated';
import Select from 'react-select'
import { LongCashrailCsvTemplate, ShortCashrailCsvTemplate } from "../../components/cashrail/templateButton"

type ParseBundleOrderResponseModel = Kuva.PL.KuvaWeb.Areas.BundleAgent.Models.ParseBundleOrderResponseModel;

type SelectValue = {
    value: number,
    label: string
}

const NewCashBundle: React.FC = observer(() => {
    const api = new BundleOrderApiClient()
    let [viewModel, setViewModel] = useState<BundleParsedOrderViewModel>(null)
    const [page, setPage] = useState<1 | 2>(1)
    const [fileName, setFileName] = useState('')
    const [loading, setLoading] = useState(false)
    const animatedComponents = makeAnimated()
    const [flow, setFlow] = useState<SelectValue>()
    let result

    const options: SelectValue[] = [
        { value: 20, label: "Individual Cashouts" },
        { value: 10, label: "Single Bulk Cash Bundle" },
    ]

    const sendCsv = async (file: File) => {
        if (file) {
            const formData = new FormData();
            formData.append("csvFile", file, file.name)
            formData.append("flowType", flow.value.toString())
            try {
                setLoading(true)
                result = await api.sendCsvForParse(formData)
                setFileName(file.name)
                setViewModel(new BundleParsedOrderViewModel(result))
                setLoading(false)
            } catch {
                result = null
                setLoading(false)
            }
        }
    }

    const handleSetFlow = (e: SelectValue) => {
        setFlow(e);
    }

    const setNextPage = () => {
        if (flow == undefined) {
            return;
        }
        setPage(2)
    }

    const handleCsvUploaded = (e: React.ChangeEvent<HTMLInputElement>) => {
        const fileList = e.target.files;
        if (!fileList) return;

        sendCsv(fileList[0])
    }

    const onCancel = (): {} => {
        setViewModel(null);
        return;
    }

    return (
        <div >
            <div className="new-cash-bundle-page">
                {
                    !viewModel?.placedOrder ?
                        <NewCashBundleHeader />
                        :
                        <>
                            <PlacedOrderHeader orderReference={viewModel.placedOrder.orderDetails.reference} />
                            <div className="container">

                                <PlacedOrderComponent viewModel={viewModel} />
                            </div>
                        </>
                }
                {
                    viewModel
                        ?
                        <CashrailParsedOrderComponent onCancel={onCancel} scvName={fileName} viewModel={viewModel} />
                        :
                        !loading ?
                            page === 1 ?
                                <div className="container new-cash-bundle-first">
                                    <div className="flow-block">
                                        <h3 className="flow-title">{t("cashrail.cashoutFlow")}</h3>
                                        <Select
                                            //closeMenuOnSelect
                                            isSearchable={false}
                                            placeholder="Select Method..."
                                            components={animatedComponents}
                                            options={options}
                                            onChange={handleSetFlow}
                                            blurInputOnSelect
                                        />
                                    </div>
                                    <div>
                                        <span className="text-error select-flow-note">
                                            {t("cashrail.selectFlow")}
                                        </span>
                                    </div>
                                    <div
                                        className="next-block">
                                        <button
                                            onClick={setNextPage}
                                            className="btn btn-default btn-primary">
                                            {t("cashrail.next")}
                                        </button>
                                    </div>
                                </div>
                                :
                                <div className="container new-cash-bundle-second">
                                    <div>
                                        <Icon.UploadCsv />
                                        <label >
                                            <input
                                                accept=".csv"
                                                onChange={(e) => handleCsvUploaded(e)}
                                                type={"file"}
                                            />
                                            <a className="upload-a">
                                                {t("cashrail.button.upload")}
                                            </a>
                                        </label>
                                    </div>
                                    {
                                        flow.value == 20
                                            ?
                                            <LongCashrailCsvTemplate />
                                            :
                                            <ShortCashrailCsvTemplate />
                                    }
                                </div>
                            :
                            <CustomSpinner
                                className="loading"
                                loading={loading}>
                                {t("cashrail.loading")}
                            </CustomSpinner>
                }
            </div>
        </div >
    )
})
export default NewCashBundle;