import 'regenerator-runtime/runtime'

import React from 'react'
import i18next, { t } from 'i18next'
import LanguageDetector from 'i18next-browser-languagedetector'
import { render } from 'react-dom'
import {
  BrowserRouter,
  Route,
  Switch,
  Redirect,
  withRouter,
  RouteComponentProps,
} from 'react-router-dom'
import { observer } from 'mobx-react'
import { AuthenticatedRoute, UnauthenticatedRoute } from './helpers/authenticatedRoute'
import { BankTopupsPage } from './pages/bankTopup/bankTopupsPage'

const root = document.getElementById('react-root')

import { LoginPage } from './pages/loginPage'
import { DashboardPage } from './pages/dashboardPage'

import { CashTopupsPage } from './pages/cashTopup/cashTopupsPage'
import { SideMenu } from './components/sideMenu'
import { AuthenticationManager } from './managers/authenticationManager'
import { CashoutsPage } from './pages/cashout/cashoutsPage'
import { ProfilePage } from './pages/profilePage'
import { AvailabilityPage } from './pages/availabilityPage'
import { CashOnDeliveryPage } from './pages/cashOnDelivery/cashOnDeliveryPage'
import { SettlementsPage } from './pages/settlement/settlementsPage'
import SettlementDetailsPage from './pages/settlement/settlementDetailsPage'
import PrivateRouteComponent from './helpers/privateRoute'
import AgentCashRailPage from './pages/cashrail/cashrailAgent/agentCashrailPage'
import BundleCashrailDetailsPage from './pages/cashrail/bundleCashrailDetailsPage'
import BundleCashRailPage from './pages/cashrail/bundleCashrailPage'
import NewCashBundle from './pages/cashrail/newCashBundle'
import BundleCashrailDetailsDialogue from './pages/cashrail/bundleCashrailDetailsDialogue'
import AgentCashrailDetailPage from './pages/cashrail/cashrailAgent/agentCashrailDetailsPage'
import ForgotPassword from './pages/forgotPasswordPage'
import RecoveryPassword from './pages/recoveryPasswordPage'
import KWLCashOutPage from './pages/kwlCashout'
import ChangeMerchantPage from './pages/changeMerchant/changeMerchantPage'
import Footer from './components/footer'
import MultiCashoutsPage from './pages/multicash/cashouts'
import KwlMultiCashOutBundlePage from './pages/kwlCashout/kwlMultiCashOut/KwlMultiCashOutBundlePage'

declare global {
  interface Window {
    configData: ConfigData
  }

  interface ConfigData {
    apiUrl: string
    appVersion: string
  }
}

function AppContainer() {
  return (
    <BrowserRouter>
      <Portal />
    </BrowserRouter>
  )
}

export const Portal = withRouter(
  observer(
    class _Portal extends React.Component<RouteComponentProps> {
      authManager = AuthenticationManager.instance

      mediaQuerySm: MediaQueryList
      mediaQueryMd: MediaQueryList

      state = {
        sideMenuMode: 'full' as 'full' | 'collapsed' | 'hidden',
        sideMenuOpen: false,
      }

      componentDidMount() {
        this.mediaQuerySm = window.matchMedia('(max-width: 576px)')
        this.mediaQueryMd = window.matchMedia('(max-width: 768px)')
        this.mediaQuerySm.addEventListener('change', () => this.handleViewportUpdate())
        this.mediaQueryMd.addEventListener('change', () => this.handleViewportUpdate())
        this.handleViewportUpdate()
      }

      handleViewportUpdate() {
        if (this.mediaQuerySm && this.mediaQueryMd) {
          if (this.mediaQuerySm.matches) {
            this.setState({ sideMenuMode: 'hidden' })
          } else if (this.mediaQueryMd.matches) {
            this.setState({ sideMenuMode: 'collapsed' })
          } else {
            this.setState({ sideMenuMode: 'full' })
          }
        }
      }

      componentDidUpdate(prevProps: Readonly<RouteComponentProps>) {
        if (prevProps.location != this.props.location) {
          this.setState({
            sideMenuOpen: false,
          })
        }
      }

      handleSideMenuToggle() {
        this.setState({
          sideMenuOpen: !this.state.sideMenuOpen,
        })
      }

      render() {
        const authenticated = this.authManager.authenticated
        const sideMenuVisible = this.authManager.authenticated

        return (
          <>
            {this.authManager.ready && sideMenuVisible ? (
              <SideMenu
                mode={this.state.sideMenuMode}
                open={this.state.sideMenuOpen}
                onToggle={() => this.handleSideMenuToggle()}
              />
            ) : null}

            <div
              className={`content-container ${
                sideMenuVisible ? `side-menu-visible-${this.state.sideMenuMode}` : null
              }`}
            >
              {!this.authManager.ready ? (
                <LoginPage {...this.props} />
              ) : (
                <Switch>
                  <Route path="/" exact>
                    {authenticated ? <Redirect to="/dashboard" /> : <Redirect to="/login" />}
                  </Route>

                  <UnauthenticatedRoute path="/login" component={LoginPage} />

                  <UnauthenticatedRoute path="/login" component={LoginPage} />
                  <UnauthenticatedRoute path="/forgot-password" component={ForgotPassword} />
                  <UnauthenticatedRoute path="/recovery-password" component={RecoveryPassword} />

                  <AuthenticatedRoute path="/dashboard" component={DashboardPage} />

                  <AuthenticatedRoute path="/cashouts" exact component={CashoutsPage} />
                  <PrivateRouteComponent
                    path="/cash-topups"
                    Component={CashTopupsPage}
                    roles={['AgentCashTopUp']}
                  />
                  <PrivateRouteComponent
                    path="/bank-topups"
                    Component={BankTopupsPage}
                    roles={['AgentBankTopUp']}
                  />
                  <AuthenticatedRoute path="/profile" component={ProfilePage} />
                  <AuthenticatedRoute path="/availability" component={AvailabilityPage} />

                  <PrivateRouteComponent
                    path="/settlements"
                    Component={SettlementsPage}
                    roles={['KuvaLocalSettlement']}
                  />
                  <PrivateRouteComponent
                    path="/settlement/details"
                    Component={SettlementDetailsPage}
                    roles={['KuvaLocalSettlement']}
                    exact
                  />
                  <PrivateRouteComponent
                    path="/ba-cashrail"
                    Component={BundleCashRailPage}
                    roles={['BundleAgent']}
                  />
                  <PrivateRouteComponent
                    path="/ca-cashrail"
                    Component={AgentCashRailPage}
                    roles={['CashrailAgent']}
                  />
                  <PrivateRouteComponent
                    path="/cashrail-order/details"
                    Component={AgentCashrailDetailPage}
                    roles={['CashrailAgent']}
                    exact
                  />
                  <PrivateRouteComponent
                    path="/cashrail/details"
                    Component={BundleCashrailDetailsPage}
                    roles={['BundleAgent']}
                    exact
                  />
                  <PrivateRouteComponent
                    path="/cashrail/new"
                    Component={NewCashBundle}
                    roles={['BundleAgent']}
                    exact
                  />
                  <PrivateRouteComponent
                    path="/kwl-cashouts"
                    Component={KWLCashOutPage}
                    roles={['KWL_cashouts']}
                    exact
                  />
                  <PrivateRouteComponent
                    path="/kwl-cashouts/bundle/:id"
                    Component={KwlMultiCashOutBundlePage}
                    roles={['KWL_cashouts']}
                    exact
                  />
                  <PrivateRouteComponent
                    path="/cash-on-delivery"
                    Component={CashOnDeliveryPage}
                    roles={['Cash_On_Delivery_Courier']}
                  />
                  <PrivateRouteComponent
                    path="/change-merchant"
                    Component={ChangeMerchantPage}
                    roles={['ChangeMerchantView']}
                  />
                  <PrivateRouteComponent
                    path="/multi-cashouts"
                    Component={MultiCashoutsPage}
                    roles={['Multicash_cashouts']}
                  />
                  <Route path="*">
                    {authenticated ? <Redirect to="/dashboard" /> : <Redirect to="/login" />}
                  </Route>
                </Switch>
              )}
            </div>
            <Footer />
          </>
        )
      }
    }
  )
)

i18next
  .use(LanguageDetector)
  .use({
    type: 'postProcessor',
    name: 'debug',
    process: function (value: any, key: any, options: any, translator: any) {
      return '[' + value.toUpperCase() + ']'
    },
  })
  .init({
    fallbackLng: ['en'],
    detection: {
      order: ['querystring', 'cookie', 'navigator'],
      caches: ['cookie'],
      cookieOptions: { path: '/', sameSite: 'strict' },
    },
    resources: {
      en: {
        translation: require('../lang/en.json'),
      },
      es: {
        translation: require('../lang/es.json'),
      },
    },
  })
  .then(() => {
    render(<AppContainer />, root)

    i18next.on('languageChanged', () => {
      //Use unique key to force dom remount. We lose all state, but this works around a forceUpdate issue with mobx observers
      const key = (Math.random() * 65536).toFixed().toString()
      render(<AppContainer key={key} />, root)
    })
  })
