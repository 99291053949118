﻿import { observer } from "mobx-react";
import React from "react";
import { Kuva } from "../../interfaces/kuvaweb";
import { CashRequestsViewModel } from "../../viewModels/cashCommon";
import { SearchInput } from "../searchInput";
import * as fmt from "../../utils/formatting";
import AgentTopUpRequestModel = Kuva.PL.KuvaWeb.Areas.Agent.Models.AgentTopUpRequestModel;
import { t } from "i18next";

@observer
export class TopupsList extends React.Component<{ viewModel: CashRequestsViewModel<AgentTopUpRequestModel> }> {
  render() {
    const viewModel = this.props.viewModel;
    const current = viewModel.current;

    return <div className="dashboard-page-panel">
      <div className="page-panel-title">
        <ul className="nav custom-nav-pills nav-pills nav-fill">
          <li className="nav-item">
            <button onClick={() => viewModel.tab = "booked"}
              className={`nav-link ${viewModel.tab == "booked" ? 'active' : ''}`} aria-current="page">
              {t("topup.bookedTopupsTab")} <span className={"badge bg-secondary"}>{viewModel.booked.requestsCount}</span>
            </button>
          </li>
          <li className="nav-item">
            <button onClick={() => viewModel.tab = "past"} className={`nav-link ${viewModel.tab == "past" ? 'active' : ''}`}>
              {t("topup.pastTopupsTab")} <span className="badge bg-secondary">{viewModel.past.requestsCount ?? 0}</span>
            </button>
          </li>
        </ul>
      </div>
      <div className={"search"}>
        <SearchInput onChange={(e) => viewModel.changeSearch(e)} />
      </div>
      <ul className={"list list-col4"}>
        <li className="list-header">
          <div className={"col-date"}>{t("topup.table.dateColumn")}</div>
          <div className={"col-phone"}>{t("topup.table.clientColumn")}</div>
          <div className={"col-ref"}>{t("topup.table.referenceColumn")}</div>
          <div className={"col-amount"}>{t("topup.table.amountColumn")}</div>
        </li>
        {current.filteredRequests.map((topup) =>
          <li key={topup.id} className={topup.isNew ? "new-item" : ""} onClick={() => viewModel.select(topup)}>
            <div className={"col-date"}>{fmt.dateTimeShort(topup.date)}</div>
            <div className={"col-phone"}>{fmt.phoneNumber(topup.phoneNumber)}</div>
            <div className={"col-ref"}>{fmt.refNum(topup.referenceNumber)}</div>
            <div className={"col-amount"}>{fmt.currencyRich(topup.fromAmount, topup.fromCurrency)}</div>
          </li>
        )}
        {(!(current.filteredRequests?.length > 0)) && <li className="list-noresults">
          {viewModel.search.length > 1 ?
            <>{t("topup.table.noSearchResults")}</>
            :
            null
          }
        </li>}
        {current.loading && <li className="list-loading">
          {t("loadingData")}
        </li>}
      </ul>
    </div>
  }
}