import {Kuva} from "../interfaces/kuvaweb";

import {ApiClientBase} from "./apiClientBase";

type MapboxApiClientInterface = Kuva.PL.KuvaWeb.Areas.Agent.Controllers.Api.MapboxApiClientInterface;

export class MapboxApiClient extends ApiClientBase implements MapboxApiClientInterface {
    base = "mapbox";

    getToken = () => this.post<void, string>([this.base, 'token']);
}