import { t } from "i18next"
import { observer } from "mobx-react-lite"
import React from "react"
import { SearchInput } from "../../components/searchInput"
import { CashOnDeliveryViewModel } from "../../viewModels/cashOnDelivery/cashOnDeliveryOrdersViewModel"
import * as fmt from "../../utils/formatting";
import { lang } from "../../utils/lang"

const CashOnDeliveryList: React.FC<{ viewModel: CashOnDeliveryViewModel }> = observer(({ viewModel }) => {
  const current = viewModel.current;
  const isCompleted = viewModel.tab == "booked" ? false : true;

  return <div className="dashboard-page-panel">
    <div className="page-panel-title">
      <ul className="nav custom-nav-pills nav-pills nav-fill">
        <li className="nav-item">
          <button onClick={() => viewModel.changeTab("booked")}
            className={`nav-link ${viewModel.tab == "booked" ? 'active' : ''}`}
            aria-current="page">
            {t("cashOnDelivery.bookedCashOnDeliveryTab")}
            <span className="badge bg-secondary">
              {viewModel.booked.requestCount}
            </span>
          </button>
        </li>
        <li className="nav-item">
          <button onClick={() => viewModel.changeTab("past")}
            className={`nav-link ${viewModel.tab == "past" ? 'active' : false}`}>
            {t("cashOnDelivery.pastCashOnDeliveryTab")}
            <span className="badge bg-secondary">
              {viewModel.past.requestCount}
            </span>
          </button>
        </li>
      </ul>
    </div>
    <div className="search">
      <SearchInput onChange={(e) => viewModel.changeSearch(e)} />
    </div>
    <ul className={`list${viewModel.tab === "past" ? " list-past list-col9" : " list-col8"} `}>
      <li className="list-header">
        <div className="col-date">{t("cashOnDelivery.table.dateColumn")}</div>
        <div className="col-ref">{t("cashOnDelivery.table.referenceColumn")}</div>
        <div className="col-pay-method">{t("cashOnDelivery.table.paymentMethod")}</div>
        <div className="col-order-status">{t("cashOnDelivery.table.statusColumn")}</div>
        <div className="col-amount">{t("cashOnDelivery.table.amountColumn")}</div>
        <div className="col-pay-with-cash">{t("cashOnDelivery.table.payWithCashColumn")}</div>
        {viewModel.tab === "past" && <div className="col-actual-paid">{t("cashOnDelivery.table.actualPaid")}</div>}
        <div className="col-phone">{t("cashOnDelivery.table.phoneColumn")}</div>
        <div className="col-ispaid">{t("cashOnDelivery.table.isPaidColumn")}</div>
      </li>
      {current.filteredRequests.map((order) =>
        <li key={order.id} className={order.isNew ? "new-item" : ""} onClick={() => viewModel.select(order, isCompleted)}>
          <div className="col-date">{fmt.dateTimeShort(order.date)}</div>
          <div className="col-ref">{order.referenceNumber}</div>
          <div className="col-pay-method">{lang.cashOnDeliveryPaymentMethod(order.paymentMethod)}</div>
          <div className="col-order-status">{lang.cashOnDeliveryStatus(order.status)}</div>
          <div className="col-amount">{order.orderAmount}</div>
          <div className="col-pay-with-cash">{order.toPayWithCash}</div>
          {viewModel.tab === "past" && <div className="col-actual-paid">{order.actualCashPaid}</div>}
          <div className="col-phone">{fmt.phoneNumber(order.phone)}</div>
          <div className="col-ispaid">{order.isPaid ? t("cashOnDelivery.order.paid") : t("cashOnDelivery.order.noPaid")}</div>
        </li>)}
      {(!(current.filteredRequests?.length > 0)) && <li className="list-noresults">
        {viewModel.search.length > 1 ?
          <>{t("topup.table.noSearchResults")}</>
          :
          null
        }
      </li>}
      {current.loading && <li className="list-loading">
        {t("loadingData")}
      </li>}
    </ul>

  </div>
})
export default CashOnDeliveryList;