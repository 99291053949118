﻿import { TopUpCashApiClient } from "../../api/agent/topUpCashApiClient";
import { Kuva } from "../../interfaces/kuvaweb";
import { tryAlternatives } from "../../utils/refNum";

import { TopupFormViewModel } from "../topup/topupFormViewModel";

import { CashTopupProcessViewModel } from "./cashTopupProcessViewModel";

type TopUpRequestDTOwithConversion = Kuva.BL.BusinessModels.Api.TopUp.TopUpRequestDTOwithConversion;

export class CashTopupFormViewModel extends TopupFormViewModel {
    api = new TopUpCashApiClient();

    protected async findTopup(referenceNumber: string): Promise<TopUpRequestDTOwithConversion | Kuva.PL.KuvaWeb.API.CustomResponse> {
        return await tryAlternatives(referenceNumber, (refNum) => this.api.detailsByReference(refNum));
    }

    protected startProcess(request: Kuva.BL.BusinessModels.Api.TopUp.TopUpRequestDTOwithConversion): CashTopupProcessViewModel {
        return new CashTopupProcessViewModel(request);
    }
}