import { t } from 'i18next'
import { observer } from 'mobx-react-lite'
import React, { useMemo, useState, ChangeEventHandler } from 'react'
import { Modal } from '../../components/modal'
import { lang } from '../../utils/lang'
import { CashOnDeliveryDetailsViewModel } from '../../viewModels/cashOnDelivery/cashOnDeliveryDetailsViewModel'
import * as fmt from '../../utils/formatting'
import { ReferenceNumberInput } from '../../components/referenceNumberInput'
import { IndicatorButton } from '../../components/indicatorButton'
import { FixedValidationMessage } from '../../components/fixedValidationMessage'
import { Kuva } from '../../interfaces/kuvaweb'
import { CashOnDeliveryViewModel } from '../../viewModels/cashOnDelivery/cashOnDeliveryOrdersViewModel'

const CompleteStatus = Kuva.Module.Enums.OrderStatus.Complete

const CashOnDeliveryDetailsDialogue: React.FC<{
  viewModel: CashOnDeliveryDetailsViewModel
  closeModel: CashOnDeliveryViewModel
}> = observer(({ viewModel, closeModel }) => {
  const order = useMemo(() => viewModel.request, [viewModel.request])
  const [hide, setHide] = useState(true)
  const changeAmount: ChangeEventHandler<HTMLInputElement> = e => {
    const {
      target: { value },
    } = e

    const amountValue = value ? parseFloat(value) : null

    viewModel.changeEnteredAmount(amountValue)
    if (amountValue < order.paidAmountRealMoneyWithRejectPaidCurrency) {
      setHide(false)
    } else {
      setHide(true)
    }
  }

  const complete = () => {
    try {
      viewModel.complete(() => closeModel.setOrderAsPaid(order), order)
    } catch {
      return
    }
  }

  console.log(viewModel.amount)

  if (order == null) return null

  return (
    <Modal size="lg" className="dialogue-cash">
      <div className="modal-header">
        <h5 className="modal-title">{t('cashOnDelivery.modal.detailsTitle')}</h5>
        <button
          type="button"
          onClick={() => viewModel.cancel()}
          className="btn-close"
          aria-label="Close"
        />
      </div>
      <div className="modal-body">
        <div className="body-container">
          <div className="section-identity">
            <div className="manifest-section">
              <InfoField label={t('cashOnDelivery.modal.toPay')}>
                {fmt.currencySymbol(order.shopCurrency)}
                {order.paidAmountRealMoneyWithRejectPaidCurrency}
              </InfoField>
              {order.manifests?.map((manifest, index) => (
                <div className="info-field" key={manifest.id}>
                  <div className="label">
                    {index + 1}. {t('cashOnDelivery.modal.manifestTitle')}(
                    {manifest.vendor.toString()}):
                  </div>
                  {manifest.deliveryManifests.map(dm => (
                    <div className="manifest" key={dm.id}>
                      <div className="manif-info" key={dm.id}>
                        {t('cashOnDelivery.modal.dManifests') + '#' + dm.id}(
                        {lang.dmShippingType(dm.shippingType)})
                        <input
                          type="checkbox"
                          onChange={() => viewModel.changeIsCompletedDM(dm.id)}
                          id={`dm-${dm.id}`}
                          name={`dm-${dm.id}`}
                          disabled={order.status == CompleteStatus}
                          checked={dm.isCompleted}
                        />
                      </div>
                    </div>
                  ))}
                </div>
              ))}
            </div>
            <div className="manifest-section-goods">
              <p>{t('cashOnDelivery.modal.goods')}</p>
            </div>
          </div>
          {viewModel.request.status === CompleteStatus ? (
            <div className="past-order-section">
              <div className="dis-flext-col">
                <div className="past-order-row">
                  <span>{t('cashOnDelivery.modal.referencePast')}</span>
                  <span className="ref-value">{order.orderReference}</span>
                </div>
                <div className="past-order-row">
                  <span className="status-static">{t('cashOnDelivery.modal.status')}</span>
                  <span className="status-value">{lang.cashOnDeliveryPay(order.isPaid)}</span>
                </div>
                <div className="past-order-row">
                  <span>{t('cashOnDelivery.modal.paidCustomer')}</span>
                  <span className="mg-left-5">
                    {fmt.currencySymbol(order.shopCurrency)}
                    {order.totalPaid.toFixed(2)}
                  </span>
                </div>
                <div className="past-order-row">
                  <div className="change-credited">
                    <span>{t('cashOnDelivery.modal.changeCredited')}</span>
                    <span className="change-value">
                      ({fmt.currencySymbol(order.shopCurrency)}
                      {viewModel.amount
                        ? (viewModel.amount - order.paidAmountRealMoneyWithRejectPaidCurrency)?.toFixed(2)
                        : order.change.toFixed(2)}
                      )
                    </span>
                  </div>
                </div>
                <div className="past-order-row">
                  <div className="release-goods">{t('cashOnDelivery.modal.releaseGoods')}</div>
                </div>
              </div>
              <div className="div-done">
                <IndicatorButton
                  onClick={() => closeModel.close()}
                  className="btn-default btn-green"
                >
                  {t('cashOnDelivery.button.done')}
                </IndicatorButton>
              </div>
            </div>
          ) : viewModel.isPaidPage ? (
            <div className="paid-section">
              <div>
                <div className="paid-status">
                  <span>{t('cashOnDelivery.modal.status')}</span>
                  <span className="mg-left-5">{lang.cashOnDeliveryPay(order.isPaid)}</span>
                </div>
                <div className="paid-change-fields">
                  <div className="paid-by-customer">
                    <span>{t('cashOnDelivery.modal.paidCustomer')}</span>
                    <span className="mg-left-5">{viewModel.amount.toFixed(2)}</span>
                  </div>
                  <div className="change-credited">
                    <span>{t('cashOnDelivery.modal.changeCredited')}</span>
                    <span className="change-value">
                      ({fmt.currencySymbol(order.shopCurrency)}
                      {viewModel.amount
                        ? (
                            viewModel.amount - order.paidAmountRealMoneyWithRejectPaidCurrency
                          )?.toFixed(2)
                        : order.paidAmountRealMoneyWithRejectPaidCurrency}
                      )
                    </span>
                  </div>
                </div>
                <div className="release-goods">{t('cashOnDelivery.modal.releaseGoods')}</div>
              </div>
              <div className="div-done txt-al-end">
                <IndicatorButton
                  onClick={() => closeModel.close()}
                  className="btn-default btn-green"
                >
                  {t('cashOnDelivery.button.done')}
                </IndicatorButton>
              </div>
            </div>
          ) : order.isPaid ? (
            <div className="section-payment">
              <div className="payment-reference">
                <div className="label">{t('cashOnDelivery.modal.reference')}</div>
                <div className="reference">{order.orderReference}</div>
              </div>
              <div className="payment-actions">
                <div className="reference-block">
                  <div className="reference-number-wrapper">
                    <ReferenceNumberInput
                      className="ref-mobile"
                      type="text"
                      value={viewModel.enteredRefNum}
                      onChange={e => viewModel.changeEnteredRefNum(e.target.value)}
                    />
                    <FixedValidationMessage message={viewModel.validationMessage} />
                  </div>
                  <div className="status-block">
                    <span> {t('cashOnDelivery.modal.status')}</span>
                    <span className="paid-status mg-left-5">
                      {lang.cashOnDeliveryPay(order.isPaid)}
                    </span>
                  </div>
                </div>
                <div className="row">
                  <div className="col-6">
                    <IndicatorButton
                      onClick={() => closeModel.close()}
                      className="btn-default btn-red"
                    >
                      {t('cashOnDelivery.button.cancel')}
                    </IndicatorButton>
                  </div>
                  <div className="col-6">
                    <IndicatorButton onClick={complete} className=" btn-default btn-green">
                      {t('cashOnDelivery.button.checkout')}
                    </IndicatorButton>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <div className="section-payment section-payment-unpaid">
              <div className="payment-reference">
                <div className="label">{t('cashOnDelivery.modal.reference')}</div>
                <div className="reference">{order.orderReference}</div>
              </div>
              <div className="payment-actions">
                <div className="reference-block">
                  <div className="reference-number-wrapper">
                    <ReferenceNumberInput
                      type="text"
                      value={viewModel.enteredRefNum}
                      onChange={e => viewModel.changeEnteredRefNum(e.target.value)}
                    />
                    {viewModel.errorMessage.length > 0 && (
                      <div className="error-text mg-bot-5">{viewModel.errorMessage}</div>
                    )}
                    <FixedValidationMessage message={viewModel.validationMessage} />
                  </div>
                  <div className={hide ? 'amount-text-input' : 'amount-text-error'}>
                    <div className="amount-input-currency">
                      <span>{fmt.currencySymbol(order.shopCurrency)}</span>
                      <input
                        className="amount-input form-control"
                        type="number"
                        placeholder={t('cashOnDelivery.modal.amountInput')}
                        value={viewModel.amount ?? ''}
                        onChange={changeAmount}
                        autoComplete="none"
                      />
                    </div>
                    <div className="error-text">
                      <span className="amount-error" hidden={hide}>
                        {t('cashOnDelivery.error.amount')}
                      </span>
                    </div>
                  </div>

                  <div className="change-block">
                    <span className="span-change">{t('cashOnDelivery.modal.change')}</span>
                    <span className="span-money">
                      ({fmt.currencySymbol(order.shopCurrency)}
                      {viewModel.amount > order.paidAmountRealMoneyWithRejectPaidCurrency
                        ? (
                            viewModel.amount - order.paidAmountRealMoneyWithRejectPaidCurrency
                          ).toFixed(2)
                        : 0}
                      )
                    </span>
                  </div>
                </div>
                <div className="row">
                  <div className="col-6">
                    <IndicatorButton
                      onClick={() => closeModel.close()}
                      className="btn-default btn-red"
                    >
                      {t('cashOnDelivery.button.cancel')}
                    </IndicatorButton>
                  </div>
                  <div className="col-6">
                    <IndicatorButton
                      loading={viewModel.loading}
                      onClick={complete}
                      className="btn-default btn-green"
                    >
                      {t('cashOnDelivery.button.checkout')}
                    </IndicatorButton>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </Modal>
  )
})
export default CashOnDeliveryDetailsDialogue

function InfoField(props: React.PropsWithChildren<{ label: string }>) {
  return (
    <div className={'info-field'}>
      <div className={'label'}>{props.label}</div>
      <div className={'info'}>{props.children}</div>
    </div>
  )
}
