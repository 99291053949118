import React, { Component, HTMLAttributes, ReactChild, ReactChildren } from "react";

export namespace Icon {
  export function Menu() {
    return <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-list"
      viewBox="0 0 16 16">
      <path fillRule="evenodd"
        d="M2.5 12a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5z" />
    </svg>;
  }

  export function Search() {
    return <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
      className="bi bi-search" viewBox="0 0 16 16">
      <path
        d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z" />
    </svg>
  }

  export function ArrowDown() {
    return <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
      width="19pt" height="19pt" viewBox="0 0 920.000000 920.000000"
      preserveAspectRatio="xMidYMid meet">

      <g transform="translate(0.000000,920.000000) scale(0.100000,-0.100000)"
        fill="#b8cfec" stroke="none">
        <path d="M1400 6458 c-72 -28 -151 -106 -173 -172 -9 -27 -17 -79 -17 -115 0 -134 -111 -14 1583 -1709 843 -844 1551 -1547 1574 -1563 105 -73 248 -71 353 7 25 18 732 720 1572 1561 1108 1110 1532 1540 1549 1573 31 63 37 170 14 241 -26 76 -112 161 -185 183 -69 20 -143 20 -206 0 -45 -15 -163 -130 -1487 -1453 l-1437 -1436 -1433 1431 c-1376 1375 -1434 1433 -1490 1453 -73 26 -146 26 -217 -1z" />
      </g>
    </svg>
  }

  export function SuccessTick() {
    return <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
      width="13pt" height="13pt" viewBox="0 0 800.000000 800.000000"
      preserveAspectRatio="xMidYMid meet">

      <g transform="translate(0.000000,800.000000) scale(0.100000,-0.100000)"
        fill="#6feda6" stroke="none">
        <path d="M3765 7759 c-1365 -88 -2566 -900 -3154 -2131 -489 -1023 -489 -2213 0 -3236 509 -1065 1489 -1831 2639 -2062 506 -102 994 -102 1500 0 1470 295  2635 1460 2930 2930 102 506 102 994 0 1500 -198 987 -794 1857 -1650 2411  -661 427 -1477 639 -2265 588z m1098 -4171 c-973 -973 -1772 -1768 -1778 -1768 -5 0 -443 433 -972 962 l-963 963 422 422 423 423 545 -545 545 -545  1350 1350 1350 1350 422 -422 423 -423 -1767 -1767z" />
      </g>
    </svg>


  }

  export function CopyText() {
    return <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M8 4V16C8 16.5304 8.21071 17.0391 8.58579 17.4142C8.96086 17.7893 9.46957 18 10 18H18C18.5304 18 19.0391 17.7893 19.4142 17.4142C19.7893 17.0391 20 16.5304 20 16V7.242C20 6.97556 19.9467 6.71181 19.8433 6.46624C19.7399 6.22068 19.5885 5.99824 19.398 5.812L16.083 2.57C15.7094 2.20466 15.2076 2.00007 14.685 2H10C9.46957 2 8.96086 2.21071 8.58579 2.58579C8.21071 2.96086 8 3.46957 8 4V4Z" stroke="#2D82F5" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M16 18V20C16 20.5304 15.7893 21.0391 15.4142 21.4142C15.0391 21.7893 14.5304 22 14 22H6C5.46957 22 4.96086 21.7893 4.58579 21.4142C4.21071 21.0391 4 20.5304 4 20V9C4 8.46957 4.21071 7.96086 4.58579 7.58579C4.96086 7.21071 5.46957 7 6 7H8" stroke="#2D82F5" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    </svg>

  }

  export function ClearCashrail() {
    return <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="10" cy="10" r="10" fill="#707070" />
      <path fillRule="evenodd" clipRule="evenodd" d="M13.3409 14.8954C13.7701 15.3245 14.4659 15.3245 14.895 14.8954C15.3242 14.4662 15.3242 13.7705 14.895 13.3413L11.5544 10.0007L14.8957 6.65936C15.3248 6.23021 15.3248 5.53443 14.8957 5.10528C14.4665 4.67613 13.7707 4.67613 13.3416 5.10528L10.0003 8.44659L6.65838 5.10468C6.22923 4.67554 5.53345 4.67554 5.1043 5.10468C4.67515 5.53383 4.67515 6.22962 5.1043 6.65876L8.44621 10.0007L5.10497 13.3419C4.67582 13.7711 4.67582 14.4668 5.10497 14.896C5.53412 15.3251 6.2299 15.3251 6.65905 14.896L10.0003 11.5548L13.3409 14.8954Z" fill="#C4C4C4" />
    </svg>
  }

  export function MenuExpand() {
    return <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
      className="bi bi-arrow-bar-right" viewBox="0 0 16 16">
      <path fillRule="evenodd"
        d="M6 8a.5.5 0 0 0 .5.5h5.793l-2.147 2.146a.5.5 0 0 0 .708.708l3-3a.5.5 0 0 0 0-.708l-3-3a.5.5 0 0 0-.708.708L12.293 7.5H6.5A.5.5 0 0 0 6 8zm-2.5 7a.5.5 0 0 1-.5-.5v-13a.5.5 0 0 1 1 0v13a.5.5 0 0 1-.5.5z" />
    </svg>;
  }

  export function UploadCsv() {
    return <svg width="84" height="60" viewBox="0 0 84 60" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M46.1663 59.166V42.4994H58.6663L41.9997 21.666L25.333 42.4994H37.833V59.166H46.1663Z" fill="#082280" />
      <path d="M21.1663 59.1673H29.4997V50.834H21.1663C14.2747 50.834 8.66634 45.2257 8.66634 38.334C8.66634 32.484 13.6622 26.8507 19.8038 25.7715L22.2247 25.3465L23.0247 23.0215C25.9538 14.4757 33.2288 9.16732 41.9997 9.16732C53.4872 9.16732 62.833 18.5132 62.833 30.0007V34.1673H66.9997C71.5955 34.1673 75.333 37.9048 75.333 42.5007C75.333 47.0965 71.5955 50.834 66.9997 50.834H54.4997V59.1673H66.9997C76.1913 59.1673 83.6663 51.6923 83.6663 42.5007C83.66 38.7657 82.4023 35.1406 80.0942 32.2041C77.7862 29.2677 74.5607 27.1892 70.933 26.3007C69.1122 11.959 56.833 0.833984 41.9997 0.833984C30.5163 0.833984 20.5413 7.54648 15.9872 18.1257C7.03717 20.8007 0.333008 29.2507 0.333008 38.334C0.333008 49.8215 9.67884 59.1673 21.1663 59.1673Z" fill="#082280" />
    </svg>

  }

  export function MenuCollapse() {
    return <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
      className="bi bi-arrow-bar-left" viewBox="0 0 16 16">
      <path fillRule="evenodd"
        d="M12.5 15a.5.5 0 0 1-.5-.5v-13a.5.5 0 0 1 1 0v13a.5.5 0 0 1-.5.5zM10 8a.5.5 0 0 1-.5.5H3.707l2.147 2.146a.5.5 0 0 1-.708.708l-3-3a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L3.707 7.5H9.5a.5.5 0 0 1 .5.5z" />
    </svg>;
  }

  export function Dashboard() {
    return <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
      className="bi bi-speedometer2" viewBox="0 0 16 16">
      <path
        d="M8 4a.5.5 0 0 1 .5.5V6a.5.5 0 0 1-1 0V4.5A.5.5 0 0 1 8 4zM3.732 5.732a.5.5 0 0 1 .707 0l.915.914a.5.5 0 1 1-.708.708l-.914-.915a.5.5 0 0 1 0-.707zM2 10a.5.5 0 0 1 .5-.5h1.586a.5.5 0 0 1 0 1H2.5A.5.5 0 0 1 2 10zm9.5 0a.5.5 0 0 1 .5-.5h1.5a.5.5 0 0 1 0 1H12a.5.5 0 0 1-.5-.5zm.754-4.246a.389.389 0 0 0-.527-.02L7.547 9.31a.91.91 0 1 0 1.302 1.258l3.434-4.297a.389.389 0 0 0-.029-.518z" />
      <path fillRule="evenodd"
        d="M0 10a8 8 0 1 1 15.547 2.661c-.442 1.253-1.845 1.602-2.932 1.25C11.309 13.488 9.475 13 8 13c-1.474 0-3.31.488-4.615.911-1.087.352-2.49.003-2.932-1.25A7.988 7.988 0 0 1 0 10zm8-7a7 7 0 0 0-6.603 9.329c.203.575.923.876 1.68.63C4.397 12.533 6.358 12 8 12s3.604.532 4.923.96c.757.245 1.477-.056 1.68-.631A7 7 0 0 0 8 3z" />
    </svg>
  }

  export function Cashout() {
    return <svg className="bi bi-cashout" width="16" height="16" fill="currentColor" version="1.1"
      viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
      <path
        d="m2 2a1 1 0 0 0-1 1h5.875l0.43945-0.42773 0.58594-0.57227h-5.9004zm11.174 0 1 1h0.82617a1 1 0 0 0-1-1h-0.82617zm-5.1738 5a2 2 0 0 0 0 4 2 2 0 0 0 1.8184-1.1738c-5.284e-4 -2.776e-4 -0.001425 2.783e-4 -0.0019532 0-0.1016-0.05354-0.20071-0.11722-0.29297-0.19922-0.29619-0.26326-0.48633-0.69741-0.48633-1.1074v-1.2285a2 2 0 0 0-1.0371-0.29102z" />
      <path
        d="m1 4a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1h14a1 1 0 0 0 1-1v-8a1 1 0 0 0-1-1h-0.054688c0.064514 0.18424 0.098712 0.37659 0.087891 0.56055-0.023701 0.4029-0.20012 0.7392-0.44726 0.98633-0.24715 0.24713-0.58345 0.42552-0.98633 0.44922-0.11369 0.0066873-0.2311-0.0059345-0.34766-0.029297a2 2 0 0 0 1.748 1.0332v4a2 2 0 0 0-2 2h-10a2 2 0 0 0-2-2v-4a2 2 0 0 0 2-2h3.1016c-0.052006-0.13656-0.090281-0.28089-0.099609-0.43945-0.010822-0.18395 0.022935-0.3763 0.087891-0.56055h-5.0898z" />
      <path
        d="m10.918 1.1601c-0.24001-0.24-0.56003-0.24-0.72004 0l-3.0402 2.96c-0.48003 0.48 0.24001 1.2 0.72004 0.72l2.1601-2.16v5.84c0 0.64 0.96006 0.64 0.96006 0v-5.84l2.1601 2.16c0.48003 0.48 1.2001-0.24 0.72004-0.72z" />
    </svg>;
  }

  export function Topup() {
    return <svg className="bi bi-topup" width="16" height="16" fill="currentColor" version="1.1"
      viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
      <path
        d="m12.379 1.2969c0.31446-0.078499 0.61935 0.15832 0.62109 0.48242v1.2207h-3.5c-0.64119 0-0.6558 1 0 1h5c0.27614 0 0.5 0.22386 0.5 0.5v9c0 0.27614-0.22386 0.5-0.5 0.5h-13c-0.27614 0-0.5-0.22386-0.5-0.5v-9c0-0.27614 0.22386-0.5 0.5-0.5h5c0.67115 0 0.65317-1 0-1h-0.9375m-4.1309 0.00195c-0.80086 0.036539-1.4314 0.69636-1.4316 1.498v9c0 0.82843 0.67157 1.5 1.5 1.5h13c0.82843 0 1.5-0.67157 1.5-1.5v-9c0-0.82843-0.67157-1.5-1.5-1.5h-0.5v-1.2207c-0.0013-0.85433-0.71491-1.535-1.5684-1.4961-0.09949 0.004497-0.19828 0.018891-0.29492 0.042969l-1.8496 0.46289" />
      <path
        d="m7.646 10.853c0.19536 0.19586 0.51264 0.19586 0.708 0l3-3c0.4713-0.472-0.2367-1.18-0.708-0.708l-2.146 2.147v-5.7931c0-0.66667-1-0.66667-1 0v5.7931l-2.146-2.147c-0.472-0.472-1.18 0.236-0.708 0.708z" />
    </svg>;
  }

  export function Profile() {
    return <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
      className="bi bi-person-badge" viewBox="0 0 16 16">
      <path d="M6.5 2a.5.5 0 0 0 0 1h3a.5.5 0 0 0 0-1h-3zM11 8a3 3 0 1 1-6 0 3 3 0 0 1 6 0z" />
      <path
        d="M4.5 0A2.5 2.5 0 0 0 2 2.5V14a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2V2.5A2.5 2.5 0 0 0 11.5 0h-7zM3 2.5A1.5 1.5 0 0 1 4.5 1h7A1.5 1.5 0 0 1 13 2.5v10.795a4.2 4.2 0 0 0-.776-.492C11.392 12.387 10.063 12 8 12s-3.392.387-4.224.803a4.2 4.2 0 0 0-.776.492V2.5z" />
    </svg>;
  }

  export function Logo() {
    return <svg width="152" height="216" version="1.1" viewBox="0 0 40.217 57.15" xmlns="http://www.w3.org/2000/svg"><defs><linearGradient id="linearGradient863" x1="-7.9375" x2="-27.781" y1="176.61" y2="158.09" gradientTransform="translate(204.37 -6.8981)" gradientUnits="userSpaceOnUse"><stop stopColor="#265cf8" offset="0" /><stop stopColor="#214fc2" offset="1" /></linearGradient><linearGradient id="linearGradient877" x1="-38.365" x2="-6.6146" y1="163.39" y2="119.73" gradientTransform="translate(204.37 -6.8981)" gradientUnits="userSpaceOnUse"><stop stopColor="#4bb1ed" offset="0" /><stop stopColor="#56defa" offset=".5" /><stop stopColor="#5aeffe" offset="1" /></linearGradient></defs><g transform="translate(-158.4 -112.57)"><path d="m179.5 112.83h16.404c1.8521 0 2.6458 1.8521 1.8521 2.9104l-27.252 38.365c-1.6273 2.2495-1.1906 4.6633-1.1906 4.6633l-9.9219-13.395c-1.3229-2.1167-1.3229-5.5562 0-7.4083l16.933-23.548c0.79375-1.0583 1.8521-1.5875 3.175-1.5875z" fill="url(#linearGradient877)" /><path d="m181.34 143.79 16.687 22.492c1.0583 1.3229-0.26458 3.4396-1.5875 3.4396h-14.023c-3.4396 0-5.5562-1.3229-7.1438-2.9104l-1.9844-2.6789c-1.7198-2.3482-2.5136-5.7337-0.37264-8.6941z" fill="url(#linearGradient863)" /></g></svg>;
  }

  export function LogoWhite() {
    return <svg width="152" height="216" version="1.1" viewBox="0 0 40.217 57.15" xmlns="http://www.w3.org/2000/svg"><defs><linearGradient id="linearGradient863" x1="-7.9375" x2="-27.781" y1="176.61" y2="158.09" gradientTransform="translate(204.37 -6.8981)" gradientUnits="userSpaceOnUse"><stop stopColor="#ffffffb9" offset="0" /><stop stopColor="#ffffff7c" offset="1" /></linearGradient><linearGradient id="linearGradient877" x1="-38.365" x2="-6.6146" y1="163.39" y2="119.73" gradientTransform="translate(204.37 -6.8981)" gradientUnits="userSpaceOnUse"><stop stopColor="#ffffffbc" offset="0" /><stop stopColor="#ffffffd4" offset=".5" /><stop stopColor="#ffffffde" offset="1" /></linearGradient></defs><g transform="translate(-158.4 -112.57)"><path d="m179.5 112.83h16.404c1.8521 0 2.6458 1.8521 1.8521 2.9104l-27.252 38.365c-1.6273 2.2495-1.1906 4.6633-1.1906 4.6633l-9.9219-13.395c-1.3229-2.1167-1.3229-5.5562 0-7.4083l16.933-23.548c0.79375-1.0583 1.8521-1.5875 3.175-1.5875z" fill="url(#linearGradient877)" /><path d="m181.34 143.79 16.687 22.492c1.0583 1.3229-0.26458 3.4396-1.5875 3.4396h-14.023c-3.4396 0-5.5562-1.3229-7.1438-2.9104l-1.9844-2.6789c-1.7198-2.3482-2.5136-5.7337-0.37264-8.6941z" fill="url(#linearGradient863)" /></g></svg>;
  }

  export function Edit() {
    return <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
      className="bi bi-pencil-fill" viewBox="0 0 16 16">
      <path
        d="M12.854.146a.5.5 0 0 0-.707 0L10.5 1.793 14.207 5.5l1.647-1.646a.5.5 0 0 0 0-.708l-3-3zm.646 6.061L9.793 2.5 3.293 9H3.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.207l6.5-6.5zm-7.468 7.468A.5.5 0 0 1 6 13.5V13h-.5a.5.5 0 0 1-.5-.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.5-.5V10h-.5a.499.499 0 0 1-.175-.032l-.179.178a.5.5 0 0 0-.11.168l-2 5a.5.5 0 0 0 .65.65l5-2a.5.5 0 0 0 .168-.11l.178-.178z" />
    </svg>
  }

  export function Availability() {
    return <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-calendar2-week" viewBox="0 0 16 16">
      <path d="M3.5 0a.5.5 0 0 1 .5.5V1h8V.5a.5.5 0 0 1 1 0V1h1a2 2 0 0 1 2 2v11a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V3a2 2 0 0 1 2-2h1V.5a.5.5 0 0 1 .5-.5zM2 2a1 1 0 0 0-1 1v11a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V3a1 1 0 0 0-1-1H2z" />
      <path d="M2.5 4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5H3a.5.5 0 0 1-.5-.5V4zM11 7.5a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5v-1zm-3 0a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5v-1zm-5 3a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5v-1zm3 0a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5v-1z" />
    </svg>;
  }

  export function MobileBackArrow() {
    return <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M8.52539 5L3.52539 10L8.52539 15" stroke="#140A5A" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M3.52539 10H11.5254C17.0484 10 21.5254 14.477 21.5254 20V21" stroke="#140A5A" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  }

  export function Cashrail() {
    return <svg className="bi bi-cashrail" width="22" height="16" viewBox="0 0 39 20" fill="none" xmlns="http://www.w3.org/2000/svg" >
      <path d="M28.5333 6.46667C27.5962 5.52957 26.3253 5.00311 25 5.00311C23.6747 5.00311 22.4038 5.52957 21.4667 6.46667C20.5296 7.40376 20.0031 8.67474 20.0031 10C20.0031 11.3253 20.5296 12.5962 21.4667 13.5333C21.9307 13.9973 22.4815 14.3654 23.0878 14.6165C23.694 14.8676 24.3438 14.9969 25 14.9969C25.6562 14.9969 26.306 14.8676 26.9122 14.6165C27.5185 14.3654 28.0693 13.9973 28.5333 13.5333C28.9973 13.0693 29.3654 12.5185 29.6165 11.9122C29.8676 11.306 29.9969 10.6562 29.9969 10C29.9969 9.3438 29.8676 8.69402 29.6165 8.08777C29.3654 7.48152 28.9973 6.93067 28.5333 6.46667ZM11.6667 0V20H38.3333V0H11.6667ZM35 13.3333C34.1167 13.3333 33.2667 13.6833 32.65 14.3167C32.0167 14.9333 31.6667 15.7833 31.6667 16.6667H18.3333C18.3333 15.7833 17.9833 14.9333 17.35 14.3167C16.7333 13.6833 15.8833 13.3333 15 13.3333V6.66667C15.8833 6.66667 16.7333 6.31667 17.35 5.68333C17.9833 5.06667 18.3333 4.21667 18.3333 3.33333H31.6667C31.6667 4.21667 32.0167 5.06667 32.65 5.68333C33.2667 6.31667 34.1167 6.66667 35 6.66667V13.3333ZM8.33333 3.33333H5C4.08333 3.33333 3.33333 2.58333 3.33333 1.66667C3.33333 0.75 4.08333 0 5 0H8.33333V3.33333ZM8.33333 11.6667H3.33333C2.41667 11.6667 1.66667 10.9167 1.66667 10C1.66667 9.08333 2.41667 8.33333 3.33333 8.33333H8.33333V11.6667ZM8.33333 20H1.66667C0.746667 20 0 19.25 0 18.3333C0 17.4167 0.746667 16.6667 1.66667 16.6667H8.33333V20Z" fill="white" />
    </svg>
  }

  export function VendorAgent() {
    return <svg width="26" height="24" viewBox="0 0 26 24" fill="none" xmlns="http://www.w3.org/2000/svg" >
      <path d="M21.4 15.4494C21.8375 14.3869 22.075 13.2494 22.075 11.9994C22.075 11.0994 21.9375 10.2369 21.7 9.43688C20.8875 9.62438 20.0375 9.72438 19.15 9.72438C17.3325 9.72633 15.5411 9.29129 13.9269 8.45591C12.3127 7.62054 10.923 6.40933 9.875 4.92438C8.75387 7.6371 6.63891 9.81914 3.9625 11.0244C3.9125 11.3369 3.9125 11.6744 3.9125 11.9994C3.9125 13.1928 4.14756 14.3745 4.60424 15.477C5.06093 16.5796 5.73031 17.5814 6.57417 18.4252C8.2784 20.1295 10.5898 21.0869 13 21.0869C14.3125 21.0869 15.575 20.7994 16.7125 20.2869C17.425 21.6494 17.75 22.3244 17.725 22.3244C15.675 23.0119 14.0875 23.3494 13 23.3494C9.975 23.3494 7.0875 22.1619 4.9625 20.0244C3.67 18.7359 2.70921 17.1531 2.1625 15.4119H0.5V9.72438H1.8625C2.2753 7.71503 3.22437 5.8549 4.60899 4.34137C5.99361 2.82785 7.76214 1.71739 9.72691 1.12783C11.6917 0.538271 13.7794 0.491599 15.7686 0.992767C17.7577 1.49394 19.5741 2.52425 21.025 3.97438C22.6003 5.54345 23.6748 7.54451 24.1125 9.72438H25.5V15.4119H25.425L20.975 19.4994L14.35 18.7494V16.6619H20.3875L21.4 15.4494ZM9.5875 11.7119C9.9625 11.7119 10.325 11.8619 10.5875 12.1369C10.8513 12.4028 10.9993 12.7623 10.9993 13.1369C10.9993 13.5115 10.8513 13.8709 10.5875 14.1369C10.325 14.3994 9.9625 14.5494 9.5875 14.5494C8.8 14.5494 8.1625 13.9244 8.1625 13.1369C8.1625 12.3494 8.8 11.7119 9.5875 11.7119ZM16.4 11.7119C17.1875 11.7119 17.8125 12.3494 17.8125 13.1369C17.8125 13.9244 17.1875 14.5494 16.4 14.5494C15.6125 14.5494 14.975 13.9244 14.975 13.1369C14.975 12.759 15.1251 12.3965 15.3924 12.1293C15.6596 11.862 16.0221 11.7119 16.4 11.7119Z" fill="white" />
    </svg>
  }

  export function DeliveryAgent() {
    return <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg" >
      <path fill-rule="evenodd" clip-rule="evenodd" d="M11.4788 0.735828C11.9789 0.445745 12.5468 0.292969 13.125 0.292969C13.7032 0.292969 14.2711 0.445745 14.7712 0.735828L24.615 6.4452C25.6275 7.03208 26.25 8.11395 26.25 9.28395V13.5946C26.25 13.9675 26.1018 14.3252 25.8381 14.5889C25.5744 14.8527 25.2167 15.0008 24.8438 15.0008C24.4708 15.0008 24.1131 14.8527 23.8494 14.5889C23.5857 14.3252 23.4375 13.9675 23.4375 13.5946V10.6452L14.5312 15.8108V27.7808C14.5313 28.1103 14.4445 28.4341 14.2796 28.7194C14.1147 29.0046 13.8775 29.2414 13.592 29.4059C13.3064 29.5703 12.9826 29.6566 12.6531 29.6561C12.3235 29.6555 12 29.5681 11.715 29.4027L1.635 23.5565C1.13766 23.268 0.72484 22.8539 0.437891 22.3557C0.150942 21.8575 -6.2912e-05 21.2926 1.96621e-08 20.7177L1.96621e-08 9.28395C1.96621e-08 8.11395 0.6225 7.03395 1.635 6.4452L11.4788 0.735828ZM13.3594 3.16958L22.0406 8.20395L13.125 13.3752L4.20937 8.20395L12.8906 3.16958C12.9619 3.12844 13.0427 3.10678 13.125 3.10678C13.2073 3.10678 13.2881 3.12844 13.3594 3.16958V3.16958ZM2.8125 20.7177V10.6452L11.7188 15.8108V26.1533L3.04688 21.1227C2.97576 21.0816 2.91667 21.0226 2.87554 20.9515C2.8344 20.8805 2.81267 20.7998 2.8125 20.7177ZM23.115 27.6252C22.8579 27.8955 22.7187 28.2568 22.728 28.6297C22.7373 29.0026 22.8944 29.3565 23.1647 29.6136C23.435 29.8707 23.7963 30.0099 24.1692 30.0006C24.5421 29.9913 24.896 29.8342 25.1531 29.564L29.6119 24.8765C29.8606 24.615 29.9994 24.268 29.9994 23.9071C29.9994 23.5462 29.8606 23.1991 29.6119 22.9377L25.1531 18.2502C24.896 17.9799 24.5421 17.8229 24.1692 17.8135C23.7963 17.8042 23.435 17.9434 23.1647 18.2005C22.8944 18.4576 22.7373 18.8115 22.728 19.1844C22.7187 19.5573 22.8579 19.9187 23.115 20.189L25.3144 22.5008H19.2188C18.8458 22.5008 18.4881 22.649 18.2244 22.9127C17.9607 23.1764 17.8125 23.5341 17.8125 23.9071C17.8125 24.28 17.9607 24.6377 18.2244 24.9014C18.4881 25.1652 18.8458 25.3133 19.2188 25.3133H25.3144L23.115 27.6252Z" fill="white" />
    </svg>
  }

  export function Clear() {
    return <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="grey"
      className="bi bi-x-circle" viewBox="0 0 16 16">
      <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
      <path
        d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
    </svg>
  }

  export function Uncheked() {
    return <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M15 1H5C2.79086 1 1 2.79086 1 5V15C1 17.2091 2.79086 19 5 19H15C17.2091 19 19 17.2091 19 15V5C19 2.79086 17.2091 1 15 1Z" stroke="#3171D8" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  }

  export function Checked() {
    return <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M15 1H5C2.79086 1 1 2.79086 1 5V15C1 17.2091 2.79086 19 5 19H15C17.2091 19 19 17.2091 19 15V5C19 2.79086 17.2091 1 15 1Z" stroke="#3171D8" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M7 10L9.25 12L13 8" stroke="#3171D8" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  }

  export function DashboardIndicator() {
    return <svg width="20" height="20" viewBox="0 0 38 38" xmlns="http://www.w3.org/2000/svg">
      <defs>
        <linearGradient x1="8.042%" y1="0%" x2="65.682%" y2="23.865%" id="a">
          <stop stop-color="#b8d6f5" stop-opacity="0" offset="0%" />
          <stop stop-color="#b8d6f5" stop-opacity=".631" offset="63.146%" />
          <stop stop-color="#b8d6f5" offset="100%" />
        </linearGradient>
      </defs>
      <g fill="none" fill-rule="evenodd">
        <g transform="translate(1 1)">
          <path d="M36 18c0-9.94-8.06-18-18-18" id="Oval-2" stroke="url(#a)" strokeWidth="2">
            <animateTransform
              attributeName="transform"
              type="rotate"
              from="0 18 18"
              to="360 18 18"
              dur="0.9s"
              repeatCount="indefinite" />
          </path>
          <circle fill="#b8d6f5" cx="36" cy="18" r="1">
            <animateTransform
              attributeName="transform"
              type="rotate"
              from="0 18 18"
              to="360 18 18"
              dur="0.9s"
              repeatCount="indefinite" />
          </circle>
        </g>
      </g>
    </svg>
  }

}