import React from 'react'
import { t } from 'i18next'
import { Kuva } from '../../../interfaces/kuvaweb'
import { lang } from '../../../utils/lang'
import * as fmt from '../../../utils/formatting'
import { Icon } from '../../../components/icons'

type AgentKWLTopupRequestModel = Kuva.PL.KuvaWeb.Areas.Agent.KWL.AgentCashoutKWLRequestModel
type MultiCashOutList = Kuva.PL.KuvaWeb.Areas.Agent.KWL.IMultiCashOutList
type Config = Kuva.Module.Table.TableConfig<AgentKWLTopupRequestModel>
type MultiCashOutConfig = Kuva.Module.Table.TableConfig<MultiCashOutList>

export const KWLSingleCashOutsTableConfig = (tab: 'booked' | 'past'): Config => {
  return {
    columns: [
      {
        id: 'date',
        header: () => t('kwlCashout.table.single.dateCol'),
        column: item => fmt.dateTimeShort(item.createdDate),
        className: 'date-column',
      },
      {
        id: 'booked',
        header: () => t('kwlCashout.table.single.booked'),
        column: item => lang.isBooked(item.booked),
        className: 'ref-column',
      },
      {
        id: 'status',
        header: () => t('kwlCashout.table.single.status'),
        column: item => lang.cashoutStatus(item.status),
        className: 'type-column',
      },
      {
        id: 'reference',
        header: () => t('kwlCashout.table.single.referenceCol'),
        column: item =>
          tab === 'past' ? item.digitalReference : fmt.digNum(item.digitalReference),
        className: 'status-column',
      },
      {
        id: 'amount',
        header: () => t('kwlCashout.table.single.amountCol'),
        column: item => item.amountUsd.toString(),
        className: 'cash-column',
      },
    ],
  }
}

export const KWLMultiCashOutsTableConfig = (tab: 'booked' | 'processing' | 'past') => {
  return tab === 'booked'
    ? ({
        columns: [
          {
            id: 'checkBox',
            header: () => '',
            column: (item, checkedList) => (
              <div className="custom-checkbox">
                {Boolean(checkedList[item.id]) ? <Icon.Checked /> : <Icon.Uncheked />}
              </div>
            ),
            className: 'checkbox-column',
          },
          {
            id: 'date',
            header: () => t('kwlCashout.table.multi.date'),
            column: item => fmt.dateTimeShort(item.createdDate),
            className: 'date-column',
          },
          {
            id: 'phone',
            header: () => t('kwlCashout.table.multi.phone'),
            column: item => item.recipient.phoneNumber,
            className: 'phone-column',
          },
          {
            id: 'status',
            header: () => t('kwlCashout.table.multi.status'),
            column: item => lang.cashoutStatus(item.status),
            className: 'status-column',
          },
          {
            id: 'recipientName',
            header: () => t('kwlCashout.table.multi.recipientName'),
            column: item => item.recipient.name,
            className: 'name-column',
          },
          {
            id: 'reference',
            header: () => t('kwlCashout.table.multi.referenceCode'),
            column: item => item.digitalReference,
            className: 'reference-column',
          },
          {
            id: 'amount',
            header: () => t('kwlCashout.table.multi.amount'),
            column: item => fmt.currency(Number(item.amountUsd), 'USD'),
            className: 'amount-column',
          },
        ],
      } as Config)
    : ({
        columns: [
          {
            id: 'bundle-id',
            header: () => t('kwlCashout.table.bundles.bundleId'),
            column: item => item.id,
            className: 'bundle-id-column',
          },
          {
            id: 'created-date',
            header: () => t('kwlCashout.table.bundles.createdDate'),
            column: item => fmt.dateTimeShort(item.createdDate),
            className: 'created-date-column',
          },
          {
            id: 'amount-id',
            header: () => t('kwlCashout.table.bundles.amount'),
            column: item => fmt.currency(Number(item.cashOutsAmount), 'USD'),
            className: 'amount-id-column',
          },
          {
            id: 'items-count-id',
            header: () => t('kwlCashout.table.bundles.itemsCount'),
            column: item => item.cashOutsCount,
            className: 'items-count-column',
          },
          {
            id: 'status-id',
            header: () => t('kwlCashout.table.bundles.status'),
            column: item => lang.cashOutBundleStatus(item.status),
            className: 'status-column',
          },
        ],
      } as MultiCashOutConfig)
}

type BundleStatus = Kuva.Module.Enums.MultiCashOutBundleStatus
type CashOuts = Kuva.PL.KuvaWeb.Areas.Agent.KWL.IKwlCashOutFromBundle
type MultiCashOutBundleConfig = Kuva.Module.Table.TableConfig<CashOuts>

export const KWLMultiCashOutBundleTableConfig = (
  status: BundleStatus
): MultiCashOutBundleConfig => {
  return {
    columns: [
      {
        id: 'date',
        header: () => t('kwlCashout.bundleDetails.date'),
        column: item => fmt.dateTimeShort(item.createdDate),
        className: 'date-column',
      },
      {
        id: 'reference-code',
        header: () => t('kwlCashout.bundleDetails.referenceCode'),
        column: item => item.digitalReference,
        className: 'reference-column',
      },
      {
        id: 'recipient-name',
        header: () => t('kwlCashout.bundleDetails.recipientName'),
        column: item => `${item.recipient.firstName} ${item.recipient.lastName}`,
        className: 'recipient-name-column',
      },
      {
        id: 'recipient-address',
        header: () => t('kwlCashout.bundleDetails.recipientAddress'),
        column: item => fmt.getRecipientAddress(item.recipient.address),
        className: 'recipient-address-column',
      },
      {
        id: 'phone-number',
        header: () => t('kwlCashout.bundleDetails.phoneNumber'),
        column: item => item.recipient.phoneNumber,
        className: 'phone-number-column',
      },
      {
        id: 'amount',
        header: () => t('kwlCashout.bundleDetails.amount'),
        column: item => fmt.currency(Number(item.amount), 'USD'),
        className: 'amount-column',
      },
      {
        id: 'status',
        header: () => t('kwlCashout.bundleDetails.status'),
        column: item => lang.cashoutStatus(item.status),
        className: 'status-column',
      },

      ...(status === 0
        ? [
            {
              id: 'remove',
              header: () => t('kwlCashout.bundleDetails.remove'),
              column: () => (
                <button className="remove-button">
                  <span />
                </button>
              ),
              className: 'remove-column',
            },
          ]
        : []),
    ],
  }
}
