﻿import { BehaviorSubject } from "rxjs";
import { Kuva } from "../interfaces/kuvaweb";

type NewT = Kuva.PL.KuvaWeb.Generic.NewT;

export abstract class CashRequestsManager<T extends NewT> {
  //private static _instance: CashRequestsManager<unknown>;
  //static get instance() { return this._instance ??= new CashRequestsManager(); }


  private bookedRequestsSubject = new BehaviorSubject<T[]>(null);
  public get bookedRequests() { return this.bookedRequestsSubject.asObservable(); }

  private bookedRequestsCountSubject = new BehaviorSubject<number>(null);
  public get bookedRequestsCount() { return this.bookedRequestsCountSubject.asObservable(); }

  private _bookedRequests: T[] = null;

  constructor() {
    this.connect();
  }

  protected async load(): Promise<void> {
    try {
      var result = await this.getRequests();
      this._bookedRequests = result;
      this.publish();
    } catch (error) {
    }
  }

  protected abstract connect(): Promise<void>;
  protected abstract filter(request: T): boolean;

  protected handleRequestUpdated(request: T): void {
    if (this._bookedRequests == null)
      return;

    const currentIndex = this._bookedRequests.findIndex(e => this.id(e) == this.id(request));
    if (currentIndex !== -1) {
      if (!this.filter(request)) {
        const newCollection = [...this._bookedRequests];
        newCollection.splice(currentIndex, 1);
        this._bookedRequests = newCollection;
      } else {
        const newCollection = [...this._bookedRequests];
        newCollection[currentIndex] = request;
        this._bookedRequests = newCollection;
      }
      this.publish();
    } else {
      if (this.filter(request)) {
        request.isNew = true;

        this._bookedRequests = [request, ...this._bookedRequests];
      }
      this.publish();
    }
  }

  private publish(): void {
    this.bookedRequestsSubject.next(this._bookedRequests);
    this.bookedRequestsCountSubject.next(this._bookedRequests.length);
  }

  protected abstract getRequests(): Promise<T[]>;
  protected abstract id(request: T): string;
}