﻿import {TopUpCashApiClient} from "../../api/agent/topUpCashApiClient";

import {TopupProcessViewModel} from "../topup/topupProcessViewModel";

export class CashTopupProcessViewModel extends TopupProcessViewModel {
    private api = new TopUpCashApiClient();

    protected async cancelTopup(): Promise<void> {
        await this.api.cancel({topUpRequestId: this.request.id, reference: this.request.reference})
    }

    protected async completeTopup(): Promise<void> {
        await this.api.complete({topUpRequestId: this.request.id, reference: this.request.reference})
    }
}

