﻿import {IReactionDisposer} from "mobx";
import {Subscription} from "rxjs";

import {TopUpBankApiClient} from "../../api/agent/topUpBankApiClient";
import {Kuva} from "../../interfaces/kuvaweb";
import {BankTopupsManager} from "../../managers/bankTopupsManager";
import { PastBankTopupsManager } from "../../managers/pastBankTopupsManager";

import {BookedCashRequestsViewModel, CashRequestsViewModel, PastCashRequestsViewModel} from "../cashCommon";

import {BankTopupDetailsViewModel} from "./bankTopupDetailsViewModel";

type AgentTopUpRequestModel = Kuva.PL.KuvaWeb.Areas.Agent.Models.AgentTopUpRequestModel;

class BookedBankTopupsViewModel extends BookedCashRequestsViewModel<AgentTopUpRequestModel> {
    private manager = BankTopupsManager.instance;

    * subscribe(): Generator<Subscription | IReactionDisposer> {
        yield this.manager.bookedRequests.subscribe((topups) => this.handleRequests(topups));
        yield* super.subscribe();
    }
}

class PastBankTopupsViewModel extends PastCashRequestsViewModel<AgentTopUpRequestModel> {
    private manager = PastBankTopupsManager.instance;
  
    *subscribe(): Generator<Subscription | IReactionDisposer> {
        yield this.manager.pastRequests.subscribe((topups)=> this.handleRequests(topups));
        yield* super.subscribe();
    }
}

export class BankTopupsViewModel extends CashRequestsViewModel<AgentTopUpRequestModel> {
    constructor() {
        super();

        this.booked = new BookedBankTopupsViewModel();
        this.past = new PastBankTopupsViewModel();
        this.updateTab();
    }
    
    select(request: AgentTopUpRequestModel) {
        if(this.details == null) {
            this.details = new BankTopupDetailsViewModel(request.id);
            this.details.promise.then(() => {
                this.details = null;
            });
        }
    }
}