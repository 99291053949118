import { t } from "i18next";
import { action, makeObservable, observable, runInAction } from "mobx";
import React, { ChangeEvent } from "react";
import { KWlCashoutApiClient } from "../../api/agent/kwlCashOutApiClient";
import { Kuva } from "../../interfaces/kuvaweb";
import * as refNum from "../../utils/refNum";
import { IPromiseViewModel } from "../promiseViewModel";
import { KWLCashoutRequestsViewModel } from "./cashoutViewModel";

type AgentKWLCashoutDetails = Kuva.PL.KuvaWeb.Areas.Agent.KWL.AgentKWLCashoutDetails;
const CashoutStatus = Kuva.Module.Enums.CashOutBookingStatus;
type CustomResponse = Kuva.PL.KuvaWeb.API.CustomResponse;


export class KWlCashoutDetailsViewModel implements IPromiseViewModel<void>{
  private api = new KWlCashoutApiClient();

  promise: Promise<void>;

  private promiseAccept: () => void;
  private promiseReject: () => void;

  @observable loading: boolean = false;

  @observable canSubmit: boolean = false;
  @observable validationMessage: string = null;

  @observable isCompleted: boolean = false;
  @observable amount: number = null;
  @observable request: AgentKWLCashoutDetails = null;

  @observable error: string = undefined;
  @observable bookError: string = undefined;

  @observable enteredRefnum: string = "";

  constructor(requestId: string) {

    makeObservable(this);
    this.promise = new Promise((acc, rej) => {
      this.promiseAccept = acc
      this.promiseReject = rej
    })

    this.load(requestId)
  }

  @action changeReference(value: string) {
    if (value.replace(/ /g, '').length > 13) {
      this.enteredRefnum = value.substring(0, 13)
      return
    }
    this.enteredRefnum = value.replace(/ /g, '');
  }

  @action private async load(requestId: string) {
    this.canSubmit = false;
    try {
      runInAction(() => {
        this.loading = true;
        this.request = null;
      })
      const cashout = await this.api.getById(requestId)
      this.request = cashout;
      this.canSubmit = this.request.status != CashoutStatus.completed &&
        this.request.status != CashoutStatus.cancelled;
      this.loading = false;
    }
    catch (error) {
      console.log(error);
      return;
    }
  }

  private validate() {
    const result = refNum.validateDigital(this.enteredRefnum);
    this.validationMessage = result.message

    return result.valid;
  }

  @action async book(requestId: string, listViewModel: KWLCashoutRequestsViewModel) {
    this.bookError = undefined;
    try {
      this.loading = true;
      await this.api.bookRequest(requestId);
      listViewModel.booked.book(requestId);

      this.loading = false;
      this.request.isBooked = true;
    } catch (error) {
      let res = error as CustomResponse;
      if (res.errorCode === "404") {
        this.bookError = res.message;
        return;
      }
      else if (res.message.length > 0) {
        this.bookError = res.message;
        return;
      }
      this.bookError = t("component.error.somWentWrong");
      return;
    }
  }

  @action async complete(enteredRefnum: string, listViewModel: KWLCashoutRequestsViewModel, setShowPopup: React.Dispatch<React.SetStateAction<boolean>>) {
    this.error = undefined;
    if (!this.validate()) {
      setShowPopup(false)
      return;
    }
    try {
      this.loading = true;
      const response = await this.api.complete(enteredRefnum.replace(/-/g, " "), this.request.id);
      this.request = response;
      // listViewModel.removeFromBooked(this.enteredRefnum)
      this.canSubmit = false;
      setShowPopup(false);
      this.loading = false;
      return;
    } catch (error) {
      this.loading = false;
      setShowPopup(false);
      let res = error as CustomResponse;
      if (res.code === 404) {
        this.error = t("kwlCashout.referenceError");
        return
      }
      else if (res.message.length > 0) {
        this.error = res.message;
        return
      }
    }
  }

  @action async delete(enteredRefnum: string, listViewModel: KWLCashoutRequestsViewModel) {
    if (!this.validate())
      return;
    try {
      this.loading = true;
      await this.api.cancel(this.request.id, enteredRefnum.replace(/-/g, " "));

      this.request.status = CashoutStatus.cancelled;
      alert("Success")
      // listViewModel.removeFromBooked(this.enteredRefnum)
      return;
    } catch (error) {
      this.loading = false;
      let res = error as CustomResponse;
      if (res.errorCode === "404") {
        alert(t("kwlCashout.referenceError"));
        return;
      }
      else if (res.message.length > 0) {
        alert(res.message)
        return;
      }
      alert("Something went wrong")
      return;
    }
  }

  @action clearErrors() {
    this.error = undefined
    this.bookError = undefined
  }

  cancel() {
    this.promiseAccept();
  }
}

