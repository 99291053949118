import { t } from "i18next";
import React, { useState } from "react"
import { Button, Dropdown, DropdownButton, Figure } from "react-bootstrap";
import { Kuva } from "../../interfaces/kuvaweb";
import { lang } from "../../utils/lang";
import * as fmt from "../../utils/formatting"
import { BundleParsedOrderViewModel } from "../../viewModels/cashrail/bundleAgentParsedOrderViewModel";
import { useHistory } from "react-router-dom";
import { Icon } from "../icons";
import { observer } from "mobx-react";
import ReactTooltip from "react-tooltip";
import { InfoCopyField } from "../../utils/infoCopyField";

const PaidStatus = Kuva.Module.Enums.BundleOrderPaymentStatus;

const PlacedOrderComponent: React.FC<{ viewModel: BundleParsedOrderViewModel }> = observer((props) => {
    const order = props.viewModel.placedOrder.orderDetails
    const bank = props.viewModel.placedOrder?.bankDetails

    const history = useHistory()

    const onCopyClick = (value: string) => {
        navigator.clipboard.writeText(value);
    }

    const onClose = () => {
        history.push('/ba-cashrail');
    }

    return (
        <div
            className="placed-body">
            <h1
                className="sec-title">
                {t("cashrail.placed.secTitle")}
            </h1>
            <h5
                className="pay-text">
                {t("cashrail.placed.plsPay")}
                <span>
                    {order?.reference}
                </span>
            </h5>
            <div
                className="bank-block">
                <span
                    className="total-cost-block">
                    {t("cashrail.placed.total")}
                    <span className="total-cost-value">
                        {fmt.currencySymbol(order.srcCurrency)}
                        {order.srcAmountIncludeFee.toFixed(2)}
                    </span>
                </span>
                <div
                    className="status-block">
                    {t("cashrail.placed.status")}
                    <span
                        className={order.paymentStatus == 10 ? "status-value-red" : "status-value-green"}>
                        {lang.cashrailDropdownPaidStatus(order.paymentStatus)}
                        <button
                            className="btn-default btn-primary"
                            onClick={() => { props.viewModel.changePaymentStatus(order.id, PaidStatus.paid) }}
                            hidden={order.paymentStatus == PaidStatus.paid ? true : false}>
                            {t("cashrail.placed.markPaid")}
                        </button>
                    </span>
                </div>
                <div
                    className="bank-square">
                    <div
                        className="bank-fields">
                        <div className="bank-field">
                            <h4 className="name">
                                {t("cashrail.placed.bank")}
                            </h4>
                            <h4>
                                {bank?.name}
                            </h4>
                        </div>
                        <div className="bank-field">
                            <h4 className="name">
                                {t("cashrail.placed.brncCode")}
                            </h4>
                            <h4>
                                {bank?.branchNumber}
                            </h4>
                        </div>
                        <div
                            className="bank-field-val">
                            <h4 className="name">
                                {t("cashrail.placed.code")}
                            </h4>
                            <InfoCopyField field={bank?.sortCode} dataFor="copySortCode" />
                        </div>
                        <div
                            className="bank-field-val">
                            <h4 className="name">
                                {t("cashrail.placed.accNumber")}
                            </h4>
                            <InfoCopyField field={bank?.number} dataFor="copyBankNumber" />
                        </div>
                        <div
                            className="bank-field-val">
                            <h4 className="name">
                                {t("cashrail.placed.reference")}
                            </h4>
                            <InfoCopyField field={order?.reference} dataFor="copyReference" />
                        </div>
                    </div>
                </div>
            </div>
            <div
                className="important-block">
                <span
                    className="imp-value">
                    {t("cashrail.placed.important")}
                </span>
            </div>
            <div className="close-block">
                <button
                    className="btn-default btn-primary"
                    onClick={() => onClose()}>
                    {t("cashrail.button.close")}
                </button>
            </div>
        </div>
    )
})
export default PlacedOrderComponent;