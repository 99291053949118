import { BehaviorSubject } from "rxjs";
import { KWlCashoutApiClient } from "../api/agent/kwlCashOutApiClient";
import { Kuva } from "../interfaces/kuvaweb";
import * as signalR from "@microsoft/signalr";
import * as url from "../utils/url";

type AgentCashoutKWLRequestModel = Kuva.PL.KuvaWeb.Areas.Agent.KWL.AgentCashoutKWLRequestModel;

import CashOutBookingStatus = Kuva.Module.Enums.CashOutBookingStatus;
import { observable } from "mobx";

export class PastKwlCashoutManager {
  private static _instance: PastKwlCashoutManager;
  static get instance() {
    return this._instance = new PastKwlCashoutManager();
  }

  @observable loading: boolean = false;

  private api = new KWlCashoutApiClient();

  private pastRequestsSubject = new BehaviorSubject<AgentCashoutKWLRequestModel[]>(null);
  public get pastRequests() { return this.pastRequestsSubject.asObservable(); }

  private pastRequestsCountSubject = new BehaviorSubject<number>(null);
  public get pastRequestsCount() { return this.pastRequestsCountSubject.asObservable(); }

  private bookedHasNextPageSubject = new BehaviorSubject<boolean>(null);
  public get pastHasNextPage() { return this.bookedHasNextPageSubject.asObservable(); }

  private _pastRequests: AgentCashoutKWLRequestModel[] = null;
  _hasNextPage: boolean = null;

  constructor() {
    this.connect();
    this.load();
  }

  protected async connect(): Promise<void> {
    const hubConnection = new signalR.HubConnectionBuilder()
      .withUrl(url.combine(window.configData.apiUrl, "api/v1/agents/events"))
      .withAutomaticReconnect()
      .build();
    hubConnection.on("KwlCashoutUpdated", (e) => {
      this.handlePastRequestUpdated(e);
    });
    await hubConnection.start();
  }

  protected async load(): Promise<void> {
    this.loading = true;
    var result = await this.getRequests();
    this._pastRequests = result.items;
    this._hasNextPage = result.hasNextPage;
    this.loading = false
    this.publish();
  }

  protected async getRequests() {
    return await this.api.getPast("", 20, 0);
  }

  protected handlePastRequestUpdated(request: AgentCashoutKWLRequestModel): void {
    if (this._pastRequests == null)
      return;

    const currentIndex = this._pastRequests.findIndex(e => this.id(e) == this.id(request));
    if (currentIndex !== -1) return
    else if (this.filter(request)) {
      this._pastRequests = [request, ...this._pastRequests];
    }
    this.publish();
  }

  private publish(): void {
    this.pastRequestsSubject.next(this._pastRequests);
    this.pastRequestsCountSubject.next(this._pastRequests.length);
    this.bookedHasNextPageSubject.next(this._hasNextPage);
  }

  protected id(request: AgentCashoutKWLRequestModel): string {
    return request.id;
  }

  protected filter(requests: AgentCashoutKWLRequestModel): boolean {
    return requests.status == CashOutBookingStatus.cancelled
      ||
      requests.status == CashOutBookingStatus.completed;
  }
}