import { Kuva } from '../../interfaces/kuvaweb'
// import { cashouts } from '../../mocks'
import { ApiClientBase } from '../apiClientBase'

type AgentCashoutKWLRequestTable = Kuva.PL.KuvaWeb.Areas.Agent.KWL.CashoutKWLRequestsTable
type AgentKWLCashoutDetails = Kuva.PL.KuvaWeb.Areas.Agent.KWL.AgentKWLCashoutDetails
type GetMultiCashOutsResponse = Kuva.PL.KuvaWeb.Areas.Agent.KWL.IGetMultiCashOutsResponse
type BundleDetailsResponse = Kuva.PL.KuvaWeb.Areas.Agent.KWL.IKwlCashOutBundleDetailsResponse

export class KWlCashoutApiClient extends ApiClientBase {
  base = 'multi-kwl/cash-outs'
  multiCashOutBase = 'kwl/cash-outs/bundle'

  getBooked = (query: string, pageSize: number, offSet: number) =>
    this.getNewUrl<AgentCashoutKWLRequestTable>([this.base, 'booked'], {
      query,
      pageSize,
      offSet,
      cashoutFlows: 30,
    })

  getPast = (query: string, pageSize: number, offSet: number) =>
    this.getNewUrl<AgentCashoutKWLRequestTable>([this.base, 'past'], {
      query,
      pageSize,
      offSet,
      cashoutFlows: 30,
    })

  getBookedMultiCashOuts = (query: string, pageSize: number, offSet: number) =>
    this.getNewUrl<AgentCashoutKWLRequestTable>([this.multiCashOutBase, 'active'], {
      query,
      pageSize,
      offSet,
      cashoutFlows: 30,
    })

  getProcessingMultiCashOuts = (pageSize: number, offSet: number) =>
    this.getNewUrl<GetMultiCashOutsResponse>([this.multiCashOutBase, 'processing'], {
      pageSize,
      offSet,
    })

  getPastMultiCashOuts = (pageSize: number, offSet: number) =>
    this.getNewUrl<GetMultiCashOutsResponse>([this.multiCashOutBase, 'past'], {
      pageSize,
      offSet,
    })

  getCashOutBundleDetails = (id: string) =>
    this.getNewUrl<BundleDetailsResponse>([this.multiCashOutBase, id])

  bookRequest = (cashoutId: string) => this.postNewUrl([this.base, 'book'], null, { cashoutId })

  bookMultiCashOut = (cashOutsExtId: string[]) =>
    this.postNewUrl([this.multiCashOutBase], { cashOutsExtId })

  cancel = (reference: string, topUpRequestId: string) =>
    this.postNewUrl([this.base, 'cancel'], {
      referenceNumber: reference,
      bookingId: topUpRequestId,
    })

  complete = (reference: string, requestId: string) =>
    this.postNewUrl<{}, AgentKWLCashoutDetails>([this.base, 'complete'], {
      referenceNumber: reference,
      bookingId: requestId,
    })
  completeBundle = (bundleId: string) =>
    this.postNewUrl([this.multiCashOutBase, bundleId, 'complete'])

  deleteCashOutFromBundle = (bundleId: string, cashOutId: string) =>
    this.deleteNewUrl([this.multiCashOutBase, bundleId, 'delete', cashOutId])

  getById = (topUpRequestId: string) =>
    this.getNewUrl<AgentKWLCashoutDetails>([this.base, topUpRequestId, 'details'])

  getByReference = (referenceNumber: string) =>
    this.getNewUrl<AgentKWLCashoutDetails>([this.base, 'details'], {
      digitalReference: referenceNumber,
    })

  getCsv = (query: Record<string, string> | undefined) =>
    this.getFile(['cash-outs', 'kwl', 'completed', 'csv'], query, 'text/csv')

  getCount = () => this.getNewUrl<number>([this.base, 'booked', 'count'], { cashoutFlows: 30 })
}
