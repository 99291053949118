﻿import { t } from 'i18next'
import { observer } from 'mobx-react'
import React from 'react'

import { IndicatorButton } from '../../components/indicatorButton'
import { Modal } from '../../components/modal'
import PopUp from '../../components/popUp'
import { TransitionPanel } from '../../components/transitionPanel'
import * as fmt from '../../utils/formatting'
import { lang } from '../../utils/lang'
import { CashoutProcessViewModel } from '../../viewModels/cashout/cashoutProcessViewModel'

@observer
export class CashoutProcessDialogue extends React.Component<{
  viewModel: CashoutProcessViewModel
}> {
  render() {
    const viewModel = this.props.viewModel
    const cashout = viewModel.request
    const completed = viewModel.completed

    return (
      <>
        <Modal className={'process-modal'} size={completed ? 'sm' : 'md'}>
          <div className="modal-header">
            <h5 className="modal-title"></h5>
            <button
              type="button"
              onClick={() => viewModel.cancel()}
              className="btn-close"
              aria-label="Close"
            />
          </div>
          <div className="modal-body">
            <TransitionPanel page={completed ? 'completed' : 'prompt'}>
              <div key={'completed'}>
                <div className={'result'}>
                  {viewModel.result == 'accepted' ? <p>{t('cashout.completedMessage')}</p> : null}
                  {viewModel.result == 'cancelled' ? <p>{t('cashout.cancelledMessage')}</p> : null}
                  {viewModel.result == 'failed' ? <p>{t('cashout.failedMessage')}</p> : null}{' '}
                  {/*TODO: Message?*/}
                </div>
                <div className="actions">
                  <div>
                    <IndicatorButton
                      className="btn-default btn-primary"
                      onCommand={() => viewModel.okay()}
                    >
                      {t('okayButton')}
                    </IndicatorButton>
                  </div>
                </div>
              </div>
              <div key={'prompt'}>
                <div className={'amount'}>{fmt.currencyRich(cashout.amountUsd, 'USD')}</div>
                <div className={'section-identity'}>
                  <div className="info-field">
                    <div className="label">{t('cashout.phoneNumberLabel')}</div>
                    <div className="info">{fmt.phoneNumber(cashout.phoneNumber)}</div>
                  </div>
                  {cashout.isShowUserDetailsEnabled ? (
                    <>
                      <div className="info-field">
                        <div className="label">{t('cashout.userNameLabel')}</div>
                        <div className="info">{cashout.fullName}</div>
                      </div>
                      <div className="info-field">
                        <div className="label">{t('cashout.userDateOfBirthLabel')}</div>
                        <div className="info">{fmt.dateShort(cashout.dateOfBirth)}</div>
                      </div>
                    </>
                  ) : null}
                  <div className="info-field">
                    <div className="label">{t('cashout.statusLabel')}</div>
                    <div className="info">{lang.cashoutStatus(cashout.status)}</div>
                  </div>
                  <div className="info-field">
                    <div className="label">{t('cashout.bookedForLabel')}</div>
                    <div className="info">{fmt.dateTimeShort(cashout.date)}</div>
                  </div>
                </div>
                <div className={'actions'}>
                  <div>
                    <IndicatorButton
                      className="btn-default btn-red"
                      onClick={() => viewModel.openPopUp("cancel")}
                    >
                      {t('requestCancelButton')}
                    </IndicatorButton>
                  </div>
                  <div>
                    <IndicatorButton
                      className="btn-default btn-green"
                      onClick={() => viewModel.openPopUp('accept')}
                    >
                      {t('requestAcceptButton')}
                    </IndicatorButton>
                  </div>
                </div>
              </div>
            </TransitionPanel>
          </div>
        </Modal>
        <PopUp
          reqType="cashout"
          sure={viewModel.onConfirmButton}
          completeMode={viewModel.completeMode}
          loading={viewModel.loading}
          onClosePopup={viewModel.onClosePopUp}
          showPopup={viewModel.showPopUp}
        />
      </>
    )
  }
}
