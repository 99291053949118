import { BundleCommon, BundleOrdersCommon } from "../bundleOrderCommon";
import { Kuva } from "../../interfaces/kuvaweb"
import { BundleOrderApiClient } from "../../api/cashrail/bundleOrderApiClient";

type BundleOrderShortModel = Kuva.PL.KuvaWeb.Areas.BundleAgent.Models.BundleOrderShortModel;

export class BundleOrdersViewModel extends BundleOrdersCommon<BundleOrderShortModel> {
    private apiClient = new BundleOrderApiClient();

    constructor(tab: 'new' | 'processing' | 'ready' | 'delivered' | 'completed') {
        super();
        this.load(tab)
    }

    protected async getRequests(tab: 'new' | 'processing' | 'ready' | 'delivered' | 'completed' = "new") {
        return await this.apiClient.getOrders(tab);
    }
}

export class BundleAgentViewModel extends BundleCommon<BundleOrderShortModel>{
    constructor() {
        super();

        this.orders = new BundleOrdersViewModel('new');
        this.completed = new BundleOrdersViewModel('completed');
        this.processing = new BundleOrdersViewModel("processing");
        this.ready = new BundleOrdersViewModel("ready");
        this.delivered = new BundleOrdersViewModel("delivered");

        this.updateTab();
    }
}
