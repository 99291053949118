import { t } from 'i18next'
import { observer } from 'mobx-react-lite'
import React from 'react'
import { Kuva } from '../../interfaces/kuvaweb'
import { Indicator } from '../indicator'

type BaseGeneric = Kuva.Module.Table.BaseGeneric

const LazyLoadingTable = observer(
  <T extends BaseGeneric>(props: Kuva.Module.Table.LazyLoadingProps<T>) => {
    const {
      list,
      tableConfig,
      columnCount,
      onClick,
      rootRef,
      sentryRef,
      loading,
      hasNextPage,
      checkedList,
      className,
    } = props

    return (
      <div id="main-list-1">
        <ul
          ref={rootRef}
          className={`list list-col${columnCount} ${className}` + ' main-list-lazy'}
        >
          <li className="list-header">
            {tableConfig.columns.map(column => (
              <div key={column.id} className={column.className}>
                {column.header()}
              </div>
            ))}
          </li>
          {list?.length > 0 ? (
            <>
              {list.map(listItem => (
                <li
                  onClick={() => onClick(listItem.id)}
                  className={listItem.isNew ? 'new-item' : '' + 'field-values'}
                  key={listItem.id}
                >
                  {tableConfig.columns.map(column => (
                    <div key={column.id} className={column.className}>
                      {column.id === 'checkBox'
                        ? column.column(listItem, checkedList)
                        : column.column(listItem)}
                    </div>
                  ))}
                </li>
              ))}
              {(loading || hasNextPage) && (
                <li ref={sentryRef} className="indicator-wrapper">
                  <Indicator />
                </li>
              )}
            </>
          ) : loading ? (
            <li className="indicator-wrapper">
              <Indicator />
            </li>
          ) : (
            <li className="list-noresults">
              <>{t('cashout.table.noResults')}</>
            </li>
          )}
        </ul>
      </div>
    )
  }
)
export default LazyLoadingTable
