import { action, IReactionDisposer, makeObservable, observable, runInAction } from "mobx";
import { Subscription } from "rxjs";
import { CashOutApiClient } from "../../api/agent/cashOutApiClient";
import { dateFormatter } from "../../helpers/dateFormatter";
import { Kuva } from "../../interfaces/kuvaweb";
import { PagedCashoutsManager } from "../../managers/paged/pagedCashoutsManager";
import { PagedPastCashoutsManager } from "../../managers/paged/pagedPastCashoutsManager";
import { ViewModelBase } from "../viewModelBase";
import { CashoutDetailsViewModel } from "./cashoutDetailsViewModel";

type CashoutAgentResponseModel = Kuva.PL.KuvaWeb.Areas.Agent.Models.AgentCashoutRequestModel;
type CashoutFilter = Kuva.Module.Models.Paging.Agent.CashoutFilter;
type LazyLoadedPagedCashoutsList = Kuva.Module.Models.Paging.LazyLoadingPagedListModel<CashoutAgentResponseModel>

export class BookedCashoutsViewModel extends ViewModelBase {
    manager = PagedCashoutsManager.instance;

    private api = new CashOutApiClient();

    constructor() {
        super();
        makeObservable(this);
    }

    @observable requests: CashoutAgentResponseModel[];

    @observable search: string;

    step = 30;
    offset: number = 0;

    timer: undefined | NodeJS.Timeout;

    @observable loading: boolean = true;
    @observable hasNextPage: boolean = null;

    @observable error: string;
    @observable dateRange: string;
    @observable startDateString: string;
    @observable endDateString: string;

    @observable startDate: Date;
    @observable endDate: Date;
    @observable withoutFilter: boolean = false;
    @observable requestsCount: number = 0;
    @observable filteredCount: number;

    @action handleRequests(request: Kuva.Module.Models.Paging.LazyLoadingPagedListModel<CashoutAgentResponseModel>) {
        if (request === null) {
            return;
        }
        this.requestsCount = request.count ?? 0;
        this.hasNextPage = request.hasNextPage;
        this.requests = request.items;
        this.loading = false;
    }

    @action handleSearchedRequests(request: Kuva.Module.Models.Paging.LazyLoadingPagedListModel<CashoutAgentResponseModel>) {
        if (request === null) {
            return;
        }
        this.filteredCount = request.count ?? 0;
        this.hasNextPage = request.hasNextPage;
        this.requests = request.items;
    }

    @action async getCashoutsWithSearch(filter: CashoutFilter) {
        try {
            this.search = filter.query
            let response: LazyLoadedPagedCashoutsList;
            this.loading = true;

            response = await this.getRequests(filter)
            this.handleSearchedRequests(response)
            this.loading = false;
        } catch (error) {
            this.loading = false;
            this.hasNextPage = false;
        }
    }

    @action async getCashoutsWithoutSearch(filter: CashoutFilter) {
        try {
            this.loading = true;

            const response = await this.getRequests(filter);

            this.hasNextPage = response.hasNextPage;
            this.requests = response.items;
            this.resetCount()

            this.loading = false;
        } catch (error) {
            this.loading = false;
            this.hasNextPage = false;
        }
    }

    // @action async changeSearchStatus(e: React.ChangeEvent<HTMLSelectElement>, startDate: Date, endDate: Date) {
    //     const { target: { options, value, selectedOptions } } = e;
    //     var start = startDate?.toISOString()
    //     var end = endDate?.toISOString()

    //     if (value != "100") {
    //         this.statuses.push(Number(value));
    //         await this.getCashoutsWithSearch({ query: this.search, offset: 0, pageSize: 15, dateRange: { startDate: start, endDate: end } })
    //     }
    //     else {
    //         this.statuses = [100]
    //         await this.getCashoutsWithSearch({ query: this.search, statuses: [], offset: 0, pageSize: 15, dateRange: { startDate: start, endDate: end } })
    //     }
    // }

    @action resetCount() {
        this.filteredCount = undefined;
    }

    @action
    async onLoadMore(step: number, startDate?: string, endDate?: string) {
        this.loading = true;
        const response = await this.getRequests({ query: this.search, offset: this.requests.length, pageSize: 15, dateRange: { startDate, endDate } })
        runInAction(() => {
            this.requests = [...this.requests, ...response.items];
            this.hasNextPage = response.hasNextPage;
        })
        this.loading = false;
    }

    @action changeSearch(search: string) {
        this.search = search;
        clearTimeout(this.timer);

        let start = this.startDate?.toISOString();
        let end = this.endDate?.toISOString();

        if (search?.length > 0 || this.startDate !== undefined || this.endDate !== undefined) {
            this.timer = setTimeout(
                () => this.getCashoutsWithSearch({ query: this.search, offset: 0, pageSize: 20, dateRange: { startDate: start, endDate: end } }), 1000)
        }
        else {
            this.timer = setTimeout(
                () => this.getCashoutsWithoutSearch({ query: this.search, offset: 0, pageSize: 20, dateRange: { startDate: start, endDate: end } }), 1000)
        }
    }

    @action resetSearch() {
        this.search = undefined
        this.filteredCount = undefined
    }

    getRequests(filter: Kuva.Module.Models.Paging.Agent.CashoutFilter): Promise<Kuva.Module.Models.Paging.LazyLoadingPagedListModel<CashoutAgentResponseModel>> {
        const { dateRange, query } = this.fillQueryFilter(filter);

        return this.api.getLazyPagedBookedCashouts({ query: query, pageSize: filter.pageSize, offset: filter.offset, dateRange });
    }

    @action setSearch(query: string) {
        this.search = query;
        if (query?.length > 0 || this.startDate !== undefined || this.endDate !== undefined) {
            this.getCashoutsWithSearch({ query, offset: 0, pageSize: 15, dateRange: { startDate: this.startDateString, endDate: this.endDateString } });
        }
        else {
            this.getCashoutsWithoutSearch({ pageSize: 15, offset: 0 })
        }
    }

    @action setStartDate(date: Date) {
        this.startDate = date;
        this.startDateString = dateFormatter.formatAsISOString(date);
    }

    @action setEndDate(date: Date) {
        this.endDate = date;
        this.endDateString = dateFormatter.formatToEndOfDay(date);
    }

    * subscribe(): Generator<Subscription | IReactionDisposer, any, unknown> {
        yield this.manager.pagedCashoutResponse.subscribe((nextPage) => this.handleRequests(nextPage))
    }

    private fillQueryFilter(filter: CashoutFilter): CashoutFilter {
        if (filter.dateRange !== undefined && filter.dateRange !== null) {
            if (filter.dateRange?.startDate === null || filter.dateRange.startDate === undefined) {
                filter.dateRange.startDate = "";
            }
            if (filter.dateRange.endDate === null || filter.dateRange.endDate === undefined) {
                filter.dateRange.endDate = "";
            }
        }
        else {
            filter.dateRange = {
                startDate: "",
                endDate: ""
            }
        }
        if (filter.query === undefined || filter.query === null) {
            filter.query = ""
        }
        return filter;
    }
}

export class PastCashoutsViewModel extends ViewModelBase {
    private api = new CashOutApiClient();
    manager = PagedPastCashoutsManager.instance;

    constructor() {
        super();

        makeObservable(this);
    }

    @observable search: string = "";

    step = 30;
    offset: number = 0;
    timer: undefined | NodeJS.Timeout;

    @observable loading: boolean = true;
    @observable hasNextPage: boolean;

    @observable requests: CashoutAgentResponseModel[];

    @observable requestsCount: number = 0;
    @observable filteredCount: number;

    // @observable statuses?: number[];
    @observable error: string;

    @observable startDate: Date;
    @observable endDate: Date;

    @observable dateRange: string;
    @observable startDateString: string;
    @observable endDateString: string;

    @action handleRequests(requests: Kuva.Module.Models.Paging.LazyLoadingPagedListModel<CashoutAgentResponseModel>) {
        if (requests === null)
            return;
        this.requestsCount = requests.count ?? 0;
        this.hasNextPage = requests.hasNextPage;
        this.requests = requests.items;
        this.loading = false;
    }

    @action handleSearchedRequests(requests: Kuva.Module.Models.Paging.LazyLoadingPagedListModel<CashoutAgentResponseModel>) {
        if (requests === null)
            return;
        this.hasNextPage = requests.hasNextPage;
        this.requests = requests.items;
        this.filteredCount = requests.count ?? 0;
        this.loading = false;
    }

    @action resetCount() {
        this.filteredCount = undefined;
    }

    @action async getCashoutsWithSearch(filter: CashoutFilter) {
        try {
            this.search = filter.query
            let response: LazyLoadedPagedCashoutsList;
            this.loading = true;

            response = await this.getRequests(filter)
            this.handleSearchedRequests(response)
        } catch (error) {
            this.loading = false;
            this.hasNextPage = false;
        }
    }

    @action async getCashoutsWithoutSearch(filter: CashoutFilter) {
        try {
            this.loading = true;

            const response = await this.getRequests(filter);

            this.hasNextPage = response.hasNextPage;
            this.requests = response.items;
            this.resetCount()

            this.loading = false;
        } catch (error) {
            this.loading = false;
            this.hasNextPage = false;
        }
    }

    @action async onLoadMore(step: number, startDate?: string, endDate?: string) {
        this.loading = true;
        const response = await this.getRequests({ query: this.search, offset: this.requests.length, pageSize: 15, dateRange: { startDate, endDate } })
        runInAction(() => {
            this.hasNextPage = response.hasNextPage;
            this.requests = [...this.requests, ...response.items];
        })
        this.loading = false;
    }

    @action resetSearch() {
        this.search = undefined
        this.filteredCount = undefined
    }

    @action changeSearch(search: string) {
        this.search = search;
        clearTimeout(this.timer);

        let start = this.startDate?.toISOString();
        let end = this.endDate?.toISOString();
        if (search?.length > 0 || this.startDate !== undefined || this.endDate !== undefined) {
            this.timer = setTimeout(
                () => this.getCashoutsWithSearch({ query: this.search, offset: 0, pageSize: 20, dateRange: { startDate: start, endDate: end } }), 1000)
        }
        else {
            this.timer = setTimeout(
                () => this.getCashoutsWithoutSearch({ query: this.search, offset: 0, pageSize: 20, dateRange: { startDate: start, endDate: end } }), 1000)
        }
    }

    protected getRequests(filter: Kuva.Module.Models.Paging.Agent.CashoutFilter): Promise<Kuva.Module.Models.Paging.LazyLoadingPagedListModel<CashoutAgentResponseModel>> {

        const { dateRange } = this.fillQueryFilter(filter);

        return this.api.getLazyPagedPastCashouts({ query: filter.query, pageSize: filter.pageSize, offset: filter.offset, dateRange });
    }

    private fillQueryFilter(filter: CashoutFilter): CashoutFilter {
        if (filter.dateRange !== undefined && filter.dateRange !== null) {
            if (filter.dateRange?.startDate === null || filter.dateRange.startDate === undefined) {
                filter.dateRange.startDate = "";
            }
            if (filter.dateRange.endDate === null || filter.dateRange.endDate === undefined) {
                filter.dateRange.endDate = "";
            }
        }
        else {
            filter.dateRange = {
                startDate: "",
                endDate: ""
            }
        }
        if (filter.query === undefined || filter.query === null) {
            filter.query = ""
        }
        return filter;
    }

    @action setSearch(query: string) {
        this.search = query;
        if (query?.length > 0 || this.startDate !== undefined || this.endDate !== undefined) {
            this.getCashoutsWithSearch({ query, offset: 0, pageSize: 15, dateRange: { startDate: this.startDateString, endDate: this.endDateString } });
        }
        else {
            this.getCashoutsWithoutSearch({ pageSize: 15, offset: 0 })
        }
    }

    @action setStartDate(date: Date) {
        this.startDate = date;
        this.startDateString = dateFormatter.formatAsISOString(date);
    }

    @action setEndDate(date: Date) {
        this.endDate = date;
        this.endDateString = dateFormatter.formatToEndOfDay(date);
    }

    * subscribe(): Generator<Subscription | IReactionDisposer, any, unknown> {
        this.manager.pastRequests.subscribe((req) => this.handleRequests(req))
    }
}

export class CashoutsViewModel extends ViewModelBase {

    @observable booked: BookedCashoutsViewModel;
    @observable past: PastCashoutsViewModel;

    @observable details: CashoutDetailsViewModel = null;

    @observable search: string = "";

    @observable tab: "past" | "booked" = "booked";


    constructor() {
        super();

        makeObservable(this);

        this.booked = new BookedCashoutsViewModel();
        this.past = new PastCashoutsViewModel();
    }

    mount() {
        this.booked.mount();
        this.past.mount();
        super.mount();
    }

    unmount() {
        this.booked.unmount();
        this.past.unmount();
        super.unmount();
    }

    @action changeTab(tab: "booked" | "past", startDate: Date, endDate: Date) {
        this.tab = tab;
        if (tab === "booked") {
            this.booked.setStartDate(startDate);
            this.booked.setEndDate(endDate);
            this.booked.setSearch(this.past.search)
        } else {
            this.past.setStartDate(startDate);
            this.past.setEndDate(endDate);
            this.past.setSearch(this.booked.search);
        }
    }

    select(request: CashoutAgentResponseModel) {
        if (this.details == null) {
            this.details = new CashoutDetailsViewModel(request.bookingId);
            this.details.promise.then(() => {
                this.details = null;
            });
        }
    }
}

