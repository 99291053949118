﻿import { t } from "i18next";
import { observer } from "mobx-react";
import React from 'react';

import { PageHeader } from "../../components/pageHeader";
import { CashoutDetailsViewModel } from "../../viewModels/cashout/cashoutDetailsViewModel";
import { CashoutsViewModel } from "../../viewModels/cashout/cashoutLazy";
import { CashoutFormViewModel } from "../../viewModels/cashout/cashoutViewModel";

import CashoutDetailsDialogue from "./cashoutDetailsDialogue";
import { CashoutForm } from "./cashoutForm";
import { CashoutProcessDialogue } from "./cashoutProcessDialogue";
import CashoutsList from "./cashoutsList";


@observer export class CashoutsPage extends React.Component {
    listViewModel = new CashoutsViewModel();
    formViewModel = new CashoutFormViewModel();
    constructor(props: any) {
        super(props)
    }

    componentDidMount() {
        this.listViewModel.mount();
    }

    componentWillUnmount() {
        this.listViewModel.unmount();
    }

    render() {
        return <div className="page-cashout">
            <PageHeader>
                {t("cashout.title")}
            </PageHeader>
            <div className="container">
                {this.formViewModel.process != null && <CashoutProcessDialogue viewModel={this.formViewModel.process} />}
                {this.listViewModel.details?.request?.data != null && <CashoutDetailsDialogue viewModel={this.listViewModel.details} />}
                <CashoutForm viewModel={this.formViewModel} />
                <CashoutsList viewModel={this.listViewModel} />
            </div>
        </div>
    }
}