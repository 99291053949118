﻿import React, { ChangeEvent, Component, HTMLProps } from "react";

export interface CompactFieldProps extends Omit<HTMLProps<HTMLInputElement>, "onChange" | "onFocus" | "onBlur"> {
  placeholder: string;
  value?: string | number;
  onChange?: (value: string) => void
}

export class CompactField extends Component<CompactFieldProps> {

  state = {
    focused: false,
    value: this.props.value
  }

  handleBlur() {
    this.setState({ focused: false })
  }

  handleFocus() {
    this.setState({ focused: true })
  }

  handleChange(e: ChangeEvent<HTMLInputElement>) {
    if (this.props.onChange)
      this.props.onChange(e.target.value);
    this.setState({ value: e.target.value })
  }

  componentDidUpdate(prevProps: Readonly<CompactFieldProps>, prevState: Readonly<CompactFieldProps>, snapshot?: any) {
    if (prevProps.value != this.props.value) {
      this.setState({ value: this.props.value });
    }
  }

  render() {
    const active = this.state.focused || (this.state.value != null && typeof (this.state.value) === "string" ? this.state.value.length > 0 : this.state.value != null);
    const focus = this.state.focused;

    const inputProps = { ...this.props };
    delete (inputProps as any).onChange;
    delete (inputProps as any).onFocus;
    delete (inputProps as any).onBlur;
    delete (inputProps as any).value;
    delete (inputProps as any).className;
    delete (inputProps as any).placeholder;

    return (
      <div className={`compact-field ${active ? 'active' : ''} ${focus ? 'focus' : ''} ${this.props.className}`}>
        <div className="inner">
          <label>{this.props.placeholder}</label>
          <input
            {...inputProps}
            value={this.props.value}
            onChange={(e) => this.handleChange(e)}
            onFocus={() => this.handleFocus()}
            onBlur={() => this.handleBlur()} />
        </div>
      </div>
    );
  }
}