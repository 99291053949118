﻿import {Kuva} from "../interfaces/kuvaweb";

import {ApiClientBase} from "./apiClientBase";

type AddressModel = Kuva.PL.KuvaWeb.Areas.Agent.Models.Profile.AddressModel;
type ProfileApiClientInterface = Kuva.PL.KuvaWeb.Areas.Agent.Controllers.Api.ProfileApiClientInterface;
type LocationModel = Kuva.PL.KuvaWeb.Areas.Agent.Models.Profile.LocationModel;

export class ProfileApiClient extends ApiClientBase implements ProfileApiClientInterface {
    base = "profile";

    getLocation = () => this.post<void, LocationModel>([this.base, 'location']);
    findAddress = (params: { longitude: number, latitude: number }) => this.post<void, AddressModel>([this.base, 'find-address'], null, { longitude: params.longitude.toString(), latitude: params.latitude.toString() });
    updateLocation = (newLocation: LocationModel) => this.post<LocationModel, void>([this.base, 'update-location'], newLocation);
}