import * as signalR from "@microsoft/signalr";
import { observable } from "mobx";
import { BehaviorSubject } from "rxjs";
import { KWlCashoutApiClient } from "../api/agent/kwlCashOutApiClient";
import { MultiCashApiClient } from "../api/multicash/multiCashoutsApiClient";

import { Kuva } from "../interfaces/kuvaweb";
import * as url from "../utils/url";

import CashOutBookingStatus = Kuva.Module.Enums.CashOutBookingStatus;

type MultiCashRequestModel = Kuva.PL.KuvaWeb.Areas.Agent.Multicash.MultiCashRequestModel;
type LazyLoadedPagedCashoutsList = Kuva.Module.Models.Paging.LazyLoadingPagedListModel<MultiCashRequestModel>

export class MultiCashoutsManager {
    private static _instance: MultiCashoutsManager;

    @observable loading: boolean = true;

    private bookedRequestsSubject = new BehaviorSubject<LazyLoadedPagedCashoutsList>(null);
    public get bookedRequests() { return this.bookedRequestsSubject.asObservable(); }

    private bookedRequestsCountSubject = new BehaviorSubject<number>(null);
    public get bookedRequestsCount() { return this.bookedRequestsCountSubject.asObservable(); }

    private bookedHasNextPageSubject = new BehaviorSubject<boolean>(null);
    public get bookedHasNextPage() { return this.bookedHasNextPageSubject.asObservable(); }

    private _bookedRequests: LazyLoadedPagedCashoutsList = null;
    private _bookedRequestsCount: number = 0;
    _hasNextPage: boolean = null;

    static get instance() {
        return this._instance = new MultiCashoutsManager();
    }

    private api = new MultiCashApiClient();

    constructor() {

        this.connect()
        this.load();
    }

    protected async load(): Promise<void> {
        try {
            this.loading = true;
            var result = await this.getRequests(
                {
                    pageSize: 15, offset: 0,
                    statuses: [], query: "",
                    dateRange:
                        { startDate: "", endDate: "" }
                });
            this._bookedRequests = result;
            this._hasNextPage = result.hasNextPage;
            this._bookedRequestsCount = result.count;
            this.loading = false;
            this.publish();
        } catch (error) {
        }
    }

    async getRequests(filter: Kuva.Module.Models.Paging.Agent.CashoutFilter) {
        const res = await this.api.getLazyPagedBookedCashouts(filter);
        return res;
    }

    protected async connect(): Promise<void> {
        const hubConnection = new signalR.HubConnectionBuilder()
            .withUrl(url.combine(window.configData.apiUrl, "api/v1/agents/events"))
            .withAutomaticReconnect()
            .build();
        hubConnection.on("MultiCashOutUpdated", (e) => {
            this.handleRequestUpdated(e);
        });
        await hubConnection.start();
    }

    protected id(request: MultiCashRequestModel): string {
        return request.id;
    }

    protected filter(request: MultiCashRequestModel): boolean {
        return request.status == CashOutBookingStatus.pendingPayment || request.status == CashOutBookingStatus.active;
    }

    protected handleRequestUpdated(request: MultiCashRequestModel): void {
        if (this._bookedRequests == null)
            return;
        const currentIndex = this._bookedRequests.items.findIndex(e => this.id(e) == this.id(request));
        if (currentIndex !== -1) {
            if (!this.filter(request)) {
                const newCollection = [...this._bookedRequests.items];
                newCollection.splice(currentIndex, 1);
                this._bookedRequests.items = newCollection;
                this._bookedRequestsCount = this._bookedRequestsCount - 1;
            } else {
                const newCollection = [...this._bookedRequests.items];
                request.isNew = true;
                newCollection[currentIndex] = request;
                this._bookedRequests.items = newCollection;
            }
            this.publish();
        } else {
            if (this.filter(request)) {
                request.isNew = true;
                this._bookedRequests.items = [request, ...this._bookedRequests.items];
                this._bookedRequestsCount = this._bookedRequestsCount + 1;
            }
            this.publish();
        }
    }

    private publish(): void {
        this.bookedRequestsSubject.next(this._bookedRequests);
        this.bookedRequestsCountSubject.next(this._bookedRequestsCount);
        this.bookedHasNextPageSubject.next(this._hasNextPage);
    }
}