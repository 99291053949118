import { t } from 'i18next'
import { makeObservable, observable, runInAction, action } from 'mobx'
import { observer } from 'mobx-react'
import React, { Component, FormEvent } from 'react'
import { AgentsApiClient } from '../api/agentsApiClient'
import { IndicatorButton } from '../components/indicatorButton'
import { PageHeader } from '../components/pageHeader'
import { Switch } from '../components/switch'
import { TimeRangeSlider } from '../components/timeRangeSlider'
import { Kuva } from '../interfaces/kuvaweb'
import { dayOfWeek } from '../utils/formatting'

type AgentScheduleDTO = Kuva.BL.BusinessModels.AdminDTO.AgentScheduleDTO

class DayAvailabilityViewModel {
  private api: AgentsApiClient

  public dayOfWeek: number
  @observable public isAvailable: boolean
  @observable public endTime: string
  @observable public startTime: string

  constructor(api: AgentsApiClient, schedule: AgentScheduleDTO) {
    this.api = api
    this.loadFrom(schedule)
    makeObservable(this)
  }

  private loadFrom(schedule: AgentScheduleDTO) {
    this.dayOfWeek = schedule.dayOfWeek
    this.isAvailable = schedule.isAvailable
    this.endTime = schedule.endTime
    this.startTime = schedule.startTime
  }

  @action public async save() {
    await this.api.updateDaySchedule({
      dayOfWeek: this.dayOfWeek,
      startTime: this.startTime,
      endTime: this.endTime,
      isAvailable: this.isAvailable,
    })
  }
}

class AvailabilityViewModel {
  private api = new AgentsApiClient()

  @observable public daySchedules: DayAvailabilityViewModel[] = []
  @observable public saving: boolean = false

  constructor() {
    this.load()
    makeObservable(this)
  }

  private async load() {
    const schedules = await this.api.getSchedules()
    const scheduleViewModels = schedules.map(e => new DayAvailabilityViewModel(this.api, e))
    runInAction(() => {
      this.daySchedules = scheduleViewModels
    })
  }

  public async save() {
    runInAction(() => {
      this.saving = true
    })
    try {
      for (const schedule of this.daySchedules) {
        console.log(schedule)
        await schedule.save()
      }
    } finally {
      runInAction(() => {
        this.saving = false
      })
    }
  }
}

@observer
export class AvailabilityPage extends Component {
  private viewModel = new AvailabilityViewModel()

  formatTime(time: string) {
    const timeComponents = time.split(':')
    return timeComponents[0] + ':' + timeComponents[1]
  }

  parseTime(time: string) {
    return time + ':00'
  }

  handleSubmit(e: FormEvent<HTMLFormElement>) {
    e.preventDefault()
    this.viewModel.save()
    console.log('testr')
  }

  render() {
    const vm = this.viewModel

    return (
      <div className="page-availability container">
        <PageHeader>Availability</PageHeader>
        <form onSubmit={e => this.handleSubmit(e)}>
          <div>
            {vm.daySchedules.map(day => (
              <div className="page-panel" key={day.dayOfWeek}>
                <div className="day-schedule">
                  <div className="page-panel-title">
                    <h2>
                      {dayOfWeek(day.dayOfWeek)}
                      <Switch
                        checked={day.isAvailable}
                        onChange={e => (day.isAvailable = e.target.checked)}
                      />
                    </h2>
                  </div>
                  {day.isAvailable && (
                    <div className="page-panel-content">
                      <TimeRangeSlider
                        value={[day.startTime, day.endTime]}
                        onChange={v => {
                          ;(day.startTime = v[0]), (day.endTime = v[1])
                        }}
                      />
                      <div className="d-flex justify-content-between">
                        <input
                          type="time"
                          className="form-control"
                          value={this.formatTime(day.startTime)}
                          onChange={e => (day.startTime = this.parseTime(e.target.value))}
                        />
                        <input
                          type="time"
                          className="form-control"
                          value={this.formatTime(day.endTime)}
                          onChange={e => (day.endTime = this.parseTime(e.target.value))}
                        />
                      </div>
                    </div>
                  )}
                </div>
              </div>
            ))}
          </div>
          <div className="d-flex justify-content-end">
            <IndicatorButton className="btn-default btn-primary" loading={vm.saving} type="submit">
              {t('saveButton')}
            </IndicatorButton>
          </div>
        </form>
      </div>
    )
  }
}
