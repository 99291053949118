import { t } from 'i18next'
import { action, makeObservable, observable, runInAction } from 'mobx'
import { ChangeEvent } from 'react'
import { CashOnDeliveryApiClient } from '../../api/agent/cashOnDeliveryApiClient'
import { Kuva } from '../../interfaces/kuvaweb'
import * as refNum from '../../utils/refNum'
import { IPromiseViewModel } from '../promiseViewModel'

type AgentCashOnDeliveryDetails = Kuva.PL.KuvaWeb.Areas.Agent.Models.AgentCashOnDeliveryDetails
type KuvaLocalDeliveryManifestDto = Kuva.BL.BusinessModels.AdminDTO.KuvaLocalDeliveryManifestDto
type CustomError = Kuva.PL.KuvaWeb.API.CustomError

const orderStatus = Kuva.Module.Enums.OrderStatus
const DeliveryManifestState = Kuva.Module.Enums.DeliveryManifestState
const DeliveryManifestStatus = Kuva.Module.Enums.DeliveryManifestStatus
const shippingType = Kuva.Module.Enums.ShippingType

export class CashOnDeliveryDetailsViewModel implements IPromiseViewModel<void> {
  private api = new CashOnDeliveryApiClient()
  availableStatuses = [30, 40, 60, 80, 90, 110]

  promise: Promise<void>
  @observable isPaidPage: boolean = false

  private promiseAccept: () => void
  private promiseReject: () => void

  @observable loading: boolean = false
  @observable validationMessage: string = null
  @observable isCompleted: boolean = false
  @observable valid: boolean = true

  @observable enteredRefNum: string = ''
  @observable amount: number = null
  @observable completedDms: KuvaLocalDeliveryManifestDto[]
  @observable errorMessage: string = ''
  private readonly requestId: string
  @observable request: AgentCashOnDeliveryDetails

  constructor(
    requestId: string = null,
    isCompleted: boolean = null,
    request: AgentCashOnDeliveryDetails = null
  ) {
    if (request === null) {
      this.requestId = requestId
      this.isCompleted = isCompleted
      this.load()
    } else {
      this.loadRequest(request)
    }

    this.promise = new Promise((accept, reject) => {
      this.promiseAccept = accept
      this.promiseReject = reject
    })

    if (this.isCompleted) {
      this.isPaidPage = true
    }

    makeObservable(this)
  }

  @action changeEnteredRefNum(value: string) {
    this.errorMessage = ''
    runInAction(() => {
      if (value.replace(/ /g, '').length > 11) {
        this.enteredRefNum = value.substring(0, 11)
        return
      }
      this.enteredRefNum = value.replace(/ /g, '')
    })
  }

  @action changeEnteredAmount(e: number) {
    this.errorMessage = ''
    runInAction(() => {
      this.amount = e
    })
  }

  @action changeIsCompletedDM(id: string) {
    this.errorMessage = ''
    let DMs: KuvaLocalDeliveryManifestDto[] = this.request.manifests.reduce(
      (prev, current) => [...prev, ...current.deliveryManifests],
      []
    )
    let dm = DMs.find(d => d.id === id)
    if ((!this.isPaidPage && dm.isCompleted) || (!this.request.isPaid && dm.isCompleted)) {
      dm.isCompleted = false
      return
    }

    if (
      dm.state == DeliveryManifestState.Completed &&
      dm.status == DeliveryManifestStatus.Delivered
    ) {
      dm.isCompleted = true
    } else {
      if (!dm.courierDispatchOrderId && dm.shippingType != shippingType.PickUp) {
        dm.isCompleted = false
        alert(t('cashOnDelivery.alert.cdo'))
        return
      }
      if (!this.availableStatuses.includes(dm.status)) {
        dm.isCompleted = false
        if (dm.shippingType === shippingType.PickUp) {
          alert(t('cashOnDelivery.alert.pickUpNotReady'))
          return
        } else {
          alert(t('cashOnDelivery.alert.dmNotReadyForDelivery'))
          return
        }
      }
      dm.isCompleted = true
    }
  }

  @action
  private async load() {
    try {
      runInAction(() => {
        this.loading = true
        this.request = null
      })

      this.request = await this.api.agentCashOnDeliveryDetails(this.requestId)
      this.loading = false
    } catch (error) {
      this.valid = false
      return
    }
  }

  @action
  private async loadRequest(request: AgentCashOnDeliveryDetails) {
    this.request = null
    this.request = request
  }

  private validate() {
    const result = refNum.validate(this.enteredRefNum)
    this.validationMessage = result.message
    return result.valid
  }

  @action async complete(
    setOrderAsPaid: (order: AgentCashOnDeliveryDetails) => void,
    order: AgentCashOnDeliveryDetails
  ) {
    this.errorMessage = ''
    if (!this.validate()) return

    let dms = this.request.manifests.reduce((pn, u) => [...pn, ...u.deliveryManifests], [])
    this.completedDms = dms.filter(
      x =>
        x.isCompleted &&
        (x.state != DeliveryManifestState.Completed || x.status != DeliveryManifestStatus.Delivered)
    )

    if (this.completedDms.length < 1) {
      alert(t('cashOnDelivery.alert.selectManifest'))
      return
    }
    try {
      this.loading = true
      const currentDate = new Date(
        new Date().getTime() - new Date().getTimezoneOffset() * 60 * 1000
      ).toISOString()
      await this.api.completeCashOnDelivery(
        this.enteredRefNum,
        this.amount ?? 0,
        this.completedDms.map(dm => dm.id),
        currentDate
      )
      runInAction(() => {
        this.request = { ...this.request, isPaid: true, status: orderStatus.Complete }
        this.loading = false
        this.isCompleted = true
        this.isPaidPage = true
      })
      setOrderAsPaid(order)
    } catch (e) {
      let error = e as CustomError
      this.request = { ...this.request, isPaid: false }
      this.errorMessage = error.message
      this.loading = false
    }
  }

  cancel() {
    this.isPaidPage = false
    this.promiseAccept()
  }
}
