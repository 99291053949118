import React from "react"
import { t } from "i18next"
import { observer } from "mobx-react";

import { PageHeader } from "../../components/pageHeader"
import { CashOnDeliveryViewModel } from "../../viewModels/cashOnDelivery/cashOnDeliveryOrdersViewModel";
import { CashOnDeliveryFormViewModel } from "../../viewModels/cashOnDelivery/cashOnDeliveryViewModel";
import { CashOnDeliveryDetailsViewModel } from "../../viewModels/cashOnDelivery/cashOnDeliveryDetailsViewModel";
import CashOnDeliveryDetailsDialogue from "./cashOnDeliveryDetailsDialogue";
import { CashOnDeliveryForm } from "./cashOnDeliveryForm";
import CashOnDeliveryList from "./cashOnDeliveryList";



@observer
export class CashOnDeliveryPage extends React.Component {
    listViewModel = new CashOnDeliveryViewModel();
    formViewModel = new CashOnDeliveryFormViewModel();

    componentDidMount() {
        this.listViewModel.mount();
    }

    componentWillUnmount() {
        this.listViewModel.unmount();
    }

    render() {
        return <div className="page-cash-on-delivery">
            <PageHeader>
                <div>
                    {t("cashOnDelivery.title")}
                </div>
            </PageHeader>
            <div className="container">
                {this.listViewModel.details != null && <CashOnDeliveryDetailsDialogue closeModel={this.listViewModel} viewModel={this.listViewModel.details as CashOnDeliveryDetailsViewModel} />}
                <CashOnDeliveryForm viewModel={this.listViewModel} />
                <CashOnDeliveryList viewModel={this.listViewModel} />
            </div>
        </div>
    }
}