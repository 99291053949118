import { t } from "i18next";
import React from "react"
import { useHistory } from "react-router-dom";
import { Icon } from "../icons";
import { PageHeader } from "../pageHeader"

const NewCashBundleHeader: React.FC = () => {
    const history = useHistory();
    const redirectBack = () => history.push('/ba-cashrail');

    return <div className="header">

        <PageHeader>
            <div className="button-form">
                <div
                    onClick={redirectBack}
                    className="redirect-back-mobile">
                    <Icon.MobileBackArrow />
                </div>
                <button className="btn-default btn-primary redirect-back-desktop"
                    onClick={redirectBack}>
                    {t("cashrail.button.back")}
                </button>
                <span
                    className="page-header-title">
                    {t("cashrail.new.title")}
                </span>
            </div>
        </PageHeader>
    </div>
}
export default NewCashBundleHeader;