import { Kuva } from "../../interfaces/kuvaweb";
import { ApiClientBase } from "../apiClientBase";

type CashrailOrderApiClientInterface = Kuva.PL.KuvaWeb.Areas.CashrailAgent.Controllers.CashrailOrderApiClientInterface
type BundleOrderModel = Kuva.PL.KuvaWeb.Areas.BundleAgent.Models.BundleOrderModel;
type BundleOrderShortModel = Kuva.PL.KuvaWeb.Areas.BundleAgent.Models.BundleOrderShortModel;
type BankDetails = Kuva.PL.KuvaWeb.Areas.BundleAgent.Models.BankDetails;

export class CashrailOrderApiClient extends ApiClientBase implements CashrailOrderApiClientInterface {
    base = "bundle-order";

    puckBundleItem = (orderId: number, itemId: number, packed: boolean) => this.putNewUrl<boolean, BundleOrderModel>([this.base, `${orderId}/order-item/${itemId}/change-status`], packed, null);
    getBankDetails = (id: number) => this.getNewUrl<BankDetails>([this.base, `${id}/bank-details`]);
    changeOrderStatus = (id: number) => this.putNewUrl<null, BundleOrderModel>([this.base, `${id}/set-delivered`]);
    getOrderDetails = (id: number) => this.getNewUrl<BundleOrderModel>([this.base, `${id}`]);
    getOrders = (tab: 'new' | 'processing' | 'ready' | 'delivered' | 'completed') => this.getNewUrl<BundleOrderShortModel[]>([this.base], { orderStatus: tab });
    getCount = () => this.getNewUrl<number>([this.base, 'count']);
}