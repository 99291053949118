import React from 'react'
import { Modal } from '../../../components/modal'
import { t } from 'i18next'
import { InfoField } from '../../../components/infoField'
import { Kuva } from '../../../interfaces/kuvaweb'
import { observer } from 'mobx-react-lite'
import * as fmt from '../../../utils/formatting'
import { lang } from '../../../utils/lang'
import { IndicatorButton } from '../../../components/indicatorButton'

type KwlDetailsProcessDialog = Kuva.PL.KuvaWeb.Areas.Agent.KWL.IKwlDetailsProcessDialog

type KwlMultiCashOutProcessDialogProps = {
  details: KwlDetailsProcessDialog
  closePopUp: () => void
  confirmFunction: () => void
  isOpenPopUp: boolean
}

const KwlMultiCashOutProcessDialog = observer(
  ({ details, closePopUp, isOpenPopUp, confirmFunction }: KwlMultiCashOutProcessDialogProps) => {
    return isOpenPopUp ? (
      <Modal size="lg" className="dialogue-cash dialogue-cash-out">
        <div className="modal-header">
          <h5>{t('kwlCashout.bundleCreationTitle')}</h5>
          <button type="button" onClick={closePopUp} className="btn-close" aria-label="Close" />
        </div>
        <div className="modal-body">
          <div className="body-container">
            <div className="section-identity">
              <ul className="list list-col-2">
                <li className="list-header">
                  <div className="recipient-name-column">{t('kwlCashout.recipientName')}</div>
                  <div>{t('kwlCashout.amount')}</div>
                </li>
                {details.cashOuts.map(cashOut => (
                  <li key={cashOut.id}>
                    <div className="recipient-name-column">{cashOut.recipient.name}</div>
                    <div>{fmt.currency(Number(cashOut.amountUsd), 'USD')}</div>
                  </li>
                ))}
              </ul>
              {/* <InfoField label={t('kwlCashout.recipientId')}>{details.totalAmount}</InfoField> */}
            </div>
            <div className="section-payment">
              <h4 className="question-text">
                {t('kwlCashout.confirmMultiCashOutQuestion', { count: details.selectedAmount })}
              </h4>
              <div className="payment-actions">
                <div className="row">
                  <div className="col-6">
                    <IndicatorButton onClick={closePopUp} className="btn-default btn-red ">
                      {t('kwlCashout.cancelButton')}
                    </IndicatorButton>
                  </div>
                  <div className="col-6">
                    <IndicatorButton onClick={confirmFunction} className="btn-default btn-green">
                      {t('confirmButton')}
                    </IndicatorButton>
                  </div>
                </div>
              </div>
              <div className="payment-info">
                <InfoField label={t('kwlCashout.selectedItem', { count: details.selectedAmount })}>
                  {details.selectedAmount}
                </InfoField>
                <InfoField label={t('kwlCashout.totalAmount')}>
                  {fmt.currency(Number(details.totalAmount), 'USD')}
                </InfoField>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    ) : null
  }
)

export default KwlMultiCashOutProcessDialog
