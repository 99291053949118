﻿import { action, makeObservable, observable, runInAction } from "mobx";

import { CashOutApiClient } from "../../api/agent/cashOutApiClient";
import { Kuva } from "../../interfaces/kuvaweb";
import * as refNum from "../../utils/refNum";

import { IPromiseViewModel } from "../promiseViewModel";

type CashoutRequestModel = Kuva.BL.BusinessModels.AdminDTO.CashoutRequestModel;
import CashOutBookingStatus = Kuva.Module.Enums.CashOutBookingStatus;
type CustomResponse = Kuva.PL.KuvaWeb.API.CustomResponse;

export class CashoutDetailsViewModel implements IPromiseViewModel<void> {
  private api = new CashOutApiClient()

  promise: Promise<void>;

  private promiseAccept: () => void;
  private promiseReject: () => void;

  @observable canSubmit: boolean = false;
  @observable validationMessage: string = null;

  @observable showPopUp = false;
  @observable completeMode: "accept" | "cancel" = "accept";
  @observable loading: boolean = false;
  @observable enteredRefNum: string = "";
  @observable error: string = undefined;
  @observable success: boolean = false;

  private readonly requestId: string;
  @observable request: { data: CashoutRequestModel | null } = { data: null };

  constructor(requestId: string) {
    this.requestId = requestId;

    this.promise = new Promise((accept, reject) => {
      this.promiseAccept = accept;
      this.promiseReject = reject;
    });

    this.load();

    makeObservable(this);
  }

  @action private async load() {
    this.canSubmit = false;
    if (this.request != undefined) {
      this.request = { data: null };
    }

    const result = await this.api.getCashoutRequestDetails(this.requestId);
    this.request.data = result
    this.canSubmit = this.request.data.status != CashOutBookingStatus.completed &&
      this.request.data.status != CashOutBookingStatus.cancelled;
  }

  sure = () => {
    if (this.completeMode === "accept") {
      this.complete()
    }
    else {
      this.delete()
    }
  }

  onClosePopUp = () => {
    this.showPopUp = false;
  }

  private validate() {
    const result = refNum.validate(this.enteredRefNum);
    this.validationMessage = result.message
    return result.valid;
  }

  @action openPopUp = (completeMode: "accept" | "cancel") => {
    this.completeMode = completeMode;
    this.showPopUp = true;
  }

  @action async complete() {
    if (!this.validate()) {
      this.onClosePopUp()
      return;
    }
    try {
      this.loading = true;
      this.success = false;
      const result = await this.api.completeCashOutRequest({ bookingId: this.requestId, referenceNumber: this.enteredRefNum });
      this.request.data.referenceNumber = result.referenceNumber;
      this.request.data.processedAgentFullName = result.processedAgentFullName
      this.request.data.processedAgentEmail = result.processedAgentEmail
      this.onClosePopUp()
      this.request.data.status = CashOutBookingStatus.completed;
      this.success = true;
      this.canSubmit = false;
      return;
    } catch (error) {
      let resError = error as CustomResponse;
      this.loading = false;
      this.onClosePopUp()
      if (resError.errorCode === "404") {

        this.error = resError.message;

        return;
      }
      else if (resError.message.length > 0) {
        this.error = resError.message

        return;
      }
    }
  }

  @action async delete() {
    if (!this.validate()) {
      this.onClosePopUp()
      return;
    }
    try {
      this.loading = true
      this.success = false
      let result = await this.api.cancelCashOutRequest({ bookingId: this.requestId, referenceNumber: this.enteredRefNum });
      this.request.data.referenceNumber = result.referenceNumber
      this.request.data.processedAgentFullName = result.processedAgentFullName
      this.request.data.processedAgentEmail = result.processedAgentEmail
      this.onClosePopUp()
      this.request.data.status = CashOutBookingStatus.cancelled

      this.success = true
      this.canSubmit = false
      this.loading = true

      return;
    } catch (error) {
      let resError = error as CustomResponse
      this.loading = false
      this.onClosePopUp()
      if (resError.message === "404") {
        this.error = error.message
        return
      }
      else if (resError.message.length > 0) {
        this.error = resError.message
        return
      }
      this.error = "Something went wrong"
      return
    }
  }

  @action changeReference(value: string) {
    this.error = "";
    if (value.replace(/ /g, '').length > 11) {
      this.enteredRefNum = value.substring(0, 11)
      return
    }
    this.enteredRefNum = value.replace(/ /g, '');
  }

  cancel() {
    this.promiseAccept();
  }
}