import { t } from "i18next";
import React, { useEffect, useState } from "react";
import { SearchInput } from "../../components/searchInput";
import * as fmt from "../../utils/formatting";
import { Kuva } from "../../interfaces/kuvaweb";
import { observer } from "mobx-react";
import { lang } from "../../utils/lang";
import { useHistory } from "react-router-dom";
import { IndicatorButton } from "../../components/indicatorButton";
import { SettlementsViewModel } from "../../viewModels/settlement/settlementsViewModel";
import useInfiniteScroll from "react-infinite-scroll-hook";
import { Indicator } from "../../components/indicator";
import { dateFormatter } from "../../helpers/dateFormatter"
import { ISOConverter } from "../../utils/isoConverter";
import SettlementFilter from "../../components/settlements/settlementsFilter";

type AgentSettlementResponseModel = Kuva.PL.KuvaWeb.Areas.Agent.Models.AgentSettlementResponseModel;

const Status = Kuva.Module.Enums.AgentSettlemenManifestStatus;
const PaymentType = Kuva.Module.Enums.AgentSettlementPaymentType;

let timer: undefined | NodeJS.Timeout;

const SettlementsList: React.FC<{ viewModel: SettlementsViewModel }> = observer((props) => {
  const { viewModel } = props;
  const history = useHistory();
  const [startDate, setStartDate] = useState<Date>(null);
  const [endDate, setEndDate] = useState<Date>(null);
  const [error, setError] = useState("");

  let step = 15;
  let loading = viewModel.currentList.loading;
  let hasNextPage = viewModel.currentList.hasNextPage;

  const settlements = viewModel?.currentList;

  const handleSelect = (settlement: AgentSettlementResponseModel) => {
    history.push(`settlement/details?id=${settlement.id}`);
  };

  const onLoadMore = () => {
    settlements.onLoadMore(step, dateFormatter.formatAsISOString(startDate), dateFormatter.formatToEndOfDay(endDate), viewModel.currentList.payType)
  }

  const onChangeStart = (date: Date) => {
    clearTimeout(timer);
    if (startDate != null && date > endDate) {
      setError(t("settlement.error.invalidDate"))
      return;
    }
    setStartDate(date)
    viewModel.currentList.changeStartDate(date)
    timer = setTimeout(() => settlements.getSettlementsWithFilter(), 1500)
  }

  const onChangeEnd = (date: Date) => {
    clearTimeout(timer);
    if (endDate != null && date < startDate) {
      setError(t("settlement.error.invalidDate"))
      return;
    }
    setEndDate(date)
    viewModel.currentList.changeEndDate(date)
    timer = setTimeout(() => settlements.getSettlementsWithFilter(), 1500)
  }

  const resetFilter = () => {
    setStartDate(null)
    setEndDate(null)
    viewModel.currentList.resetFilter()
    viewModel.currentList.getSettlementsWithFilter()
  }

  const [sentryRef, { rootRef }] = useInfiniteScroll({
    loading,
    hasNextPage,
    onLoadMore,
    disabled: false,
    rootMargin: "0px 0px 150px 0px"
  })

  return <>
    <div className="mobile-settl-block">
      <IndicatorButton className="btn-default btn-primary btn-pdf"
        loading={viewModel.downLoading}
        onClick={() => viewModel.generatePDF()}>
        {t("settlement.button.getPdf")}
      </IndicatorButton>
    </div>
    <div className="dashboard-page-panel">
      <div className={"search"}>
        <SearchInput onChange={(e) => viewModel.currentList.changeSearch(e)} />
        <SettlementFilter endDate={endDate} startDate={startDate} error={error} onChangeEnd={onChangeEnd} onChangeStart={onChangeStart} viewModel={viewModel} resetFilter={resetFilter} />
      </div>
      <ul ref={rootRef} className="list list-col9 li list-settlement">
        <li className="list-header">
          <div className={"col-settlement-id"}>{t("settlement.table.idColumn")}</div>
          <div className={"col-date"}>{t("settlement.table.createdDColumn")}</div>
          <div className={"col-amount"}>{t("settlement.table.amountColumn")}</div>
          <div className={"col-currency"}>{t("settlement.table.currencyColumn")}</div>
          <div className={"col-payment"}>{t("settlement.table.paymentColumn")}</div>
          <div className={"col-payment"}>{t("settlement.table.flowTypeColumn")}</div>
          <div className={"col-status"}>{t("settlement.table.statusColumn")}</div>
          <div className={"col-createdBy"}>{t("settlement.table.createdByColumn")}</div>
          <div className={"col-manifCount"}>{t("settlement.table.manCountCol")}</div>
        </li>
        {
          loading ?
            <div className="indicator-wrapper">
              <Indicator />
            </div>
            :
            settlements.filteredRequests.length > 0
              ?
              <>
                {
                  settlements.filteredRequests.map((settlement) =>
                    <li ref={sentryRef} key={settlement.id} className={settlement.isNew ? "new-item" : ""} onClick={() => handleSelect(settlement)}>
                      <div title={settlement.id.toString()} className={"col-settlement-id"}>{settlement.id}</div>
                      <div title={fmt.dateTimeShort(settlement.createdDate)} className={"col-date"}>{fmt.dateTimeShort(settlement.createdDate)}</div>
                      <div title={settlement.amount.toFixed(2)} className={"col-amount"}>{settlement.amount.toFixed(2)}</div>
                      <div title={ISOConverter(settlement.currency)} className={"col-currency"}>{ISOConverter(settlement.currency)}</div>
                      <div title={lang.settlementPaymentType(settlement.paymentType)} className={"col-payment"}>{lang.settlementPaymentType(settlement.paymentType)}</div>
                      <div title={lang.settlementFlowType(settlement.flowType)} className={"col-flow"}>{lang.settlementFlowType(settlement.flowType)}</div>
                      <div title={lang.settlementStatus(settlement.status)} className={"col-status"}>{lang.settlementStatus(settlement.status)}</div>
                      <div title={settlement.generatedByEmail} className={"col-createdBy"}>{settlement.generatedByEmail}</div>
                      <div title={settlement.ordersCount.toString()} className={"col-manifCount"}>{settlement.ordersCount}</div>
                    </li>
                  )}
                {
                  (loading || hasNextPage) && (
                    <li ref={sentryRef} className="indicator-wrapper">
                      <Indicator />
                    </li>
                  )}
              </>
              :
              <li className="list-noresults">
                <>{t("cashout.table.noResults")}</>
              </li>

        }
      </ul>
    </div>
  </>
})

export default SettlementsList