function isInRole(roles: string[], permitedRole: string | string[]) {
  if (roles && permitedRole) {
    if (Array.isArray(permitedRole)) {
      return permitedRole.some((permRole) => roles.some(role => role === permRole))
    }
    else {
      return roles.some(role => role === permitedRole);
    }
  }
  else {
    return false;
  }
}
export default isInRole;