import { t } from "i18next";
import React, { useMemo } from "react"
import { Button, ListGroup, Table } from "react-bootstrap"
import { BundleParsedOrderViewModel } from "../../viewModels/cashrail/bundleAgentParsedOrderViewModel"
import { observer } from "mobx-react"
import * as fmt from "../../utils/formatting"
import { Icon } from "../icons";
import { IndicatorButton } from "../indicatorButton";
import { Kuva } from "../../interfaces/kuvaweb";
import { lang } from "../../utils/lang";

const CashRailFlowType = Kuva.Module.Enums.CashRailFlowType;

const CashrailParsedOrderComponent: React.FC<{ viewModel: BundleParsedOrderViewModel, scvName: string, onCancel: () => {} }> = observer((props) => {
    let errors = useMemo(() => props.viewModel?.errors, [props.viewModel?.errors]);
    const order = useMemo(() => props.viewModel?.parsedModel, [props.viewModel?.parsedModel]);
    const name = props.scvName;
    const viewModel = props.viewModel;

    return (
        <>
            {
                viewModel?.placedOrder ?
                    <div>

                    </div>
                    :
                    <div>
                        {
                            errors?.length < 1 ?
                                <div className="parsed-body container">
                                    <div className="csv-name">
                                        <span className="value">
                                            {name}
                                        </span>
                                        <div className="btn-cancel"
                                            onClick={() => props.onCancel()}>
                                            <Icon.ClearCashrail />
                                            <span
                                                className="label">
                                                {t("cashrail.button.cancel")}
                                            </span>
                                        </div>
                                    </div>
                                    <div className="info-block">
                                        <div className="order-info">
                                            <div
                                                className="order-info fields">
                                                <div className="field-title">
                                                    <h4>
                                                        <span>
                                                            {order.bundleOrderItems.length}
                                                        </span>
                                                        {t("cashrail.new.orders")}
                                                    </h4>
                                                    <h4>
                                                        {fmt.currencySymbol(order.dstCurrency)}
                                                        {order.dstAmount}
                                                    </h4>
                                                </div>
                                                <hr />
                                                <div className="field-cost">
                                                    <h4>{t("cashrail.new.cost")}</h4>
                                                    <h4>
                                                        {fmt.currencySymbol(order.srcCurrency)}
                                                        {order.srcAmount}
                                                    </h4>
                                                </div>
                                                <div className="field-fee">
                                                    <h4>
                                                        {t("cashrail.new.fee")}
                                                    </h4>
                                                    <h4>
                                                        {fmt.currencySymbol(order.srcCurrency)}
                                                        {(order.kuvaFixedFee + order.kuvaPercentFee + order.agentFixedFee + order.agentPercentFee).toFixed(2)}
                                                    </h4>
                                                </div>
                                                <div className="field field-total">
                                                    <h4>
                                                        {t("cashrail.new.total")}
                                                    </h4>
                                                    <h4>
                                                        {fmt.currencySymbol(order.srcCurrency)}
                                                        {order.srcAmountIncludeFee.toFixed(2)}
                                                    </h4>
                                                </div>
                                            </div>
                                        </div>
                                        <div>
                                            <IndicatorButton
                                                loading={viewModel.loading}
                                                className="btn-default btn-primary"
                                                onClick={() => props.viewModel.placeOrder(order)}>
                                                {t("cashrail.button.placeOrder")}
                                            </IndicatorButton>
                                        </div>
                                    </div>
                                    <ul
                                        className="list bundle-table">
                                        <li className="list-header cols">
                                            <div className="col-id">
                                                {t("cashrail.new.customerId")}
                                            </div>
                                            {/* {
                                                order?.flowType == CashRailFlowType.Individual &&
                                                <>
                                                    <div className="col-id">
                                                        {t("cashrail.bundleDetails.documentType")}
                                                    </div>
                                                    <div className="col-id">
                                                        {t("cashrail.bundleDetails.documentNumber")}
                                                    </div>
                                                    <div className="col-id">
                                                        {t("cashrail.bundleDetails.fullName")}
                                                    </div>
                                                    <div className="col-id">
                                                        {t("phone")}
                                                    </div>
                                                </>
                                            } */}
                                            <div className="col-amount">
                                                {t("cashrail.new.amount")}
                                            </div>
                                        </li>
                                        {
                                            order.bundleOrderItems.map((item) =>
                                                <li>
                                                    <div className="col-id-val" key={item.id}>
                                                        {item.customerId}
                                                    </div>
                                                    {/* {
                                                        order?.flowType == CashRailFlowType.Individual &&
                                                        <>
                                                            <div>
                                                                {lang.documentType(item.recipient.documentType)}
                                                            </div>
                                                            <div>
                                                                {item.recipient.documentNumber}
                                                            </div>
                                                            <div>
                                                                {item.recipient.firstName + " " + item.recipient.lastName}
                                                            </div>
                                                            <div>
                                                                {item.recipient.phoneNumber}
                                                            </div>
                                                        </>
                                                    } */}
                                                    <div className="col-amount-val" key={item.id}>
                                                        {fmt.currencySymbol(item.destinationCurrency) + item.amount.toFixed(2)}
                                                    </div>
                                                </li>
                                            )
                                        }

                                    </ul>

                                </div>
                                :
                                <div className="errors">
                                    <ListGroup>
                                        {
                                            errors?.map((error) =>
                                                <ListGroup.Item key={error}>
                                                    <span className="error-item">
                                                        {error}
                                                    </span>
                                                </ListGroup.Item>)
                                        }
                                    </ListGroup>
                                </div>
                        }
                    </div>
            }
        </>)
})
export default CashrailParsedOrderComponent;