﻿import { t } from "i18next";
import { type } from "os";
import { ApiError } from "../api/apiError";
import { Kuva } from "../interfaces/kuvaweb";

type CustomResponse = Kuva.PL.KuvaWeb.API.CustomError;

export function validate(referNum: string): { valid: boolean, message: string } {
  if (referNum.replace(/-/g, "").replace(/\s/g, "").length === 9) {
    return { valid: true, message: null };
  }

  if (referNum.length < 11) {
    return { valid: false, message: t("component.refNumValidate.tooShortMessage") };
  }

  if (referNum.length > 11) {
    return { valid: false, message: t("component.refNumValidate.tooLongMessage") };
  }

  if (referNum.length === 0) {
    return { valid: false, message: t("component.refNumValidate.requiredMessage") };
  }

  return { valid: false, message: t("component.refNumValidate.invalidMessage") };
}

export function validateDigital(referNum: string): { valid: boolean, message: string } {
  if (referNum.replace(/-/g, "").replace(/\s/g, "").length === 11) {
    return { valid: true, message: null };
  }

  if (referNum.length < 13) {
    return { valid: false, message: t("component.refNumValidate.tooShortMessage") };
  }

  if (referNum.length > 13) {
    return { valid: false, message: t("component.refNumValidate.tooLongMessage") };
  }

  if (referNum.length === 0) {
    return { valid: false, message: t("component.refNumValidate.requiredMessage") };
  }

  return { valid: false, message: t("component.refNumValidate.invalidMessage") };
}

export async function tryAlternatives<T>(referNum: string, action: (refNum: string) => Promise<T>): Promise<T> {
  const alternatives = [referNum, referNum.replace(/-/g, " "), referNum.replace(/-/g, "")];
  for (let alternative of alternatives) {
    try {
      return await action(alternative);
    } catch (e) {
      let err = e as CustomResponse;
      if (err.code === 400) {
        return Promise.reject(e)
      }
      continue;
    }
  }
}