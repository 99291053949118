import { makeAutoObservable, observable } from "mobx";
import { CashOnDeliveryApiClient } from "../../api/agent/cashOnDeliveryApiClient";
import { IPromiseViewModel } from "../promiseViewModel";
import { CashOnDeliveryProcessViewModel } from "./cashOnDeliveryProcessViewModel";

export class CashOnDeliveryFormViewModel {
    api = new CashOnDeliveryApiClient();

    @observable referenceNumber: string = "";
    @observable valid: boolean = null;
    @observable loading: boolean = false;

    @observable details: IPromiseViewModel<void> = null;

    constructor() {
        makeAutoObservable(this);
    }

    async select(reference: string) {
        if (this.details == null) {
            this.loading = true;
            this.details = new CashOnDeliveryProcessViewModel(reference);
            this.loading = false;
            this.details.promise.then(() => {
                this.details = null;
            })
        }
    }

}