﻿import { t } from "i18next";
import {observer} from "mobx-react";
import React from "react";

import {IndicatorButton} from "../../components/indicatorButton";
import {Modal} from "../../components/modal";
import {TransitionPanel} from "../../components/transitionPanel";

import {Kuva} from "../../interfaces/kuvaweb";
import * as fmt from "../../utils/formatting";
import {currencySymbol} from "../../utils/formatting";
import {lang} from "../../utils/lang";
import {BankTopupProcessViewModel} from "../../viewModels/bankTopup/bankTopupProcessViewModel";

type TopUpRequestDTOwithConversion = Kuva.BL.BusinessModels.Api.TopUp.TopUpRequestDTOwithConversion;

@observer
export class BankTopupProcessDialogue extends React.Component<{ viewModel: BankTopupProcessViewModel }> {
    render() {
        const viewModel = this.props.viewModel;
        const topup = viewModel.request;
        const completed = viewModel.completed;

        let stage = completed ? "completed" : "prompt";
        
        if(!viewModel.amountVerified)
            stage = "amount";

        return <Modal className={"process-modal"} size={completed ? "sm" : "md"}>
            <div className="modal-header">
                <h5 className="modal-title">{t("topup.process.title")}</h5>
                <button type="button" onClick={() => viewModel.cancel()} className="btn-close" aria-label="Close"/>
            </div>
            <div className="modal-body">
                <TransitionPanel page={stage}>
                    <div key={"completed"}>
                        <div className={"result"}>
                            {viewModel.result == "accepted" ? <p>{t("topup.completedMessage")}</p> : null}
                            {viewModel.result == "cancelled" ? <p>{t("topup.cancelledMessage")}</p> : null}
                            {viewModel.result == "failed" ? <p>{t("topup.failedMessage")}</p> : null} { /*TODO: Message?*/}
                        </div>
                        <div className={"actions"}>
                            <div>
                                <IndicatorButton className={"btn-default btn-primary"}
                                                 onCommand={() => viewModel.okay()}>{t("okayButton")}</IndicatorButton>
                            </div>
                        </div>
                    </div>
                    <div key={"amount"}>
                        <div className={"m-4"}>
                            <p className={"text-center mb-3"}>{t("topup.bank.verifyAmountLabel")}</p>
                            <div className="input-group">
                                <span className="input-group-text">{currencySymbol(topup.from.currency)}</span>
                                <input className={"form-control"} type={"text"} value={viewModel.enteredAmount} onChange={(e) => viewModel.enteredAmount = e.target.value} />
                            </div>
                        </div>
                        <RequestInfo topup={topup}/>
                        <div className={"actions"}>
                            <div>
                                <IndicatorButton className={"btn-default btn-primary"}
                                                 onCommand={() => viewModel.verify()}>{t("okayButton")}</IndicatorButton>
                            </div>
                        </div>
                    </div>
                    <div key={"prompt"}>
                        <div className={"amount"}>
                            {fmt.currencyRich(viewModel.verifiedAmount, topup.from.currency)}
                        </div>
                        {viewModel.amountMismatch && <>
                            <div className={"info"}>
                                <div>
                                    <div>{t("topup.bank.fromLabel")}</div>
                                    <div>{fmt.currencyRich(topup.from.totalFromAmount, topup.from.currency)}</div> 
                                </div>
                            </div>
                            <div className={"p-3"}>
                                {(t("topup.bank.amountThresholdWarning") as string[]).map(e => <p>{e}</p>)}
                            </div>
                        </>}
                        <RequestInfo topup={topup}/>
                        <div className={"actions"}>
                            <div>
                                <IndicatorButton className={"btn-default btn-warning"}
                                                 onCommand={() => viewModel.delete()}>{t("requestCancelButton")}</IndicatorButton>
                            </div>
                            <div>
                                <IndicatorButton className={"btn-default btn-green"}
                                                 onCommand={() => viewModel.accept()}>{t("requestAcceptButton")}</IndicatorButton>
                            </div>
                        </div>
                    </div>
                </TransitionPanel>
            </div>
        </Modal>
    }
}

function RequestInfo({ topup }: { topup: TopUpRequestDTOwithConversion }) {
    return <div className={"info"}>
        {/*<div>
                                <div>Phone Number</div>
                                <div>{fmt.phoneNumber(topup.phoneNumber)}</div>
                            </div>*/}
        <div>
            <div>{t("topup.userNameLabel")}</div>
            <div>{topup.requesterName}</div>
        </div>
        {/*<div>
                                <div>User Date Of Birth</div>
                                <div>{fmt.date(topup.dateOfBirth, "dd-MM-yyyy")}</div>
                            </div>*/}

        <div>
            <div>{t("topup.statusLabel")}</div>
            <div>{lang.topupStatus(topup.status)}</div>
        </div>
    </div>
}