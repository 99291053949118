﻿import { t } from "i18next";
import { observer } from "mobx-react";
import React, { FormEvent } from "react";
import { CSSTransition } from "react-transition-group";

import { IndicatorButton } from "../../components/indicatorButton";
import { ReferenceNumberInput } from "../../components/referenceNumberInput";
import { CashoutFormViewModel } from "../../viewModels/cashout/cashoutViewModel";

import { CashoutProcessDialogue } from "./cashoutProcessDialogue";

@observer
export class CashoutForm extends React.Component<{ viewModel: CashoutFormViewModel }> {
  handleSubmit(e: FormEvent<HTMLFormElement>) {
    e.preventDefault();
    this.props.viewModel.verify();
  }

  render() {
    const viewModel = this.props.viewModel;

    return <>
      {viewModel.process != null ? <CashoutProcessDialogue viewModel={viewModel.process} /> : null}
      <div className={"verify-form"}>
        <form onSubmit={(e) => this.handleSubmit(e)}>
          <div className={"reference-number-wrapper"}>
            <ReferenceNumberInput
              value={viewModel.referenceNumber}
              onChange={(e) => viewModel.changeReference(e.target.value)} />
            <IndicatorButton className="btn-default btn-primary" loading={viewModel.validating}
              type="submit">{t("verifyButton")}</IndicatorButton>
          </div>
          <CSSTransition in={viewModel.valid == false} unmountOnExit timeout={150}>
            <p>{t("referenceNumberNotFound")}</p>
          </CSSTransition>
        </form>
      </div>
    </>
  }
}