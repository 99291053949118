import { action, makeAutoObservable, observable } from 'mobx'
import { KWlCashoutApiClient } from '../../api/agent/kwlCashOutApiClient'
import { ApiError } from '../../api/apiError'
import { KWLCashOutProcessViewModel } from './kwlCashOutProcessViewModel'


const fileDownload = require('js-file-download')
export class KWLCashOutFormViewModel {
  api = new KWlCashoutApiClient()

  @observable referenceNumber: string = ''
  @observable validating: boolean = false
  @observable valid: boolean = null

  @observable process: KWLCashOutProcessViewModel = null

  constructor() {
    makeAutoObservable(this)
  }

  @action changeReference(value: string) {
    if (value.replace(/ /g, '').length > 13) {
      this.referenceNumber = value.substring(0, 13)
      return
    }
    this.referenceNumber = value.replace(/ /g, '')
  }

  async verify() {
    if (this.process != null) return

    this.validating = true
    this.valid = null
    try {
      let cashoutRequest = await this.api.getByReference(this.referenceNumber)
      if (cashoutRequest != null && typeof cashoutRequest !== 'string')
        this.process = new KWLCashOutProcessViewModel(cashoutRequest)
      else this.valid = false
    } catch (e) {
      this.valid = false
    } finally {
      this.validating = false
    }

    if (this.process) {
      try {
        await this.process.promise
      } finally {
        this.process = null
        this.referenceNumber = ''
      }
    }
  }
}
