﻿import { computed, makeAutoObservable, observable, flow, action } from 'mobx'
import { t } from 'i18next'

import { CashOutApiClient } from '../../api/agent/cashOutApiClient'
import { Kuva } from '../../interfaces/kuvaweb'
import { Mutex } from '../../utils/mutex'

type CashoutRequestModel = Kuva.BL.BusinessModels.AdminDTO.CashoutRequestModel
import CashOutBookingStatus = Kuva.Module.Enums.CashOutBookingStatus
type CustomResponse = Kuva.PL.KuvaWeb.API.CustomResponse

export class CashoutProcessViewModel {
  api = new CashOutApiClient()

  @computed get completed(): boolean {
    return this.result != null
  }
  @observable result: 'accepted' | 'cancelled' | 'failed' = null
  @observable completeMode: 'accept' | 'cancel' = 'accept'
  @observable showPopUp = false
  @observable loading: boolean = false
  @observable error: string = undefined
  @observable success: boolean = false

  request: CashoutRequestModel
  promise: Promise<void>

  private promiseAccept: () => void
  private promiseReject: () => void
  private mutex = new Mutex()

  constructor(cashoutRequest: CashoutRequestModel) {
    makeAutoObservable(this, {
      completeCashOut: flow,
    })

    if (cashoutRequest.status == CashOutBookingStatus.cancelled) {
      this.result = 'cancelled'
    } else if (cashoutRequest.status == CashOutBookingStatus.completed) {
      this.result = 'accepted'
    } else if (
      cashoutRequest.status != CashOutBookingStatus.active &&
      cashoutRequest.status != CashOutBookingStatus.pendingPayment
    ) {
      this.result = 'failed'
    }

    this.request = cashoutRequest
    this.promise = new Promise((accept, reject) => {
      this.promiseAccept = accept
      this.promiseReject = reject
    })
  }

  onConfirmButton = () => {
    if (this.completeMode === 'accept') {
      this.completeCashOut()
    } else {
      this.cancelCashOut()
    }
  }

  @action openPopUp = (completeMode: 'accept' | 'cancel') => {
    this.completeMode = completeMode
    this.showPopUp = true
  }

  @action onClosePopUp = () => {
    this.showPopUp = false
  };

  *completeCashOut() {
    this.loading = true
    this.success = false

    try {
      yield this.api.completeCashOutRequest({
        bookingId: this.request.bookingId,
        referenceNumber: this.request.referenceNumber,
      })
      this.request.status = CashOutBookingStatus.completed
      this.result = 'accepted'
      this.success = true
      this.loading = false
      this.onClosePopUp()
    } catch (e) {
      const error = e as CustomResponse
      this.loading = false
      this.result = 'failed'
      this.onClosePopUp()
      if (error.errorCode === '404') {
        this.error = error.message

        return
      } else if (error.message.length > 0) {
        this.error = error.message

        return
      }

      this.error = 'Something went wrong'
      return
    }
  }

  *cancelCashOut() {
    this.loading = true
    this.success = false

    try {
      yield this.api.cancelCashOutRequest({
        bookingId: this.request.bookingId,
        referenceNumber: this.request.referenceNumber,
      })
      this.request.status = CashOutBookingStatus.cancelled
      this.result = 'cancelled'
      this.success = true
      this.loading = false
      this.onClosePopUp()
    } catch (e) {
      let error = e as CustomResponse
      this.result = 'failed'
      this.loading = false
      this.onClosePopUp()

      if (error.message === '404') {
        this.error = error.message
        return
      } else if (error.message.length > 0) {
        this.error = error.message
        return
      }
      this.error = 'Something went wrong'
      return
    }
  }

  async accept() {
    await this.mutex.auto(async () => {
      if (confirm(t('cashout.completePrompt'))) {
        try {
          await this.api.completeCashOutRequest({
            bookingId: this.request.bookingId,
            referenceNumber: this.request.referenceNumber,
          })
          this.request.status = CashOutBookingStatus.completed
          this.result = 'accepted'
        } catch {
          this.result = 'failed'
        }
      }
    })
  }

  async delete() {
    await this.mutex.auto(async () => {
      if (confirm(t('cashout.deletePrompt'))) {
        try {
          await this.api.cancelCashOutRequest({
            bookingId: this.request.bookingId,
            referenceNumber: this.request.referenceNumber,
          })
          this.request.status = CashOutBookingStatus.cancelled
          this.result = 'cancelled'
        } catch {
          this.result = 'failed'
        }
      }
    })
  }

  async cancel() {
    this.promiseAccept()
  }

  async okay() {
    this.promiseAccept()
  }
}
