import {
  action,
  computed,
  makeAutoObservable,
  observable,
  onBecomeObserved,
  runInAction,
} from 'mobx'
import { KWlCashoutApiClient } from '../../../api/agent/kwlCashOutApiClient'
import { Kuva } from '../../../interfaces/kuvaweb'

type MultiCashOutList = Kuva.PL.KuvaWeb.Areas.Agent.KWL.IMultiCashOutList
type CustomError = Kuva.PL.KuvaWeb.API.CustomError

export default class KwlProcessingMultiCashOutViewModel {
  private api = new KWlCashoutApiClient()

  protected requests: MultiCashOutList[] = []
  protected search: string = ''

  step = 15
  offset: number = 0

  @observable isOpenPopUp: boolean = false
  @observable loading: boolean = false
  @observable loaded: boolean = false
  @observable filteredRequests: MultiCashOutList[] = []
  @observable requestsCount: number = 0
  @observable hasNextPage: boolean = null
  @observable error: string = ''

  constructor() {
    makeAutoObservable(this, {}, { autoBind: true })
  }

  @action
  async onLoadMore(step: number) {
    if ((this.step = 10)) {
      this.loading = false
    } else {
      this.loading = true
    }
    const response = await this.getRequests(step, this.requests.length)
    runInAction(() => {
      this.requests = [...this.requests, ...response.items]
      this.hasNextPage = response.hasNextPage
      this.requestsCount = response.items.length
    })
    this.updateFilter()
    this.loading = false
  }

  @action private updateFilter() {
    if (this.requests == null) return

    this.filteredRequests = this.requests
  }

  @action async getCashouts() {
    this.loading = true
    try {
      const response = await this.getRequests(this.step, 0)
      this.hasNextPage = response.hasNextPage
      this.requests = null
      this.requests = response.items

      this.updateFilter()

      this.loading = false
    } catch (e) {
      const error = e as CustomError
      this.error = error.message

      alert(this.error)
      
      this.loading = false
    }
  }

  setSearch(search: string) {
    this.search = search
    this.updateFilter()
  }

  async getRequests(pageSize: number, offset: number) {
    return await this.api.getProcessingMultiCashOuts(pageSize, offset)
  }
}
