import { observer } from 'mobx-react-lite'
import React from 'react'
import { Kuva } from '../../interfaces/kuvaweb'

type BaseGeneric = Kuva.Module.Table.BaseGeneric

const Table = observer(<T extends BaseGeneric>(props: Kuva.Module.Table.Props<T>) => {
  const { list, tableConfig, columnCount, onClick, className = '' } = props

  return (
    <div id="main-list-1">
      <ul className={`list list-col${columnCount} ${className}`}>
        <li className="list-header">
          {tableConfig.columns.map(column => (
            <div key={column.id} className={column.className}>
              {column.header()}
            </div>
          ))}
        </li>
        {list.map(listItem => (
          <li
            className="field-values"
            key={listItem.id}
            {...(onClick && { onClick: () => onClick(listItem.id) })}
          >
            {tableConfig.columns.map(column => (
              <div key={column.id} className={column.className}>
                {column.column(listItem)}
              </div>
            ))}
          </li>
        ))}
      </ul>
    </div>
  )
})
export default Table
