import React, { useMemo } from "react";


const year: number = new Date().getFullYear(); 
const Footer: React.FC = () => {

  const version = useMemo(() => window.configData.appVersion, [window.configData.appVersion]);

  return <footer className="site-footer">
      <div className="copiright">
        Copyright © {year} KuvaCash. All rights reserved.
      </div>
      <div className="version">v: {version}</div>
    
  </footer>
}
export default Footer;