import React from "react"
import { Kuva } from "../interfaces/kuvaweb"

type CustomSelectorProps = Kuva.Module.Props.CustomSelectorProps

const CustomSelector: React.FC<CustomSelectorProps> = (props) => {
    const { options, value, onChange } = props

    return <select
        className="select"
        onChange={onChange}
        value={value}>
        {
            options.map((opt) =>
                <option
                    key={opt.id}
                    value={opt.value}>
                    {opt.label()}
                </option>
            )
        }
    </select>
}
export default CustomSelector;