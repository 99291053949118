import { t } from "i18next"
import { observer } from "mobx-react-lite"
import React, { useMemo } from "react"
import ReactDatePicker from "react-datepicker"
import { Kuva } from "../../interfaces/kuvaweb"
import { settlementFlowTypeOptions, settlementPaymentTypeOptions, settlementSearchStatusOptions } from "../../pages/settlement/config/selectorsConfig"
import CustomSelector from "../customSelector"
import { IndicatorButton } from "../indicatorButton"

type SettlementFilterProps = Kuva.Module.Props.SettlementFilterProps

const SettlementFilter: React.FC<SettlementFilterProps> = observer((props) => {
    const { viewModel, startDate, endDate, error, onChangeStart, onChangeEnd, resetFilter } = props
    const searchStatusOptions = useMemo(() => settlementSearchStatusOptions(), []);
    const paymentTypeOptions = settlementPaymentTypeOptions;
    const flowTypeOptions = settlementFlowTypeOptions;

    return <div className="date-select-wrapper d-flex mt-20 mb-20">
        <CustomSelector value={viewModel.currentList.status} options={searchStatusOptions} onChange={viewModel.currentList.changeSettlementSearchStatus} />
        <CustomSelector value={viewModel.currentList.payType} options={paymentTypeOptions} onChange={viewModel.currentList.changePaymentType} />
        <CustomSelector value={viewModel.currentList.flowType} options={flowTypeOptions} onChange={viewModel.currentList.changeFlowType} />
        <div className="datepicker">
            <span className="text-error">{error}</span>
            <div className="d-flex">
                <div className="datepicker-item">
                    <ReactDatePicker
                        selected={startDate}
                        className="cur-default"
                        dateFormat="dd/MM/yyyy"
                        selectsStart
                        placeholderText="Start date"
                        onChange={onChangeStart}
                        startDate={startDate}
                        maxDate={endDate}
                        endDate={endDate}>
                        <div className="react-datepicker-text">{t("settlement.picker.start")}</div>
                    </ReactDatePicker>
                </div>
                <div className="datepicker-item">
                    <ReactDatePicker
                        selected={endDate}
                        dateFormat="dd/MM/yyyy"
                        className="cur-default"
                        selectsEnd
                        placeholderText="End date"
                        endDate={endDate}
                        onChange={onChangeEnd}
                        minDate={startDate}
                        startDate={startDate}>
                        <div className="react-datepicker-text">{t("settlement.picker.end")}</div>
                    </ReactDatePicker>
                </div>
            </div>
        </div>
        <IndicatorButton
            className={"btn-default btn-primary"}
            onClick={resetFilter}>
            Reset Filter
        </IndicatorButton>
    </div>
})
export default SettlementFilter;