﻿import i18next from 'i18next'
import React from 'react'
import { Kuva } from '../interfaces/kuvaweb'

const currencyCodeSymbols = {
  USD: '$', // US Dollar
  USDk: '$', // US Dollar
  EUR: '€', // Euro
  CRC: '₡', // Costa Rican Colón
  GBP: '£', // British Pound Sterling
  ILS: '₪', // Israeli New Sheqel
  INR: '₹', // Indian Rupee
  JPY: '¥', // Japanese Yen
  KRW: '₩', // South Korean Won
  NGN: '₦', // Nigerian Naira
  PHP: '₱', // Philippine Peso
  PLN: 'zł', // Polish Zloty
  PYG: '₲', // Paraguayan Guarani
  THB: '฿', // Thai Baht
  UAH: '₴', // Ukrainian Hryvnia
  VND: '₫', // Vietnamese Dong
  ZAR: 'R', // South Africa
}

export function currencySymbol(code: string) {
  return currencyCodeSymbols[code]
}

// Return the first non-null, non-empty, non-whitespace string
export function coalesceWhitespace(...strings: string[]): string | null {
  const nonEmpty = strings.filter(e => e != null && e.replace(' ', '').length > 0)
  return nonEmpty.length > 0 ? nonEmpty[0] : null
}

export function currency(amount: number, code: string): string {
  if (amount == null) return ''

  return i18next.t('format.currency', {
    val: amount,
    code: code,
    formatParams: {
      val: {
        minimumFractionDigits: 2,
        currency: code,
      },
    },
  })
}

export function currencyRich(amount: number, code: string): React.ReactNode {
  var text = currency(amount, code)

  var codeIndex = text.indexOf(code)

  if (codeIndex == -1) return text

  var prefix = text.substr(0, codeIndex)
  var suffix = text.substr(codeIndex + code.length)

  return React.createElement(
    React.Fragment,
    null,
    prefix,
    React.createElement('span', { className: 'currency-code' }, code),
    suffix
  )
}

export function timeOfDayHour(hour: number) {
  const date = new Date(2020, 1, 6, hour, 0, 0, 0)
  return i18next.t('format.timeOfDayHour', { val: date })
}

export function dayOfWeek(dayOfWeek: number) {
  // 0 = sunday, according to .net's DayOfWeek
  const weekdays = [
    new Date('2020-01-12T00:00:00.000Z'),
    new Date('2020-01-06T00:00:00.000Z'),
    new Date('2020-01-07T00:00:00.000Z'),
    new Date('2020-01-08T00:00:00.000Z'),
    new Date('2020-01-09T00:00:00.000Z'),
    new Date('2020-01-10T00:00:00.000Z'),
    new Date('2020-01-11T00:00:00.000Z'),
  ]

  var date = weekdays[dayOfWeek]
  return i18next.t('format.weekdayLong', { val: date })
}

export function dateTimeShort(date: Date | string): string {
  if (date == null) return ''

  //TODO: Typed date strings
  if (typeof date === 'string') date = new Date(date)

  return i18next.t('format.dateTimeShort', { val: date })
}

export function dateShort(date: Date | string): string {
  if (date == null) return ''

  //TODO: Typed date strings
  if (typeof date === 'string') date = new Date(date)

  return i18next.t('format.dateShort', { val: date })
}

export function phoneNumber(phoneNumber: string) {
  //TODO: Complete implementation
  //This method only supports a handful for mobile formats for a small selection of country codes

  if (phoneNumber == null) return ''

  function split(number: string, format: number[]) {
    let values = []
    for (let len of format) {
      values.push(number.substr(0, len))
      number = number.substr(len)
    }
    return values.join(' ')
  }

  function format(prefix: string, ...formats: number[][]) {
    const suffix = phoneNumber.substr(prefix.length)
    for (let format of formats) {
      if (suffix.length == format.reduce((p, c) => p + c, 0))
        return `${prefix} ${split(suffix, format)}`
    }
    return phoneNumber
  }

  if (phoneNumber.startsWith('+44')) return format('+44', [4, 3, 3])
  if (phoneNumber.startsWith('+380')) return format('+380', [2, 3, 2, 2])
  if (phoneNumber.startsWith('+263')) return format('+263', [2, 3, 4])
  if (phoneNumber.startsWith('+94')) return format('+94', [2, 7])
  if (phoneNumber.startsWith('+27')) return format('+27', [3, 3, 3])

  return phoneNumber
}

export function refNum(referenceNumber: string): string {
  if (referenceNumber == null) return null

  if (referenceNumber.length != 9 && referenceNumber.length != 11) return referenceNumber

  referenceNumber = referenceNumber.replace(/\*/g, '\u2022') // Password dot

  let a, b, c
  if (referenceNumber.length == 9) {
    //Raw undelimited ref
    a = referenceNumber.substr(0, 3)
    b = referenceNumber.substr(3, 3)
    c = referenceNumber.substr(6, 3)
  } else if (referenceNumber.length == 11) {
    a = referenceNumber.substr(0, 3)
    b = referenceNumber.substr(4, 3)
    c = referenceNumber.substr(8, 3)
  }

  return `${a}-${b}-${c}`
}

export function digNum(referenceNumber: string): string {
  if (referenceNumber == null) return null

  if (referenceNumber.length != 11 && referenceNumber.length != 13) return referenceNumber

  referenceNumber = referenceNumber.replace(/\*/g, '\u2022') // Password dot

  let a, b, c
  if (referenceNumber.length == 11) {
    //Raw undelimited ref
    a = referenceNumber.substr(0, 3)
    b = referenceNumber.substr(3, 4)
    c = referenceNumber.substr(7, 4)
  } else if (referenceNumber.length == 13) {
    a = referenceNumber.substr(0, 3)
    b = referenceNumber.substr(4, 4)
    c = referenceNumber.substr(8, 4)
  }

  return `${a}-${b}-${c}`
}

type KwlRecipientAddress = { countryName: string; city: string; street: string; postcode: string }

export const getRecipientAddress = (recipientAddress: KwlRecipientAddress) => {
  const recipientAddressStr = [recipientAddress.street, recipientAddress.city, recipientAddress.countryName].filter(Boolean).join(', ')

  return recipientAddressStr
}
