import React, { FormEvent } from 'react'
import { t } from 'i18next'
import { observer } from 'mobx-react-lite'
import { CSSTransition } from 'react-transition-group'
import { DigitalInput } from '../../components/digitalInput'
import { IndicatorButton } from '../../components/indicatorButton'
import { KWLCashOutFormViewModel } from '../../viewModels/kwlCashout/KWLCashOutsFormViewModel'
import KWLCashOutProcessDialog from './kwlCashOutProcessDialog'

const KWLCashOutVerifyForm: React.FC<{ viewModel: KWLCashOutFormViewModel }> = observer(props => {
  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    props.viewModel.verify()
  }

  const viewModel = props.viewModel

  return (
    <>
      {viewModel.process != null ? <KWLCashOutProcessDialog viewModel={viewModel.process} /> : null}
      <div className="verify-form kwl-cashout-verify-form">
        <form onSubmit={e => handleSubmit(e)}>
          <div className={'reference-number-wrapper'}>
            <DigitalInput
              value={viewModel.referenceNumber}
              onChange={e => viewModel.changeReference(e.target.value)}
            />
            <IndicatorButton
              className="btn-default btn-primary"
              loading={viewModel.validating}
              type="submit"
            >
              {t('verifyButton')}
            </IndicatorButton>
          </div>
          <CSSTransition in={viewModel.valid == false} unmountOnExit timeout={150}>
            <p>{t('referenceNumberNotFound')}</p>
          </CSSTransition>
        </form>
      </div>
    </>
  )
})
export default KWLCashOutVerifyForm
