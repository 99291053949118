import { BehaviorSubject } from "rxjs";
import { Kuva } from "../interfaces/kuvaweb";
import * as signalR from "@microsoft/signalr";
import * as url from "../utils/url";

type MultiCashRequestModel = Kuva.PL.KuvaWeb.Areas.Agent.Multicash.MultiCashRequestModel;
type LazyLoadedPagedCashoutsList = Kuva.Module.Models.Paging.LazyLoadingPagedListModel<MultiCashRequestModel>
const CashOutBookingStatus = Kuva.Module.Enums.CashOutBookingStatus;

import { observable } from "mobx";
import { MultiCashApiClient } from "../api/multicash/multiCashoutsApiClient";

export class PastMultiCashoutsManager {
    private static _instance: PastMultiCashoutsManager;
    static get instance() {
        return this._instance = new PastMultiCashoutsManager();
    }

    @observable loading: boolean = false;

    private api = new MultiCashApiClient();

    private pastRequestsSubject = new BehaviorSubject<LazyLoadedPagedCashoutsList>(null);
    public get pastRequests() { return this.pastRequestsSubject.asObservable(); }

    private pastRequestsCountSubject = new BehaviorSubject<number>(null);
    public get pastRequestsCount() { return this.pastRequestsCountSubject.asObservable(); }

    private bookedHasNextPageSubject = new BehaviorSubject<boolean>(null);
    public get pastHasNextPage() { return this.bookedHasNextPageSubject.asObservable(); }

    private _pastRequests: LazyLoadedPagedCashoutsList;
    _hasNextPage: boolean;
    private _pastRequestsCount: number = 0;

    constructor() {
        this.connect();
        this.load();
    }

    protected async connect(): Promise<void> {
        const hubConnection = new signalR.HubConnectionBuilder()
            .withUrl(url.combine(window.configData.apiUrl, "api/v1/agents/events"))
            .withAutomaticReconnect()
            .build();
        hubConnection.on("MultiCashOutUpdated", (e) => {
            this.handlePastRequestUpdated(e);
        });
        await hubConnection.start();
    }

    protected async load(): Promise<void> {
        this.loading = true;
        var result = await this.getRequests(
            {
                pageSize: 15, offset: 0,
                query: "", dateRange:
                {
                    startDate: "", endDate: ""
                }
            });
        this._pastRequests = result;
        this._pastRequestsCount = result.count;
        this._hasNextPage = result.hasNextPage;
        this.publish();
    }

    protected async getRequests(filter: Kuva.Module.Models.Paging.Agent.CashoutFilter) {
        return await this.api.getLazyPagedPastCashouts(filter);
    }

    protected handlePastRequestUpdated(request: MultiCashRequestModel): void {
        if (this._pastRequests == null)
            return;

        const currentIndex = this._pastRequests.items.findIndex(e => this.id(e) == this.id(request));
        if (currentIndex !== -1) return
        else if (this.filter(request)) {
            this._pastRequests.items = [request, ...this._pastRequests.items];
            this._pastRequestsCount = this._pastRequests.count + 1;
        }
        this.publish();
    }

    private publish(): void {
        this.pastRequestsSubject.next(this._pastRequests);
        this.pastRequestsCountSubject.next(this._pastRequestsCount);
        this.bookedHasNextPageSubject.next(this._hasNextPage);
    }

    protected id(request: MultiCashRequestModel): string {
        return request.id;
    }

    protected filter(requests: MultiCashRequestModel): boolean {
        return requests.status == CashOutBookingStatus.cancelled
            ||
            requests.status == CashOutBookingStatus.completed;
    }
}