﻿import React, { ChangeEvent, ClipboardEvent } from "react";
import Cleave from "cleave.js/react";

export interface SpecificProps extends Omit<React.InputHTMLAttributes<HTMLInputElement>, 'onChange'> {
  onChange: (e: { target: { value: string } }) => void
}

export class ReferenceNumberInput extends React.Component<SpecificProps> {
  private originalPlaceholder = "___-___-___";

  state = {
    placeholder: this.originalPlaceholder
  }

  getPlaceholderFor(current: string) {
    if (current.length >= 9)
      return "";
    return Array(current.length).fill("\u00A0").join("") + this.originalPlaceholder.substr(current.length);
  }

  componentDidMount() {
    const currentValue = this.props.value;
    this.setState({
      placeholder: this.getPlaceholderFor(currentValue as string),
    });
  }

  componentDidUpdate(prevProps: Readonly<React.InputHTMLAttributes<HTMLInputElement>>, prevState: Readonly<{}>, snapshot?: any) {
    if (prevProps.value != this.props.value) {
      const currentValue = this.props.value;
      this.setState({
        placeholder: this.getPlaceholderFor(currentValue as string),
      });
    }
  }

  handleChange(e: ChangeEvent<HTMLInputElement>) {
    const currentValue = e.target.value.replace(/-/g, '');

    this.setState({
      placeholder: this.getPlaceholderFor(currentValue),
    })
    if (this.props.onChange != null)
      this.props.onChange(e);
  }


  onPaste = (event: ClipboardEvent<HTMLInputElement>) => {
    event.preventDefault();
    let paste = (event.clipboardData).getData('text').trim();
    paste = paste.replace(/ /g, '').toUpperCase()

    this.props.onChange({
      ...event,
      target: {
        ...event.target,
        value: paste
      }
    })
  }

  render() {
    // 3, 3, _6_ to allow for extra pasted data
    return <div className={"reference-number-input"}>
      <span>{this.state.placeholder}</span>
      <Cleave {...this.props}
        autoComplete="none"
        autoCapitalize="characters"
        className={`form-control ${this.props.className}`}
        onChange={(e) => this.handleChange(e)}
        onPaste={(e) => this.onPaste(e)}
        options={{ blocks: [3, 3, 3], delimiters: ["-"], uppercase: true }}
      />
    </div>
  }
}