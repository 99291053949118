import mapboxgl from "mapbox-gl";
import React, {Component, createRef} from "react";
import {MapboxApiClient} from "../api/mapboxApiClient";

export interface MapProps {
    location: [number, number];
    allowSelect?: boolean;
    onLocationChange?: (location: [longitude: number, latitude: number]) => void;
}

export class Map extends Component<MapProps> {
    tokenApi = new MapboxApiClient();
    map: mapboxgl.Map = null;
    marker: mapboxgl.Marker = null;
    mapContainer = createRef<HTMLDivElement>();

    state = {
        loaded: false,
        error: false
    }
    
    componentDidMount() {
        this.attach().catch((e) => {
            this.setState({error: true});
        });
    }
    
    componentDidUpdate(prevProps: Readonly<MapProps>, prevState: Readonly<{}>, snapshot?: any) {
        try {
            if (prevProps.location != this.props.location) {
                if (this.marker != null) {
                    this.marker.setLngLat(this.props.location);
                }
                if (this.map != null) {
                    this.map.setCenter(this.props.location);
                }
            }
            if (prevProps.allowSelect != this.props.allowSelect) {
                //TODO?
            }
        } catch (e) {
            console.error(e);
            this.setState({error: true});
        }
    }

    private updateLocation() {
        if(this.props.onLocationChange != null) {
            const newCenter = this.map.getCenter();
            this.props.onLocationChange([newCenter.lng, newCenter.lat]);
        }
    }
    
    private updateLocationPreview() {
        if(this.props.allowSelect) {
            const newCenter = this.map.getCenter();
            this.marker.setLngLat(newCenter);
        }
    }

    async attach() {
        if (mapboxgl.accessToken == null) {
            mapboxgl.accessToken = await this.tokenApi.getToken();
        }

        this.map = new mapboxgl.Map({
            container: this.mapContainer.current,
            style: 'mapbox://styles/mapbox/streets-v11',
            interactive: this.props.allowSelect == true,
            zoom: 14,
            center: this.props.location
        });
        if (this.props.allowSelect) {
            this.map.addControl(new mapboxgl.GeolocateControl(), 'bottom-right');
        }
        this.map.addControl(new mapboxgl.NavigationControl({showCompass: false}), 'bottom-right');
        this.map.on("error", () => {
            this.setState({error: true})
        });
        this.map.on("zoomend", (event) => {
            this.updateLocation();
        });
        this.map.on("dragend", (event) => {
            this.updateLocation();
        });
        this.map.on("zoom", (event) => {
            this.updateLocationPreview();
        })
        this.map.on("drag", (event) => {
            this.updateLocationPreview();
        })
        this.map.on('load', () => {
            this.setState({
                loaded: true
            });

            this.marker = new mapboxgl.Marker()
            try {
                this.marker.setLngLat(this.props.location);
            } catch(e) {
                //This may fail with weird coords
                console.error(e);
            }
            this.marker.addTo(this.map);
        });
    }
    

    render() {
        return (<div className={"component-map"}>
                {this.state.loaded ? null : <div className="map-loading-overlay"></div> }
                <div className={"map-ref-container"} ref={this.mapContainer}></div>
            </div>
        );
    }
}
