import { action, IReactionDisposer } from "mobx";
import { Subscription } from "rxjs";
import { Kuva } from "../../interfaces/kuvaweb";
import { CashOnDeliveryManager } from "../../managers/cashOnDeliveryManager";
import { PastCashOnDeliveryManager } from "../../managers/pastCashOnDeliveryManager";
import { CashOnDeliveryDetailsViewModel } from "./cashOnDeliveryDetailsViewModel";
import { BookedCashOnDeliveryRequestsViewModel, CashOnDeliveryRequestsViewModel, PastCashOnDeliveryRequestsViewModel } from "../cashOnDeliveryCommon";
import { CashOnDeliveryApiClient } from "../../api/agent/cashOnDeliveryApiClient";
import { ChangeEvent } from "react";

type AgentCashOnDeliveryRequestModel = Kuva.PL.KuvaWeb.Areas.Agent.Models.AgentCashOnDeliveryRequestModel;
type AgentCashOnDeliveryDetails = Kuva.PL.KuvaWeb.Areas.Agent.Models.AgentCashOnDeliveryDetails;

class BookedCashOnDeliveryViewModel extends BookedCashOnDeliveryRequestsViewModel {
  private manager = CashOnDeliveryManager.instance;

  *subscribe(): Generator<Subscription | IReactionDisposer> {
    yield this.manager.bookedRequests.subscribe((orders) => this.handleRequests(orders));
    yield* super.subscribe();
  }
}

class PastCashOnDeliveryViewModel extends PastCashOnDeliveryRequestsViewModel {
  private manager = PastCashOnDeliveryManager.instance;

  *subscribe(): Generator<Subscription | IReactionDisposer> {
    yield this.manager.pastRequests.subscribe((orders) => this.handleRequests(orders));
    yield* super.subscribe();
  }
}
export class CashOnDeliveryViewModel extends CashOnDeliveryRequestsViewModel {
  private api = new CashOnDeliveryApiClient();
  constructor() {
    super();

    this.booked = new BookedCashOnDeliveryViewModel();
    this.past = new PastCashOnDeliveryViewModel();
    this.updateTab();
  }

  @action
  close() {
    this.details = null;
  }

  @action setOrderAsPaid = (order: AgentCashOnDeliveryDetails) => {
    this.booked.filteredRequests.forEach(request => {
      if (request.id === order.id) {
        request.isPaid = true;
      }
    })
  }

  @action
  async select(request: AgentCashOnDeliveryRequestModel = null, isCompleted: boolean = null, reference: string = null) {
    try {
      this.details = null;
      this.valid = true;
      if (reference != null) {
        const result = await this.api.agentCashOnDeliveryDetailsByReference(reference)
        this.details = new CashOnDeliveryDetailsViewModel(null, null, result)
        this.details.promise.then(() => {
          this.details = null;
        })
      } else {
        if (this.details == null) {
          this.details = new CashOnDeliveryDetailsViewModel(request?.id, isCompleted);
          this.details.promise.then(() => {
            this.details = null;
          })
        }
      }
    } catch (error) {
      this.valid = false;
    }
  }

  @action changeReference(value: string) {
    if (value.replace(/ /g, '').length > 11) {
      this.reference = value.substring(0, 11)
      return
    }
    this.reference = value.replace(/ /g, '');
  }

  @action changeSearch(e: React.ChangeEvent<HTMLInputElement>) {
    this.search = e.target.value;
  }
}