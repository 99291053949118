import React, { useMemo, useState } from "react";
import { SearchInput } from "../../../components/searchInput";
import { MultiCashoutsViewModel } from "../../../viewModels/multicash/cashouts/multicashCashoutsViewModel";
import Table from "../../../components/table/table";
import MultiCashoutsTableConfig from "./config";
import { observer } from "mobx-react";
import useInfiniteScroll from "react-infinite-scroll-hook";
import LazyLoadingTable from "../../../components/table/lazyLoadingTable";
import { t } from "i18next";

const step = 15

const MultiCashoutsList: React.FC<{ viewModel: MultiCashoutsViewModel }> = observer((props) => {
    const viewModel = props.viewModel;
    const tab = viewModel.tab;
    const cashouts = useMemo(() => tab === "booked" ? viewModel.booked : viewModel.past, [tab]);
    const loading = cashouts?.loading;
    const hasNextPage = cashouts?.hasNextPage;
    const tableConfig = useMemo(() => MultiCashoutsTableConfig(props.viewModel.tab,), []);
    const [startDate, setStartDate] = useState<Date>(undefined);
    const [endDate, setEndDate] = useState<Date>(undefined);
    const [error, setError] = useState("");

    const onLoadMore = () => {
        cashouts.onLoadMore(step)
    }

    const [sentryRef, { rootRef }] = useInfiniteScroll({
        loading,
        hasNextPage,
        onLoadMore,
        disabled: false,
        rootMargin: "0px 0px 200px 0px"
    })

    return <div className="dashboard-page-panel">
        <div className="page-panel-title">
            <ul className="nav custom-nav-pills nav-pills nav-fill">
                <li className="nav-item">
                    <button onClick={() => viewModel.changeTab("booked", startDate, endDate)}
                        className={`nav-link ${viewModel.tab == "booked" ? 'active' : ''}`}
                        aria-current="page">
                        {t("cashout.bookedCashoutsTab")} <span
                            className={"badge bg-secondary"}>
                            {viewModel.booked.filteredCount !== undefined ?
                                (viewModel.booked.filteredCount
                                    + " " + "(Total: " + viewModel.booked.requestsCount + ")") : ""
                                + viewModel.booked.requestsCount}
                        </span>
                    </button>
                </li>
                <li className="nav-item">
                    <button onClick={() => viewModel.changeTab("past", startDate, endDate)}
                        className={`nav-link ${viewModel.tab == "past" ? 'active' : ''}`}>
                        {t("cashout.pastCashoutsTab")}
                        <span className="badge bg-secondary">
                            {viewModel.past.filteredCount !== undefined ?
                                (viewModel.past.filteredCount
                                    + " " + "(Total: " + viewModel.past.requestsCount + ")") : ""
                                + viewModel.past.requestsCount}
                        </span>
                    </button>
                </li>
            </ul>
        </div>
        <div
            className="search">
            <SearchInput onChange={e => cashouts.setSearch(e.target.value)} />
        </div>
        <LazyLoadingTable loading={loading} hasNextPage={hasNextPage} sentryRef={sentryRef} rootRef={rootRef} list={cashouts?.requests}
            onClick={props.viewModel.select.bind(props.viewModel)}
            columnCount={5}
            tableConfig={tableConfig} />
    </div>
})
export default MultiCashoutsList;