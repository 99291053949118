﻿import { observer } from "mobx-react";
import React from "react";
import { FixedValidationMessage } from "../../components/fixedValidationMessage";
import { IndicatorButton } from "../../components/indicatorButton";
import { currencySymbol } from "../../utils/formatting";

import * as fmt from "../../utils/formatting";
import { lang } from "../../utils/lang";
import { BankTopupDetailsViewModel } from "../../viewModels/bankTopup/bankTopupDetailsViewModel";

import { Modal } from "../../components/modal";
import { ReferenceNumberInput } from "../../components/referenceNumberInput";
import { t } from "i18next";
import PopUp from "../../components/popUp";

@observer
export class BankTopupDetailsDialogue extends React.Component<{ viewModel: BankTopupDetailsViewModel }> {
  render() {
    const viewModel = this.props.viewModel;
    const topup = viewModel.request;

    if (topup == null)
      return null;

    return <>
      <Modal size={"lg"} className={"dialogue-cash dialogue-top-up"}>
        <div className="modal-header">
          <h5 className="modal-title">{t("topup.detailsTitle")}</h5>
          <button type="button" onClick={() => viewModel.cancel()} className="btn-close" aria-label="Close" />
        </div>
        <div className="modal-body">
          <div className="body-container">
            <div className="section-identity">
              <InfoField label={t("topup.createdDateLabel")}>
                {fmt.dateTimeShort(topup.createdDate)}
              </InfoField>
              <InfoField label={t("topup.typeLabel")}>
                {lang.addFundsType(topup.requestType)}
              </InfoField>
              <InfoField label={t("topup.requesterNameLabel")}>
                {topup.requesterName}
              </InfoField>
              <InfoField label={t("topup.expirationDateLabel")}>
                {fmt.dateTimeShort(topup.expirationDate)}
              </InfoField>
              {topup.completedDate != null ?
                <InfoField label={t("topup.completedDateLabel")}>
                  {fmt.dateTimeShort(topup.completedDate)}
                </InfoField>
                : null}
              {topup.treasuryTransfer != null ?
                <>
                  <InfoField label={t("topup.treasuryTransferLabel")}>
                    {topup.treasuryTransfer.treasuryTransferStatus}
                  </InfoField>

                  <InfoField label={t("topup.treasuryIdLabel")}>
                    {topup.treasuryTransfer.id}
                  </InfoField>
                </>
                : null}
              <InfoField label={t("topup.statusLabel")}>
                {lang.topupStatus(topup.status)}
              </InfoField>
            </div>
            <div className="section-payment">
              <div className={"payment-reference"}>
                <div className={"label"}>{t("topup.referenceLabel")}</div>
                <div className={"reference"}>{fmt.refNum(topup.reference)}</div>
              </div>
              {viewModel.canSubmit && <div className="payment-actions">
                <div className="reference-number-wrapper">
                  <ReferenceNumberInput type="text" value={viewModel.enteredRefnum}
                    onChange={(e) => viewModel.enteredRefnum = e.target.value} />
                  <div className="input-group">
                    <span className="input-group-text">{currencySymbol(topup.from.currency)}</span>
                    <input className={"form-control"} type={"text"} value={viewModel.enteredFrom} onChange={(e) => viewModel.enteredFrom = e.target.value} />
                  </div>
                  <FixedValidationMessage message={viewModel.validationMessage} />
                </div>
                <div className="row">
                  {viewModel.errorMessage?.length > 0 && <div className="error text-center mb-10">
                    {viewModel.errorMessage}
                  </div>}
                  <div className={"col-6"}>
                    <IndicatorButton onClick={() => viewModel.openPopUp("cancel")} className="btn-default btn-red">
                      {t("requestCancelButton")}
                    </IndicatorButton>
                  </div>
                  <div className={"col-6"}>
                    <IndicatorButton onClick={() => viewModel.openPopUp("accept")} className="btn-default btn-green">
                      {t("requestCompleteButton")}
                    </IndicatorButton>
                  </div>
                </div>
              </div>}
              <div className={"payment-info"}>
                <InfoField label={t("topup.fromLabel")}>
                  {fmt.currency(topup.from.totalFromAmount, topup.from.currency)}
                </InfoField>
                <InfoField label={t("topup.toLabel")}>
                  {fmt.currency(topup.to.amount, topup.to.currency)}
                </InfoField>
                <InfoField label={t("topup.agentFixedFeeLabel")}>
                  {fmt.currency(topup.agentFixedFee, topup.settlementCurrency)}
                </InfoField>
                <InfoField label={t("topup.agentPercentFeeLabel")}>
                  {fmt.currency(topup.agentPercentFee, topup.settlementCurrency)}
                </InfoField>
                <InfoField label={t("topup.agentTotalFeeLabel")}>
                  {fmt.currency(topup.agentPercentFee + topup.agentFixedFee, topup.settlementCurrency)}
                </InfoField>
                <InfoField label={t("topup.kuvaFeeLabel")}>
                  {fmt.currency(topup.kuvaFee, topup.from.currency)}
                </InfoField>
                <InfoField label={t("topup.totalFeeLabel")}>
                  {fmt.currency(topup.totalFee, topup.from.currency)}
                </InfoField>
              </div>
            </div>
          </div>
        </div>
      </Modal>
      <PopUp
        reqType="topUp"
        sure={viewModel.sure}
        completeMode={viewModel.completeMode}
        loading={viewModel.loading}
        onClosePopup={viewModel.onClosePopUp}
        showPopup={viewModel.showPopUp} />
    </>
  }
}

function InfoField(props: React.PropsWithChildren<{ label: string }>) {
  return <div className={"info-field"}>
    <div className={"label"}>{props.label}</div>
    <div className={"info"}>{props.children}</div>
  </div>
}