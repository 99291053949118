import { ChangeMerchantApiClient } from "../../api/agent/changeMerchantApiClient";
import { Kuva } from "../../interfaces/kuvaweb";
import { action, runInAction } from "mobx";
import { ChangeMerchantCommon, ChangeMerchantTXsCommon } from "../changeMerchantCommon";
import { ChangeMerchantDetailsViewModel } from "./changeMerchantDetailsViewModel";


export class ChangeMerchantTransactionsViewModel extends ChangeMerchantTXsCommon {
  private api = new ChangeMerchantApiClient();

  constructor() {
    super();
    this.load()
  }

  protected async getRequests() {
    return await this.api.getTransactions();
  }
}

export class ChangeMerchantViewModel extends ChangeMerchantCommon {

  constructor() {
    super();

    this.transactions = new ChangeMerchantTransactionsViewModel();
  }

  @action
  async select(orderId: number) {
    this.details = null;
    this.details = new ChangeMerchantDetailsViewModel(orderId);
  }

  @action
  close() {
    this.details = null;
  }
}