import { t } from "i18next"
import React from "react"
import { exportToCsv } from "../../helpers/exportToCsv"


export const ShortCashrailCsvTemplate = () => {

    return <button
        className="btn-default btn-primary"
        onClick={() => {
            exportToCsv("cashrail_template.csv",
                [['123', '125', 'USD'],
                ['321', '125', 'USD'],
                ['212', '150', 'USD'],
                ['213', '150', 'USD']],
                ["CustomerId", "Amount", "DestinationCurrency"])
        }}>
        {t("cashrail.button.download")}
    </button>
}

export const LongCashrailCsvTemplate = () => {

    return <button
        className="btn-default btn-primary"
        onClick={() => {
            exportToCsv("multicash_template.csv",
                [['Donna', 'Shark', 'Male', '1991-11-26', '30', '123456', '+380506543211', '123', '70', 'USD'],
                ['Don', 'Shark', 'Female', '1991-11-26', '10', '123456', '+380506543212', '3211', '40', 'USD'],
                ['Donna', 'Shark', '0', '1991-11-26', '20', '123456', '+380506543213', '121', '20', 'USD'],
                ['Don', 'Shark', '1', '1991-11-26', '30', '123456', '+380506543214', '2312', '50', 'USD']],
                ["FirstName", "LastName","Gender", "DateOfBirth", "DocumentType", "DocumentNumber", "PhoneNumber", "CustomerId", "Amount", "DestinationCurrency"])
        }}>
        {t("cashrail.button.download")}
    </button>
}
