import React from 'react'
import { IndicatorButton } from '../indicatorButton'
import { Modal } from '../modal'

type DialogModalProps = {
  mainText: string
  cancelBtnText: string
  acceptBtnText: string
  isOpenModal: boolean
  closeModal: () => void
  confirmModal: () => void
}

const DialogModal: React.FC<DialogModalProps> = ({
  mainText,
  cancelBtnText,
  acceptBtnText,
  isOpenModal,
  closeModal,
  confirmModal,
}) => {
  return (
    <>
      {isOpenModal && (
        <Modal className="confirm-modal">
          <div className="modal-header">
            <h4>{mainText}</h4>
            <button type="button" onClick={closeModal} className="btn-close" />
          </div>
          <div className="modal-body">
            <div className="button-form justifyCenter">
              <button className="btn-default btn-red" onClick={closeModal}>
                {cancelBtnText}
              </button>
              <IndicatorButton
                className="btn-default btn-green"
                // loading={loading}
                onClick={() => confirmModal()}
              >
                {acceptBtnText}
              </IndicatorButton>
            </div>
          </div>
        </Modal>
      )}
    </>
  )
}

export default DialogModal
