import React, { useEffect, useMemo, useState } from "react";
import { t } from "i18next";
import { observer } from "mobx-react";
import { PageHeader } from "../../components/pageHeader";
import { ChangeMerchantViewModel } from "../../viewModels/changeMerchant/changemerchantTransactionsViewModel";
import ChangeMerchantDetailsDialogue from "../../components/changeMerchant/changeMerchantDetailsDialogue";
import ChangeMerchantList from "../../components/changeMerchant/changeMerchantList";


const ChangeMerchantPage: React.FC = observer(() => {
  const viewModel = useMemo(() => new ChangeMerchantViewModel(), []);
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    viewModel.mount()

    return () => {
      viewModel.unmount()
    }
  }, [viewModel.mount, viewModel.unmount])


  const openDetails = (orderId: number) => {
    viewModel.select(orderId)
  }

  const onClick = () => {
    setShowModal(!showModal);
  }

  return (
    <div className="change-merchant-page">
      <PageHeader>
        {t("changeMerchant.header")}
      </PageHeader>
      <div className="container verify-form">
        <button
          onClick={onClick}
          className="btn-default btn-primary">
          {t("changeMerchant.button.addTX")}
        </button>
      </div>
      <div className="container">
        {Boolean(viewModel.details?.transaction) &&
          <ChangeMerchantDetailsDialogue
            closeModel={viewModel}
            viewModel={viewModel.details} />}
        <ChangeMerchantList setShowModal={() => onClick()} showModal={showModal} viewModel={viewModel} openDetails={openDetails} />
      </div>
    </div>
  )
})
export default ChangeMerchantPage;