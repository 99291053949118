﻿import React, {Component, createRef} from "react";
import qr from "qr.js";

export class QRCode extends Component<{ color: string, uri: string }> {
    private ref = createRef<HTMLCanvasElement>();
    
    componentDidMount() {
        this.update();
    }

    componentDidUpdate() {
        this.update();
    }

    utf16to8(data: string) {
        let out = "";
        for (let i = 0; i < data.length; i++) {
            const c = data.charCodeAt(i);
            if ((c >= 0x0001) && (c <= 0x007F)) {
                out += data.charAt(i);
            } else if (c > 0x07FF) {
                out += String.fromCharCode(0xE0 | ((c >> 12) & 0x0F));
                out += String.fromCharCode(0x80 | ((c >> 6) & 0x3F));
                out += String.fromCharCode(0x80 | ((c >> 0) & 0x3F));
            } else {
                out += String.fromCharCode(0xC0 | ((c >> 6) & 0x1F));
                out += String.fromCharCode(0x80 | ((c >> 0) & 0x3F));
            }
        }
        return out;
    }

    update() {
        const scale = 1;
        
        const data = this.utf16to8(this.props.uri);
        const qrcode = qr(data);
        
        const canvas = this.ref.current;
        const ctx = canvas.getContext('2d');
        
        const cells = qrcode.modules;
        canvas.width = cells.length;
        canvas.height = cells.length;
        
        ctx.fillStyle = this.props.color;

        ctx.scale(scale, scale);
        ctx.clearRect(0, 0, canvas.width, canvas.height);

        for(let row = 0; row < cells.length; row++) {
            for(let col = 0; col < cells[row].length; col++) {
                if(cells[row][col]) {
                    ctx.fillRect(row, col, 1, 1);
                }
            }
        }
    }

    render() {
        return <canvas ref={this.ref}/>;
    }
}