import { t } from 'i18next'
import { observer } from 'mobx-react'
import React, { useEffect, useMemo, useState } from 'react'
import { Modal } from '../../components/modal'
import { InfoField } from '../../components/infoField'
import { KWLCashoutRequestsViewModel } from '../../viewModels/kwlCashout/cashoutViewModel'
import { KWlCashoutDetailsViewModel } from '../../viewModels/kwlCashout/kwlCashoutDetailsViewModel'
import * as fmt from '../../utils/formatting'
import { FixedValidationMessage } from '../../components/fixedValidationMessage'
import { IndicatorButton } from '../../components/indicatorButton'
import { Indicator } from '../../components/indicator'
import { Kuva } from '../../interfaces/kuvaweb'
import { AuthenticationManager } from '../../managers/authenticationManager'
import { Icon } from '../../components/icons'
import { DigitalInput } from '../../components/digitalInput'

const CashoutStatus = Kuva.Module.Enums.CashOutBookingStatus

const KwlDetailsDialog: React.FC<{
  listViewModel: KWLCashoutRequestsViewModel
  viewModel: KWlCashoutDetailsViewModel
}> = observer(props => {
  const { viewModel, listViewModel } = props
  const cashout = viewModel?.request
  const [checked, setChecked] = useState(cashout?.isBooked)
  const [showPopup, setShowPopup] = useState(false)
  const [completeMode, setCompleteMode] = useState<'accept' | 'cancel'>()
  const agent = AuthenticationManager.instance.agent

  useEffect(() => {
    setChecked(cashout?.isBooked)
  }, [cashout?.isBooked])

  const onCheck = () => {
    setChecked(true)
  }

  const onClosePopup = () => {
    setShowPopup(false)
  }

  const onCancel = () => {
    setShowPopup(true)
    setCompleteMode('cancel')
  }

  const onAccept = () => {
    viewModel.clearErrors()
    setShowPopup(true)
    setCompleteMode('accept')
  }

  const Sure = () => {
    viewModel.complete(viewModel.enteredRefnum, listViewModel, setShowPopup)
  }

  return (
    <Modal size="lg" className="dialogue-cash dialogue-cash-out">
      <>
        <div className="modal-header">
          <h5>{t('kwlCashout.detailsTitle')}</h5>
          <button
            type="button"
            onClick={() => viewModel.cancel()}
            className="btn-close"
            aria-label="Close"
          />
        </div>
        <div className="modal-body">
          {cashout != null ? (
            <div className="body-container">
              <div className="section-identity">
                <InfoField label={t('kwlCashout.createdDate')}>
                  {fmt.dateTimeShort(cashout?.createdDate)}
                </InfoField>
                {agent.canViewKWLPhoneNumbers && (
                  <>
                    <InfoField label={t('kwlCashout.senderPhone')}>
                      {fmt.phoneNumber(cashout?.senderPhoneNumber)}
                    </InfoField>
                    <InfoField label={t('kwlCashout.recipPhone')}>
                      {fmt.phoneNumber(cashout?.recipient?.phoneNumber)}
                    </InfoField>
                  </>
                )}
                {agent.canViewRecipientInfo && (
                  <>
                    <InfoField label={t('kwlCashout.recipientName')}>
                      {cashout?.recipient?.firstName + ' ' + cashout?.recipient?.lastName}
                    </InfoField>
                    <InfoField label={t('kwlCashout.recipientDoB')}>
                      {fmt.dateShort(cashout?.recipient?.dateOfBirth) === '1/1/1'
                        ? '-'
                        : fmt.dateShort(cashout?.recipient?.dateOfBirth)}
                    </InfoField>
                  </>
                )}
                {fmt.getRecipientAddress(cashout?.recipient?.address) && (
                  <InfoField label={t('kwlCashout.recipientAddress')}>
                    {fmt.getRecipientAddress(cashout?.recipient?.address)}
                  </InfoField>
                )}
                <InfoField label={t('kwlCashout.recipientId')}>
                  {cashout?.recipient?.documentNumber || '-'}
                </InfoField>

                {cashout?.status === CashoutStatus.completed && (
                  <>
                    {cashout?.recipient?.tellerId && (
                      <InfoField label={t('cashout.tellerId')}>
                        {cashout?.recipient?.tellerId}
                      </InfoField>
                    )}
                    {cashout?.recipient?.branchId && (
                      <InfoField label={t('cashout.branchId')}>
                        {cashout?.recipient?.branchId}
                      </InfoField>
                    )}
                  </>
                )}

                <div className="checkbox-field">
                  <div className="custom-checkbox" onClick={onCheck}>
                    {checked ? <Icon.Checked /> : !agent.kwlCashOutViewerOnly && <Icon.Uncheked />}
                  </div>
                  {((agent.kwlCashOutViewerOnly && checked) || !agent.kwlCashOutViewerOnly) && (
                    <label htmlFor="idChecked">{t('kwlCashout.idCheck')}</label>
                  )}
                </div>
                {checked && cashout?.isBooked === false && !agent.kwlCashOutViewerOnly ? (
                  <div className="button-form justifyCenter mb-10">
                    <IndicatorButton
                      loading={viewModel.loading}
                      className="btn-default btn-green"
                      onClick={() => viewModel.book(viewModel.request.id, listViewModel)}
                    >
                      {t('kwlCashout.bookButton')}
                    </IndicatorButton>
                  </div>
                ) : (
                  cashout?.isBooked && (
                    <div
                      className="button-form justifyCenter cursor-default"
                      title="this cashout assigned to you"
                    >
                      <p>{t('kwlCashout.booked.true')}</p>
                    </div>
                  )
                )}
              </div>
              <div className="section-payment">
                <div className="payment-reference">
                  <div className="label">{t('kwlCashout.paymentRefLabel')}</div>
                  {cashout.status === CashoutStatus.active ||
                  cashout.status === CashoutStatus.invalid ? (
                    <div className="reference">{fmt.digNum(cashout?.digitalReference)}</div>
                  ) : (
                    <div className="reference">{cashout?.digitalReference}</div>
                  )}
                </div>
                {cashout.isBooked && viewModel?.canSubmit && !agent.kwlCashOutViewerOnly ? (
                  <div className="payment-actions">
                    <div className="reference-number-wrapper">
                      <DigitalInput
                        type="text"
                        value={viewModel.enteredRefnum}
                        onChange={e => viewModel.changeReference(e.target.value)}
                      />
                      <FixedValidationMessage message={viewModel.validationMessage} />
                      {viewModel.error !== undefined ? (
                        <div className="error">{viewModel.error}</div>
                      ) : (
                        false
                      )}
                    </div>
                    <div className="row">
                      <div className="col-6">
                        <IndicatorButton
                          onClick={() => viewModel.cancel()}
                          className="btn-default btn-red "
                        >
                          {t('kwlCashout.cancelButton')}
                        </IndicatorButton>
                      </div>
                      <div className="col-6">
                        <IndicatorButton onClick={onAccept} className="btn-default btn-green">
                          {t('requestAcceptButton')}
                        </IndicatorButton>
                      </div>
                    </div>
                  </div>
                ) : (
                  cashout.status === CashoutStatus.completed && (
                    <div>
                      <div className="success text-center mb-10">
                        {t('kwlCashout.completedCashoutLabel')}
                      </div>
                      <InfoField label={t('kwlCashout.redemDate')}>
                        {fmt.dateTimeShort(cashout?.redeemedDate)}
                      </InfoField>
                    </div>
                  )
                )}
                <div className="payment-info">
                  <InfoField label={t('kwlCashout.transactionDateLabel')}>
                    {fmt.dateTimeShort(cashout?.createdDate)}
                  </InfoField>
                  <InfoField label={t('kwlCashout.amountSentLabel')}>
                    {fmt.currency(cashout?.amount, 'USD')}
                  </InfoField>
                </div>
              </div>
            </div>
          ) : (
            <div className="indicator-wrapper">
              <Indicator />
            </div>
          )}
        </div>
        {showPopup && (
          <Modal className="complete-cash-out">
            <div className="modal-header">
              <h4>
                {' '}
                Are you sure you want to {completeMode === 'accept' ? 'complete' : 'cancel'} this
                cashout?
              </h4>
              <button type="button" onClick={onClosePopup} className="btn-close" />
            </div>
            <div className="modal-body">
              <div className="button-form justifyCenter">
                <IndicatorButton
                  className="btn-default btn-green"
                  loading={viewModel.loading}
                  onClick={Sure}
                >
                  {t('kwlCashout.sureButton')}
                </IndicatorButton>
                <button className="btn-default btn-red" onClick={onClosePopup}>
                  {t('kwlCashout.cancelButton')}
                </button>
              </div>
            </div>
          </Modal>
        )}
      </>
    </Modal>
  )
})
export default KwlDetailsDialog
