import { action, observable, runInAction } from "mobx";
import { ChangeMerchantApiClient } from "../../api/agent/changeMerchantApiClient";

export class ChangeMerchantProcessViewModel {
  private api = new ChangeMerchantApiClient();

  @observable loading: boolean = false;
  @observable error: string = "";

  @action async createTransaction(purchase: number, received: number, change: number, fullPhoneNumber: string, receipt: string, idCheck: boolean, termsAgree: boolean) {
    try {
      return await this.api.createTransaction({ changeAmount: change, idConfirmed: idCheck, phone: fullPhoneNumber, purchaseAmount: purchase, receipt: receipt, terms: termsAgree, receivedAmount: received })
    } catch (error) {
      throw error
    }
  }


  @action async checkCustomerName(phone: string) {
    this.loading = true;
    try {
      return await this.api.customerNameCheck(phone);
    }
    catch (error) { throw error }
  }
}