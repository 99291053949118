import React, { useEffect, useMemo, useState } from 'react'
import { observer } from 'mobx-react'
import { Kuva } from '../../../interfaces/kuvaweb'
import { Modal } from '../../../components/modal'
import { InfoField } from '../../../components/infoField'
import { t } from 'i18next'
import { FixedValidationMessage } from '../../../components/fixedValidationMessage'
import { IndicatorButton } from '../../../components/indicatorButton'
import { Indicator } from '../../../components/indicator'
import { AuthenticationManager } from '../../../managers/authenticationManager'
import { Icon } from '../../../components/icons'
import { DigitalInput } from '../../../components/digitalInput'
import { MultiCashoutsViewModel } from '../../../viewModels/multicash/cashouts/multicashCashoutsViewModel'
import { MultiCashoutDetailsViewModel } from '../../../viewModels/multicash/cashouts/multicashDetailsViewModel'
import * as fmt from '../../../utils/formatting'
import { ReferenceNumberInput } from '../../../components/referenceNumberInput'

const CashoutStatus = Kuva.Module.Enums.CashOutBookingStatus

const MultiCashoutDetailsDialog: React.FC<{
  listViewModel: MultiCashoutsViewModel
  detailsViewModel: MultiCashoutDetailsViewModel
}> = observer(props => {
  const { detailsViewModel, listViewModel } = props
  const cashout = detailsViewModel?.request.data
  const [checked, setChecked] = useState(cashout?.isBooked)
  const [showPopup, setShowPopup] = useState(false)
  const [completeMode, setCompleteMode] = useState<'accept' | 'cancel'>()
  const agent = AuthenticationManager.instance.agent

  useEffect(() => {
    setChecked(cashout?.isBooked)
  }, [cashout?.isBooked])

  const onCheck = () => {
    setChecked(true)
  }

  const onClosePopup = () => {
    setShowPopup(false)
  }

  const onCancel = () => {
    setShowPopup(true)
    setCompleteMode('cancel')
  }

  const onAccept = () => {
    detailsViewModel.clearErrors()
    setShowPopup(true)
    setCompleteMode('accept')
  }

  const Sure = () => {
    detailsViewModel.complete(detailsViewModel.enteredRefnum, setShowPopup)
  }

  return (
    <Modal size="lg" className="dialogue-cash dialogue-cash-out">
      <>
        <div className="modal-header">
          <h5>{t('kwlCashout.detailsTitle')}</h5>
          <button
            type="button"
            onClick={() => detailsViewModel.cancel()}
            className="btn-close"
            aria-label="Close"
          />
        </div>
        <div className="modal-body">
          {cashout != null ? (
            <div className="body-container">
              <div className="section-identity">
                <InfoField label={t('kwlCashout.createdDate')}>
                  {fmt.dateTimeShort(cashout?.createdDate)}
                </InfoField>
                {agent.canViewKWLPhoneNumbers && (
                  <>
                    <InfoField label={t('kwlCashout.senderPhone')}>
                      {fmt.phoneNumber(cashout?.senderPhoneNumber)}
                    </InfoField>
                    <InfoField label={t('kwlCashout.recipPhone')}>
                      {fmt.phoneNumber(cashout?.recipient?.phoneNumber)}
                    </InfoField>
                  </>
                )}
                {agent.canViewRecipientInfo && (
                  <>
                    <InfoField label={t('kwlCashout.recipientName')}>
                      {cashout?.recipient?.firstName + ' ' + cashout?.recipient?.lastName}
                    </InfoField>
                    <InfoField label={t('kwlCashout.recipientDoB')}>
                      {fmt.dateShort(cashout?.recipient?.dateOfBirth) === '1/1/1'
                        ? '-'
                        : fmt.dateShort(cashout?.recipient?.dateOfBirth)}
                    </InfoField>
                  </>
                )}
                <InfoField label={t('kwlCashout.recipientId')}>
                  {cashout?.recipient?.documentNumber || '-'}
                </InfoField>
                <div className="checkbox-field">
                  <div className="custom-checkbox" onClick={onCheck}>
                    {checked ? <Icon.Checked /> : <Icon.Uncheked />}
                  </div>
                  <label htmlFor="idChecked">{t('kwlCashout.idCheck')}</label>
                </div>
                <>
                  {checked && cashout?.isBooked === false ? (
                    <div className="button-form justifyCenter mb-10">
                      <IndicatorButton
                        loading={detailsViewModel.loading}
                        className="btn-default btn-green"
                        onClick={() =>
                          detailsViewModel.book(detailsViewModel.request.data.id, listViewModel)
                        }
                      >
                        {t('kwlCashout.bookButton')}
                      </IndicatorButton>
                    </div>
                  ) : (
                    cashout?.isBooked && (
                      <div
                        className="button-form justifyCenter cursor-default"
                        title="this cashout assigned to you"
                      >
                        <p>{t('kwlCashout.booked.true')}</p>
                      </div>
                    )
                  )}
                  <div>
                    {detailsViewModel.bookError?.length > 0 && (
                      <span className="error">{detailsViewModel.bookError}</span>
                    )}
                  </div>
                </>
              </div>
              <div className="section-payment">
                <div className="payment-reference">
                  <div className="label">{t('kwlCashout.paymentRefLabel')}</div>
                  {cashout.status === CashoutStatus.active ||
                  cashout.status === CashoutStatus.invalid ? (
                    <div className="reference">{fmt.refNum(cashout?.referenceNumber)}</div>
                  ) : (
                    <div className="reference">{cashout?.referenceNumber}</div>
                  )}
                </div>
                {cashout.isBooked && detailsViewModel?.canSubmit ? (
                  <div className="payment-actions">
                    <div className="reference-number-wrapper">
                      <ReferenceNumberInput
                        type="text"
                        value={detailsViewModel.enteredRefnum}
                        onChange={e => detailsViewModel.changeReference(e.target.value)}
                      />
                      <FixedValidationMessage message={detailsViewModel.validationMessage} />
                      {detailsViewModel.error !== undefined ? (
                        <div className="error">{detailsViewModel.error}</div>
                      ) : (
                        false
                      )}
                    </div>
                    <div className="row">
                      <div className="col-6">
                        <IndicatorButton
                          onClick={() => detailsViewModel.cancel()}
                          className="btn-default btn-red "
                        >
                          {t('kwlCashout.cancelButton')}
                        </IndicatorButton>
                      </div>
                      <div className="col-6">
                        <IndicatorButton onClick={onAccept} className="btn-default btn-green">
                          {t('requestAcceptButton')}
                        </IndicatorButton>
                      </div>
                    </div>
                  </div>
                ) : (
                  cashout.status === CashoutStatus.completed && (
                    <div>
                      <div className="success text-center mb-10">
                        {t('kwlCashout.completedCashoutLabel')}
                      </div>
                      <InfoField label={t('kwlCashout.redemDate')}>
                        {fmt.dateTimeShort(cashout?.redeemedDate)}
                      </InfoField>
                    </div>
                  )
                )}
                <div className="payment-info">
                  <InfoField label={t('kwlCashout.transactionDateLabel')}>
                    {fmt.dateTimeShort(cashout?.createdDate)}
                  </InfoField>
                  <InfoField label={t('kwlCashout.amountSentLabel')}>
                    {fmt.currency(cashout?.amount, 'USD')}
                  </InfoField>
                </div>
              </div>
            </div>
          ) : (
            <div className="indicator-wrapper">
              <Indicator />
            </div>
          )}
        </div>
        {showPopup && (
          <Modal className="complete-cash-out">
            <div className="modal-header">
              <h4>
                {' '}
                Are you sure you want to {completeMode === 'accept' ? 'complete' : 'cancel'} this
                cashout?
              </h4>
              <button type="button" onClick={onClosePopup} className="btn-close" />
            </div>
            <div className="modal-body">
              <div className="button-form justifyCenter">
                <IndicatorButton
                  className="btn-default btn-green"
                  loading={detailsViewModel.loading}
                  onClick={Sure}
                >
                  {t('kwlCashout.sureButton')}
                </IndicatorButton>
                <button className="btn-default btn-red" onClick={onClosePopup}>
                  {t('kwlCashout.cancelButton')}
                </button>
              </div>
            </div>
          </Modal>
        )}
      </>
    </Modal>
  )
})
export default MultiCashoutDetailsDialog
