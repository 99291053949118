import { t } from "i18next"
import { observer } from "mobx-react"
import React, { useState } from "react"
import { Button, Card, Modal } from "react-bootstrap"
import { Icon } from "../../components/icons"
import { Kuva } from "../../interfaces/kuvaweb"
import { lang } from "../../utils/lang"
import * as ftm from "../../utils/formatting"
import BundleAgentOrderDetailsViewModel from "../../viewModels/cashrail/bundleAgentOrderDetailsViewModel"
import ReactTooltip from "react-tooltip"
import { InfoCopyField } from "../../utils/infoCopyField"

type BankDetails = Kuva.PL.KuvaWeb.Areas.BundleAgent.Models.BankDetails;
type Order = Kuva.PL.KuvaWeb.Areas.BundleAgent.Models.BundleOrderModel;
const PaidStatus = Kuva.Module.Enums.BundleOrderPaymentStatus;

interface BundleCashrailDialogueProps {
    onHide: () => void;
    show: boolean;
}

const BundleCashrailDetailsDialogue: React.FC<BundleCashrailDialogueProps & { bankDetails: BankDetails, order: Order, viewModel: BundleAgentOrderDetailsViewModel, onClick: () => {} }> = observer((props) => {
    const { bankDetails, order, onClick, viewModel, ...rest } = props
    const [show, setShow] = useState(false);

    const onSureSave = () => {
        setShow(false);
        viewModel.setOrderAsPaid(order.id)
    }

    const onSavePaymentStatus = () => {
        setShow(!show);
    }

    return (
        <div className="container">
            <Modal
                className="modal"
                {...rest}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered>
                <Modal.Header
                    className="modal-header">
                    <Modal.Title
                        className="modal-title">
                        <div
                            className="title-field">
                            {t("cashrail.modal.title")}
                        </div>
                        <div
                            className="total-field">
                            {t("cashrail.modal.total")}
                            <span>{ftm.currencySymbol(order?.srcCurrency)} {order?.srcAmountIncludeFee.toFixed(2)}</span>
                        </div>
                        <div
                            className="status-field">
                            {t("cashrail.modal.status")}
                            <span
                                className={order?.paymentStatus === 10 ? "error" : "success"}>
                                {lang.cashrailDropdownPaidStatus(order?.paymentStatus)}
                            </span>
                        </div>
                    </Modal.Title>
                    <button
                        className="btn-default btn-primary"
                        hidden={order?.paymentStatus == 20}
                        onClick={() => onSavePaymentStatus()}>
                        {t("cashrail.button.markAsPaid")}
                    </button>
                </Modal.Header>
                <Modal.Body>
                    <Card>
                        <Card.Body>
                            <div className="bank-details">
                                <div className="info-field">
                                    <h4 className="label">{t("cashrail.card.bankName")}</h4>
                                    <h5 className="info">{bankDetails?.name}</h5>
                                </div>
                                <div className="info-field">
                                    <h4 className="label">{t("cashrail.card.brncCode")}</h4>
                                    <h5 className="info">{bankDetails?.branchNumber}</h5>
                                </div>
                                <div className="info-field">
                                    <h4 className="label">{t("cashrail.card.sCode")}</h4>
                                    <InfoCopyField field={bankDetails?.sortCode} dataFor="copySortCode" />
                                </div>
                                <div className="info-field">
                                    <h4 className="label">{t("cashrail.card.accNumber")}</h4>
                                    <InfoCopyField field={bankDetails?.number} dataFor="copyNumber" />
                                </div>
                                <div className="info-field">
                                    <h4 className="label">{t("cashrail.card.reference")}</h4>
                                    <InfoCopyField field={order?.reference} dataFor="copyReference" />
                                </div>
                            </div>
                        </Card.Body>
                    </Card>
                </Modal.Body>
                <Modal.Footer>
                    <span className="text-muted">
                        {t("cashrail.card.important")}
                    </span>
                    <div
                        className="close-block">
                        <button
                            className="btn-default btn-primary"
                            onClick={onClick}>
                            {t("cashrail.button.close")}
                        </button>
                    </div>
                </Modal.Footer>
            </Modal>
            <Modal
                className="sure-modal"
                show={show}
                onHide={() => onSavePaymentStatus()}
                backdrop="static">
                <Modal.Header
                    style={{ fontSize: 20 }}>
                    {t("cashrail.placed.alert")}
                </Modal.Header>
                <Modal.Footer
                    style={{
                        border: 0,
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'space-around'
                    }}>
                    <Button
                        className="mr-2"
                        style={{ margin: 5 }}
                        size="lg"
                        variant="primary"
                        onClick={() => onSureSave()}>
                        {t("cashrail.button.sure")}
                    </Button>
                    <Button
                        className="mr-2"
                        variant="primary"
                        size="lg"
                        style={{ margin: 5 }}
                        onClick={() => setShow(false)}>
                        {t("cashrail.button.close")}
                    </Button>
                </Modal.Footer>
            </Modal>
        </div >
    )
})
export default BundleCashrailDetailsDialogue;