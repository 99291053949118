﻿import { action, makeObservable, observable, runInAction } from "mobx";
import { ApiError } from "../api/apiError";
import { AuthApiClient } from "../api/authApiClient";
import { Kuva } from "../interfaces/kuvaweb";
import { BankTopupsManager } from "./bankTopupsManager";
import { CashTopupsManager } from "./cashTopupsManager";
import { KwlCashoutManager } from "./kwlCashoutManager";
import { PagedCashoutsManager } from "./paged/pagedCashoutsManager";
import { PastCashTopUpsManager } from "./pastCashTopUpsManager";
import { PastKwlCashoutManager } from "./pastKwlCashoutManager";
import LoginResult = Kuva.BL.Interfaces.Services.LoginResult;
type LoginResponseModel = Kuva.PL.KuvaWeb.Areas.Agent.Models.Account.LoginResponseModel;
type AgentInfoModel = Kuva.PL.KuvaWeb.Areas.Agent.Models.Account.AgentInfoModel;
type LoginInfoModel = Kuva.PL.KuvaWeb.Areas.Agent.Models.Account.LoginInfoModel;
type CustomResponse = Kuva.PL.KuvaWeb.API.CustomResponse;

export class AuthenticationManager {
  private static _instance: AuthenticationManager;
  bankTopUpsManager = BankTopupsManager.instance;
  kwlCashoutsManager = KwlCashoutManager.instance;
  pastKwlCashoutsManager = PastKwlCashoutManager.instance;
  cashoutsManager = PagedCashoutsManager.instance;
  cashTopUpsManager = CashTopupsManager.instance;
  pastTopUpsManager = PastCashTopUpsManager.instance;
  static get instance() { return this._instance ??= new AuthenticationManager(); }

  apiClient = new AuthApiClient();

  @observable authenticated: boolean = null;
  @observable ready: boolean = false;
  @observable readyError: boolean = false;

  @observable agent: AgentInfoModel = null;

  @observable roles: string[] = [];

  @observable error: string = null;
  @observable recoveryError: string = null;
  @observable showOk: boolean = false;

  constructor() {
    makeObservable(this);

    this.checkLogin();
  }

  async checkLogin() {
    runInAction(() => {
      this.readyError = false;
      this.ready = false;
    });


    try {
      const currentLogin = await this.apiClient.getCurrentLogin();
      this.setAuthenticated(currentLogin, true);
    } catch (error) {
      console.error("Error on checkLogin", error);
      if (error.title === "Unauthorized") {
        this.setUnauthenticated(true);
      } else {
        this.readyError = true;
        this.setUnauthenticated(false);
      }
    }

  }

  async logout() {
    await this.apiClient.logout();
    this.cashoutsManager = null;
    this.pastKwlCashoutsManager = null;
    this.kwlCashoutsManager = null;
    this.bankTopUpsManager = null;
    this.cashTopUpsManager = null;
    this.pastTopUpsManager = null;
    this.setUnauthenticated(true);
  }

  async login(email: string, password: string, twoFactorCode: string): Promise<LoginResponseModel> {
    const loginResult = await this.apiClient.login({
      email: email,
      password: password,
      twoFactorCode: twoFactorCode
    });

    if ((loginResult as LoginResponseModel).result !== null) {
      var loginRes = loginResult as LoginResponseModel;
      switch (loginRes.result) {
        case LoginResult.success:
          this.setAuthenticated(loginRes, true);
          break;
        default:
          this.setUnauthenticated(true);
          break;
      }

      return loginRes;
    }
  }

  async forgotPassword(email: string) {
    try {
      await this.apiClient.forgot(email);
      runInAction(() => {
        this.showOk = true;
      })
    } catch (error) {
      runInAction(() => {
        switch (error) {
          case "Not Found":
            this.error = "accNotFound";
            break;
          case "Forbidden":
            this.error = "accBlocked";
            break;
          case "Bad Request":
            this.error = "badEmail";
            break;
          default:
            break;
        }
      })
    }
  }

  async recoveryPassword(key: string, password: string) {
    runInAction(() => {
      this.readyError = false;
      this.recoveryError = null;
    })
    try {
      await this.apiClient.recoveryPassword({ key: key, password: password });
      this.showOk = true;
    } catch (error) {
      runInAction(() => {
        switch (error) {
          case "Forbidden":
            this.recoveryError = "expired";
            break;
          case "Bad Request":
            this.recoveryError = "somWrong";
            break;
          default:
            this.readyError = true;
            return;
        }
      })
    }
  }

  @action
  setAuthenticated(result: { roles: string[], agent: AgentInfoModel }, ready: boolean) {
    this.authenticated = true;
    this.agent = result.agent;
    this.roles = result.roles;
    this.ready = ready;
  }

  @action
  setUnauthenticated(ready: boolean) {
    this.authenticated = false;
    this.agent = null;
    this.roles = [];
    this.ready = ready;
  }
}