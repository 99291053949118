﻿import * as signalR from "@microsoft/signalr";

import { TopUpCashApiClient } from "../api/agent/topUpCashApiClient";
import { Kuva } from "../interfaces/kuvaweb";
import * as url from "../utils/url";

import { CashRequestsManager } from "./cashRequestsManager";

import TopUpStatus = Kuva.Module.Enums.TopUpStatus;
import AgentTopUpRequestModel = Kuva.PL.KuvaWeb.Areas.Agent.Models.AgentTopUpRequestModel;
import AddFundsType = Kuva.Module.Enums.AddFundsType;

export class CashTopupsManager extends CashRequestsManager<AgentTopUpRequestModel> {
    private static _instance: CashTopupsManager;
    static get instance() {
        return this._instance = new CashTopupsManager();
    }

    private api = new TopUpCashApiClient();

    constructor() {
        super();
        this.load();
    }

    protected async getRequests() {
        const result = await this.api.getAgentBookedTopups();
        if (typeof result !== "string") {
            return result
        }
        else {
            alert(result);
        }
    }

    protected async connect(): Promise<void> {
        const hubConnection = new signalR.HubConnectionBuilder()
            .withUrl(url.combine(window.configData.apiUrl, "api/v1/agents/events"))
            .withAutomaticReconnect()
            .build();
        hubConnection.on("TopupUpdated", (e) => {
            this.handleRequestUpdated(e);
        });
        await hubConnection.start();
    }

    protected id(request: AgentTopUpRequestModel): string {
        return request.id;
    }

    protected filter(request: AgentTopUpRequestModel): boolean {
        return (request.status == TopUpStatus.ordered || request.status == TopUpStatus.transferred) && request.type == AddFundsType.cash;
    }
}