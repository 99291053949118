import React, { useState } from 'react'
import { IndicatorButton } from '../../components/indicatorButton'
import { Modal } from '../../components/modal'
import { TransitionPanel } from '../../components/transitionPanel'
import { KWLCashOutProcessViewModel } from '../../viewModels/kwlCashout/kwlCashOutProcessViewModel'
import * as fmt from '../../utils/formatting'
import { t } from 'i18next'
import { lang } from '../../utils/lang'
import { AuthenticationManager } from '../../managers/authenticationManager'
import { Icon } from '../../components/icons'
import { DigitalInput } from '../../components/digitalInput'
import { FixedValidationMessage } from '../../components/fixedValidationMessage'
import { InfoField } from '../../components/infoField'
import { Kuva } from '../../interfaces/kuvaweb'
import { observer } from 'mobx-react'

const CashoutStatus = Kuva.Module.Enums.CashOutBookingStatus

const KWLCashOutProcessDialog: React.FC<{ viewModel: KWLCashOutProcessViewModel }> = observer(
  props => {
    const viewModel = props.viewModel
    const completed = ''
    const instance = AuthenticationManager.instance
    const [checked, setChecked] = useState(viewModel.request?.isBooked)
    const [showPopup, setShowPopup] = useState(false)

    const onCheck = () => {
      setChecked(true)
    }
    const onClosePopup = () => {
      setShowPopup(false)
    }

    const onAccept = () => {
      viewModel.clearErrors()
      setShowPopup(true)
    }

    const Sure = () => {
      viewModel.complete(viewModel.digitalNumber, setShowPopup)
    }

    return (
      <Modal className={'process-modal'} size={completed ? 'sm' : 'md'}>
        <div className="modal-header">
          <h5 className="modal-title">To cashout</h5>
          <button
            type="button"
            onClick={() => viewModel.cancel()}
            className="btn-close"
            aria-label="Close"
          />
        </div>
        <div className="modal-body">
          <TransitionPanel page={completed ? 'completed' : 'prompt'}>
            <div key={'completed'}>
              <div className={'result'}>
                {viewModel.result == 'accepted' ? <p>{t('cashout.completedMessage')}</p> : null}
                {viewModel.result == 'cancelled' ? <p>{t('cashout.cancelledMessage')}</p> : null}
                {viewModel.result == 'failed' ? <p>{t('cashout.failedMessage')}</p> : null}{' '}
                {/*TODO: Message?*/}
              </div>
              <div className="actions">
                <div>
                  <IndicatorButton
                    className="btn-default btn-primary"
                    onCommand={() => viewModel.okay()}
                  >
                    {t('okayButton')}
                  </IndicatorButton>
                </div>
              </div>
            </div>
            <div key={'prompt'}>
              <div className={'amount'}>{fmt.currencyRich(viewModel.request.amount, 'USD')}</div>
              <div className={'section-identity'}>
                <div className="info-field">
                  <div className="label">{t('cashout.phoneNumberLabel')}</div>
                  <div className="info">
                    {fmt.phoneNumber(viewModel.request.recipient.phoneNumber)}
                  </div>
                </div>

                {instance.agent.canViewRecipientInfo ? (
                  <>
                    <div className="info-field">
                      <div className="label">{t('cashout.userNameLabel')}</div>
                      <div className="info">
                        {viewModel.request.recipient.firstName +
                          ' ' +
                          viewModel.request.recipient.lastName}
                      </div>
                    </div>
                    <div className="info-field">
                      <div className="label">{t('cashout.userDateOfBirthLabel')}</div>
                      <div className="info">
                        {fmt.dateShort(viewModel.request.recipient.dateOfBirth)}
                      </div>
                    </div>
                  </>
                ) : null}
                <div className="info-field">
                  <div className="label">{t('cashout.statusLabel')}</div>
                  <div className="info">{lang.cashoutStatus(viewModel.request.status)}</div>
                </div>
                <div className="info-field">
                  <div className="label">{t('cashout.bookedForLabel')}</div>
                  <div className="info">{fmt.dateTimeShort(viewModel.request.createdDate)}</div>
                </div>
                <div className="info-field">
                  <div className="label">{t('cashout.recipientId')}</div>
                  <div className="info">{viewModel.request.recipient.documentNumber}</div>
                </div>
                {viewModel.request.status === CashoutStatus.completed && (
                  <>
                    {viewModel.request.recipient.tellerId && (
                      <div className="info-field">
                        <div className="label">{t('cashout.tellerId')}</div>
                        <div className="info">{viewModel.request.recipient.tellerId}</div>
                      </div>
                    )}
                    {viewModel.request.recipient.branchId && (
                      <div className="info-field">
                        <div className="label">{t('cashout.branchId')}</div>
                        <div className="info">{viewModel.request.recipient.branchId}</div>
                      </div>
                    )}
                  </>
                )}

                <div className="checkbox-field">
                  <div className="custom-checkbox" onClick={onCheck}>
                    {checked ? (
                      <Icon.Checked />
                    ) : (
                      !instance.agent.kwlCashOutViewerOnly && <Icon.Uncheked />
                    )}
                  </div>
                  {((instance.agent.kwlCashOutViewerOnly && checked) ||
                    !instance.agent.kwlCashOutViewerOnly) && (
                    <label htmlFor="idChecked">{t('kwlCashout.idCheck')}</label>
                  )}
                </div>

                <div>
                  {checked &&
                  viewModel.request?.isBooked === false &&
                  !instance.agent.kwlCashOutViewerOnly ? (
                    <div className="button-form justifyCenter mb-10">
                      <IndicatorButton
                        loading={viewModel.loading}
                        className="btn-default btn-green"
                        onClick={() => viewModel.book(viewModel.request.id)}
                      >
                        {t('kwlCashout.bookButton')}
                      </IndicatorButton>
                    </div>
                  ) : (
                    viewModel.request?.isBooked && (
                      <div
                        className="button-form justifyCenter cursor-default"
                        title="this cashout assigned to you"
                      >
                        <p>{t('kwlCashout.booked.true')}</p>
                      </div>
                    )
                  )}
                </div>
                <div>
                  {viewModel.request.isBooked &&
                    viewModel.canSubmit &&
                    !instance.agent.kwlCashOutViewerOnly && (
                      <div className="payment-actions">
                        <div className="reference-number-wrapper">
                          <DigitalInput
                            type="text"
                            value={viewModel.digitalNumber}
                            onChange={e => viewModel.changeDigital.bind(viewModel)(e.target.value)}
                          />
                        </div>
                        <FixedValidationMessage message={viewModel.validationMessage} />
                        {viewModel.error !== undefined ? (
                          <div className="error text-center">{viewModel.error}</div>
                        ) : (
                          false
                        )}
                      </div>
                    )}
                </div>
                {viewModel.request.isBooked &&
                  viewModel.canSubmit &&
                  !instance.agent.kwlCashOutViewerOnly && (
                    <div className={'actions'}>
                      <div>
                        <IndicatorButton
                          className="btn-default btn-red"
                          onCommand={() => viewModel.cancel()}
                        >
                          {t('requestCancelButton')}
                        </IndicatorButton>
                      </div>
                      <div>
                        <IndicatorButton className="btn-default btn-green" onClick={onAccept}>
                          {t('requestAcceptButton')}
                        </IndicatorButton>
                      </div>
                    </div>
                  )}
                {viewModel.request.status === CashoutStatus.completed && (
                  <div>
                    <div className="success text-center mb-10">
                      {t('kwlCashout.completedCashoutLabel')}
                    </div>
                    <InfoField label={t('kwlCashout.redemDate')}>
                      {fmt.dateTimeShort(viewModel.request.redeemedDate)}
                    </InfoField>
                  </div>
                )}
              </div>
            </div>
          </TransitionPanel>
        </div>
        {showPopup && (
          <Modal>
            <div className="modal-header">
              <h4> Are you sure you want to complete this cashout?</h4>
              <button type="button" onClick={onClosePopup} className="btn-close" />
            </div>
            <div className="modal-body">
              <div className="button-form justifyCenter">
                <IndicatorButton
                  className="btn-default btn-green"
                  loading={viewModel.loading}
                  onClick={Sure}
                >
                  {t('kwlCashout.sureButton')}
                </IndicatorButton>
                <button className="btn-default btn-red" onClick={onClosePopup}>
                  {t('kwlCashout.cancelButton')}
                </button>
              </div>
            </div>
          </Modal>
        )}
      </Modal>
    )
  }
)
export default KWLCashOutProcessDialog
