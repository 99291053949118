﻿import { IReactionDisposer, observable } from "mobx";
import { Subscription } from "rxjs";

import { TopUpCashApiClient } from "../../api/agent/topUpCashApiClient";
import { Kuva } from "../../interfaces/kuvaweb";
import { CashTopupsManager } from "../../managers/cashTopupsManager";
import { PastCashRequestsManager } from "../../managers/pastCashRequestsManager";
import { PastCashTopUpsManager } from "../../managers/pastCashTopUpsManager";

import { BookedCashRequestsViewModel, CashRequestsViewModel, PastCashRequestsViewModel } from "../cashCommon";

import { CashTopupDetailsViewModel } from "./cashTopupDetailsViewModel";

type AgentTopUpRequestModel = Kuva.PL.KuvaWeb.Areas.Agent.Models.AgentTopUpRequestModel;

class BookedTopupsViewModel extends BookedCashRequestsViewModel<AgentTopUpRequestModel> {
    private manager = CashTopupsManager.instance;

    * subscribe(): Generator<Subscription | IReactionDisposer> {
        yield this.manager.bookedRequests.subscribe((topups) => this.handleRequests(topups));
        yield* super.subscribe();
    }
}

class PastTopupsViewModel extends PastCashRequestsViewModel<AgentTopUpRequestModel> {
    private manager = PastCashTopUpsManager.instance;


    *subscribe(): Generator<Subscription | IReactionDisposer> {
        yield this.manager.pastRequests.subscribe((topups) => this.handleRequests(topups));
        yield* super.subscribe();
    }
}

export class CashTopupsViewModel extends CashRequestsViewModel<AgentTopUpRequestModel> {

    constructor() {
        super();

        this.booked = new BookedTopupsViewModel();
        this.past = new PastTopupsViewModel();
        this.updateTab();
    }

    select(request: AgentTopUpRequestModel) {
        if (this.details == null) {
            this.details = new CashTopupDetailsViewModel(request.id);
            this.details.promise.then(() => {
                this.details = null;
            });
        }
    }
}