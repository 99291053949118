﻿import { t } from "i18next";
import { observer } from "mobx-react";
import React from "react";
import { FixedValidationMessage } from "../../components/fixedValidationMessage";
import { Indicator } from "../../components/indicator";
import { IndicatorButton } from "../../components/indicatorButton";
import { InfoField } from "../../components/infoField";

import { Modal } from "../../components/modal";
import PopUp from "../../components/popUp";
import { ReferenceNumberInput } from "../../components/referenceNumberInput";

import { Kuva } from "../../interfaces/kuvaweb";
import { AuthenticationManager } from "../../managers/authenticationManager";

import * as fmt from "../../utils/formatting";
import { lang } from "../../utils/lang";
import { CashoutDetailsViewModel } from "../../viewModels/cashout/cashoutDetailsViewModel";
import { CashoutsViewModel } from "../../viewModels/cashout/cashoutLazy";
import CashOutBookingStatus = Kuva.Module.Enums.CashOutBookingStatus;

const CashoutDetailsDialogue: React.FC<{ viewModel: CashoutDetailsViewModel }> = observer((props) => {
  const cashoutViewModel = props.viewModel;

  const cashout = cashoutViewModel.request.data;
  const agent = AuthenticationManager.instance.agent;

  return <>
    <Modal size={"lg"} className={"dialogue-cash dialogue-cash-out"}>
      <>
        <div className="modal-header">
          <h5 className="modal-title">{t("cashout.detailsTitle")}</h5>
          <button type="button" onClick={() => cashoutViewModel.cancel()} className="btn-close" aria-label="Close" />
        </div>
        <div className="modal-body">
          <div className={"body-container"}>
            <div className={"section-identity"}>
              <InfoField label={t("cashout.phoneNumberLabel")}>
                {cashout.phoneNumber}
              </InfoField>
              {
                cashout.isShowUserDetailsEnabled && (<InfoField label={t("cashout.userNameLabel")}>
                  {cashout.fullName}
                </InfoField>)
              }
              {
                cashout.isShowUserDetailsEnabled && (<InfoField label={t("cashout.userDateOfBirthLabel")}>
                  {fmt.dateShort(cashout.dateOfBirth)}
                </InfoField>)
              }
              <InfoField label={t("cashout.statusLabel")}>
                {lang.cashoutStatus(cashout.status)}
              </InfoField>
              <InfoField label={t("cashout.processedByAgentLabel")}>
                {cashout.processedAgentFullName != null ||
                  cashout.processedAgentEmail != null
                  ? `${cashout.processedAgentFullName} (${cashout.processedAgentEmail})`
                  : '-'}
              </InfoField>
              <InfoField label={t("cashout.bookedForLabel")}>
                {fmt.dateTimeShort(cashout.date)}
              </InfoField>
              {cashout.updatedDate != null && cashout.status !== CashOutBookingStatus.active ?
                <InfoField label={t("cashout.completedAtLabel")}>
                  {fmt.dateTimeShort(cashout.updatedDate)}
                </InfoField>
                : null
              }
              <InfoField label={t("cashout.locationLabel")}>
                {cashout.location}
              </InfoField>
            </div>
            <div className={"section-payment"}>
              <div className={"payment-reference"}>
                <div className={"label"}>{t("cashout.paymentRefLabel")}</div>
                <div className={"reference"}>{fmt.refNum(cashout.referenceNumber)}</div>
              </div>
              {cashoutViewModel.canSubmit && <div className="payment-actions">
                <div className="reference-number-wrapper">
                  <ReferenceNumberInput type="text" value={cashoutViewModel.enteredRefNum}
                    onChange={(e) => cashoutViewModel.changeReference(e.target.value)} />
                  <FixedValidationMessage message={cashoutViewModel.validationMessage} />
                </div>
                {
                  (cashoutViewModel.canSubmit && !cashoutViewModel.success) ?
                    <div className="row">
                      {cashoutViewModel.error?.length > 0 && <div className="error text-center mb-10">
                        {cashoutViewModel.error}
                      </div>}
                      <div className="col-6">
                        <IndicatorButton onClick={() => cashoutViewModel.openPopUp("cancel")} className="btn-default btn-red ">
                          {t("requestCancelButton")}
                        </IndicatorButton>
                      </div>
                      <div className="col-6">
                        <IndicatorButton onClick={() => cashoutViewModel.openPopUp("accept")} className="btn-default btn-green">
                          {t("requestAcceptButton")}
                        </IndicatorButton>
                      </div>
                    </div>
                    :
                    <div className="success text-center mb-10">
                      {t("cashout.completedLabel")}
                    </div>
                }
              </div>}
              <div className={"payment-info"}>
                <InfoField label={t("cashout.transactionDateLabel")}>
                  {fmt.dateTimeShort(cashout.createdDate)}
                </InfoField>
                <InfoField label={t("cashout.amountSentLabel")}>
                  {fmt.currency(cashout.amountUsd, 'USD')}
                </InfoField>
                {
                  agent.canViewCashoutTotalFee &&
                  < InfoField label={t("cashout.totalFeeLabel")}>
                    {fmt.currency(cashout.totalFee, 'USD')}
                  </InfoField>}
                {
                  agent.canViewCashoutAgentFee &&
                  <InfoField label={t("cashout.agentFixedFeeLabel")}>
                    {fmt.currency(cashout.agentFixedFee, 'USD')}
                  </InfoField>
                }
                {
                  agent.canViewCashoutAgentFee &&
                  <InfoField label={t("cashout.agentPercentFeeLabel")}>
                    {fmt.currency(cashout.agentFeePersents, 'USD')}
                  </InfoField>}
                {/* Do we need Kuva Direct Fee ? */}
                {/* <InfoField label={t("cashout.kuvaDirectAgentPercentFeeLabel")}>
                    {fmt.currency(cashout.kuvaDirectAgentFeePersents, 'USD')}
              </InfoField> */}
                {agent.canViewCashoutKuvaPercentFee &&
                  <InfoField label={t("cashout.kuvaPercentFeeLabel")}>
                    {fmt.currency(cashout.kuvaFeePersents, 'USD')}
                  </InfoField>
                }
              </div>
            </div>
          </div>
        </div>
      </>
    </Modal >
    <PopUp
      reqType="cashout"
      sure={cashoutViewModel.sure}
      completeMode={cashoutViewModel.completeMode}
      loading={cashoutViewModel.loading}
      onClosePopup={cashoutViewModel.onClosePopUp}
      showPopup={cashoutViewModel.showPopUp} />
  </>
})
export default CashoutDetailsDialogue;

