import { t } from "i18next";
import { observer } from "mobx-react"
import React, { useEffect, useMemo } from "react"
import { PageHeader } from "../../../components/pageHeader";
import CashrailListOrders from "../../../components/cashrail/cashrailListOrders";
import { CashrailAgentViewModel } from "../../../viewModels/cashrail/cashrailViewModel";

const AgentCashRailPage: React.FC = observer(() => {
    const viewModel = useMemo(() => new CashrailAgentViewModel(), [])

    useEffect(() => {
        viewModel.mount()

        return () => {
            viewModel.unmount()
        }
    }, [viewModel])

    return <div className="ca-main">
        <div className="ca-page-header">
            <PageHeader>
                <span>
                    {t("cashrail.ca-title")}
                </span>
            </PageHeader>
        </div>
        <div className="container">
            <CashrailListOrders viewModel={viewModel} />
        </div>
    </div>
});
export default AgentCashRailPage;