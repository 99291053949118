import { Kuva } from "../../interfaces/kuvaweb";
import { ApiClientBase } from "../apiClientBase";

type MultiCashRequestModel = Kuva.PL.KuvaWeb.Areas.Agent.Multicash.MultiCashRequestModel;
type LazyLoadedPagedCashoutsList = Kuva.Module.Models.Paging.LazyLoadingPagedListModel<MultiCashRequestModel>
type MulticashDetailsRequestModel = Kuva.PL.KuvaWeb.Areas.Agent.Multicash.MulticashDetailsRequestModel;
type CashoutFilter = Kuva.Module.Models.Paging.Agent.CashoutFilter;

export class MultiCashApiClient extends ApiClientBase {
    base = "multi-kwl/cash-outs";

    getLazyPagedBookedCashouts = (filter: CashoutFilter) => {

        return this.getNewUrl<LazyLoadedPagedCashoutsList>
            ([this.base, 'booked'],
                {
                    query: filter.query,
                    "createdDates.startDate": filter.dateRange.startDate,
                    "createdDates.endDate": filter.dateRange.endDate,
                    pageSize: filter.pageSize,
                    offSet: filter.offset,
                    cashoutFlows: 50
                })
    }
    getLazyPagedPastCashouts = (filter: CashoutFilter) => {

        return this.getNewUrl<LazyLoadedPagedCashoutsList>
            ([this.base, 'past'],
                {
                    query: filter.query,
                    "createdDates.startDate": filter.dateRange.startDate,
                    "createdDates.endDate": filter.dateRange.endDate,
                    pageSize: filter.pageSize,
                    offSet: filter.offset,
                    cashoutFlows: 50
                })
    }
    bookRequest = (cashoutId: string) => this.postNewUrl([this.base, 'book'], null, { cashoutId });
    cancel = (reference: string, topUpRequestId: string) => this.postNewUrl([this.base, 'cancel'], { referenceNumber: reference, bookingId: topUpRequestId })
    complete = (reference: string, requestId: string) => this.postNewUrl<{}, MulticashDetailsRequestModel>([this.base, 'complete'], { referenceNumber: reference, bookingId: requestId })
    getById = (topUpRequestId: string) => this.getNewUrl<MulticashDetailsRequestModel>([this.base, topUpRequestId, 'details'])
}