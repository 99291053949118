import { CashOutApiClient } from "../../api/agent/cashOutApiClient";
import { Kuva, KuvaWeb } from "../../interfaces/kuvaweb";
import { LazyLoadingPagedCashRequestsManager } from "../generic/pagedCashRequestsManager";
import * as signalR from "@microsoft/signalr";
import * as url from "../../utils/url";
import { BehaviorSubject } from "rxjs";

import CashOutBookingStatus = Kuva.Module.Enums.CashOutBookingStatus;
const CountType = Kuva.Module.Enums.RecordCountType;


type CashoutAgentResponseModel = Kuva.PL.KuvaWeb.Areas.Agent.Models.AgentCashoutRequestModel;
type LazyLoadedPagedCashoutsList = Kuva.Module.Models.Paging.LazyLoadingPagedListModel<CashoutAgentResponseModel>

export class PagedPastCashoutsManager {
    private static _instance: PagedPastCashoutsManager;
    static get instance() {
        return this._instance = new PagedPastCashoutsManager();
    }

    private pastRequestsSubject = new BehaviorSubject<LazyLoadedPagedCashoutsList>(null);
    public get pastRequests() { return this.pastRequestsSubject.asObservable(); }

    private pastRequestsCountSubject = new BehaviorSubject<number>(null);
    public get pastRequestsCount() { return this.pastRequestsCountSubject.asObservable(); }

    private _pastRequests: LazyLoadedPagedCashoutsList;
    private _pastRequestsCount: number;

    private api = new CashOutApiClient();

    constructor() {
        this.connect()

        this.load();
    }

    private async getRequests(filter: Kuva.Module.Models.Paging.Agent.CashoutFilter): Promise<Kuva.Module.Models.Paging.LazyLoadingPagedListModel<Kuva.PL.KuvaWeb.Areas.Agent.Models.AgentCashoutRequestModel>> {
        return await this.api.getLazyPagedPastCashouts(filter)
    }

    private async getRequestsCount() {
        return await this.api.getBookedCashoutsCount(CountType.booked)
    }

    private publish(): void {
        this.pastRequestsSubject.next(this._pastRequests)
        this.pastRequestsCountSubject.next(this._pastRequestsCount)
    }

    private async connect(): Promise<void> {
        const hubConnection = new signalR.HubConnectionBuilder()
            .withUrl(url.combine(window.configData.apiUrl, "api/v1/agents/events"))
            .build();
        hubConnection.on("CashoutUpdated", (e) => {
            this.handlePastRequestUpdated(e);
        });
        await hubConnection.start();
    }

    private id(request: CashoutAgentResponseModel): string {
        return request.bookingId;
    }

    private filter(requests: CashoutAgentResponseModel): boolean {
        return requests.status == CashOutBookingStatus.cancelled
            ||
            requests.status == CashOutBookingStatus.completed;
    }

    private async load(): Promise<void> {
        try {
            var response = await
                this.getRequests(
                    {
                        pageSize: 30, offset: 0,
                        query: "", dateRange:
                        {
                            startDate: "", endDate: ""
                        }
                    });
            this._pastRequests = response;

            const count = await this.getRequestsCount();
            this._pastRequestsCount = count.value;

            this.publish();
        } catch (error) {
        }
    }

    private handlePastRequestUpdated(request: CashoutAgentResponseModel): void {
        if (this._pastRequests == null)
            return;

        const currentIndex = this._pastRequests.items.findIndex(e => this.id(e) == this.id(request));
        if (currentIndex !== -1) return
        if (this.filter(request)) {
            this._pastRequests.items = [request, ...this._pastRequests.items,];
            this._pastRequests.count += 1;
            this._pastRequestsCount = this._pastRequests.count;
        }
        this.publish();
    }
}