﻿import { t } from 'i18next'

import { Kuva } from '../interfaces/kuvaweb'

export module lang {
  export function topupStatus(status: Kuva.Module.Enums.TopUpStatus): string {
    return Kuva.Module.Enums.TopUpStatus[status] || t('enum.topupStatus.unknown')
  }

  export function cashoutStatus(status: Kuva.Module.Enums.CashOutBookingStatus) {
    return (
      t(`enum.cashoutStatus.${Kuva.Module.Enums.CashOutBookingStatus[status]}`) ||
      t('enum.cashoutStatus.unknown')
    )
  }

  export function addFundsType(requestType: Kuva.Module.Enums.AddFundsType) {
    return Kuva.Module.Enums.AddFundsType[requestType] || t('enum.addFundsType.unknown')
  }

  export function settlementStatus(status: Kuva.Module.Enums.AgentSettlemenManifestStatus) {
    return (
      t(`enum.settlementStatus.${Kuva.Module.Enums.AgentSettlemenManifestStatus[status]}`) ||
      t('enum.settlementStatus.unknown')
    )
  }
  export function cashOutBundleStatus(status: Kuva.Module.Enums.MultiCashOutBundleStatus) {
    return (
      t(`enum.cashOutBundleStatus.${Kuva.Module.Enums.MultiCashOutBundleStatus[status]}`) ||
      t('enum.cashOutBundleStatus.unknown')
    )
  }

  export function settlementPaymentType(paymentType: Kuva.Module.Enums.AgentSettlementPaymentType) {
    return (
      t(
        `enum.settlementPaymentType.${Kuva.Module.Enums.AgentSettlementPaymentType[paymentType]}`
      ) || t('enum.settlementPaymentType.unknown')
    )
  }

  export function settlementOrderType(orderType: Kuva.Module.Enums.OrderType): string {
    return (
      t(`enum.orderType.${Kuva.Module.Enums.OrderType[orderType]}`) || t('enum.orderType.unknown')
    )
  }

  export function settlementDirection(direction: Kuva.Module.Enums.AgentSettlementDirection) {
    return (
      t(`enum.settlementDirection.${Kuva.Module.Enums.AgentSettlementDirection[direction]}`) ||
      t('enum.settlementDirection.unknown')
    )
  }

  export function cashOnDeliveryPaymentMethod(paymentMethod: Kuva.Module.Enums.PaymentMethod) {
    return (
      t(`enum.cashOnDeliveryPaymentMethod.${Kuva.Module.Enums.PaymentMethod[paymentMethod]}`) ||
      t('enum.cashOnDeliveryPaymentMethod.unknown')
    )
  }

  export function cashOnDeliveryStatus(orderStatus: Kuva.Module.Enums.OrderStatus) {
    return (
      t(`enum.cashOnDeliveryOrderStatus.${Kuva.Module.Enums.OrderStatus[orderStatus]}`) ||
      t('enum.cashOnDeliveryOrderStatus.unknown')
    )
  }

  export function dmShippingType(shippingType: Kuva.Module.Enums.ShippingType) {
    return (
      t(`enum.dmShippingType.${Kuva.Module.Enums.ShippingType[shippingType]}`) ||
      t('enum.dmShippingType.unknown')
    )
  }

  export function cashOnDeliveryPay(payment: boolean) {
    return t(`enum.cashOnDeliveryPay.${payment}`)
  }

  export function cashrailOrderStatus(itemStatus: Kuva.Module.Enums.BundleOrderStatus) {
    return (
      t(`enum.bundleOrderStatus.${Kuva.Module.Enums.BundleOrderStatus[itemStatus]}`) ||
      t('enum.cashrailItemStatus.unknown')
    )
  }

  export function cashrailDropdownPaidStatus(
    paidStatus: Kuva.Module.Enums.BundleOrderPaymentStatus
  ) {
    return (
      t(`enum.cashrailPaidStatus.${Kuva.Module.Enums.BundleOrderPaymentStatus[paidStatus]}`) ||
      t('enum.cashrailPaidStatus.unknown')
    )
  }

  export function cashrailPaymentStatus(paidStatus: Kuva.Module.Enums.BundleOrderPaymentStatus) {
    return (
      t(`enum.paymentStatus.${Kuva.Module.Enums.BundleOrderPaymentStatus[paidStatus]}`) ||
      t('enum.cashrailPaidStatus.unknown')
    )
  }

  export function isBooked(booked: boolean) {
    return t(`kwlCashout.booked.${booked}`)
  }

  export function changeMerchantType(type: Kuva.Module.Enums.ChangeMerchantType) {
    return (
      t(`enum.changeMerchant.${Kuva.Module.Enums.ChangeMerchantType[type]}`) ||
      t('enum.changeMerchant.unknown')
    )
  }

  export function creditWalletTransactionStatus(
    status: Kuva.Module.Enums.CreditWalletTransactionStatus
  ) {
    return (
      t(`enum.creditWalletTX.${Kuva.Module.Enums.CreditWalletTransactionStatus[status]}`) ||
      t('enum.creditWalletTX.unknown')
    )
  }

  export function cashrailFlow(flowType: Kuva.Module.Enums.CashRailFlowType) {
    return t(
      `enum.cashrailflow.${Kuva.Module.Enums.CashRailFlowType[flowType]}` ||
        t('enum.cashrail.unknown')
    )
  }

  export function documentType(docType?: Kuva.Module.Enums.DocumentType) {
    return docType == undefined
      ? t('enum.unknown')
      : t(`enum.documentType.${Kuva.Module.Enums.DocumentType[docType]}`)
  }

  export function settlementFlowType(flowType: Kuva.Module.Enums.SettlementFlowType) {
    return t(`enum.settlementFlow.${Kuva.Module.Enums.SettlementFlowType[flowType]}`)
  }

  export function settlementDetailsFlowType(flowType: Kuva.Module.Enums.SettlementFlowType) {
    return t(`settlement.flowTitle.${Kuva.Module.Enums.SettlementFlowType[flowType]}`)
  }
}
