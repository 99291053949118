import { t } from 'i18next'
import { Kuva } from '../interfaces/kuvaweb'
import { IndicatorButton } from './indicatorButton'
import { Modal } from './modal'
import React from 'react'

type Props = Kuva.PL.KuvaWeb.Props.PopUpProps

const PopUp: React.FC<Props> = props => {
  const { showPopup, completeMode, loading, onClosePopup, sure, reqType } = props

  return (
    <>
      {showPopup && (
        <Modal className="confirm-modal">
          <div className="modal-header">
            <h4>
              {' '}
              Are you sure you want to {completeMode === 'accept' ? 'complete' : 'cancel'} this{' '}
              {reqType === 'cashout' ? 'cashout' : 'topUp'} ?
            </h4>
            <button type="button" onClick={onClosePopup} className="btn-close" />
          </div>
          <div className="modal-body">
            <div className="button-form justifyCenter">
              <button className="btn-default btn-red" onClick={onClosePopup}>
                {t('kwlCashout.cancelButton')}
              </button>
              <IndicatorButton
                className="btn-default btn-green"
                loading={loading}
                onClick={() => sure()}
              >
                {t('kwlCashout.sureButton')}
              </IndicatorButton>
            </div>
          </div>
        </Modal>
      )}
    </>
  )
}
export default PopUp
