import { t } from "i18next";
import { computed, makeObservable, observable } from "mobx";
import { CashOnDeliveryApiClient } from "../../api/agent/cashOnDeliveryApiClient";
import { Kuva } from "../../interfaces/kuvaweb";
import { Mutex } from "../../utils/mutex";

type AgentCashOnDeliveryDetails = Kuva.PL.KuvaWeb.Areas.Agent.Models.AgentCashOnDeliveryDetails;
const a = Kuva.Module.Enums.OrderStatus

export class CashOnDeliveryProcessViewModel {
    api = new CashOnDeliveryApiClient();

    @computed get completed(): boolean {
        return this.result != null;
    }
    @observable result: "paid" | "completed" | "pending" | "processing" | "failed" = null;

    request: AgentCashOnDeliveryDetails;
    promise: Promise<void>;

    private promiseAccept: () => void;
    private promiseReject: () => void;
    private mutex = new Mutex();

    constructor(reference: string) {
        makeObservable(this);

        this.promise = new Promise((acc, rej) => {
            this.promiseAccept = acc;
            this.promiseReject = rej;
        })
    }
    
    async cancel() {
        this.promiseAccept();
    }

    async okay() {
        this.promiseAccept();
    }
}