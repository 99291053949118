﻿import { ApiClientBase } from "../apiClientBase";
import { Kuva, KuvaWeb } from "../../interfaces/kuvaweb";

type CashoutAgentResponseModel = Kuva.PL.KuvaWeb.Areas.Agent.Models.AgentCashoutRequestModel;
type CashOutApiClientInterface = KuvaWeb.Areas.Agent.Controllers.CashOutApiClientInterface;
type CashoutRequestModel = Kuva.BL.BusinessModels.AdminDTO.CashoutRequestModel;
type RecordsCount = Kuva.Module.Models.RecordsCount;
type RecordCountType = Kuva.Module.Enums.RecordCountType;
type LazyLoadedPagedCashoutsList = Kuva.Module.Models.Paging.LazyLoadingPagedListModel<CashoutAgentResponseModel>
type CashoutFilter = Kuva.Module.Models.Paging.Agent.CashoutFilter;

export class CashOutApiClient extends ApiClientBase implements CashOutApiClientInterface {
    apiBasePath = "cash-outs"

    getLazyPagedPastCashouts = (filter: CashoutFilter) => {

        return this.getNewUrl<LazyLoadedPagedCashoutsList>([this.apiBasePath, 'past'],
            {
                query: filter.query,
                "createdDates.startDate": filter.dateRange.startDate,
                "createdDates.endDate": filter.dateRange.endDate,
                offset: filter.offset,
                pageSize: filter.pageSize
            })
    };

    getLazyPagedBookedCashouts = (filter: CashoutFilter) => {

        return this.getNewUrl<LazyLoadedPagedCashoutsList>([this.apiBasePath, 'booked'],
            {
                query: filter.query,
                "createdDates.startDate": filter.dateRange.startDate,
                "createdDates.endDate": filter.dateRange.endDate,
                offset: filter.offset,
                pageSize: filter.pageSize
            })
    };

    getCashoutRequestDetails = (bookingId: string) => this.getNewUrl<CashoutRequestModel>([this.apiBasePath, `${bookingId}`]);
    getCashoutRequestDetailsByReference = (referenceNumber: string) => this.getNewUrl<CashoutRequestModel>([this.apiBasePath, `reference/${referenceNumber}`]);
    completeCashOutRequest = (body: { bookingId: string, referenceNumber: string }) => this.putNewUrl<{}, CashoutRequestModel>([this.apiBasePath, 'complete'], body);
    cancelCashOutRequest = (body: { bookingId: string, referenceNumber: string }) => this.putNewUrl<{}, CashoutRequestModel>([this.apiBasePath, 'cancel'], body);
    getBookedCashoutsCount = (countType: RecordCountType): Promise<Kuva.Module.Models.RecordsCount> => this.getNewUrl<RecordsCount>([this.apiBasePath, "count"], { countType });
    getPastCashoutsCount = (countType: RecordCountType): Promise<Kuva.Module.Models.RecordsCount> => this.getNewUrl<RecordsCount>([this.apiBasePath, "count"], { countType });
}