﻿import {makeObservable, observable} from "mobx";
import {t} from "i18next";

import {TopUpBankApiClient} from "../../api/agent/topUpBankApiClient";
import {Kuva} from "../../interfaces/kuvaweb";

import {TopupProcessViewModel} from "../topup/topupProcessViewModel";

type TopUpRequestDTOwithConversion = Kuva.BL.BusinessModels.Api.TopUp.TopUpRequestDTOwithConversion;

export class BankTopupProcessViewModel extends TopupProcessViewModel {
    private api = new TopUpBankApiClient();
    
    @observable enteredAmount: string = "";
    
    @observable verifiedAmount: number = 0;

    @observable amountVerified: boolean = false;
    @observable amountMismatch: boolean = false;

    constructor(topupRequest: TopUpRequestDTOwithConversion) {
        super(topupRequest);
        
        makeObservable(this);
    }
    
    protected async cancelTopup(): Promise<void> {
        await this.api.cancel({topUpRequestId: this.request.id, reference: this.request.reference})
    }
    
    async verify() {
        let parsed = parseFloat(this.enteredAmount);
        if(isNaN(parsed)) {
            alert(t("topup.bank.amountInvalid"));
            return;
        }
        
        if(parsed === this.request.from.totalFromAmount) {
            this.verifiedAmount = parsed;
            this.amountVerified = true;
        } else {
            const amountDecimal = parseFloat(parsed.toFixed(2));

            const mistmach = Math.abs(1.0 - (this.request.from.totalFromAmount / amountDecimal));
            if (mistmach > this.request.amountThreshold) {
                alert(t("topup.bank.amountThresholdInvalid"));
                return;
            } else {
                this.verifiedAmount = parsed;
                this.amountMismatch = true;
                this.amountVerified = true;
            }
        }
    }

    protected async completeTopup(): Promise<void> {
        await this.api.complete({topUpRequestId: this.request.id, reference: this.request.reference, enteredAmount: this.verifiedAmount})
    }
}