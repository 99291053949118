﻿import { action, makeObservable, observable, runInAction } from "mobx";
import { t } from "i18next";

import { TopUpCashApiClient } from "../../api/agent/topUpCashApiClient";
import { Kuva } from "../../interfaces/kuvaweb";
import * as refNum from "../../utils/refNum";

import { IPromiseViewModel } from "../promiseViewModel";

import TopUpStatus = Kuva.Module.Enums.TopUpStatus;
import { ChangeEvent } from "react";

type TopUpRequestDTOwithConversion = Kuva.BL.BusinessModels.Api.TopUp.TopUpRequestDTOwithConversion;
type CustomError = Kuva.PL.KuvaWeb.API.CustomError;

export class CashTopupDetailsViewModel implements IPromiseViewModel<void> {
  private api = new TopUpCashApiClient()

  promise: Promise<void>;

  private promiseAccept: () => void;
  private promiseReject: () => void;

  @observable canSubmit: boolean = false;
  @observable validationMessage: string = null;
  @observable loading: boolean = false;
  @observable error: string;
  @observable showPopUp = false;
  @observable completeMode: "accept" | "cancel" = "accept";
  @observable enteredRefnum: string = "";

  private readonly requestId: string;
  @observable request: TopUpRequestDTOwithConversion;

  constructor(requestId: string) {
    this.requestId = requestId;

    this.promise = new Promise((accept, reject) => {
      this.promiseAccept = accept;
      this.promiseReject = reject;
    });

    this.load();

    makeObservable(this);
  }

  @action openPopUp = (completeMode: "accept" | "cancel") => {
    if (!this.validate())
      return;
    this.completeMode = completeMode;
    this.showPopUp = true;
  }

  sure = () => {
    if (this.completeMode === "accept") {
      this.complete()
    }
    else {
      this.delete()
    }
  }

  onClosePopUp = () => {
    this.showPopUp = false;
  }

  private async load() {
    this.canSubmit = false;
    this.request = null;

    const topup = await this.api.detailsById(this.requestId);
    this.canSubmit = topup.status == TopUpStatus.ordered ||
      topup.status == TopUpStatus.transferred;
    this.request = topup;
  }

  private validate() {
    const result = refNum.validate(this.enteredRefnum);
    this.validationMessage = result.message
    return result.valid;
  }

  async complete() {
    if (!this.validate())
      return;
    try {
      this.loading = true;
      const response = await this.api.complete({ topUpRequestId: this.requestId, reference: this.enteredRefnum });
      this.request = response;
      this.loading = false;
      this.showPopUp = false;
      this.canSubmit = false;
    } catch (e) {
      const resError = e as CustomError;
      runInAction(() => {
        this.loading = false;
        this.showPopUp = false;
        if (resError.message.length > 0) {
          this.error = resError.message
        } else {
          alert(t("topup.referenceError"));
        }
      })
    }
  }

  async delete() {
    if (!this.validate())
      return;
    this.error = null;
    try {
      this.loading = true
      const response = await this.api.cancel({ topUpRequestId: this.requestId, reference: this.enteredRefnum })
      // this.request.status = TopUpStatus.cancelled;
      this.request = response;
      this.loading = false
      this.canSubmit = false
      this.onClosePopUp();
    } catch (e) {
      let error = e as CustomError;
      if (error.message?.length > 0) {
        this.error = error.message;
      }
      else {
        alert(t("topup.referenceError"));
      }
    }
  }

  @action changeReference(value: string) {
    this.error = "";
    if (value.replace(/ /g, '').length > 11) {
      this.enteredRefnum = value.substring(0, 11)
      return
    }
    this.enteredRefnum = value.replace(/ /g, '');
  }

  cancel() {
    this.promiseAccept();
  }
}