﻿import { t } from "i18next";
import { observer } from "mobx-react";
import React, { useEffect, useMemo, useState } from "react";
import ReactDatePicker from "react-datepicker";
import useInfiniteScroll from "react-infinite-scroll-hook";
import { Indicator } from "../../components/indicator";

import { SearchInput } from "../../components/searchInput";
import { dateFormatter } from "../../helpers/dateFormatter";
import { Kuva } from "../../interfaces/kuvaweb";
import * as fmt from "../../utils/formatting";
import { BookedCashoutsViewModel, CashoutsViewModel, PastCashoutsViewModel } from "../../viewModels/cashout/cashoutLazy";

type CashoutAgentResponseModel = Kuva.PL.KuvaWeb.Areas.Agent.Models.AgentCashoutRequestModel;

let timer: undefined | NodeJS.Timeout;

const CashoutsList: React.FC<{ viewModel: CashoutsViewModel }> = observer((props) => {
  const { viewModel } = props;
  const tab = props.viewModel.tab;
  const [startDate, setStartDate] = useState<Date>(undefined);
  const [endDate, setEndDate] = useState<Date>(undefined);
  const [error, setError] = useState("");

  let step = 15;

  const cashouts = useMemo(() => tab === "booked" ? viewModel.booked : viewModel.past, [tab]);

  let loading = cashouts.loading;
  let hasNextPage = cashouts?.hasNextPage;

  const onLoadMore = () => {
    var startDateIso = dateFormatter.formatAsISOString(startDate)
    var endDateIso = dateFormatter.formatToEndOfDay(endDate)
    cashouts.onLoadMore(step, startDateIso, endDateIso)
  }

  const onChangeStart = (date: Date) => {
    clearTimeout(timer);
    if (endDate !== undefined && startDate !== undefined) {
      if (date > endDate) {
        setError(t("cashout.error.invalidDate"))
        return;
      }
    }
    cashouts.setStartDate(date)

    var startDateIso = dateFormatter.formatAsISOString(date)
    var endDateIso = dateFormatter.formatToEndOfDay(endDate)

    timer = setTimeout(() => cashouts.getCashoutsWithSearch(
      {
        query: cashouts.search,
        offset: 0,
        pageSize: 15,
        dateRange:
        {
          startDate: startDateIso,
          endDate: endDateIso
        }
      }),
      1500)

    setStartDate(date)
  }

  const onChangeEnd = (date: Date) => {
    clearTimeout(timer);
    if (startDate !== undefined && endDate !== undefined) {
      if (endDate < startDate) {
        setError(t("cashout.error.invalidDate"))
        return;
      }
    }
    cashouts.setEndDate(date)
    var startDateIso = dateFormatter.formatAsISOString(startDate)
    var endDateIso = dateFormatter.formatToEndOfDay(date)
    timer = setTimeout(() => cashouts?.getCashoutsWithSearch(
      {
        query: cashouts.search,
        offset: 0,
        pageSize: 15,
        dateRange:
        {
          startDate: startDateIso,
          endDate: endDateIso
        }
      }),
      1500)

    setEndDate(date)
  }

  const getWithoudDates = () => {
    viewModel.past.resetCount();
    viewModel.booked.resetCount();
    cashouts.getCashoutsWithoutSearch({ query: cashouts.search, offset: 0, pageSize: 15 })
  }

  const changeSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.value.length < 1) {
      viewModel.booked.resetSearch()
      viewModel.past.resetSearch()
      cashouts.changeSearch.bind(cashouts)(undefined)
    }
    else {
      cashouts.changeSearch.bind(cashouts)(e.target.value)
    }
  }

  const resetDates = () => {
    setEndDate(undefined)
    setStartDate(undefined)

    cashouts.setEndDate(undefined);
    cashouts.setStartDate(undefined);

    getWithoudDates()
  }

  const [sentryRef, { rootRef }] = useInfiniteScroll({
    loading,
    hasNextPage,
    onLoadMore,
    disabled: false,
    rootMargin: "0px 0px 200px 0px"
  })

  return (
    <div className="dashboard-page-panel">
      <div className="page-panel-title">
        <ul className="nav custom-nav-pills nav-pills nav-fill">
          <li className="nav-item">
            <button onClick={() => viewModel.changeTab("booked", startDate, endDate)}
              className={`nav-link ${viewModel.tab == "booked" ? 'active' : ''}`}
              aria-current="page">
              {t("cashout.bookedCashoutsTab")} <span
                className={"badge bg-secondary"}>
                {viewModel.booked.filteredCount !== undefined ?
                  (viewModel.booked.filteredCount
                    + " " + "(Total: " + viewModel.booked.requestsCount + ")") : ""
                  + viewModel.booked.requestsCount}
              </span>
            </button>
          </li>
          <li className="nav-item">
            <button onClick={() => viewModel.changeTab("past", startDate, endDate)}
              className={`nav-link ${viewModel.tab == "past" ? 'active' : ''}`}>
              {t("cashout.pastCashoutsTab")}
              <span className="badge bg-secondary">
                {viewModel.past.filteredCount !== undefined ?
                  (viewModel.past.filteredCount
                    + " " + "(Total: " + viewModel.past.requestsCount + ")") : ""
                  + viewModel.past.requestsCount}
              </span>
            </button>
          </li>
        </ul>
      </div>
      <div className={"search"}>
        <SearchInput onChange={changeSearch} />
        <div className="date-select-wrapper item-top d-flex mt-20 mb-20">
          <>
            {/* {
              tab === "booked" ?
                <select
                  value={viewModel.booked?.statuses}
                  className="select"
                  onChange={(e) => viewModel.booked?.changeSearchStatus(e, startDate, endDate)}>
                  <option value={100}>
                    {t("cashout.search.pendingAndSettled")}
                  </option>
                  <option value={Status.pending}>
                    {t("cashout.search.payDue")}
                  </option>
                  <option value={Status.active}>
                    {t("cashout.search.settled")}
                  </option>
                  <option value={Status.invalid}>
                    {t("cashout.search.settled")}
                  </option>
                  <option value={Status.pendingPayment}>{t("cashout.search.settled")}
                  </option>
                </select>
                :
                <select
                  value={viewModel.past?.statuses}
                  className="select"
                  onChange={(e) => viewModel.past?.changeSearchStatus(e, startDate, endDate)}>
                  <option value={Status.completed}>
                    {t("cashout.search.settled")}
                  </option>
                  <option value={Status.cancelled}>
                    {t("cashout.search.settled")}
                  </option>
                </select>
            } */}
            <div className="datepicker">
              {<span>{error}</span>}
              <div className="d-flex align-items-center">
                <div className="datepicker-item">
                  <ReactDatePicker
                    selected={startDate}
                    className="cur-default"
                    dateFormat="dd/MM/yyyy"
                    selectsStart
                    placeholderText="Start date"
                    onChange={onChangeStart}
                    startDate={startDate}
                    maxDate={endDate}
                    endDate={endDate}>
                    <div className="react-datepicker-text">{t("cashout.picker.start")}</div>
                  </ReactDatePicker>
                </div>
                <div className="datepicker-item">
                  <ReactDatePicker
                    selected={endDate}
                    dateFormat="dd/MM/yyyy"
                    className="cur-default"
                    selectsEnd
                    placeholderText="End date"
                    endDate={endDate}
                    onChange={onChangeEnd}
                    minDate={startDate}
                    startDate={startDate}>
                    <div className="react-datepicker-text">{t("cashout.picker.end")}</div>
                  </ReactDatePicker>
                </div>
                <button
                  className="btn-default btn-primary"
                  onClick={resetDates}
                >
                  {t("cashout.picker.resetDates")}
                </button>
              </div>
            </div>
          </>
        </div>
      </div>
      <ul ref={rootRef} className={"list list-col4"}>
        <li className="list-header">
          <div className={"col-date"}>{t("cashout.table.dateColumn")}</div>
          <div className={"col-phone"}>{t("cashout.table.clientColumn")}</div>
          <div className={"col-ref"}>{t("cashout.table.referenceColumn")}</div>
          <div className={"col-amount"}>{t("cashout.table.amountColumn")}</div>
        </li>
        {
          cashouts.requestsCount > 0
            ?
            <>
              {
                cashouts.requests.map((cashout) =>
                  <li key={cashout.bookingId} className={cashout.isNew ? "new-item" : ""} onClick={() => viewModel.select(cashout)}>
                    <div className={"col-date"}>{fmt.dateTimeShort(cashout.date)}</div>
                    <div className={"col-phone"}>{fmt.phoneNumber(cashout.phoneNumber)}</div>
                    <div className={"col-ref"}>{fmt.refNum(cashout.referenceNumber)}</div>
                    <div className={"col-amount"}>{fmt.currencyRich(cashout.amountUsd, 'USD')}</div>
                  </li>
                )}
              {
                (loading || hasNextPage) &&
                (<li ref={sentryRef} className="indicator-wrapper">
                  <Indicator />
                </li>
                )}
            </>
            :
            loading ?
              <li className="indicator-wrapper">
                <Indicator />
              </li>
              : <li className="list-noresults">
                <>{t("cashout.table.noResults")}</>
              </li>
        }
      </ul>
    </div >
  )
})
export default CashoutsList;