import React, { Dispatch, SetStateAction, useMemo } from "react"
import { SearchInput } from "../searchInput";
import { observer } from "mobx-react";
import Table from "../table/table";
import changeMerchantTableConfig from "./config";
import ChangeMerchantProcessDialogue from "./changeMerchantProcessDialogue";
import { ChangeMerchantViewModel } from "../../viewModels/changeMerchant/changemerchantTransactionsViewModel";

const ChangeMerchantList: React.FC<{ viewModel: ChangeMerchantViewModel; openDetails: (orderId: number) => void; showModal: boolean, setShowModal: Dispatch<SetStateAction<boolean>> }> = observer(({ viewModel, openDetails, showModal, setShowModal }) => {

  const tableConfig = useMemo(() => changeMerchantTableConfig(openDetails), [openDetails])

  return (
    <>
      <div className="dashboard-page-panel">
        <div
          className="search">
          <SearchInput onChange={e => viewModel.search = e.target.value} />
        </div>
        <Table
          list={viewModel.transactions.filteredRequests}
          columnCount={9}
          tableConfig={tableConfig} />
      </div>

      {showModal && <ChangeMerchantProcessDialogue showModal={showModal} setShowModal={() => setShowModal(!showModal)} viewModel={viewModel} countries={viewModel.transactions.countryCodes} />}
    </>
  )
});
export default ChangeMerchantList;