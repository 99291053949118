﻿import { Kuva } from "../../interfaces/kuvaweb";
import { ApiClientBase } from "../apiClientBase";

type TopUpApiClientInterface = Kuva.PL.KuvaWeb.Areas.Agent.Controllers.TopUpApiClientInterface;
type TopUpRequestDTOwithConversion = Kuva.BL.BusinessModels.Api.TopUp.TopUpRequestDTOwithConversion;
type AgentTopUpRequestModel = Kuva.PL.KuvaWeb.Areas.Agent.Models.AgentTopUpRequestModel;

export class TopUpCashApiClient extends ApiClientBase implements TopUpApiClientInterface {
    apiBasePath = "top-ups"

    getAgentPastTopups = () => this.postNewUrl<void, AgentTopUpRequestModel[]>([this.apiBasePath, 'past']);
    getAgentBookedTopups = () => this.postNewUrl<void, AgentTopUpRequestModel[]>([this.apiBasePath, 'booked']);
    detailsByReference = (reference: string) => this.postNewUrl<void, TopUpRequestDTOwithConversion>([this.apiBasePath, 'by-ref'], null, { reference: reference });
    detailsById = (topUpRequestId: string) => this.postNewUrl<string, TopUpRequestDTOwithConversion>([this.apiBasePath, 'by-id'], topUpRequestId);
    cancel = (query: { topUpRequestId: string, reference: string }) => this.postNewUrl<void, TopUpRequestDTOwithConversion>([this.apiBasePath, 'cancel'], null, query);
    complete = (query: { topUpRequestId: string, reference: string }) => this.postNewUrl<void, TopUpRequestDTOwithConversion>([this.apiBasePath, 'complete'], null, query);
} 