﻿import { Kuva } from "../../interfaces/kuvaweb";
import { ApiClientBase } from "../apiClientBase";

type TopUpBankApiClientInterface = Kuva.PL.KuvaWeb.Areas.Agent.Controllers.TopUpBankApiClientInterface;
type TopUpRequestDTOwithConversion = Kuva.BL.BusinessModels.Api.TopUp.TopUpRequestDTOwithConversion;
type AgentTopUpRequestModel = Kuva.PL.KuvaWeb.Areas.Agent.Models.AgentTopUpRequestModel;

export class TopUpBankApiClient extends ApiClientBase implements TopUpBankApiClientInterface {
    apiBasePath = "bank-top-ups"

    getAgentPastTopups = () => this.getNewUrl<AgentTopUpRequestModel[]>([this.apiBasePath, 'past']);
    getAgentBookedTopups = () => this.getNewUrl<AgentTopUpRequestModel[]>([this.apiBasePath, 'booked']);
    detailsByReference = (reference: string) => this.getNewUrl<TopUpRequestDTOwithConversion>([this.apiBasePath, `details`], { reference });
    detailsById = (topUpRequestId: string) => this.getNewUrl<TopUpRequestDTOwithConversion>([this.apiBasePath, `${topUpRequestId}/details`],);
    cancel = (query: { topUpRequestId: string, reference: string }) => this.postNewUrl<void, TopUpRequestDTOwithConversion>([this.apiBasePath, 'cancel'], null, query);
    complete = (query: { topUpRequestId: string, reference: string, enteredAmount: number }) => this.postNewUrl<void, TopUpRequestDTOwithConversion>([this.apiBasePath, 'complete'], null, { topUpRequestId: query.topUpRequestId, reference: query.reference, enteredAmount: query.enteredAmount.toFixed(2) });
    checkAmount = (query: { topUpRequestId: string, amount: number }) => this.postNewUrl<void, void>([this.apiBasePath, 'checkAmount'], null, { topUpRequestId: query.topUpRequestId, amount: query.amount.toFixed(2) });
}