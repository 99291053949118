import { action, makeAutoObservable, observable, runInAction } from 'mobx'
import { KWlCashoutApiClient } from '../../../api/agent/kwlCashOutApiClient'
import { Kuva } from '../../../interfaces/kuvaweb'

type IKwlCashOutResponse = Kuva.PL.KuvaWeb.Areas.Agent.KWL.AgentCashoutKWLRequestModel
type KwlDetailsProcessDialog = Kuva.PL.KuvaWeb.Areas.Agent.KWL.IKwlDetailsProcessDialog
type CustomError = Kuva.PL.KuvaWeb.API.CustomError

class KwlDetailsProcessDialogModel {
  cashOuts: [
    {
      createdDate: ''
      completedDate: ''
      id: ''
      recipient: { name: ''; phoneNumber: '' }
      digitalReference: ''
      referenceNumber: ''
      amountUsd: ''
      booked: false
      status: 10
      isNew: false
    }
  ]
  totalAmount: ''
  selectedAmount: 0
}

export default class KwlBookedMultiCashOutViewModel {
  private api = new KWlCashoutApiClient()

  protected requests: IKwlCashOutResponse[] = []
  protected search: string = ''

  detailsModal: KwlDetailsProcessDialog = new KwlDetailsProcessDialogModel()
  step = 15
  offset: number = 0

  @observable checkedList = {}
  @observable isOpenPopUp: boolean = false
  @observable loading: boolean = false
  @observable filteredRequests: IKwlCashOutResponse[] = []
  @observable requestsCount: number = 0
  @observable hasNextPage: boolean = null
  @observable error: string = ''

  constructor() {
    makeAutoObservable(this, {}, { autoBind: true })
  }
  @action showPopUp() {
    this.isOpenPopUp = true
  }

  @action closePopUp() {
    this.isOpenPopUp = false
    this.detailsModal = new KwlDetailsProcessDialogModel()
  }

  @action book(requestId: string) {
    this.requests.forEach(req => (req.id === requestId ? (req.booked = true) : req))
  }

  async getRequests(query: string, pageSize: number, offset: number) {
    return await this.api.getBookedMultiCashOuts(query, pageSize, offset)
  }

  @action onCheckBoxChange = (id: string) => {
    if (id in this.checkedList) {
      delete this.checkedList[id]
    } else {
      this.checkedList = { ...this.checkedList, [id]: true }
    }
  }

  @action bookCashOuts = () => {
    if (Object.keys(this.checkedList).length > 0) {
      this.detailsModal.cashOuts = this.filteredRequests.filter(item =>
        Object.keys(this.checkedList).includes(item.id)
      )
      this.detailsModal.totalAmount = this.detailsModal.cashOuts
        .reduce((sum: number, item: any) => sum + item.amountUsd, 0)
        .toFixed(2)
      this.detailsModal.selectedAmount = this.detailsModal.cashOuts.length

      this.showPopUp()
    }
  };

  @action *processCashOuts() {
    const cashOutsArray = Object.keys(this.checkedList).filter(Boolean)

    this.loading = true
    this.error = ''
    try {
      yield this.api.bookMultiCashOut(cashOutsArray)

      this.requests = this.requests.filter(item => !Object.keys(this.checkedList).includes(item.id))
      this.updateFilter()

      this.checkedList = {}

      this.loading = false
    } catch (e) {
      const error = e as CustomError
      this.error = error.message

      alert(this.error)

      this.loading = false
    }

    this.closePopUp()
  }

  @action
  async onLoadMore(step: number) {
    if ((this.step = 10)) {
      this.loading = false
    } else {
      this.loading = true
    }
    const response = await this.getRequests(this.search, step, this.requests.length)
    runInAction(() => {
      this.requests = [...this.requests, ...response.items]
      this.hasNextPage = response.hasNextPage
      this.requestsCount = response.items.length
    })
    this.updateFilter()
    this.loading = false
  }

  @action private updateFilter() {
    if (this.requests == null) return

    this.filteredRequests = this.requests
  }

  @action async getCashoutsWithSearch(search: string) {
    this.search = search
    this.loading = true
    const response = await this.getRequests(search, this.step, 0)
    this.hasNextPage = response.hasNextPage
    this.requests = null
    this.requests = response.items
    this.updateFilter()
    this.loading = false
  }

  setSearch(search: string) {
    this.search = search
    this.updateFilter()
  }
}
