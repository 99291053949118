import React from "react";
import { t } from "i18next"
import { Kuva } from "../../../../interfaces/kuvaweb";
import { lang } from "../../../../utils/lang";
import * as fmt from "../../../../utils/formatting";

type BundleOrderShortModel = Kuva.PL.KuvaWeb.Areas.Agent.Multicash.MultiCashRequestModel;

type Config = Kuva.Module.Table.TableConfig<BundleOrderShortModel>;

const MultiCashoutsTableConfig = (tab: "booked" | "past"): Config => {
    return {
        columns: [
            {
                id: "date",
                header: () => t("multicash.table.dateCol"),
                column: item => fmt.dateTimeShort(item.createdDate),
                className: "date-column"
            },
            {
                id: "booked",
                header: () => t("multicash.table.bookedCol"),
                column: item => lang.isBooked(item.booked),
                className: "ref-column"
            },
            {
                id: "status",
                header: () => t("multicash.table.statusCol"),
                column: item => lang.cashoutStatus(item.status),
                className: "type-column"
            },
            {
                id: "reference",
                header: () => t("multicash.table.referenceCol"),
                column: item => tab === "past" ? item.referenceNumber : fmt.refNum(item.referenceNumber),
                className: "status-column"
            },
            {
                id: "amount",
                header: () => t("multicash.table.amountCol"),
                column: item => (item.amountUsd).toString(),
                className: "cash-column"
            },
            
        ]
    }
}

export default MultiCashoutsTableConfig;