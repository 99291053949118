import { t } from "i18next";
import { observer } from "mobx-react";
import React from "react";
import { IndicatorButton } from "../../components/indicatorButton";
import { PageHeader } from "../../components/pageHeader";
import { SettlementsViewModel } from "../../viewModels/settlement/settlementsViewModel";
import SettlementsList from "./settlementsList";

@observer
export class SettlementsPage extends React.Component {
    listViewModel = new SettlementsViewModel();

    componentDidMount() {
        this.listViewModel.mount();
    }

    componentWillUnmount() {
        this.listViewModel.unmount()
    }

    render() {
        return <div className="page-settlement">
            <PageHeader>
                <span>{t("settlement.title")}</span>
                <span className="download-btns">
                    <IndicatorButton className="btn-default btn-primary btn-pdf"
                        loading={this.listViewModel.downLoading}
                        onClick={() => this.listViewModel.generatePDF()}>
                        {t("settlement.button.getPdf")}
                    </IndicatorButton>
                </span>
            </PageHeader>
            <div className="container">
                <SettlementsList viewModel={this.listViewModel} />
            </div>
        </div>
    }
}