import { action, autorun, makeObservable, observable, runInAction } from "mobx";
import { BundleOrderApiClient } from "../../api/cashrail/bundleOrderApiClient";
import { Kuva } from "../../interfaces/kuvaweb";
import { IPromiseViewModel } from "../promiseViewModel";
import { ViewModelBase } from "../viewModelBase";

type Order = Kuva.PL.KuvaWeb.Areas.BundleAgent.Models.BundleOrderModel;
type BankDetails = Kuva.PL.KuvaWeb.Areas.BundleAgent.Models.BankDetails;
type PaymentStatus = Kuva.Module.Enums.BundleOrderPaymentStatus;
type BundleOrderItemModel = Kuva.PL.KuvaWeb.Areas.BundleAgent.Models.BundleOrderItemModel

class BundleAgentOrderDetailsViewModel extends ViewModelBase {
    private api = new BundleOrderApiClient();

    private readonly orderId: number;

    @observable order: Order = null;
    @observable search: string = "";
    @observable filteredRequests: BundleOrderItemModel[] = new Array();
    @observable bankDetails: BankDetails = null;

    @observable orderLoading: boolean = false;
    @observable bankLoading: boolean = false;

    constructor(id: number) {
        super();
        this.orderId = id;
        this.getOrder();
        makeObservable(this);
    }

    mount() {
        super.mount();
    }

    unmount() {
        super.unmount();
    }

    *subscribe() {
        yield autorun(() => {
            this.updateFilter();
        })
    }

    @action
    updateFilter = () => {
        this.filteredRequests = this.order?.bundleOrderItems?.filter(applyFilter(this.search));
    }

    @action
    private getOrder = async () => {
        this.orderLoading = true
        const response = await this.api.getOrderDetails(this.orderId);
        runInAction(() => {
            this.order = response;
            this.updateFilter();
        })
        this.orderLoading = false;
    }

    @action
    setOrderAsPaid = async (id: number) => {
        await this.api.changePaymentStatus(id, 20);
        runInAction(() => {
            this.order.paymentStatus = 20;
        })
    }

    @action
    getBankDetails = async (id: number) => {
        this.bankLoading = true;
        const response = await this.api.getBankDetails(id);
        runInAction(() => {
            this.bankDetails = response;
        })
        this.bankLoading = false;
    }

    @action
    changePaymentStatus = async (id: number, status: PaymentStatus) => {
        try {
            const response = await this.api.changePaymentStatus(id, status);
            this.order.paymentStatus = status;
        } catch (error) {
            return;
        }
    }
}
export default BundleAgentOrderDetailsViewModel;

type SearchFunction = (settlement: BundleOrderItemModel) => string

const searchableFields: (keyof BundleOrderItemModel | SearchFunction)[] = [
    'amount',
    'customerId',
    'reference'
]

function applyFilter(query: string): (a: BundleOrderItemModel) => boolean {
    if (query === null || query.length < 1)
        return () => true;
    query = query.trim().toLocaleLowerCase();

    return (a) => {
        return searchableFields.some(field => {
            let value = ''
            if (typeof field === 'function') {
                value = field(a)
            } else {
                value = a[field].toString()
            }

            return value.toLocaleLowerCase().startsWith(query)
        })
    }
}