﻿import { t } from "i18next";
import { action, flow, makeObservable, observable, runInAction } from "mobx";
import { observer } from "mobx-react";
import React, { Component } from "react";
import { ProfileApiClient } from "../api/profileApiClient";
import { CompactField } from "../components/compactField";
import { Icon } from "../components/icons";
import { IndicatorButton } from "../components/indicatorButton";
import { Map } from "../components/map";
import { Modal } from "../components/modal";
import { PageHeader } from "../components/pageHeader";
import { TransitionPanel } from "../components/transitionPanel";

class ProfileLocationViewModel {
    private api = new ProfileApiClient();

    @observable street: string = "";
    @observable city: string = "";
    @observable zipCode: string = "";
    @observable country: string = "";

    @observable latitude: number = null;
    @observable longitude: number = null;

    @observable editing: boolean = false;
    @observable editingLatitude: number = null;
    @observable editingLongitude: number = null;
    @observable editingAddress: boolean = false;
    @observable editingStreet: string = "";
    @observable editingCity: string = "";
    @observable editingZipCode: string = "";
    @observable editingCountry: string = "";

    constructor() {
        this.load();
        makeObservable(this);
    }

    private async load() {
        const location = await this.api.getLocation();

        runInAction(() => {
            this.street = location.street;
            this.city = location.city;
            this.zipCode = location.zipCode;
            this.country = location.country;

            this.latitude = location.latitude;
            this.longitude = location.longitude;
        });
    }

    @action
    edit() {
        this.editingLatitude = this.latitude;
        this.editingLongitude = this.longitude;
        this.editing = true;
        this.editingAddress = false;
    }

    @action
    cancelEdit() {
        this.editing = false;
    }

    @action
    updateLocation(longitude: number, latitude: number) {
        this.editingLongitude = longitude;
        this.editingLatitude = latitude;
        console.log(longitude, latitude);
    }

    async selectLocation() {
        try {
            const address = await this.api.findAddress({
                longitude: this.editingLongitude,
                latitude: this.editingLatitude
            });

            runInAction(() => {
                this.editingAddress = true;
                this.editingStreet = address.street;
                this.editingCity = address.city;
                this.editingZipCode = address.zipCode;
                this.editingCountry = address.country;
            });
        } catch {
            alert(t("profile.editLocation.couldNotFindAddressError"));

            runInAction(() => {
                this.editingAddress = true;
                this.editingStreet = "";
                this.editingCity = "";
                this.editingZipCode = "";
                this.editingCountry = "";
            });
        }
    }

    @action
    async back() {
        this.editingAddress = false;
    }

    async save() {
        await this.api.updateLocation({
            street: this.editingStreet,
            city: this.editingCity,
            zipCode: this.editingZipCode,
            country: this.editingCountry,

            longitude: this.editingLongitude,
            latitude: this.editingLatitude
        });
        await this.load();
        runInAction(() => {
            this.editing = false;
        });
    }
}

@observer
export class ProfilePage extends Component {
    private viewModel = new ProfileLocationViewModel();

    render() {
        const vm = this.viewModel;

        return <div className="page-profile container">
            <PageHeader>
                {t("profile.title")}
            </PageHeader>
            {vm.editing && <ProfileLocationEditDialogue viewModel={vm} />}
            <div className={"current-address"}>
                <div className={"details"}>
                    <h3>{t("profile.addressSubtitle")}</h3>
                    {vm.street}<br />
                    {vm.city}<br />
                    {vm.zipCode}<br />
                    {vm.country}<br />
                </div>
                <div className={"edit"}>
                    <button className={"btn btn-link"} onClick={() => vm.edit()}><Icon.Edit /> {t("editButton")}</button>
                </div>
            </div>
            <Map location={[vm.longitude, vm.latitude]} />
        </div>
    }
}

@observer
export class ProfileLocationEditDialogue extends Component<{ viewModel: ProfileLocationViewModel }> {
    render() {
        const vm = this.props.viewModel;

        const stage = vm.editingAddress ? "address" : "map";

        return <Modal className={"profile-location-modal"} size={"md"}>
            <div className="modal-header">
                <h5 className="modal-title">{t("profile.editLocation.title")}</h5>
                <button type="button" onClick={() => vm.cancelEdit()} className="btn-close" aria-label={t("closeButton")} />
            </div>
            <div className="modal-body">
                <TransitionPanel page={stage}>
                    <div key={"map"}>
                        <Map location={[vm.editingLongitude, vm.editingLatitude]} allowSelect onLocationChange={(location) => vm.updateLocation(location[0], location[1])} />
                        <div className={"actions"}>
                            <div>
                                <IndicatorButton className={"btn-primary"} onCommand={() => vm.selectLocation()}>{t("profile.editLocation.selectButton")}</IndicatorButton>
                            </div>
                        </div>
                    </div>
                    <div key={"address"}>
                        <div>
                            <CompactField className={"mb-3"} placeholder={t("profile.editLocation.streetLabel")} type={"text"} value={vm.editingStreet} onChange={(e) => vm.editingStreet = e} />
                            <CompactField className={"mb-3"} placeholder={t("profile.editLocation.cityLabel")} type={"text"} value={vm.editingCity} onChange={(e) => vm.editingCity = e} />
                            <CompactField className={"mb-3"} placeholder={t("profile.editLocation.zipCodeLabel")} type={"text"} value={vm.editingZipCode} onChange={(e) => vm.editingZipCode = e} />
                            <CompactField className={"mb-3"} placeholder={t("profile.editLocation.countryLabel")} type={"text"} value={vm.editingCountry} onChange={(e) => vm.editingCountry = e} />
                        </div>
                        <div className={"button-form"}>
                            <div>
                                <IndicatorButton className={"btn-default btn-red"} onCommand={() => vm.back()}>{t("backButton")}</IndicatorButton>
                            </div>
                            <div>
                                <IndicatorButton className={"btn-default btn-primary"} onCommand={() => vm.save()}>{t("saveButton")}</IndicatorButton>
                            </div>
                        </div>
                    </div>
                </TransitionPanel>
            </div>
        </Modal>
    }
}