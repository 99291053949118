﻿import {computed, makeObservable, observable} from "mobx";
import {t} from "i18next";

import {Kuva} from "../../interfaces/kuvaweb";
import {Mutex} from "../../utils/mutex";

import {IPromiseViewModel} from "../promiseViewModel";

type TopUpRequestDTOwithConversion = Kuva.BL.BusinessModels.Api.TopUp.TopUpRequestDTOwithConversion;
import TopUpStatus = Kuva.Module.Enums.TopUpStatus;

export abstract class TopupProcessViewModel implements IPromiseViewModel<void> {
    //api = new TopUpCashApiClient();

    @computed get completed(): boolean {
        return this.result != null;
    };
    @observable result: "accepted" | "cancelled" | "failed" | "" = null;

    request: TopUpRequestDTOwithConversion;
    promise: Promise<void>;

    private promiseAccept: () => void;
    private promiseReject: () => void;
    private mutex = new Mutex();

    constructor(topupRequest: TopUpRequestDTOwithConversion) {
        makeObservable(this);

        if(topupRequest.status == TopUpStatus.completed)
            this.result = "accepted";
        else if(topupRequest.status == TopUpStatus.cancelled)
            this.result = "cancelled";
        else if(topupRequest.status != TopUpStatus.ordered && topupRequest.status != TopUpStatus.transferred)
            this.result = "failed";

        this.request = topupRequest;
        this.promise = new Promise((accept, reject) => {
            this.promiseAccept = accept;
            this.promiseReject = reject;
        });
    }

    async accept() {
        await this.mutex.auto(async () => {
            if(confirm(t("topup.completePrompt"))) {
                try {
                    await this.completeTopup();
                    this.request.status = TopUpStatus.completed;
                    this.result = "accepted";
                } catch {
                    this.result = "failed";
                }
            }
        });
    }

    async delete() {
        await this.mutex.auto(async () => {
            if (confirm(t("topup.deletePrompt"))) { 
                try {
                    await this.cancelTopup();
                    this.request.status = TopUpStatus.cancelled;
                    this.result = "cancelled";
                } catch {
                    this.result = "failed";
                }
            }
        });
    }

    async cancel() {
        this.promiseAccept();
    }

    async okay() {
        this.promiseAccept();
    }

    protected abstract cancelTopup(): Promise<void>;
    protected abstract completeTopup(): Promise<void>;
}

