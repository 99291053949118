﻿import { action, makeObservable, observable, runInAction } from 'mobx'
import { t } from 'i18next'

import { TopUpBankApiClient } from '../../api/agent/topUpBankApiClient'
import { Kuva } from '../../interfaces/kuvaweb'
import * as refNum from '../../utils/refNum'

import { IPromiseViewModel } from '../promiseViewModel'

import TopUpStatus = Kuva.Module.Enums.TopUpStatus

type TopUpRequestDTOwithConversion = Kuva.BL.BusinessModels.Api.TopUp.TopUpRequestDTOwithConversion
type ExtendedError = Kuva.PL.KuvaWeb.API.CustomError

export class BankTopupDetailsViewModel implements IPromiseViewModel<void> {
  private api = new TopUpBankApiClient()

  promise: Promise<void>

  private promiseAccept: () => void
  private promiseReject: () => void

  @observable canSubmit: boolean = false
  @observable validationMessage: string = null

  @observable enteredRefnum: string = ''
  @observable enteredFrom: string = ''
  @observable errorMessage: string
  @observable showPopUp = false
  @observable completeMode: 'accept' | 'cancel' = 'accept'
  @observable loading: boolean = false

  private readonly requestId: string
  @observable request: TopUpRequestDTOwithConversion

  constructor(requestId: string) {
    this.requestId = requestId

    this.promise = new Promise((accept, reject) => {
      this.promiseAccept = accept
      this.promiseReject = reject
    })

    this.load()

    makeObservable(this)
  }

  private async load() {
    this.canSubmit = false
    this.request = null

    const topup = await this.api.detailsById(this.requestId)

    this.canSubmit = topup.status == TopUpStatus.ordered || topup.status == TopUpStatus.transferred
    this.request = topup
  }

  private validateRefNum() {
    const result = refNum.validate(this.enteredRefnum)
    this.validationMessage = result.message
    return result.valid
  }

  onClosePopUp = () => {
    this.showPopUp = false
  }

  sure = () => {
    if (this.completeMode === 'accept') {
      this.complete()
    } else {
      this.delete()
    }
  }

  private validateAmount() {
    if (this.enteredFrom == null || this.enteredFrom.length == 0) {
      this.validationMessage = t('topup.bank.amountRequired')
      return false
    }

    const parsed = parseFloat(this.enteredFrom)
    if (isNaN(parsed)) {
      this.validationMessage = t('topup.bank.amountInvalid')
      return false
    }
    return true
  }

  async complete() {
    if (!this.validateRefNum()) return
    if (!this.validateAmount()) return

    const enteredAmountDecimal = parseFloat(parseFloat(this.enteredFrom).toFixed(2))

    if (enteredAmountDecimal != this.request.from.totalFromAmount) {
      const mistmach = Math.abs(1.0 - this.request.from.totalFromAmount / enteredAmountDecimal)
      if (mistmach > this.request.amountThreshold) {
        this.validationMessage = t('topup.bank.amountThresholdInvalid')

        return
      } else {
        if (!confirm(t('topup.bank.amountThresholdPrompt'))) return
      }
    }

    try {
      this.loading = true
      this.errorMessage = null
      const response = await this.api.complete({
        topUpRequestId: this.requestId,
        reference: this.enteredRefnum,
        enteredAmount: enteredAmountDecimal,
      })

      this.fillCashoutOnProcess(TopUpStatus.completed, response)

      this.canSubmit = false
      this.loading = false
      this.onClosePopUp()
    } catch (e) {
      this.onClosePopUp()
      const error = e as ExtendedError
      this.errorMessage = error.message
      this.loading = false
    }
  }

  @action openPopUp = (completeMode: 'accept' | 'cancel') => {
    if (!this.validateRefNum()) return
    this.completeMode = completeMode
    this.showPopUp = true
  }

  async delete() {
    if (!this.validateRefNum()) return
    this.errorMessage = null
    try {
      this.loading = true
      const response = await this.api.cancel({
        topUpRequestId: this.requestId,
        reference: this.enteredRefnum,
      })

      this.fillCashoutOnProcess(TopUpStatus.cancelled, response)

      this.canSubmit = false
      this.loading = false
      this.onClosePopUp()
    } catch (e) {
      const error = e as ExtendedError
      this.onClosePopUp()
      this.loading = false
      if (error.message?.length > 0) {
        this.errorMessage = error.message
      } else {
        alert(t('topup.referenceError'))
      }
    }
  }

  private fillCashoutOnProcess(status: TopUpStatus, response: TopUpRequestDTOwithConversion) {
    this.request.expirationDate = response.expirationDate
    this.request.completedDate = response.completedDate
    this.request.treasuryTransfer = response.treasuryTransfer
    this.request.status = status
    this.request.reference = response.reference
  }

  cancel() {
    this.promiseAccept()
  }
}
