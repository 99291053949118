import { Kuva } from "../../interfaces/kuvaweb";
import { ApiClientBase } from "../apiClientBase";


type ChangeMerchantApiClientInterface = Kuva.PL.KuvaWeb.Areas.Agent.Controllers.ChangeMerchantApiClientInterface
type AgentChangeMerchantTransactionsViewModel = Kuva.PL.KuvaWeb.Areas.Agent.Models.AgentChangeMerchantTransactionsViewModel;
type ChangeMerchantTransaction = Kuva.DL.DomainModel.NopDbEntities.KuvaLocal.ChangeMerchantTransaction;
type CustomerName = Kuva.BL.BusinessModels.Api.KuvaLocal.CustomerName;
type ChangeMerchantTransactionRequestModel = Kuva.BL.BusinessModels.Api.KuvaLocal.ChangeMerchantTransactionRequestModel;

export class ChangeMerchantApiClient extends ApiClientBase implements ChangeMerchantApiClientInterface {
  apiBasePath = "change-merchant";

  getTransactions = () => this.getNewUrl<AgentChangeMerchantTransactionsViewModel>([this.apiBasePath], null);
  detailsById = (id: number) => this.getNewUrl<ChangeMerchantTransaction>([this.apiBasePath, `${id}`], null);
  customerNameCheck = (phone: string) => this.getNewUrl<CustomerName>([this.apiBasePath, 'customer-name'], { phone });
  createTransaction = (request: ChangeMerchantTransactionRequestModel) => this.postNewUrl<ChangeMerchantTransactionRequestModel, Kuva.DL.DomainModel.NopDbEntities.KuvaLocal.ChangeMerchantTransaction>([this.apiBasePath, '', 'create-tx'], request);
  getTransactionsCount = () => this.getNewUrl<number>([this.apiBasePath, 'count'])
}