﻿import { action, makeObservable, observable } from "mobx";
import { ChangeEvent } from "react";

import { ApiError } from "../../api/apiError";
import { Kuva } from "../../interfaces/kuvaweb";

import { IPromiseViewModel } from "../promiseViewModel";

import { TopupProcessViewModel } from "./topupProcessViewModel";

import TopUpStatus = Kuva.Module.Enums.TopUpStatus;

type TopUpRequestDTOwithConversion = Kuva.BL.BusinessModels.Api.TopUp.TopUpRequestDTOwithConversion;
type CustomResponse = Kuva.PL.KuvaWeb.API.CustomResponse;

export abstract class TopupFormViewModel {

  @observable referenceNumber: string = "";
  @observable validating: boolean = false;
  @observable valid: boolean = null;

  @observable process: IPromiseViewModel<void> = null;

  constructor() {
    makeObservable(this);
  }

  protected abstract findTopup(referenceNumber: string): Promise<TopUpRequestDTOwithConversion | Kuva.PL.KuvaWeb.API.CustomResponse>;
  protected abstract startProcess(request: TopUpRequestDTOwithConversion): TopupProcessViewModel;

  @action changeReference(value: string) {
    if (value.replace(/ /g, '').length > 11) {
      this.referenceNumber = value.substring(0, 11)
      return
    }
    this.referenceNumber = value.replace(/ /g, '');
  }

  async verify() {
    if (this.process != null)
      return;

    this.validating = true;
    this.valid = null;
    try {
      let topupRequest = await this.findTopup(this.referenceNumber);
      if (topupRequest != null) {
        this.process = this.startProcess(topupRequest as TopUpRequestDTOwithConversion);
      } else {
        this.valid = false;
      }
    } catch (e) {
      if (e instanceof ApiError) {
        this.valid = false;
      } else {
        //TODO: Handle network errors
      }
    } finally {
      this.validating = false;
    }

    if (this.process) {
      try {
        await this.process.promise;
      } finally {
        this.process = null;
        this.referenceNumber = "";
      }
    }
  }
}