import { action, makeAutoObservable, observable } from "mobx";

import { KWlCashoutApiClient } from "../../api/agent/kwlCashOutApiClient";

import format from 'date-fns/format'

const fileDownload = require('js-file-download')

export default class KwlReportViewModal {
  private api = new KWlCashoutApiClient()

  @observable isOpenPopUp = false
  @observable startDate: Date | null = null
  @observable endDate: Date | null = null


  constructor() {
    makeAutoObservable(this, {}, { autoBind: true })
  }

  @action showPopUp() {
    this.isOpenPopUp = true
  }

  @action closePopUp() {
    this.isOpenPopUp = false
    this.resetDates()
  }

  @action onChangeStart = (date: Date) => {
    this.startDate = date
  }

  @action onChangeEnd = (date: Date) => {
    this.endDate = date
  }

  @action resetDates = () => {
    this.startDate = null
    this.endDate = null
  }


  @action
  async getCsv() {
    const startDate = this.startDate ? `${format(this.startDate, 'yyyy-MM-dd')}T00:00:00Z` : null
    const endDate = this.endDate ? `${format(this.endDate, 'yyyy-MM-dd')}T23:59:59Z` : null

    let query = null

    if (startDate) {
      query = { 'dateRange.startDate': startDate }
    }
    if (endDate) {
      query = { ...query, 'dateRange.endDate': endDate }
    }

    const fileResponse = await this.api.getCsv(query)
    const file = await fileResponse.blob()
    const header = fileResponse.headers.get('Content-Disposition')
    const fileName = header.match(/filename=(.*)\;/)[1]
    await fileDownload(file, fileName)

  }
}