
import { t } from "i18next";
import { observer } from "mobx-react-lite";
import React, { useState } from "react"
import { CompactField } from "../components/compactField";
import { IndicatorButton } from "../components/indicatorButton";
import { LanguageSelection } from "../components/languageSelection";
import { AuthenticationManager } from "../managers/authenticationManager";



const ForgotPassword: React.FC = observer(() => {
    const authManager = AuthenticationManager.instance;

    const [email, setEmail] = useState("");
    const [loading, setLoading] = useState(false);

    const onSubmit = async (e: React.SyntheticEvent) => {
        e.preventDefault();
        setLoading(true);
        await authManager.forgotPassword(email);
        setLoading(false)
    }

    return (
        <div
            className="page-login">
            <div
                className="login-panel">
                <div
                    className="contents">
                    <div>
                        <h1>
                            {t("forgotPage.forgotPageTitle")}
                        </h1>
                    </div>
                    {
                        !authManager.showOk ?
                            <form onSubmit={(e: React.SyntheticEvent) => onSubmit(e)}>
                                <div className="email-input">
                                    <CompactField
                                        inputMode="email"
                                        autoCapitalize="none"
                                        type="email"
                                        placeholder={t("forgotPage.forgotInput")}
                                        value={email}
                                        onChange={(e) => setEmail(e)}
                                        required />
                                </div>
                                <div className="text-center">
                                    <IndicatorButton
                                        type="submit"
                                        className="btn-default btn-login"
                                        loading={loading}>
                                        {t("forgotPage.send")}
                                    </IndicatorButton>
                                    {authManager.error?.length > 0 && <p className="text-center text-danger small">{t(`forgotPage.accResult.${authManager.error}`)}</p>}
                                </div>
                            </form>
                            :
                            <div
                                className="check-email">
                                {t("forgotPage.checkEmail")}
                            </div>
                    }
                    <LanguageSelection />
                </div>
            </div>
        </div>
    )
})
export default ForgotPassword;