import React, { ButtonHTMLAttributes } from "react";

export interface IndicatorButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
    loading?: boolean;
}

export class CustomSpinner extends React.Component<IndicatorButtonProps> {
    state = {
        loading: null as boolean
    }

    render() {
        const props = { ...this.props };
        delete props.loading;

        let loading = this.props.loading;
        if (typeof loading === "undefined")
            loading = this.state.loading;

        return <div className="loading"><div className="spinner-border text-primary spin" role="status"></div></div>
    }
}