﻿import React, {ChangeEvent, Component, createRef, InputHTMLAttributes} from "react";
import {t} from "i18next";

import {Icon} from './icons';

export interface SearchInputProps extends InputHTMLAttributes<HTMLInputElement> {
}

export class SearchInput extends Component<SearchInputProps> {
    inputRef = createRef<HTMLInputElement>();
    
    state = {
        displayClear: false
    }
    
    handleClear() {
        this.inputRef.current.value = "";

        this.setState({displayClear: false});

        if(this.props.onChange != null)
            this.props.onChange({ target: { value: "" }} as any); //Ugh...
    }
    
    handleChange(e: ChangeEvent<HTMLInputElement>) {
        if(this.props.onChange != null)
            this.props.onChange(e);

        this.setState({displayClear: (e.target.value != null && e.target.value.length > 0)});
    }
    
    render() {
        return <div className={`${this.props.className} search-input form-floating`}>
            <input ref={this.inputRef} {...this.props} onChange={(e) => this.handleChange(e)} className={"form-control"} type={"text"} placeholder={t("component.search.label")} />
            {this.state.displayClear && <button type="button" onClick={() => this.handleClear()}><Icon.Clear/></button>}
            <label>{t("component.search.label")}</label>
            <span><Icon.Search/></span>
        </div>
    }
}