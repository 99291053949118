import { t } from "i18next";
import { action, makeObservable, observable, runInAction } from "mobx";
import { BundleOrderApiClient } from "../../api/cashrail/bundleOrderApiClient";
import { Kuva } from "../../interfaces/kuvaweb";
import { ViewModelBase } from "../viewModelBase";

type response = Kuva.PL.KuvaWeb.Areas.BundleAgent.Models.ParseBundleOrderResponseModel;
type Order = Kuva.PL.KuvaWeb.Areas.BundleAgent.Models.BundleOrderModel;
type CreateBundleOrderResponseModel = Kuva.PL.KuvaWeb.Areas.BundleAgent.Models.CreateBundleOrderResponseModel;
type PaidStatus = Kuva.Module.Enums.BundleOrderPaymentStatus;
const PaymentStatus = Kuva.Module.Enums.BundleOrderPaymentStatus;


export class BundleParsedOrderViewModel extends ViewModelBase {
    private api = new BundleOrderApiClient();

    parsedLocalModel: Order;
    localErrors: string[] = [];

    @observable parsedModel: Order = null;
    @observable errors: string[] = null;
    @observable placedOrder: CreateBundleOrderResponseModel = null;
    @observable loading: boolean = null;

    constructor(model: response) {
        super();
        this.parsedModel = model.order;
        this.errors = model.errors;
        makeObservable(this);
    }

    @action
    load() {
        this.errors = this.localErrors;
        this.parsedLocalModel = this.parsedModel;
    }

    mount() {
        super.mount();
    }

    unmount() {
        super.unmount();
    }

    @action
    async changePaymentStatus(orderId: number, status: PaidStatus) {
        try {
            await this.api.changePaymentStatus(orderId, status);
            runInAction(() => {
                this.placedOrder.orderDetails.paymentStatus = PaymentStatus.paid;
            })
        }
        catch (e) { }
    }

    @action
    async placeOrder(model: Order) {
        this.loading = true
        try {
            const response = await this.api.createOrder(model);

            runInAction(() => {
                this.placedOrder = response as CreateBundleOrderResponseModel;
            })
            
        } catch (error) {
            alert(t("api.cashrail.bankDetails"))
        }

        this.loading = false;
    }
}