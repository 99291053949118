import { t } from "i18next";
import React from "react"
import { RouteComponentProps, withRouter } from "react-router-dom";
import { Kuva } from "../../interfaces/kuvaweb";
import { CashrailAgentViewModel } from "../../viewModels/cashrail/cashrailViewModel";
import * as fmt from "../../utils/formatting";
import { observer } from "mobx-react";
import { SearchInput } from "../searchInput";
import { lang } from "../../utils/lang";

const PaymentStatus = Kuva.Module.Enums.BundleOrderPaymentStatus
const OrderStatus = Kuva.Module.Enums.BundleOrderStatus;
const paid = Kuva.Module.Enums.BundleOrderPaymentStatus.paid;

@observer
class CashrailListOrders extends React.Component<{ viewModel: CashrailAgentViewModel } & RouteComponentProps> {

    handleSelect = (orderId: number) => {
        this.props.history.push(`/cashrail-order/details?id=${orderId}`)
    }

    handleTabChange = (tab: 'new' | 'processing' | 'ready' | 'delivered' | 'completed') => {
        this.props.viewModel.changeTab(tab);
    }

    render() {
        const list = this.props.viewModel.currentList;
        const viewModel = this.props.viewModel;

        return <div className="dashboard-page-panel">
            <div
                className="page-panel-title">
                <ul className="nav custom-nav-pills nav-pills nav-fill">
                    <li className="nav-item">
                        <button
                            onClick={() => this.handleTabChange("new")}
                            className={`nav-link ${viewModel.tab == "new" ? 'active' : ''}`}
                            aria-current="page">
                            {t("cashrail.ba-table.button.new")}
                            <span className={"badge bg-secondary"}>
                                {viewModel.orders?.filteredRequests?.length === 0 ? '' : viewModel.orders?.filteredRequests?.length}
                            </span>
                        </button>
                    </li>
                    <li className="nav-item">
                        <button
                            onClick={() => this.handleTabChange("processing")}
                            className={`nav-link ${viewModel.tab == "processing" ? 'active' : ''}`}>
                            {t("cashrail.ca-table.button.process")}
                            <span
                                className={"badge bg-secondary"}>
                                {viewModel.processing?.filteredRequests?.length === 0 ? '' : viewModel.processing?.filteredRequests?.length}
                            </span>
                        </button>
                    </li>
                    <li className="nav-item">
                        <button
                            onClick={() => this.handleTabChange("ready")}
                            className={`nav-link ${viewModel.tab == "ready" ? 'active' : ''}`}>
                            {t("cashrail.ca-table.button.ready")}
                            <span
                                className={"badge bg-secondary"}>
                                {viewModel.ready?.filteredRequests?.length === 0 ? '' : viewModel.ready?.filteredRequests?.length}
                            </span>
                        </button>
                    </li>
                    <li className="nav-item">
                        <button
                            onClick={() => this.handleTabChange("delivered")}
                            className={`nav-link ${viewModel.tab == "delivered" ? 'active' : ''}`}>
                            {t("cashrail.ca-table.button.delivered")}
                            <span className={"badge bg-secondary"}>
                                {viewModel.delivered?.filteredRequests?.length === 0 ? '' : viewModel.delivered?.filteredRequests?.length}
                            </span>
                        </button>
                    </li>
                    <li className="nav-item">
                        <button
                            onClick={() => this.handleTabChange("completed")}
                            className={`nav-link ${viewModel.tab == "completed" ? 'active' : ''}`}>
                            {t("cashrail.ca-table.button.completed")}
                            <span className={"badge bg-secondary"}>
                                {viewModel.completed?.filteredRequests?.length === 0 ? '' : viewModel.completed?.filteredRequests?.length}
                            </span>
                        </button>
                    </li>
                </ul>
            </div>
            <div className="search">
                <SearchInput onChange={(e) => viewModel.changeSearch(e)} />
            </div>
            <ul className="list list-col7 cashrail-list">
                <li className="list-header">
                    <div className="col-date">{t("cashrail.ca-table.date")}</div>
                    <div className="col-code">{t("cashrail.ca-table.code")}</div>
                    <div className="col-amount">{t("cashrail.ca-table.amount")}</div>
                    <div className="col-cost">{t("cashrail.ca-table.cost")}</div>
                    <div className="col-type">{t("cashrail.ca-table.orderType")}</div>
                    <div className="col-paid">{t("cashrail.ca-table.paid")}</div>
                    <div className="col-settled">{t("cashrail.ca-table.settled")}</div>
                </li>
                {list?.filteredRequests?.filter(order => OrderStatus[order.orderStatus] === viewModel.tab).map((order) =>
                    <li key={order.id} onClick={() => this.handleSelect(order.id)}>
                        <div
                            className="col-date">
                            {fmt.dateShort(order.createdDate)}
                        </div>
                        <div
                            className="col-code">
                            {order.reference}
                        </div>
                        <div
                            className="col-amount" >
                            {fmt.currencySymbol(order.dstCurrency) + order.dstAmount.toFixed(2)}
                        </div>
                        <div
                            className="col-cost">
                            {fmt.currencySymbol(order.srcCurrency) + order.srcAmountIncludeFee.toFixed(2)}
                        </div>
                        <div>
                            {lang.cashrailFlow(order?.orderType)}
                        </div>
                        <div
                            className="col-paid">
                            <input readOnly style={{ backgroundColor: "#517ca5" }} type="checkbox" checked={order?.paymentStatus == PaymentStatus.paid} />
                        </div>
                        <div
                            className="col-settled">
                            <input readOnly style={{ backgroundColor: "#517ca5" }} type="checkbox" checked={order?.settled} />
                        </div>
                    </li>
                )}
                {
                    list.loading ?
                        <li className="list-loading">
                            {t("loadingData")}
                        </li>
                        :
                        list.filteredRequests?.length < 1 ?
                            <li className="list-noresults">
                                {
                                    viewModel.search ?
                                        <>{t("cashrail.noSearchResults")}</>
                                        :
                                        <>{t("cashrail.noResults")}</>
                                }
                            </li>
                            :
                            <div />
                }
            </ul>
        </div>
    }
}
export default withRouter(CashrailListOrders);