﻿//Utility for running mutually exclusive asynchronous tasks
export class Mutex {
    private _locked = false;
    
    get locked() {
        return this._locked;
    }
    
    unlock(): boolean {
        if(!this._locked)
            return false;
        this._locked = false;
        return true;
    }
    
    lock(): boolean {
        if(this._locked)
            return false;
        this._locked = true;
        return true;
    } 
    
    //Execute an async delegate (i.e promise returning) only if the mutex is unlocked
    //Returns true if the delegate was executed, false otherwise
    async auto<T>(action: () => Promise<T>): Promise<boolean> {
        if(this._locked)
            return false;

        this._locked = true;
        try {
            await action();
        } finally {
            this._locked = false;
        }
        return true;
    }
}
