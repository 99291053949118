﻿import {autorun, IReactionDisposer} from "mobx";
import React, {Component, ComponentType} from "react";
import {RouteComponentProps} from "react-router";
import {Redirect, Route} from "react-router-dom";
import {AuthenticationManager} from "../managers/authenticationManager";

interface AuthenticatedRouteProps {
    exact?: boolean;
    path: string;
    component?: React.ComponentType<RouteComponentProps<any>> | ComponentType<any>;
}

export class AuthenticatedRoute extends Component<AuthenticatedRouteProps> {
    private autorun: IReactionDisposer = null;

    state = {
        authenticated: AuthenticationManager.instance.authenticated
    }

    componentDidMount() {
        this.autorun = autorun(() => {
            this.setState({authenticated: AuthenticationManager.instance.authenticated});
        });
    }

    componentWillUnmount() {
        if(this.autorun != null)
            this.autorun();
    }
    
    render() {
        const props = {...this.props};
        const authed = this.state.authenticated;
        const Component = props.component;
        delete props.component;

        return <Route
            {...props}
            render={(injectedProps) => authed === true
                ? <Component {...injectedProps}/>
                : <Redirect to={{pathname: '/login', state: {from: injectedProps.location}}} />}
        />
    }
}

export class UnauthenticatedRoute extends Component<AuthenticatedRouteProps> {
    private autorun: IReactionDisposer = null;
    
    state = {
        authenticated: AuthenticationManager.instance.authenticated
    }
    
    componentDidMount() {
        this.autorun = autorun(() => {
            this.setState({authenticated: AuthenticationManager.instance.authenticated});
        })
    }
    
    componentWillUnmount() {
        if(this.autorun != null)
            this.autorun();
    }

    render() {
        const props = {...this.props};
        const authed = this.state.authenticated;
        const Component = props.component;
        delete props.component;

        return <Route
            {...props}
            render={(injectedProps) => authed === true
                ? <Redirect to={{pathname: '/dashboard', state: {from: injectedProps.location}}} />
                : <Component {...injectedProps}/>}
        />
    }
}