import { t } from 'i18next'
import { observer } from 'mobx-react'
import React, { useMemo } from 'react'
import { IndicatorButton } from '../../components/indicatorButton'
import { PageHeader } from '../../components/pageHeader'
import { SettlementDetailsViewModel } from '../../viewModels/settlement/settlementDetailsViewModel'
import useQuery from '../../helpers/useQuery'
import { lang } from '../../utils/lang'
import { Kuva } from '../../interfaces/kuvaweb'
import * as fmt from '../../utils/formatting'

import PaymentType = Kuva.Module.Enums.AgentSettlementPaymentType
import { useHistory, withRouter } from 'react-router-dom'
import { Icon } from '../../components/icons'
import { AuthenticationManager } from '../../managers/authenticationManager'
import { ISOConverter } from '../../utils/isoConverter'

const orderType = Kuva.Module.Enums.OrderType
const flowType = Kuva.Module.Enums.SettlementFlowType

const SettlementDetailsPage: React.FC = observer(() => {
  let query = useQuery()
  const history = useHistory()

  let requestId = query.get('id')
  let settlementId = parseInt(requestId)
  let bankType = PaymentType.Bank
  const settlementManifestStatus = Kuva.Module.Enums.AgentSettlemenManifestStatus

  const agent = AuthenticationManager.instance.agent
  // const agent = {canViewSettlementDetailsFields: false}

  const handleRedirect = () => {
    history.push('/settlements')
  }

  const settlementViewModel = useMemo(
    () => new SettlementDetailsViewModel(settlementId),
    [settlementId]
  )

  const settlement = settlementViewModel.request

  return (
    <>
      {settlement != null ? (
        <div className="page-settlement-details">
          <PageHeader>
            <span className="button-form">
              <span onClick={handleRedirect} className="redirect-back-mobile">
                <Icon.MobileBackArrow />
              </span>
              <button
                onClick={handleRedirect}
                className="btn-default redirect-back redirect-back-desktop"
              >
                {t('settlement.redirectBack')}
              </button>
              <span className="page-header-title">
                {settlement.flowType !== flowType.other ? (
                  <div className="settlement-flow-title">
                    <span>
                      {lang.settlementDetailsFlowType(settlement.flowType)}
                      {settlement.id} - {lang.settlementStatus(settlement.status)}
                    </span>
                  </div>
                ) : (
                  <>
                    {t('settlement.detailTitle')} {settlement.id} -{' '}
                    {lang.settlementStatus(settlement.status)}
                  </>
                )}
              </span>
            </span>
            <span className="download-btns">
              <IndicatorButton
                onCommand={() => settlementViewModel.getPdf()}
                className="btn-default btn-primary btn-pdf"
              >
                {t('settlement.button.getPdf')}
              </IndicatorButton>
              <IndicatorButton
                onCommand={() => settlementViewModel.getCsv()}
                className="btn-default btn-primary btn-pdf"
              >
                {t('settlement.button.getCsv')}
              </IndicatorButton>
            </span>
          </PageHeader>

          <div className="container">
            <div className="mobile-settl-block">
              <IndicatorButton
                onCommand={() => settlementViewModel.getPdf()}
                className="btn-default btn-primary btn-pdf"
              >
                {t('settlement.button.getPdf')}
              </IndicatorButton>
              <IndicatorButton
                onCommand={() => settlementViewModel.getCsv()}
                className="btn-default btn-primary btn-pdf "
              >
                {t('settlement.button.getCsv')}
              </IndicatorButton>
            </div>
            <div className="dashboard-page-panel">
              <ul
                className={
                  agent?.canViewSettlementDetailsFields
                    ? 'list list-col14 list-settlement'
                    : 'list list-col7 list-settlement'
                }
              >
                <li className="list-header">
                  <div className="col-orderType">{t('settlement.detailsTable.orderType')}</div>
                  <div className="col-orderStatus">{t('settlement.detailsTable.orderStatus')}</div>
                  {/* <div className="col-order-number-val">{t("settlement.detailsTable.orderNumber")}</div> */}
                  <div className="col-ordered">{t('settlement.detailsTable.ordered')}</div>
                  <div className="col-digitalRef">{t('settlement.detailsTable.digitalRef')}</div>
                  {agent?.canViewSettlementDetailsFields && (
                    <>
                      <div className="col-cost">{t('settlement.detailsTable.cost')}</div>
                      <div className="col-costgbp">{t('settlement.detailsTable.costGBP')}</div>
                      <div className="col-agentfee">{t('settlement.detailsTable.agentFee')}</div>
                      <div className="col-kuvafee">{t('settlement.detailsTable.kuvaFee')}</div>
                      {/* <div className="col-tokuva">{t("settlement.detailsTable.toKuva")}</div>
                      <div className="col-toagent">{t("settlement.detailsTable.toAgent")}</div> */}
                    </>
                  )}
                  <div className="col-order-number-val">
                    {t('settlement.detailsTable.orderNumber')}
                  </div>
                  <div className="col-cashoutagent">
                    {t('settlement.detailsTable.cashoutAgent')}
                  </div>
                  <div className="col-timebooking">{t('settlement.detailsTable.timeBooking')}</div>
                  <div className="col-timecashout">{t('settlement.detailsTable.timeCashout')}</div>
                  {agent?.canViewSettlementDetailsFields && (
                    <>
                      <div className="col-tokuva">{t('settlement.detailsTable.toKuva')}</div>
                      <div className="col-toagent">{t('settlement.detailsTable.toAgent')}</div>
                    </>
                  )}
                </li>
                {settlement.items.map(item => (
                  <li key={item.entityId} className="settlement-row">
                    <div className="col-orderType">{lang.settlementOrderType(item.orderType)}</div>
                    <div className="col-orderStatus">{item.orderStatus}</div>
                    {/* <div className="col-order-number-val">{item.reference}</div> */}
                    {settlement.currency === 'USD' ? (
                      <div className="col-ordered">${item.ordered.toFixed(2)}</div>
                    ) : (
                      <div className="col-ordered">
                        {item.ordered.toFixed(2) + ' ' + ISOConverter(settlement.currency)}
                      </div>
                    )}
                    <div className="col-digitalRef">
                      {item.digitalReference ? item.digitalReference : ''}
                    </div>
                    {agent?.canViewSettlementDetailsFields && (
                      <>
                        {settlement.currency === 'USD' ? (
                          <div className="col-cost">${item.cost.toFixed(2)}</div>
                        ) : item.orderType === orderType.KuvaWhileLabel ? (
                          <div className="col-cost">$ {item.cost.toFixed(2)}</div>
                        ) : item.orderType === orderType.Cashout ? (
                          <div className="col-cost">
                            {`-${item.cost.toFixed(2)} ${ISOConverter(settlement.currency)}`}
                          </div>
                        ) : (
                          <div className="col-cost">
                            {`${item.cost.toFixed(2)} ${ISOConverter(settlement.currency)}`}
                          </div>
                        )}
                        {settlement.paymentType != bankType['Bank'] ? (
                          <div className="col-costgbp">
                            {item.fromAmount != null ? `£${item.fromAmount}` : '-'}
                          </div>
                        ) : null}
                        {settlement.currency === 'USD' ? (
                          <div className="col-agentfee">${item.agentFee.toFixed(2)}</div>
                        ) : (
                          <div className="col-agentfee">
                            {item.agentFee.toFixed(2) + ' ' + ISOConverter(settlement.currency)}
                          </div>
                        )}
                        {settlement.currency === 'USD' ? (
                          <div className="col-kuvafee">
                            -${item.kuvaFee.toFixed(2)}
                            <span className="price-danger"></span>
                          </div>
                        ) : (
                          <div className="col-kuvafee">
                            -{item.kuvaFee.toFixed(2) + ' ' + ISOConverter(settlement.currency)}
                            <span className="price-danger"></span>
                          </div>
                        )}
                        {/* {
                          item.kuvaPayment === 0
                            ?
                            settlement.currency === "USD"
                              ?
                              <div className="col-tokuva">${item.kuvaPayment.toFixed(2)}<span className="price-danger"></span></div>
                              :
                              <div className="col-tokuva">{item.kuvaPayment.toFixed(2) + " " + settlement.currency}<span className="price-danger"></span></div>
                            :
                            settlement.currency === "USD"
                              ?
                              <div className="col-tokuva">-${item.kuvaPayment.toFixed(2)}<span className="price-danger"></span></div>
                              :
                              <div className="col-tokuva">-{item.kuvaPayment.toFixed(2) + " " + settlement.currency}<span className="price-danger"></span></div>
                        }
                        {
                          settlement.currency === "USD"
                            ?
                            <div className="col-toagent">${item.agentPayment.toFixed(2)}</div>
                            :
                            <div className="col-toagent">{item.agentPayment.toFixed(2) + " " + settlement.currency}</div>
                        } */}
                      </>
                    )}
                    <div className="col-order-number-val">{item.reference}</div>
                    <div className="col-cashoutagent">{item.agentThatCashedOut ?? '-'}</div>
                    <div className="col-timebooking">
                      {item.timeOfBooking != null ? fmt.dateTimeShort(item.timeOfBooking) : '-'}
                    </div>
                    <div className="col-timecashout">
                      {item.timeOfCashout != null ? fmt.dateTimeShort(item.timeOfCashout) : '-'}
                    </div>
                    {agent?.canViewSettlementDetailsFields && (
                      <>
                        {item.kuvaPayment === 0 ? (
                          settlement.currency === 'USD' ? (
                            <div className="col-tokuva">
                              ${item.kuvaPayment.toFixed(2)}
                              <span className="price-danger"></span>
                            </div>
                          ) : (
                            <div className="col-tokuva">
                              {item.kuvaPayment.toFixed(2) +
                                ' ' +
                                ISOConverter(settlement.currency)}
                              <span className="price-danger"></span>
                            </div>
                          )
                        ) : settlement.currency === 'USD' ? (
                          <div className="col-tokuva">
                            -${item.kuvaPayment.toFixed(2)}
                            <span className="price-danger"></span>
                          </div>
                        ) : (
                          <div className="col-tokuva">
                            -{item.kuvaPayment.toFixed(2) + ' ' + ISOConverter(settlement.currency)}
                            <span className="price-danger"></span>
                          </div>
                        )}
                        {settlement.currency === 'USD' ? (
                          <div className="col-toagent">${item.agentPayment.toFixed(2)}</div>
                        ) : (
                          <div className="col-toagent">
                            {item.agentPayment.toFixed(2) + ' ' + ISOConverter(settlement.currency)}
                          </div>
                        )}
                      </>
                    )}
                  </li>
                ))}
              </ul>
              <div className="list-settlement-info">
                <span>{t('settlement.total')}</span>
                <div>
                  {settlement.totalKuvaPayment !== 0 &&
                    (settlement.currency === 'USD' ? (
                      <div className="total-val">
                        -${settlement.totalKuvaPayment.toFixed(2)}
                        <span className="price-danger"></span>
                      </div>
                    ) : (
                      <div className="total-val">
                        -
                        {settlement.totalKuvaPayment.toFixed(2) +
                          ' ' +
                          ISOConverter(settlement.currency)}
                        <span className="price-danger"></span>
                      </div>
                    ))}
                </div>
                <div className="field-total">
                  {settlement.currency === 'USD'
                    ? '$' + ' ' + settlement.totalAgentPayment.toFixed(2)
                    : settlement.totalAgentPayment.toFixed(2) +
                      ' ' +
                      ISOConverter(settlement.currency)}
                </div>
              </div>
            </div>
            <div className="order-details order-details-right ">
              <div>
                <strong>{t('settlement.orderInfo.direction')}</strong>{' '}
                {lang.settlementDirection(settlement.direction)}
              </div>
              <div>
                <strong>{t('settlement.orderInfo.createdBy')}</strong> {settlement.generatedByEmail}
              </div>
              <div>
                <strong>{t('settlement.orderInfo.createdDate')}</strong>{' '}
                {fmt.dateTimeShort(settlement.createdDate)}
              </div>
              {settlement.notes ? (
                <div>
                  <strong>{t('settlement.orderInfo.notes')}</strong> {settlement.notes}
                </div>
              ) : (
                <div>
                  <strong>{t('settlement.orderInfo.notes')} </strong> -
                </div>
              )}
              {settlement.status === settlementManifestStatus.Completed ||
              settlement.status === settlementManifestStatus.PayoutFailed ? (
                <div>
                  <strong>{t('settlement.orderInfo.completedDate')}</strong>{' '}
                  {fmt.dateTimeShort(settlement.updatedDate)}
                </div>
              ) : (
                <div></div>
              )}
              {settlement.paymentType === bankType ? (
                <div>
                  <strong>{t('settlement.orderInfo.transDetails')} </strong>
                  {settlement.transactionDetails}
                </div>
              ) : (
                <div></div>
              )}
              {settlement.destinationUser?.phone != null ? (
                <div>
                  <strong>{t('settlement.orderInfo.destAccInfo')} </strong>{' '}
                  {fmt.phoneNumber(settlement.destinationUser?.phone)}
                </div>
              ) : (
                <div>
                  <strong>{t('settlement.orderInfo.destAccInfo')} </strong> -
                </div>
              )}
              {settlement.treasuryTransfer?.id > 0 ? (
                <>
                  <div>
                    <strong>{t('settlement.orderInfo.treasuryTransferId')}</strong>{' '}
                    {settlement.treasuryTransfer?.id}
                  </div>
                  <div>
                    <strong>{t('settlement.orderInfo.treasuryTransferStatus')}</strong>{' '}
                    {settlement.treasuryTransfer.treasuryTransferStatus}
                  </div>
                </>
              ) : (
                ''
              )}
              <div>
                <strong>{t('settlement.orderInfo.paymentMethod')}</strong>{' '}
                {lang.settlementPaymentType(settlement.paymentType)}
              </div>
              <div>
                <strong>{t('settlement.orderInfo.paymentCurrency')}</strong>{' '}
                {ISOConverter(settlement.currency)}
              </div>
              <div>
                <strong>{t('settlement.orderInfo.status')}</strong>{' '}
                <span>{lang.settlementStatus(settlement.status)}</span>
              </div>
            </div>
          </div>
        </div>
      ) : settlementViewModel.loadIng !== false ? (
        <div className="page-settlement-details container">
          <PageHeader>
            <IndicatorButton onClick={handleRedirect} className={'btn-outline-primary btn-lg'}>
              {t('settlement.redirectBack')}
            </IndicatorButton>
          </PageHeader>
          <div className="loading">
            <div className="spinner-border text-primary spin" role="status"></div>
          </div>
        </div>
      ) : (
        <div className="page-settlement-details container">
          <PageHeader>
            <IndicatorButton onClick={handleRedirect} className={'btn-outline-primary btn-lg'}>
              {t('settlement.redirectBack')}
            </IndicatorButton>
          </PageHeader>
          <div className="loading">
            <div className="not-found">{t('settlement.notFound')}</div>
          </div>
        </div>
      )}
    </>
  )
})
export default withRouter(SettlementDetailsPage)
