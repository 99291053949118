import { t } from "i18next";
import { observer } from "mobx-react";
import React, { useEffect, useMemo } from "react";
import { PageHeader } from "../../../components/pageHeader";
import { MultiCashoutsViewModel } from "../../../viewModels/multicash/cashouts/multicashCashoutsViewModel";
import { MultiCashoutDetailsViewModel } from "../../../viewModels/multicash/cashouts/multicashDetailsViewModel";
import MultiCashoutDetailsDialog from "./multiCashoutDetailsDialog";
import MultiCashoutsList from "./multiCashoutList";


const MultiCashoutsPage: React.FC = observer(() => {
    const listViewModel = useMemo(() => new MultiCashoutsViewModel(), []);

    useEffect(() => {
        listViewModel.mount()

        return () => {
            listViewModel.unmount()
        }
    }, [listViewModel.mount, listViewModel.unmount])

    return <div className="page-cashout">
        <PageHeader>
            {t("multicash.multiCashoutsTitle")}
        </PageHeader>
        <div
            className="container">
            {listViewModel.details?.request?.data !== undefined && <MultiCashoutDetailsDialog detailsViewModel={listViewModel.details} listViewModel={listViewModel} />}
            <MultiCashoutsList viewModel={listViewModel} />
        </div>
    </div>
})
export default MultiCashoutsPage;