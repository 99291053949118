import { action, observable, runInAction } from "mobx";
import { SettlementApiClient } from "../../api/agent/settlementApiClient";
import { Kuva } from "../../interfaces/kuvaweb";
import { BookedSettlementsCommon, SettlementsCommon } from "../settlementCommon";

var fileDownload = require('js-file-download');

type AgentSettlementsResponseModel = Kuva.PL.KuvaWeb.Areas.Agent.Models.AgentSettlementResponseModel;
type AgentSettlementFilter = Kuva.Module.Models.Paging.Agent.AgentSettlementFilter;

class BookedSettlementsViewModel extends BookedSettlementsCommon {
  private apiClient = new SettlementApiClient();

  constructor() {
    super();
    this.load();
  }

  protected async getRequests(filter: AgentSettlementFilter) {
    const { startDate, endDate, statuses: status, paymentTypes: paymentType, flowTypes, query } = this.fillSettlementFilter(filter);

    return await this.apiClient.getAgentSettlements({
      query,
      flowTypes,
      pageSize: filter.pageSize,
      offset: filter.offset,
      statuses: status,
      endDate,
      startDate,
      paymentTypes: paymentType
    });
  }

  private fillSettlementFilter(filter: AgentSettlementFilter): AgentSettlementFilter {
    if (filter.startDate === "" || filter.startDate === null) {
      filter.startDate = ""
    }
    if (filter.endDate === "" || filter.endDate === null) {
      filter.endDate = ""
    }
    if (filter.paymentTypes === undefined || filter.paymentTypes.toString() === "100") {
      filter.paymentTypes = ""
    }
    if (filter.statuses === undefined || filter.statuses.toString() === "100") {
      filter.statuses = ""
    }
    if (filter.flowTypes === undefined || filter.flowTypes.toString() === "100") {
      filter.flowTypes = ""
    }
    if (filter.query === null || filter.query === undefined) {
      filter.query = ""
    }
    return filter
  }
}

export class SettlementsViewModel extends SettlementsCommon<AgentSettlementsResponseModel>{
  api = new SettlementApiClient();

  @observable downLoading: boolean = false;

  constructor() {
    super();

    this.settlements = new BookedSettlementsViewModel();
    this.updateTab();
  }

  @action
  async generatePDF() {
    runInAction(() => {
      this.downLoading = true;
    })
    const fileResponse = await this.api.getAgentsSettlementsPDF()
    const file = await fileResponse.blob()
    const header = fileResponse.headers.get("Content-Disposition")
    const fileName = header.match(/filename=(.*)\;/)[1]
    
    await fileDownload(file, fileName);
    runInAction(() => {
      this.downLoading = false;
    })
  }
}