import { action, autorun, IReactionDisposer, makeObservable, observable } from "mobx";
import { Subscription } from "rxjs";
import { Kuva } from "../interfaces/kuvaweb";
import { lang } from "../utils/lang";
import { IPromiseViewModel } from "./promiseViewModel";
import { ViewModelBase } from "./viewModelBase";
import * as fmt from '../utils/formatting';

type AgentCashOnDeliveryRequestModel = Kuva.PL.KuvaWeb.Areas.Agent.Models.AgentCashOnDeliveryRequestModel;
interface ICashOnDeliveryRequestList<AgentCashOnDeliveryRequestModel> extends ViewModelBase {
    loading: boolean;
    requestCount: number;
    filteredRequests: AgentCashOnDeliveryRequestModel[];
    setSearch(search: string): void;
}

export abstract class BookedCashOnDeliveryRequestsViewModel extends ViewModelBase implements ICashOnDeliveryRequestList<AgentCashOnDeliveryRequestModel>{
    protected requests: AgentCashOnDeliveryRequestModel[] = [];
    protected search: string = "";

    @observable filteredRequests: AgentCashOnDeliveryRequestModel[] = [];
    @observable requestCount: number = 0;
    @observable loading: boolean = false;

    constructor() {
        super();
        makeObservable(this);
    }

    *subscribe(): Generator<Subscription | IReactionDisposer> {
        yield autorun(() => {
            this.updateFilter(this.search);
        });
    }

    @action
    handleRequests(request: AgentCashOnDeliveryRequestModel[]) {
        if (request == null) {
            this.loading = true;
            return
        }
        this.requestCount = request.length;
        this.requests = request.sort(applySort());
        this.updateFilter(this.search);
        this.loading = false;
    }

    @action
    updateFilter(search: string) {
        if (this.requests == null)
            return;
        this.filteredRequests = this.requests.filter(applyFilter(search)).sort(applySort());
    }

    @action
    setSearch(search: string): void {
        this.search = search;
        this.updateFilter(this.search);
    }
}

export abstract class PastCashOnDeliveryRequestsViewModel extends ViewModelBase implements ICashOnDeliveryRequestList<AgentCashOnDeliveryRequestModel>{
    protected requests: AgentCashOnDeliveryRequestModel[] = [];
    protected search: string = "";

    @observable filteredRequests: AgentCashOnDeliveryRequestModel[] = [];
    @observable requestCount: number = 0;
    @observable loading: boolean = false;

    constructor() {
        super();
        makeObservable(this);
    }

    *subscribe(): Generator<Subscription | IReactionDisposer> {
        yield autorun(() => {
            this.updateFilter();
        })
    }

    @action
    handleRequests(requests: AgentCashOnDeliveryRequestModel[]) {
        if (requests == null) {
            this.loading = true;
            return;
        }
        this.requestCount = requests.length;
        this.requests = requests.sort(applySort());
        this.loading = false;
    }

    @action
    private updateFilter() {
        if (this.requests == null)
            return;
        this.filteredRequests = this.requests.filter(applyFilter(this.search));
    }

    @action
    setSearch(search: string): void {
        this.search = search;
        this.updateFilter();
    }
}

export abstract class CashOnDeliveryRequestsViewModel extends ViewModelBase {
    booked: ICashOnDeliveryRequestList<AgentCashOnDeliveryRequestModel> = null;
    past: ICashOnDeliveryRequestList<AgentCashOnDeliveryRequestModel> = null;

    @observable current: ICashOnDeliveryRequestList<AgentCashOnDeliveryRequestModel> = null;

    @observable details: IPromiseViewModel<void> = null;

    @observable search: string = "";

    @observable tab: "past" | "booked" = "booked";

    @observable reference: string = "";
    @observable valid: boolean = null;
    @observable loading: boolean = false;

    protected constructor() {
        super();

        this.updateTab();
        makeObservable(this);
    }

    mount() {
        this.booked.mount();
        this.past.mount();
        super.mount();
    }

    unmount() {
        this.booked.unmount();
        this.past.unmount();
        super.unmount();
    }

    *subscribe(): Generator<Subscription | IReactionDisposer> {
        yield autorun(() => {
            this.updateTab();
        })
    }

    @action changeTab = (tab: 'booked' | 'past') => {
        this.tab = tab;
        this.updateTab();
    }

    abstract select(request: AgentCashOnDeliveryRequestModel, isCompleted: boolean): void;

    protected updateTab() {
        this.current = this.tab == "booked" ? this.booked : this.past;
        this.current?.setSearch(this.search);
    }
}

type SearchFunction = (order: AgentCashOnDeliveryRequestModel) => string

const searchableFields: (keyof AgentCashOnDeliveryRequestModel | SearchFunction)[] = [
    order => lang.cashOnDeliveryPay(order.isPaid),
    'orderAmount',
    order => fmt.phoneNumber(order.phone),
    order => fmt.dateTimeShort(order.date),
    "referenceNumber",
    order => lang.cashOnDeliveryStatus(order.status)
]

function applyFilter(query: string): (a: AgentCashOnDeliveryRequestModel) => boolean {
    if (query === null || query.length < 1)
        return () => true;
    query = query.trim().toLocaleLowerCase();

    return (a) => {
        return searchableFields.some(field => {
            let value = ''
            if (typeof field === 'function') {
                value = field(a)
            } else {
                value = a[field].toString()
            }

            return value.toLocaleLowerCase().startsWith(query)
        })
    }
}

function applySort(): (a: AgentCashOnDeliveryRequestModel, b: AgentCashOnDeliveryRequestModel) => number {
    return (a, b) => {
        return new Date(b.date).getTime() - new Date(a.date).getTime();
    }
}