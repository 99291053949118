﻿import { Kuva } from "../interfaces/kuvaweb";
import { ApiClientBase } from "./apiClientBase";

type LoginInfoModel = Kuva.PL.KuvaWeb.Areas.Agent.Models.Account.LoginInfoModel;
type EnableTwoFactorRequestModel = Kuva.PL.KuvaWeb.Areas.Agent.Models.Account.EnableTwoFactorRequestModel;
type AuthApiClientInterface = Kuva.PL.KuvaWeb.Areas.Agent.Controllers.Api.AuthApiClientInterface;
type LoginResponseModel = Kuva.PL.KuvaWeb.Areas.Agent.Models.Account.LoginResponseModel;
type LoginRequestModel = Kuva.PL.KuvaWeb.Areas.Agent.Models.Account.LoginRequestModel;
type RecoveryPasswordModel = Kuva.PL.KuvaWeb.Areas.Agent.Models.RecoveryPasswordModel;
type CustomResponse = Kuva.PL.KuvaWeb.API.CustomResponse;

export class AuthApiClient extends ApiClientBase implements AuthApiClientInterface {
    base = "auth";

    login = (request: LoginRequestModel) => this.postNewUrl<LoginRequestModel, LoginResponseModel>([this.base, 'login'], request);
    logout = () => this.postNewUrl<void, void>([this.base, 'logout']);
    enableTwoFactor = (request: EnableTwoFactorRequestModel) => this.postNewUrl<EnableTwoFactorRequestModel, boolean>([this.base, 'enable-two-factor'], request);
    getCurrentLogin = () => this.postNewUrl<void, LoginInfoModel>([this.base, 'current-login'], null);
    forgot = (email: string) => this.postNewUrl<Record<string, string>, void>([this.base, 'forgot-password'], { email: email });
    recoveryPassword = (credentials: RecoveryPasswordModel) => this.postNewUrl<RecoveryPasswordModel, void>([this.base, 'recover'], credentials);
}